@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.employee-list {
  height: calc(100% - 178px);
  margin: 10px 20px 0px 20px;
  &__modal {
    height: calc(100% - 35px);
    margin: 10px 0px 0px 0px;
  }
  &__details {
    column-gap: 10px;
    &--modal {
      column-gap: 10px;
      margin-left: 0px;
      margin-top: 10px;
      #search {
        margin-top: 0px;
      }
    }
    &--addnew {
      display: flex !important;
      justify-content: flex-end !important;
      margin-left: auto !important;
      margin-right: 0px !important;
      margin-top: 0px !important;
    }
    #search {
      margin-top: 0px;
    }
  }
  &__tableblock {
    height: calc(100% - 85px);
    margin-top: 10px;
    &--table {
      @include table();
      td {
        height: 30px;
      }
      tr:hover {
        .employee-list__tableblock--table--name {
          @include tdContainsOnclick();
        }
      }
      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-slno {
        width: calc(100% - 96%);
      }
      &--th-date {
        width: calc(100% - 92%);
      }
      &--date {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-number {
        width: calc(100% - 96%);
      }
      &--number {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }
      &--desc {
        width: calc(100% - 88%);
      }
      &--actions {
        width: calc(100% - 94%);
      }
    }
  }
}
