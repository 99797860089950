@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.feedback-preview {
    height: calc(100% - 160px);
    margin: 0px 20px;
    &__flex {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        &--header {
            width: fit-content;
            background-color: colors.get-color(colors.$cool_grey, level_2);
            padding: 10px;
            font-size: 16px;
            font-family: fonts.$eduate-font-family;
            display: flex;
            border-radius: 6px;

            &--acd {
                font-family: fonts.$eduate-font-family;
                font-size: 14px;
                background-color: var(--level-4);
                color: white;
                font-weight: 600;
                padding: 2px 10px;
                border-radius: 2px;
                width: fit-content;
            }
        }
        &--sub {
            display: flex;
            font-family: fonts.$eduate-font-family;
            font-size: 12px;
            align-items: center;
            padding: 10px;
            background-color: var(--level-1);
            column-gap: 10px;
            border-radius: 6px;

            b {
                color: var(--level-5);
                font-size: 16px;
            }
            span {
                color: colors.get-color(colors.$cool_grey, level_6);
                background-color: colors.get-color(colors.$cool_grey, level_2);
                padding: 2px;
                border-radius: 2px;
            }
        }
        &--dates {
            display: flex;
            column-gap: 5px;
            align-items: center;
            img {
                width: 25px;
            }
        }
        &--textfield {
            @include textfield();
            width: fit-content !important;
        }
    }
    &__datablock {
        margin-top: 10px;
        height: calc(100% - 118px);
        border: 1px solid var(--border-color);
        border-radius: 6px;
        padding: 10px;
        &--list {
            height: calc(100% - 85px);
            margin-top: 20px;
            overflow: auto;
            display: flex;
            &--left{
                border-right: 1px dashed var(--border-color);
            padding-right: 10px;
            width: calc(100% - 100px);
            }

            &--edit {
                width: 100px;
                display: flex;
                align-items: center;


                img{
                    width:30px;
                    height:30px;
                    display: block;
                    margin: auto;
                }
            }
            ul {
                margin-bottom: 0px;
                width: 100%;
             
            }
            li {
                border: 1px solid var(--border-color);
                border-radius: 6px;
                padding: 10px;
                margin-top: 10px;
                display: flex;

            }
            &--ques {
                font-size: 16px;
                font-family: fonts.$eduate-font-family;
                font-weight: 700;
            }
            &--flex {
                display: flex;
                column-gap: 15px;
                margin-top: 15px;
                label {
                    font-size: 16px;
                    font-family: fonts.$eduate-font-family;
                    color: colors.get-color(colors.$cool_grey, level_5);
                }
                &--remark {
                    width: 100%;
                    background-color: var(--disabled-color);
                    font-size: 14px;
                    font-family: fonts.$eduate-font-family;
                    padding: 2px 5px;
                }
                b {
                    background-color: var(--level-1);
                    color: var(--level-5);
                    font-weight: 700;
                    font-family: fonts.$eduate-font-family;
                    padding: 2px 5px;
                }
            }
        }
        &--emp {
            width: 200px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: flex;
            column-gap: 10px;
            align-items: center;
            padding: 5px;
            border: 1px solid var(--border-color);
            border-radius: 6px;
            height: 60px;

            box-shadow:
                0px 10px 15px -3px rgba(0, 0, 0, 0.1),
                0px 4px 6px -2px rgba(0, 0, 0, 0.05);
            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }

            &--block {
                display: block;
            }
            &--name {
                font-weight: 700;
                font-family: fonts.$eduate-font-family;
                font-size: 14px;
                color: var(--text-color);
            }
            &--id {
                color: colors.get-color(colors.$cool_grey, level_6);
                font-size: 14px;
                display: block;
            }
        }
    }
}

.feedback-preview__datablock--list::-webkit-scrollbar {
    @include scrollbar();
}

.feedback-preview__datablock--list::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.feedback-preview__datablock--list::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}

.feedback-edit{
    height: 100%;
    &__title{
        display: flex;
        justify-content: space-between;
    }
    &__data{
        height: calc(100% - 95px);
        &--span{
            color:colors.get-color(colors.$cool_grey, level_5);
            font-size: 14px;
        }
        &--remarks{
            height:calc(100% - 115px) ;
            .storybook-textarea{
                height: 100% !important;
            }
        }
    }
}