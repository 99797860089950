@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.academic-dept-list {
  margin: 10px 20px 10px 20px;
  height: calc(100% - 10px);
  &__table {
    height: 100%;

    &--data {
      @include table();
      border-radius: 15px 15px 0px 0px !important;

      height: 100%;
      tr:hover .institution__table--name {
        @include tdContainsOnclick();
      }
      &--list {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }
      &--total {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }
      &--dropout {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-list {
        width: calc(100% - 90%);
      }
      &--th-total {
        width: calc(100% - 90%);
      }
      &--th-dropout {
        width: calc(100% - 90%);
      }
    }
  }
}

.academics-table-logo {
  background: white;
  border-radius: 30px;
  padding: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
  filter: invert(0%);
}
.academic-dept-list__table--data::-webkit-scrollbar {
  @include scrollbar();
}

.academic-dept-list__table--data::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.academic-dept-list__table--data::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
//complete list

.academics-all-studentlist {
  height: calc(100% - 240px);
  margin: 10px 20px 0px 20px;

  &__table {
    @include table();
    border-radius: 15px 15px 0px 0px;
    &--slno {
      width: calc(100% - 95%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-slno {
      width: calc(100% - 95%);
    }
    &--th-admno {
      width: calc(100% - 88%);
    }
    &--admno {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
      img {
        width: 30px;
        margin: 5px;
      }
    }
    &--regno {
      width: calc(100% - 93%);
      font-family: fonts.$eduate-number-font;
    }
    &--date {
      width: calc(100% - 95%);
      font-family: fonts.$eduate-number-font;
    }
    &--dropout {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-regno {
      width: calc(100% - 93%);
    }
    &--th-date {
      width: calc(100% - 95%);
    }
    &--th-dropout {
      width: calc(100% - 96%);
    }
  }
}

.academics-all-studentlist__table::-webkit-scrollbar {
  @include scrollbar();
}

.academics-all-studentlist__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.academics-all-studentlist__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
