.add-doc-type {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  &--textfield {
    width: 250px;
  }
  &--btns {
    width: fit-content;
  }
}
