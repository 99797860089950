@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.assign-route-for-multiple {
 @include mainFrameWithTitle();
  &__options {
    gap: 10px;
  }
  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 85px);
  }
  &__table {
    @include table();
    @include tableCheckbox();
    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--admno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }
    &--status {
      width: calc(100% - 90%);
    }
  }
}
