
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.demand-fee {
  margin: 10px 20px 0px 20px;
  column-gap: 20px;
  height: 120px;

  &--modal {
    margin: 10px 0px 0px 0px;
    column-gap: 20px;
    height: 120px;
  }

  &__grid {
    display: flex;

    .MuiAutocomplete-root {
      margin: 5px 0px 3px 0px;
    }
  }

  &__image {
    height: 100%;
    @include studentProfile();
  }

  &__textfield {
    @include textfield();
    margin: 5px 0px 3px 0px !important;

    &--date {
      @include textfield();
      margin: 5px 0px 3px 0px !important;
      width: fit-content;
    }
  }

  &__table {
    @include Accountstable();

    &--slno {
      width: calc(100% - 94%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-slno {
      width: calc(100% - 94%);
    }

    &--desc {
      width: cal(100% - 40%);
    }

    &--amount {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-amount {
      width: calc(100% - 92%);
    }

    &--actions {
      width: calc(100% - 96%);
    }
  }

  &__tableblock {
    height: calc(100% - 350px);
    margin: 10px 20px 0px 20px;

    &--modal {
      height: calc(100% - 218px);
      margin-top: 10px;
    }
  }

  &__select {
    margin-top: 10px;
  }

  &__buttons {
    margin-left: 20px;
  }

  &__input {
    background-color: colors.get-color(colors.$yellow, level_2);
  }
}

.demand-fee__table::-webkit-scrollbar {
  @include scrollbar();
}

.demand-fee__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.demand-fee__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}