@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.std-detailed-report {
  @include mainFrameWithTitle();

  &__options {
    column-gap: 10px;
    margin-top: 10px;

    #search {
      margin-top: 0px !important;
    }

    .studentlist__select-columns {
      margin-right: 0px !important;
    }
  }

  &__datablock {
    height: calc(100% - 55px);
    margin-top: 10px;
    display: flex;
    column-gap: 20px;

    &--left {
      width: 250px;
      height: 100%;
      box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
    }

    &--right {
      width: calc(100% - 250px);
      height: 100%;
      border: 1.8px dashed var(--border-color);
      padding: 10px;

      .std-reservation__modal {
        margin-top: 0px !important;
      }

      .std-reservation__tableblock {
        height: calc(100% - 90px);
      }
    }
  }

  &__tableblock {
    height: 100%;
  }

  &__table {
    @include table();
  }
}