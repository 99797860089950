@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.emp-assign-leave-ledger {
  @include mainFrameWithTitle();

  &__options {
    height: 120px;
    gap: 20px;
    .MuiAutocomplete-root {
      width: 100% !important;
      margin-top: 5px;
    }
    &--imageflex {
      display: flex;
      .data-fetch-icon {
        margin-top: 5px;
      }
    }
  }
  &__tableblock {
    height: calc(100% - 170px);
    margin-top: 5px;
  }
  &__table {
    @include table();
    .MuiFormControl-root {
      margin-top: 0px !important;
    }
    &--actions {
      width: calc(100% - 92%);
    }
    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--number {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
      @include inputSalaryTable();
    }
    &--enter-image {
      width: 20px;
    }
  }
  &__profile {
    @include studentProfile();
  }
  &__textfield {
    @include textfield();
    margin: 8px 0px 0px 0px !important;

    &--date {
      @include textfield();
      margin: 8px 0px 0px 0px !important;
      width: fit-content !important;
    }
    &--editable {
      margin: 8px 0px 0px 0px !important;
      @include textfield();
      .MuiInputBase-root {
        background-color: colors.get-color(colors.$yellow, level_2) !important;
      }
      input {
        font-weight: 400 !important;
        text-align: right !important;
        color: var(--text-color) !important;
      }
      label {
        color: var(--text-color) !important;
      }
    }
  }
  &__row {
    @include selectTableRow();
    @include autoCompleteInTableRow();

    .MuiAutocomplete-root,
    .auto-complete:focus {
      background-color: var(--bg-color) !important;
    }
    .storybook-input {
      margin-top: 0px !important;
    }

    input {
      &:focus {
        background-color:colors.get-color(colors.$colors, white);
      }
    }
    td {
      color: colors.get-color(colors.$colors, black) !important;
    }
  }
}
