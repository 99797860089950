@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.userneeds {
  height: 100%;

  &__frame {
    height: calc(100% - 210px);
    margin: 0px 20px 0px 20px;
    column-gap: 20px;

    &--label-grid {
      display: grid;
      grid-template-columns: 1fr 2fr;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 2.5fr 3.5fr;
  }

  &__switch {
    display: flex;
    justify-content: flex-end;
  }

  &__gridradio {
    display: grid;
    grid-template-columns: 1.5fr 2.5fr;
  }

  &__label {
    padding: 6px;
    background: var(--level-5);
    width: 100%;
    height: 48px;
    color: white;
  }

  &__menu {
    transform: scaleY(1);
    transform-origin: top left;
    transition-duration: 0.2s;
  }

  &__table {
    height: calc(100% - 10px);

    &--option {
      height: 100%;
      overflow: auto;
      padding-top: 10px;

      p {
        padding-top: 4px;
        color: colors.get-color(colors.$colors, white);
      }

      img {
        filter: invert(0%);
      }
    }
  }

  &__switch-grid {
    display: grid;
    grid-template-columns: 1fr 0.8fr;
    margin-top: 5px;
  }

  &__prefill-grid {
    display: grid;
    grid-template-columns: 0.6fr 1fr;
  }

  &__preview-data {
    input {
      color: var(--level-5) !important;
      font-size: 16px;
      font-weight: bold;
    }
  }

  &__voucher-flex {
    display: flex;

    Input {
      margin-top: 0px !important;
    }

    .MuiFormControl-root {
      margin-top: 0px !important;
    }
  }

  .MuiAccordionSummary-content {
    .MuiTypography-root {
      color: colors.get-color(colors.$colors, white) !important;
      margin-top: 3px;
    }
  }

  .MuiCollapse-root {
    border: 1px solid colors.get-color(colors.$colors, cloud-white);
  }

  .MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-family: fonts.$eduate-font-family;
    font-style: normal;
    font-weight: 500 !important;
    font-size: fonts.$eduate-default-font-size;
    color: var(--text-color);
  }

  .MuiButtonBase-root-MuiCheckbox-root {
    padding: 0px;
  }

  .MuiFormLabel-root {
    margin-left: 8px;
  }

  .MuiFormControlLabel-root {
    justify-content: space-between !important;
    margin-left: 5px !important;
    margin-right: 0px !important;
    margin-top: 5px;
  }
}

.userneeds__table--option::-webkit-scrollbar {
  @include scrollbar();
}

.userneeds__table--option::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.userneeds__table--option::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
