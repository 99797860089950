@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.online-transaction {
 @include mainFrameWithTitle();

  &__details {
    column-gap: 20px;
    height: 120px;

    &--flex {
      display: flex;

      .MuiAutocomplete-root,
      .data-fetch-icon {
        margin-top: 10px !important;
      }
    }

    &--textfield {
      @include textfield();
      margin: 10px 0px 0px 0px !important;
    }

    &--image {
      height: 100%;
      margin-top: 10px;
      @include studentProfile();
    }
  }

  &__filters {
    border-top: 1.5px dashed var(--border-color);
    column-gap: 10px;
    margin-top: 15px;
    padding-top: 5px;

    .MuiAutocomplete-root {
      margin-top: 10px !important;
    }

    &--textfield {
      @include textfieldInForms();
      width: fit-content !important;
    }
  }

  &__tableblock {
    height: calc(100% - 240px);
    margin-top: 10px;
  }

  &__table {
    @include table();

    tr:hover {
      .online-transaction__table--vno {
        @include tdContainsOnclick();
      }
    }

    &--success {
      color:colors.get-color(colors.$colors, white) !important;
      font-weight: 700 !important;
      background-color: colors.get-color(colors.$Green, level_5);
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;      font-size: 16px !important;
    }

    &--failure {
      @extend .online-transaction__table--success;
      background-color: colors.get-color(colors.$red, level_5);
    }

    &--no-voucher {
      @extend .online-transaction__table--success;
      background-color:colors.get-color(colors.$amber, level_5);
    }

    &--incomplete {
      @extend .online-transaction__table--success;
      background-color: colors.get-color(colors.$grey, level_5);
    }

    &--vno {
      font-weight: 700 !important;
    }

    &--message {
      width: calc(100% - 90%);
    }

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--date {
      width: calc(100% - 96%);
    }

    &--number {
      width: calc(100% - 96%);
    }

    &--actions {
      width: calc(100% - 94%);

      button {
        @include viewButton();
      }
    }
  }

  &__total-count {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size:fonts.$eduate-default-font-size;
    font-family: fonts.$eduate-font-family;
    margin: 10px 20px 0px 20px;

    b {
      padding: 5px;
      font-family: fonts.$eduate-number-font;
      background: var(--level-5);
      border-radius: 2px;
      color:colors.get-color(colors.$colors, white);
    }
  }
}