@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.documents-content {
  height: 100%;
  .quill {
    border: 1px solid var(--border-color) !important;
    margin-top: 10px !important;
  }
  &__title {
    img {
      @include closeIconInModal();
    }
  }

  &__data {
    height: calc(100% - 75px);
  }
}
