@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.groups-list {
  @include mainFrameWithTitle();


  &__frame {
    height: calc(100% - 100px);
    margin-top: 10px;
  }

  &__table {
    @include table();
    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--name {
      &:hover {
        @include tdContainsOnclick();
      }
    }
    ul {
      margin-bottom: 0px !important;
    }
  }
}
