@use "../../../scss/common/mixins" as *;
@use "../../../scss/common/fonts" as fonts;
@use "../../../scss/common/colors" as colors;
.channel {
  height: calc(100% - 170px);
  margin: 0px 20px;

  &__datablock {
    height: calc(100% - 40px);
    display: flex;
    gap: 40px;

    &--left {
      height: 100%;
      border: 1px solid var(--border-color);
      width: 260px;
      padding: 10px 20px;

      &--menus {
        height: 30px;
        display: flex;
        justify-content: space-between;

        span {
          font-size:fonts.$eduate-default-font-size;
          font-family: fonts.$eduate-font-family;
        }

        img {
          width: 20px;
        }
      }

      &--button {
        button {
          font-family: fonts.$eduate-font-family;
          font-size:fonts.$eduate-default-font-size;
          margin-left: -10px;
          padding: 5px;
          background: var(--bg-color) !important;
          color: var(--text-color) !important;

          &:disabled {
            color: colors.get-color(colors.$cool_grey, level_5) !important;
            background-color: var(--disabled-color) !important;
          }
        }
      }

      &--channels {
        height: calc(100% - 85px);
        overflow: auto;
        animation-duration: 5s;

        li {
          display: flex;
          justify-content: space-between;
          font-family: fonts.$eduate-font-family;
          font-size:fonts.$eduate-default-font-size;
          border-radius: 6px;
          padding: 8px 5px;

          &:hover {
            background-color: var(--level-1);

            img {
              display: flex;
            }
          }
        }

        img {
          display: none;
        }
      }

      &--selected-row {
        background-color: var(--level-5);
        color:colors.get-color(colors.$colors, white);
        font-weight: 700;

        img {
          display: flex;
          filter: invert(100%);
        }
      }

      &--levels {
        li {
          display: flex;
          column-gap: 10px;
          margin-top: 5px;

          span {
            font-size: 12px;
            font-weight: 500;
            font-family: fonts.$eduate-font-family;
            margin-top: auto;
            margin-bottom: auto;
          }

          img {
            margin-top: auto;
            background-color:colors.get-color(colors.$colors, white);
            border-radius: 50%;
            padding: 2px;
          }
        }
      }
    }

    &--right {
      height: 100%;
      border: 1px solid var(--border-color);
      width: calc(100% - 260px);
      padding: 5px;
    }
  }

  &__message-block {
    padding: 5px;

    &--modal {
      height: 100%;

      &--info {
        height: calc(100% - 80px);

        &--topic {
          margin-top: 5px;
          height: calc(50% - 5px);
          border-radius: 20px;
          border: 1px solid var(--border-color);
          overflow: auto;
          padding: 5px;

          span,
          b {
            font-size:fonts.$eduate-default-font-size;
            font-family: fonts.$eduate-font-family;
          }
        }

        &--files {
          img {
            width: 35px;
            padding-right: 10px;
          }

          Label {
            padding-left: 35px;
            margin-top: 0px;
          }
        }
      }
    }

    &--messages-list {
      height: 100%;

      &--modal {
        height: 100%;
      }

      &--messages {
        height: calc(100% - 35px);
        overflow: auto;
        display: flex;
        background-color: colors.get-color(colors.$grey, level_2);
        padding: 5px 20px;

        ul {
          width: 100% !important;
        }

        &--list {
          display: flex;
          gap: 10px;
          height: fit-content;
          width: fit-content;
          border-radius: 6px;
          background-color:colors.get-color(colors.$colors, white);
          padding: 5px 10px;

          &--li {
            padding: 5px 200px 5px 5px;
          }

          Label {
            margin-top: 2px;
            padding: 0px;
            font-weight: 700 !important;
            margin-right: 10px;
          }

          &--sender {
            border-radius: 6px;
            display: flex;
            justify-content: flex-end !important;
            margin-right: 0% !important;
            width: fit-content;
            margin-left: auto !important;
            background-color: var(--level-1);
            padding: 5px 10px;
            border-radius: 6px;

            &--li {
              padding: 5px 5px 5px 200px;
            }

            &--left {
              width: 25%;
            }
          }
        }

        &--file-image {
          width: 320px;
          height: 240px;
        }

        p {
          font-family: fonts.$eduate-font-family;
          font-size:fonts.$eduate-default-font-size;
          margin: 0px;
        }

        b {
          font-family: fonts.$eduate-font-family;
          font-size: 16px;
          color: colors.get-color(colors.$grey, level_7) !important;
        }
      }

      &--image {
        width: 35px;
        height: 35px;
        margin-top: 5px;
      }

      &--time {
        font-family: fonts.$eduate-number-font;
        font-size: 12px;
        font-weight: 700;
        text-align: right;
      }
    }

    &--channel-name {
      border-bottom: 1px solid var(--border-color);
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      column-gap: 20px;

      span {
        font-size: 16px;
        font-family: fonts.$eduate-font-family;
        color: var(--text-color);
        font-weight: 600;
      }

      &--count {
        font-weight: 700;
        color: var(--level-6) !important;
        margin-left: 5px;
        font-family: fonts.$eduate-number-font;
      }

      &--flex {
        display: flex;
        column-gap: 20px;
      }
    }

    &--text-area {
      margin-top: 10px;
      height: 110px;

      .ql-editor {
        height: 80px !important;
      }

      &--flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 20px;
        height: 100%;

        .browse-icon {
          width: 20px;
        }

        &--text-field {
          width: calc(100% - 100px);
          border: 1px solid var(--border-color);
        }

        &--send {
          width: 40px;
          height: 40px;
          margin: auto;
          background-color: colors.get-color(colors.$emarald, level_5);
          padding: 10px;
          border-radius: 6px;

          img {
            display: block;
            margin: auto !important;
          }
        }
      }
    }

    &--levels {
      font-size: 16px;
      font-family: fonts.$eduate-font-family;
      color: var(--level-7);
      font-weight: 500;
    }
  }

  &__details {
    height: calc(100% - 70px);
    overflow: auto;

    &--channel-name {
      border: 1px solid var(--border-color);
      border-radius: 6px 6px 0px 0px;
      padding: 5px;
      display: flex;
      justify-content: space-between;

      &--list {
        display: flex;
        flex-direction: column;

        Label {
          margin-top: 0px !important;
          padding: 0px;
        }

        .LabelPrimary {
          margin-top: 0px !important;
        }
      }
    }

    &--created-by {
      @extend .channel__details--channel-name--list;
      border: 1px solid var(--border-color);
      padding: 5px;
      border-radius: 0px 0px 6px 6px;
    }

    &--files {
      margin-top: 20px;
      border: 1px solid var(--border-color);
      padding: 5px;
      @extend .channel__details--channel-name--list;

      &--first-row {
        display: flex;
        justify-content: space-between;
      }

      &--documents {
        &--created-by {
          display: flex;
          justify-content: space-between;
          margin-left: 30px;
        }
      }
    }
  }

  &__add-channel {
    height: 100%;
  }

  &__student-info {
    column-gap: 20px;
    height: 120px;

    &--image {
      @include studentProfile();
      margin-top: 5px;
    }

    &--textfield {
      @include textfield();
      width: 100%;
      margin: 8px 0px 0px 0px !important;

      &--date {
        @extend .channel__student-info--textfield;
        width: fit-content;
      }
    }

    &--data {
      margin-top: 10px;
      height: calc(100% - 325px);
    }
  }
}

.channel__details::-webkit-scrollbar,
.ql-editor::-webkit-scrollbar,
.channel__datablock--left--channels::-webkit-scrollbar,
.channel__message-block--messages-list--messages::-webkit-scrollbar {
  @include scrollbar();
}

.channel__details::-webkit-scrollbar-thumb,
.ql-editor::-webkit-scrollbar-thumb,
.channel__datablock--left--channels::-webkit-scrollbar-thumb,
.channel__message-block--messages-list--messages::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.channel__details::-webkit-scrollbar-thumb:hover,
.ql-editor::-webkit-scrollbar-thumb:hover,
.channel__datablock--left--channels::-webkit-scrollbar-thumb:hover,
.channel__message-block--messages-list--messages::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

