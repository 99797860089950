@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.miscellaneous {
  @include mainFrameWithTitle();
  &__modal {
    height: calc(100% - 30px);
  }
  &__title {
    img {
      @include closeIconInModal();
    }
  }
  &__filters {
    column-gap: 10px;
    .MuiAutocomplete-root {
      margin-top: 10px;
    }
    &--flex {
      display: flex;
      column-gap: 5px;
      align-items: center;
    }
    &--textfield {
      @include textfieldInForms();
      width: fit-content !important;
    }
  }
  &__datablock {
    margin-top: 10px;
    height: calc(100% - 100px);
  }
}
