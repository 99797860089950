@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.section-allocation {
  @include mainFrameWithTitle();

  .MuiDrawer-root {
    z-index: 100 !important;
  }

  .react-swipeable-view-container,
  p {
    height: 100% !important;
  }

  &__tabs {
    @include muiTabs();
    margin-top: 10px;
  }

  &__swipable {
    height: calc(100% - 40px);

    .bulk-data-alter__swipable {
      height: 100% !important;
    }
  }

  &__options {
    column-gap: 10px;
    position: relative;
    top: 10px;
  }

  &__datablock {
    height: calc(100% - 110px);
  }

  &__tableblock {
    height: calc(100% - 30px);
  }

  &__move-to {
    height: 100%;
    padding: 15px 10px 10px 10px;

    &--tableblock {
      height: calc(100% - 115px);
      margin-top: 10px;
    }

    &--count {
      display: flex;

      &:hover {
        text-decoration: underline;

        .Label,
        b {
          font-weight: 700;
          color: var(--level-6);
        }
      }
      Label {
        margin-top: 5px;
      }

      b {
        font-weight: 700;
        font-size: 16px !important;
        font-family: fonts.$eduate-number-font;
        color: var(--level-6);
        margin-top: 10px;
      }
    }
  }

  &__without-error {
    margin: 0px 10px 0px 10px;
    height: calc(100% - 260px);
  }

  &__title {
    height: 40px;
    padding-right: 20px;
  }

  &__table {
    @include table();
    @include tableCheckbox();

    &--th-slno {
      width: calc(100% - 92%);
    }

    &--th-admno {
      width: calc(100% - 90%);
    }

    &--slno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--admno {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--desc {
      width: calc(100% - 85%);
    }
  }

  &__total {
    display: flex;
    justify-content: flex-end;

    .Label {
      margin-top: 0px;

      &:hover {
        text-decoration: underline;
        font-weight: bold !important;
      }
    }
  }

  &__totalcount {
    @include totalCountShadowEffect();
    margin-top: -5px;
  }

  &__allocated {
    margin-top: 10px;
    height: calc(100% - 80px);
  }

  &--total-count {
    .student-total-count {
      text-align: right;
    }
  }
}

.move-section {
  &__total {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;

    &--count {
      @include totalCountShadowEffect();
      margin-top: 5px;
    }
  }
}
.section-interchange {
  margin: 0px 20px;
  height: calc(100% - 165px);
}
