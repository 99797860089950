@use "../../../../scss/common/mixins" as*;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.sub-details {
  height: 100%;

  &__title {
    display: flex;
    height: 60px;
    align-items: center;

    .Heading {
      text-align: left !important;
    }
  }

  &__classteacher {
    width: 400px;
    padding: 5px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    height: 80px;

    &--profile {
      display: flex;
      column-gap: 5px;

      img {
        width: 50px;
        border-radius: 50%;
      }

      &--details {
        width: calc(100% - 50px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        span {
          font-family: fonts.$eduate-number-font;
          font-size:fonts.$eduate-default-font-size;
          color: var(--text-color);
          display: block;
        }

        b {
          font-size:fonts.$eduate-default-font-size;
          font-family: fonts.$eduate-font-family;
        }
      }
    }

    &--desc {
      display: flex;
      font-size: 12px;
      font-family: fonts.$eduate-font-family;
      color: colors.get-color(colors.$cool_grey, level_6);
    }
  }
  &__images {
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    margin-right: 10px;
    height: 40px;

    .active-image {
      background-color: var(--level-4);

      border-radius: 6px;
    }

    img {
      &:hover {
        background-color: var(--level-2);
        padding: 5px;
        border-radius: 6px;
      }
    }
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 195px);
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--code {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--type {
      width: calc(100% - 92%);
    }

    &--teacher {
      width: calc(100% - 88%);

      &--details {
        display: flex;
        column-gap: 5px;

        img {
          width: 25px;
          border-radius: 50%;
        }

        b {
          font-size: 16px !important;
        }

        span {
          display: block;
        }
      }
    }

    &--open-elective {
      td {
        background-color: var(--bg-color) !important;
        font-size: 16px !important;
        font-weight: 700 !important;
      }
    }
  }
  &__std-list {
    height: 100%;
    &--title {
      img {
        @include closeIconInModal();
      }
    }
    &--subtitle {
      display: flex;
      margin-top: 5px;
      b {
        font-size: 20px !important;
      }
      span {
        font-size: 14px;
        font-family: fonts.$eduate-number-font;
        color: colors.get-color(colors.$cool_grey, level_6);
        margin-top: 5px;
      }
    }
    &--classteacher {
        margin-top: 10px;
      @extend .sub-details__classteacher;
      &--profile {
        @extend .sub-details__classteacher--profile;

        &--details {
          @extend .sub-details__classteacher--profile--details;
        }
      }

      &--desc {
        @extend .sub-details__classteacher--desc;
      }
    }
    &--tableblock{

        height: calc(100% - 280px);
        margin-top: 10px;
    }
  }
  &__per-std{
    height: 100%;
    &--title{
        img{
            @include closeIconInModal();
        }
    }
    &--details{
        height: 130px;
        column-gap: 10px;
        margin-top: 10px

    }
    &--std-profile{
        @include studentProfile();
    }
    &--textfield{
        @include textfield();
        margin:10px 0px 0px 0px !important;
    }
    &--tableblock{
        height: calc(100% - 285px);
        margin-top: 10px;
    }

  }
}
