@use "../../../../scss/common/mixins" as*;

.lesson-status {
    height: 100%;

    &__title {
        display: flex;
        justify-content: flex-start;
    }

    &__frame {
        height: calc(100% - 30px);

        display: flex;
        column-gap: 10px;

        &--left {
            width: 210px;
            overflow: auto;
            height: 100%;
            background-color: var(--level-1);
        }

        &--right {
            width: calc(100% - 210px);
            height: 100%;
            overflow: auto;
        }
    }

    &__select-wrapper {
        border-radius: 20px;
        padding: 2px 8px;
        width: fit-content;
        border: 1px solid var(--border-color);

        img {
            width: 18px !important;
        }
    }

    &__select {
        background: transparent;

    }
}

.lesson-status__frame--left::-webkit-scrollbar,
.lesson-status__frame--right::-webkit-scrollbar {
    @include scrollbar();
}


.lesson-status__frame--left::-webkit-scrollbar-thumb,
.lesson-status__frame--right::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}


.lesson-status__frame--left::-webkit-scrollbar-thumb:hover,
.lesson-status__frame--right::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}