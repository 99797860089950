@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.std-enquiry-followup {
  margin-top:2px;
  height: 100%;

  &__details {
    gap: 20px;

    &--textfield {
      @include textfield();
      margin: 8px 0px 0px 0px !important;

    }

    .MuiAutocomplete-root {
      margin-top: 8px !important;
    }

    &--clear {
      display: flex;
      justify-content: flex-end;
      margin-left: auto !important;
      margin-right: 0px !important;
    }
  }

  &__tableblock {
    height: calc(100% - 100px);
    margin-top: 10px;
  }

  &__table {
    @include table();

    tr:hover {
      .std-enquiry-followup__table--name {
        @include tdContainsOnclick();
      }
    }

    &--slno {
      font-family: fonts.$eduate-number-font;
      width: calc(100% - 96%);
    }

    &--email {
      width: calc(100% - 90%);
    }

    &--mobileno {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--inst {
      width: calc(100% - 90%);
    }

    &--dept {
      width: calc(100% - 92%);
    }

    &--status {
      width: calc(100% - 90%);
    }

    &--date {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--count {
      width: calc(100% - 95%);
      font-family: fonts.$eduate-number-font;
    }
  }

  &__view {
    height: calc(100% - 30px);

    &--details {
      height: 120px;
      gap: 20px;

      &--span-4 {
        column-count: 4;
        column-gap: 20px;
        height: 100%;
      }

      &--textfield {
        @include textfield();
        width: 100%;
        margin: 8px 0px 0px 0px !important;
      }
    }

    &--tableblock {
      height: calc(100% - 300px);
      margin-top: 10px;
    }

    &--table {
      @include table();
      height: calc(100% - 30px);

      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }

      &--status {
        width: calc(100% - 90%);
      }

      &--date {
        width: calc(100% - 92%);
      }
    }

    &--additional {
      height: 125px;
      column-gap: 20px;

      &--frame {
        height: 100%;
        border: 1px solid var(--border-color);
        padding: 5px;
        border-radius: 6px;

        Label,
        .storybook-input
         {
          margin-top: 5px !important;
        }

        &--textfield {
          @include textfield();
          height: 100px;
        }

        &--label-grid {
          display: grid;
          grid-template-columns: 0.5fr 2.5fr;
        }
      }
    }
  }

  &__total {
    text-align: right;

    .student-total-count {
      margin: 10px 0px 0px 0px !important;
    }
  }
}