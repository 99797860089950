@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.daily-diary-review {
  height: 100%;

  &__title {
    img {
      @include closeIconInModal();
    }
  }

  &__select {
    &--textfield {
      @include textfield();
    }
  }

  &__datablock {
    margin-top: 10px;
    height: calc(100% - 110px);

    &--ul {
      height: calc(100% - 30px);
      overflow: auto;

      &--text {
        width: calc(100% - 60px);
        border-right: 1px dashed var(--border-color);
        padding: 10px;
      }

      li {
        padding: 5px;
        border-bottom: 1px dashed var(--border-color);
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      b {
        font-family: fonts.$eduate-font-family;
        font-size: 20px;
        font-family: fonts.$eduate-font-family;
        color: colors.get-color(colors.$sky, level_6);
        display: block;
      }

      span {
        font-family: fonts.$eduate-font-family;
        font-size: 18px;
      }

      &--image {
        padding-left: 10px;
        width: 60px;
      }
    }
  }
}

.daily-diary-review__datablock--ul::-webkit-scrollbar {
  @include scrollbar();
}

.daily-diary-review__datablock--ul::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.daily-diary-review__datablock--ul::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
