@use "../../../../scss/common/mixins" as *;

.miscellaneous-edit{
    height: 100%;
    &__title{
        img{
            @include closeIconInModal();
        }
    }
    &__form{
        height: calc(100% - 75px);
    }
}