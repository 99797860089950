
@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.demand-summary-treeview__tree-view--title {
  font-family: fonts.$eduate-font-family;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size:fonts.$eduate-default-font-size;
}

.demand-summary-treeview__tree-view--amount {
  font-size:fonts.$eduate-default-font-size;
  text-align: center;
  min-width: 200px;
  max-width: 200px;
}

.demand-summary-treeview__tree-view--count {
  font-size:fonts.$eduate-default-font-size;
  text-align: center;
  min-width: 100px;
  max-width: 100px;
}

.demand-summary-treeview {
 @include mainFrameWithTitle();

  &__modal {
    height: calc(100% - 30px);
    margin: 0px;
  }

  &__datablock {
    height: calc(100% - 95px);
    margin-top: 10px;

    &--modal {
      @extend .demand-summary-treeview__datablock;
      height: calc(100% - 55px) !important;
    }
  }

  &__tree-view {
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 10px;
    height: 100%;
    overflow: auto;

    &--header {
      margin-bottom: 0px !important;
      margin-bottom: 10px;

      &--title {
        min-width: 350px !important;
        max-width: 350px !important;
        @extend .demand-summary-treeview__tree-view--title;
      }

      &--c-title {
        min-width: 100px;
        max-width: 100px;

        span {
          text-align: center;
          font-family: fonts.$eduate-font-family;
          font-size: 16px;
          font-weight: 700;
          display: block;
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }

      &--a-title {
        @extend .demand-summary-treeview__tree-view--amount;
        font-family: fonts.$eduate-number-font;

        span {
          text-align: center;
          font-family: fonts.$eduate-font-family;
          font-size: 16px;
          font-weight: 700;
          display: block;
          margin-left: auto !important;
          margin-right: auto !important;
        }
      }

      &--li {
        display: flex;
        column-gap: 5px;
        padding: 5px;
      }
    }

    li {
      padding: 2px;
      display: flex;
      column-gap: 5px;
      border-radius: 6px;
      width: fit-content;

      &:hover {
        background-color: var(--level-2);
      }

      &:focus {
        background-color: var(--level-4);
        color: white;
      }
    }

    &--count {
      font-family: fonts.$eduate-number-font;
      @extend .demand-summary-treeview__tree-view--count;
    }

    &--amount {
      font-family: fonts.$eduate-number-font;
      @extend .demand-summary-treeview__tree-view--amount;
    }

    &__level-1 {
      height: calc(100% - 50px);

      &--li {
        border: 1.5px dashed var(--border-color);
      }

      &--title {
        min-width: 350px !important;
        max-width: 350px !important;
        font-family: fonts.$eduate-font-family;
        @extend .demand-summary-treeview__tree-view--title;
      }
    }

    &__level-2 {
      &--title {
        min-width: 325px !important;
        max-width: 325px !important;
        @extend .demand-summary-treeview__tree-view--title;
      }

      &--li {
        border-left: 1.5px dashed var(--border-color);
        border-bottom: 1.5px dashed var(--border-color);
        border-right: 1.5px dashed var(--border-color);
      }
    }

    &__level-3 {
      &--title {
        min-width: 300px !important;
        max-width: 300px !important;
        @extend .demand-summary-treeview__tree-view--title;
      }
    }

    &__level-4 {
      &--title {
        min-width: 275px !important;
        max-width: 275px !important;
        @extend .demand-summary-treeview__tree-view--title;
      }
    }

    &__level-5 {
      &--title {
        min-width: 250px !important;
        max-width: 250px !important;
        @extend .demand-summary-treeview__tree-view--title;
      }
    }
  }

  &__icons {
    display: flex !important;
    margin-left: auto !important;
    margin-right: 0px !important;
    column-gap: 10px;
    background: colors.get-color(colors.$grey, level_2);
    border-radius: 5px;
    padding: 5px;
    width: fit-content;

    .active {
      background: var(--level-5);
      border-radius: 6px;

      img {
        filter: invert(100%);
      }
    }
  }
}

.demand-summary-treeview__tree-view::-webkit-scrollbar {
  @include scrollbar();
}

.demand-summary-treeview__tree-view::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.demand-summary-treeview__tree-view::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}