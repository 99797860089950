.faculty-device-details{
    margin :0px 20px;
    height: calc(100% - 165px);
    &__select{
        column-gap: 10px;
        .MuiAutocomplete-root{
            margin-top: 10px;
        }
    }
    &__tableblock{
        margin-top: 10px;
        height: calc(100% - 100px);
        &--image{
            width: 20px;
        }
    }
}