@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.template-list {
    height: calc(100% - 35px);

    &__tableblock {
        margin-top: 10px;
        height: calc(100% - 50px);
    }

    &__table {
        @include table();
        @include tableCheckbox();

        &--slno {
            width: calc(100% - 94%);
            font-family: fonts.$eduate-number-font;
        }

        &--desc {
            width: calc(100% - 88%);
        }
    }
}