@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.parentReg {
  height: calc(100% - 145px);
  margin: 10px 20px 0px 20px;

  &__update {
    @extend .parentReg;
    height: calc(100% - 150px);
  }

  &__modal {
    height: calc(100% - 20px);

    .MuiAutocomplete-endAdornment {
      right: 10px !important;
    }
  }

  .MuiAutocomplete-endAdornment {
    right: 10px !important;
  }

  .storybook-input[disabled] {
    color: var(--level-6) !important;
    font-weight: bold;
  }

  &__details {
    height: calc(100% - 45px);
    overflow: auto;
    column-gap: 30px;

    &--label-grid {
      display: grid;
      grid-template-columns: 0.8fr 1.8fr 0.2fr;

      .MuiAutocomplete-endAdornment {
        right: 10px !important;
      }
    }
  }

  &__form {
    height: calc(100% - 60px);

    &--modal {
      height: calc(100% - 10px);
    }

    &--update {
      height: calc(100% - 30px);
    }
  }

  &__image-flex {
    display: grid;
    grid-template-columns: 0.8fr 1.8fr 0.1fr;

    .data-fetch-icon {
      margin-top: 10px;
    }

    .MuiAutocomplete-root {
      margin-top: 0px;
    }
  }

  &__textfield {
    @include textfield();
    margin: 10px 0px 0px 0px !important;
  }
}

.parentReg__details::-webkit-scrollbar,
.staffReg::-webkit-scrollbar {
  @include scrollbar();
}

.parentReg__details::-webkit-scrollbar-thumb,
.staffReg::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.parentReg__details::-webkit-scrollbar-thumb:hover,
.staffReg::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

.staffReg {
  height: calc(100% - 40px);
  overflow: auto;
  column-gap: 30px;

  .MuiAutocomplete-endAdornment {
    right: 10px !important;
  }
}

.parentlist__modal {
  margin-top: 10px;
  height: calc(100% - 125px);
}

.parentlist__total {
  margin-top: 10px 0px 20px 0px;
  text-align: right;
}