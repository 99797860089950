@use "../../../../scss/common/mixins" as *;

.sibling-association{
    @include mainFrameWithTitle();
 
    &__select{
        height: 120px;
        column-gap: 20px;
        &--flex{
            display: flex;
            margin-top: 10px;
        }
    }
    &__tableblock{
        height: calc(100% - 175px);
        margin-top: 10px;
    }

}