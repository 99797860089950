@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/colors" as colors;
@use "../../../../../scss/common/fonts" as fonts;
.combination-report {
 @include mainFrameWithTitle();
  &__back {
    img {
      transform: rotate(180deg);
      width: 15px;
    }
    color: colors.get-color(colors.$cool_grey, level_6);
    font-size: 16px;
    font-family: fonts.$eduate-font-family;
    display: flex;
    column-gap: 5px;
    margin-top: 5px;
  }
  &__title {
    color: var(--level-6);
    font-size: 20px;
    font-family: fonts.$eduate-font-family;
  }
  &__tabs {
    @include muiTabs();
    margin-top: 10px;
  }
  &__datablock {
    height: calc(100% - 250px);
    margin-top: 10px;
    border: 1px solid var(--border-color);
    padding: 5px;
    border-radius: 6px;
    &--tab-panel {
      height: 100%;
    }
  }
  &__details {
    height: 100%;
    &--grids {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 5px;
    }
    &--grid {
      background-color: var(--level-1);
      text-align: center;
      padding: 5px;
      border-radius: 6px;
      b {
        font-size: 16px;
        font-family: fonts.$eduate-font-family;
      }
    }
    &--flex {
      display: flex;
      justify-content: space-between;
      span {
        display: block;
        font-size: 14px;
        font-family: fonts.$eduate-font-family;
        color: colors.get-color(colors.$cool_grey, level_6);
      }
      b {
        font-size: 14px;
        font-family: fonts.$eduate-number-font;
        color: var(--text-color);
      }
    }
    &--view{
        @include viewButton();
    }
  }
}
