@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.acd-admin-dashboard {
  @include mainFrameWithTitle();

  &__cards {
    height: 230px;
    column-gap: 20px;
    display: flex;
    column-gap: 10px;

    .echarts-for-react {
      height: 75px !important;
    }
  }

  &__card {
    height: 100% !important;
    @include blocksInDashboard();
    width: 25%;

    &--title {
      display: flex;

      justify-content: space-between;

      .subtitle1 {
        width: calc(100% - 30px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
    }

    &--details {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size:fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
      }

      img {
        width: 30px;
        margin-right: 10px;
      }

      b {
        font-size: 16px;
        font-family: fonts.$eduate-number-font;
      }

      &--pers {
        width: 35px;
      }
    }
  }

  &__datablock {
    margin-top: 10px;
    height: calc(100% - 245px);
    display: flex;
    column-gap: 20px;
  }

  &__top-right {
    height: 100% !important;
    @include blocksInDashboard();
    width: 25%;
  }

  &__bottom-right {
    height: 100% !important;
    @include blocksInDashboard();

    // &--top {
    //     height: 100px !important;
    //     @include blocksInDashboard();
    // }

    // &--bottom {
    //     margin-top: 5px;
    //     height: calc(100% - 105px) !important;
    //     @include blocksInDashboard();
    // }
    &--tableblock {
      height: calc(100% - 35px);
    }
  }

  &__staff {
    height: 120px;

    &--graph {
      height: calc(100% - 150px);

      .echarts-for-react {
        height: 100% !important;
      }
    }
  }

  &__calendar {
    height: calc(100% - 25px);
  }

  &__upcoming-test {
    height: calc(100% - 30px);
    background-color: var(--level-1);
    overflow: auto;

    ul {
      margin-bottom: 0px;
      height: 100%;
    }

    li {
      padding: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1.2px dashed var(--border-color);
    }
    &--names {
      display: flex;
      flex-direction: column;
      column-gap: 5px;

      &--title {
        display: flex;
        align-items: center;
        column-gap: 5px;
      }

      &--test {
        font-size:fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        color: var(--text-color);
      }

      &--test-type {
        font-size:fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        color: colors.get-color(colors.$cool_grey, level_7);
      }

      &--s-date {
        font-size:fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        color: colors.get-color(colors.$cool_grey, level_7);
      }

      &--date {
        color: var(--level-6);
        font-family: fonts.$eduate-number-font;
        font-size: 12px;
      }
    }
    &--total {
      width: 30px;

      &--inprogress {
        background-color:colors.get-color(colors.$amber, level_6);
        color:colors.get-color(colors.$colors, white);
        padding: 5px;
        border-radius: 20px;
        font-size: 10px;
        font-family: fonts.$eduate-font-family;
        display: flex;

        img {
          width: 15px;
        }
      }

      &--upcoming {
        border: 1px solid var(--border-color);
        padding: 5px;
        border-radius: 20px;
        font-size: 10px;
        font-family: fonts.$eduate-font-family;
        background-color: colors.get-color(colors.$cool_grey, level_2);
        display: flex;

        img {
          width: 15px;
        }
      }

      img {
        width: 25px;
        display: block;
        margin-left: auto;
        margin-right: 0px;
      }
    }
  }

  &__att-behaviour {
    height: 70px;
    overflow: auto;
    display: flex;
    column-gap: 10px;
    align-items: center;
    justify-content: space-evenly;

    &--flex {
      display: flex;
      align-items: center;

      b {
        font-size: 12px;
        color: var(--text-color);
        font-family: fonts.$eduate-font-family;
      }

      span {
        font-size: 12px;
        color: var(--text-color);
        font-family: fonts.$eduate-font-family;
      }
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .student-total-count {
      margin: 0px 0px 0px 0px !important;
    }
  }

  &__total {
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
}

.acd-admin-dashboard__upcoming-test::-webkit-scrollbar {
  @include scrollbar();
}

.acd-admin-dashboard__upcoming-test::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.acd-admin-dashboard__upcoming-test::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
