@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.fine-slab {
  height: calc(100% - 170px);
  &__details {
    height: calc(100% - 50px);
    margin: 0px 10px 10px 10px;
    column-gap: 20px;
  }
  &__frame {
    height: calc(100% - 30px);
    &--autocomplete {
      height: 45px;
      margin-top: 5px;
    }
    &--library {
      @extend .fine-slab__frame;
      overflow: auto;
    }

    &--fine {
      background-color: colors.get-color(colors.$red, level_6) !important;
      color: white !important;

      border-radius: 6px;
      display: flex;
      align-items: center;
      column-gap: 10px;
      padding-left: 10px;
      font-family: fonts.$eduate-font-family;
      img {
        width: 30px;
        height: 30px;
      }
    }
    &--block {
      height: calc(100% - 50px);
      overflow: auto;
    }
    &--tableblock {
      margin-top: 10px;
      height: calc(100% - 40px);
    }
    &--table {
      @include table();
      &--slno {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-slno {
        width: calc(100% - 90%);
      }
      &--actions {
        width: calc(100% - 94%);
      }
    }
  }
  &__formlabels {
    display: flex;

    &--columns {
      margin-right: 10px;
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        opacity: 1;
      }

      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: inner-spin-button !important;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
      }
      .label-grid {
        display: flex !important;
        margin-left: -10px;
      }
      &--amountperday {
        .label-grid {
          display: flex !important;
          margin-left: -10px;
        }
      }
    }
  }
}

.fine-slab__frame--block::-webkit-scrollbar,
.fine-slab__frame::-webkit-scrollbar {
  @include scrollbar();
}

.fine-slab__frame--block::-webkit-scrollbar-thumb,
.fine-slab__frame::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.fine-slab__frame--block::-webkit-scrollbar-thumb:hover,
.fine-slab__frame::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
