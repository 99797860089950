@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.delete-student {
  margin: 10px 20px 0px 20px;
  height: calc(100% - 220px);

  &__select-options {
    column-gap: 10px;

    #search {
      margin-top: 0px;
    }
  }

  &__table {
    @include table();
    @include tableCheckbox();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-slno {
      width: calc(100% - 96%);
    }

    &--admno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-admno {
      width: calc(100% - 92%);
    }

    &--amount {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-amount {
      width: calc(100% - 90%);
    }

    &--desc {
      width: calc(100% - 94%);
    }
  }

  &__tableblock {
    height: calc(100% - 40px);
    margin-top: 10px;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;

    &--total-count {
      @include totalCountShadowEffect();
      margin-top: 2px;
    }
  }

  &__total {
    text-align: right;
  }
}

.delete-student__table::-webkit-scrollbar {
  @include scrollbar();
}

.delete-student__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.delete-student__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}