@use "../scss/common/mixins" as *;
.demo {
  &__header {
    &--button-block {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
      margin-right: 30px;
    }

    &--button {
      background-color: #ff0000;
      color: #ffffff;

      font-weight: 700;
      font-family: "Josefin Sans";
      font-size: 16px;
      padding: 8px 20px;
      border-radius: 0px;
      border: none !important;
    }
  }

  &__form {
    padding: 0px 10px 0px 0px;
    height: 550px;
    column-gap: 60px;
    display: flex;

    h4 {
      color: #ff0000;
      font-weight: 700;
      font-family: "Josefin Sans";
      text-align: center;
      margin-top: 10px;
    }

    &--image {
      width: 50%;
      height: 600px;
      background-color: #dfebf9;

      img {
        width: 500px;
        height: 600px;
        display: block;
        margin: auto;
      }
    }

    &--data {
      width: calc(100% - 50%);
    }

    &--data-block {
      display: block;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      padding: 0px 10px;
    }

    &--button {
      display: flex;
      column-gap: 30px;
      margin-top: 20px;
    }

    &--submit {
      @extend .demo__header--button;
      background-color: green;
    }

    &--cancel {
      @extend .demo__header--button;
    }
  }

  &__banner {
    width: 100%;

    &--image {
      width: 100%;

      &--mobile {
        display: none;
      }
    }
  }

  &__footer {
    &--data-block {
      padding: 20px;
      display: flex;
      justify-content: space-around;

      &--flex {
        display: flex;
        align-items: center;
        column-gap: 5px;

        b {
          font-size: 18px;
        }
      }

      span {
        font-size: 18px;
        font-family: "Josefin Sans";
        display: block;
        font-weight: 600;
      }

      h3 {
        margin-top: 10px;
        border-bottom: 1px solid grey;
        width: fit-content;
        color: #374151;
      }

      h4 {
        margin-top: 10px;
        font-weight: 700;
      }

      a {
        color: black;
        font-size: 18px;
        font-family: "Josefin Sans";
        display: block;
        padding: 5px;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          color: black;
        }
      }
    }

    &--copyright {
      background-color: #54595f;
      height: 80px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;
      font-weight: 700;
      font-size: 20px;
      color: white;
      font-family: "Josefin Sans";
    }
  }

  &__label {
    display: block;
    margin-top: 10px;
    font-size: 16px;
    font-family: "Josefin Sans";
    font-weight: 700;
  }

  &__input {
    width: 100%;
    height: 35px;
    border-radius: 3px;
    border: 1px solid #e2e8f0;
    margin-top: 10px;
  }

  &__textarea {
    border-radius: 3px;
    border: 1px solid #e2e8f0;
    margin-top: 5px;
    width: 100%;
  }

  &__grid {
    display: grid;
    grid-template-columns: 0.6fr 1fr;
  }

  &__captcha {
    height: 50px;
    width: 100%;
    display: flex;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    margin-top: 10px;

    &--text {
      width: calc(100% - 30px);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--level-1);

      span {
        color: var(--level-5);
        font-weight: 700;
        font-size: 16px;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
      }
    }

    img {
      width: 18px;
      display: block;
      margin: auto;
    }
  }
}

.nav {
  height: 80px;
  width: 100%;
  background-color: #002e50;
  opacity: 0.95;
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  align-items: center;
  justify-content: space-around;
  font-stretch: expanded;
}

.nav > .nav-header {
  display: inline;
}

.nav > .nav-header > .nav-title {
  display: flex;
  font-size: 1.625rem;
  color: #ffffff;
  padding: 10px 10px 10px 10px;
  font-family: "Josefin Sans";
  align-items: center;
  font-weight: 550;
  line-height: 1;
  letter-spacing: 0.05em;
  background-clip: text;
  -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;

  img {
    margin-right: 10px;
    width: 55px;
  }

  h2 {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}

.nav > .nav-btn {
  display: none;
}

.nav > .nav-links {
  display: inline;
  float: right;
}

.nav > .nav-links > a {
  align-items: center;
  padding: 10px 15px;
  color: rgba(255, 255, 255, 0.9);

  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  font-weight: 500;
  font-family: "Josefin Sans";
  text-decoration: none;
  position: relative;
  left: 20px;
  font-size: 20px;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(90deg, #f8f8f8, #e0e0e0);

  span {
    color: #ffffff;
  }
}

.nav > .nav-links > a:hover {
  background-color: #002e50;
}

.nav > #nav-check {
  display: none;
}

@media (max-width: 600px) {
  .nav {
    height: 60px;
    justify-content: space-between;
  }

  .nav > .nav-header > .nav-title {
    img {
      width: 40px;
    }
  }

  .demo__banner--image {
    display: none;
  }

  .demo__banner--image--mobile {
    display: flex;
    width: 100%;
    height: 60%;
  }

  .demo__form--data-block {
    width: 100%;
  }

  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }

  .nav > .nav-btn > label:hover,
  .nav #nav-check:checked ~ .nav-btn > label {
    background-color: #002e50;
  }

  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 8px;
    border-top: 2px solid #eee;
  }

  .nav > .nav-links {
    position: absolute;
    display: block;
    width: 100%;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
    background-color: #fff;
  }

  .nav > .nav-links > a {
    display: block;
    width: 100%;
    color: black;
    font-family: "Josefin Sans";
    font-size: 16px;
    background-image: none !important;
    left: 0px;

    span {
      color: black;
    }
  }

  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }

  .nav > #nav-check:checked ~ .nav-links {
    height: fit-content;
    overflow-y: auto;
  }

  .demo__footer--data-block {
    display: block;
  }

  .demo__form--image {
    display: none;
  }

  .demo__form--data {
    width: 100%;
  }

  .demo__form {
    display: block;
    height: fit-content;
  }

  .demo__header--button-block {
    display: none;
  }

  .demo__footer--copyright {
    font-size: 16px;
  }

  .demo__grid {
    display: block;
  }

  .demo__footer--data-block {
    width: 100%;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .nav {
    height: 60px;
    justify-content: space-between;
  }

  .nav > .nav-header > .nav-title {
    img {
      width: 40px;
    }
  }

  .demo__banner--image {
    display: none;
  }

  .demo__banner--image--mobile {
    display: flex;
    width: 100%;
    height: 60%;
  }

  .demo__form--data-block {
    width: 100%;
  }

  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }

  .nav > .nav-btn > label:hover,
  .nav #nav-check:checked ~ .nav-btn > label {
    background-color: #002e50;
  }

  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 8px;
    border-top: 2px solid #eee;
  }

  .nav > .nav-links {
    position: absolute;
    display: block;
    width: 100%;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
    background-color: #fff;
  }

  .nav > .nav-links > a {
    display: block;
    width: 100%;
    color: black;
    font-family: "Josefin Sans";
    font-size: 16px;
    background-image: none !important;
    left: 0px;

    span {
      color: black;
    }
  }

  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }

  .nav > #nav-check:checked ~ .nav-links {
    height: fit-content;
    overflow-y: auto;
  }

  .demo__footer--data-block {
    display: block;
  }

  .demo__form--image {
    display: none;
  }

  .demo__form--data {
    width: 100%;
  }

  .demo__form {
    display: block;
    height: fit-content;
  }

  .demo__header--button-block {
    display: none;
  }

  .demo__footer--copyright {
    font-size: 16px;
  }

  .demo__grid {
    display: block;
  }

  .demo__footer--data-block {
    width: 100%;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .nav {
    height: 60px;
    justify-content: space-between;
  }

  .nav > .nav-header > .nav-title {
    img {
      width: 40px;
    }
  }

  .demo__banner--image {
    display: none;
  }

  .demo__banner--image--mobile {
    display: flex;
    width: 100%;
    height: 60%;
  }

  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 0px;
  }

  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }

  .nav > .nav-btn > label:hover,
  .nav #nav-check:checked ~ .nav-btn > label {
    background-color: #002e50;
  }

  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 8px;
    border-top: 2px solid #eee;
  }

  .nav > .nav-links {
    position: absolute;
    display: block;
    width: 100%;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
    background-color: #fff;
  }

  .nav > .nav-links > a {
    display: block;
    width: 100%;
    color: black;
    font-family: "Josefin Sans";
    font-size: 16px;
    background-image: none !important;
    left: 0px;

    span {
      color: black;
    }
  }

  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }

  .nav > #nav-check:checked ~ .nav-links {
    height: fit-content;
    overflow-y: auto;
  }

  .demo__footer--data-block {
    display: block;
  }

  .demo__form--image {
    display: none;
  }

  .demo__form--data {
    width: 100%;
  }

  .demo__form {
    display: block;
    height: fit-content;
  }

  .demo__header--button-block {
    display: none;
  }

  .demo__footer--copyright {
    font-size: 16px;
  }

  .demo__grid {
    display: block;
  }

  .demo__footer--data-block {
    width: 100%;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.demo-list {
  @include mainFrameWithTitle();

  &__select {
    column-gap: 10px;
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 95px);
    border: 1px solid (--border-color);
  }
}
