@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.preview {
  margin: 0px 20px 0px 20px;
  height: calc(100% - 210px);

  &--modal {
    height: calc(100% - 85px);
  }
  Label {
    margin-top: 0px;
  }
  Input {
    margin-top: 0px;
  }
  Select {
    margin-top: 0px;
  }
  &__imagegrid {
    display: flex;
    img {
      padding-left: 10px;
    }
  }
  &__image {
    @include studentProfile();
    margin-top: 0px !important;
  }
  &__textfield {
    @include textfield();
    margin: 10px 10px 0px 0px !important;
  }
  &__span-4 {
    column-count: 4;
    column-gap: 20px;
  }
  &__details {
    height: 120px;
    column-gap: 20px;
    margin-top: 10px;
    border-bottom: 1.8px dashed var(--border-color);
    &--textfield {
      @include textfield();
      margin-left: 0px !important;
      margin: 6px 0px 0px 0px !important;
      &--date {
        @include textfield();
        margin-left: 0px !important;
        margin: 6px 0px 0px 0px !important;
        width: fit-content !important;
      }
    }
  }
  &__frame {
    margin-top: 10px;
    height: calc(100% - 140px);
    &--modal {
      height: calc(100% - 115px);
    }

    &--data {
      height: calc(100% - 45px);
      overflow: auto;
    }
    &--parent {
      display: flex;
      .data-fetch-icon {
        margin-top: 10px;
      }
    }
    &--parent-info {
      height: fit-content;
      column-count: 4;
      column-gap: 20px;
      &--image {
        height: 120px;
        margin-left: 20px;
        @include studentProfile();
      }
    }
  }

  &__docs_upload {
    height: calc(100% - 180px);
  }
}

.preview__frame--data::-webkit-scrollbar,
.preview-parentstudentframe::-webkit-scrollbar,
.preview-parentstudentupdateframe::-webkit-scrollbar {
  @include scrollbar();
}

.preview__frame--data::-webkit-scrollbar-thumb,
.preview-parentstudentframe::-webkit-scrollbar-thumb,
.preview-parentstudentupdateframe::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.preview__frame--data::-webkit-scrollbar-thumb:hover,
.preview-parentstudentframe::-webkit-scrollbar-thumb:hover,
.preview-parentstudentupdateframe::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
.preview-parentstudentframe {
  height: calc(100% - 195px);
  overflow: auto;
}
.preview-parentstudentupdateframe {
  height: calc(100% - 285px);
  overflow: auto;
}

.fee-payable-student {
  height: calc(100% - 235px);

  &__details {
    height: calc(100% - 30px);
    &--table {
      @include Accountstable();
      &--slno {
        width: calc(100% - 94%) !important;
        font-family: fonts.$eduate-number-font;
      }
      &--amt {
        width: calc(100% - 92%) !important;
        font-family: fonts.$eduate-number-font;
      }
      &--status {
        width: calc(100% - 92%) !important;
      }
      &--th-slno {
        width: calc(100% - 94%) !important;
      }
      &--th-amt {
        width: calc(100% - 92%) !important;
      }
    }
  }
  &__tableblock {
    height: 100px;
    margin: 10px 0px 0px 0px;
  }
  &__demand-structure {
    height: calc(100% - 140px);
    margin: 10px 0px 0px 0px;

    &--table {
      @include Accountstable();

      &--slno {
        width: calc(100% - 94%) !important;
        font-family: fonts.$eduate-number-font;
      }
      &--amt {
        width: calc(100% - 92%) !important;
        font-family: fonts.$eduate-number-font;
      }
      &--th-slno {
        width: calc(100% - 94%) !important;
      }
      &--th-amt {
        width: calc(100% - 92%) !important;
      }
    }
  }
  &__btns {
    margin-left: 20px;
    padding-top: 10px;
  }
}

.previous-inst-update {
  height: calc(100% - 265px) !important;
  margin: 0px 20px 0px 20px;
}

.studentlist-update::-webkit-scrollbar,
.previous-inst-update::-webkit-scrollbar {
  @include scrollbar();
}

.studentlist-update::-webkit-scrollbar-thumb,
.previous-inst-update::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.studentlist-update::-webkit-scrollbar-thumb:hover,
.previous-inst-update::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
