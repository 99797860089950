@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.fine-assoc {
  height: calc(100% - 210px);
  margin: 0px 10px 0px 10px;
  &__tableblock {
    height: calc(100% - 50px);
    .subtitle1 {
      height: 30px;
    }
  }
  &__details {
    margin: 0px 10px 0px 5px;
    &--multiselect {
      .MuiChip-label {
        font-family: fonts.$eduate-font-family;
        font-size: 14px !important;
      }
      .MuiChip-root {
        height: 25px !important;
      }
      .MuiAutocomplete-tag {
        margin: -4px 3px !important;
      }
    }
  }
  &__table {
    @include table();
    height: calc(100% - 30px);
    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-slno {
      width: calc(100% - 96%);
    }
    &--desc {
      width: calc(100% - 80%);
    }
    &--actions {
      width: calc(100% - 92%);
    }
  }
  &__link-image {
    display: block;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  &__fine-list {
    height: calc(100% - 30px);
    overflow: auto;
  }
}

.fine-assoc__table::-webkit-scrollbar,
.fine-assoc__fine-list::-webkit-scrollbar {
  @include scrollbar();
}

.fine-assoc__table::-webkit-scrollbar-thumb,
.fine-assoc__fine-list::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.fine-assoc__table::-webkit-scrollbar-thumb:hover,
.fine-assoc__fine-list::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
