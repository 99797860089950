@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.delete-account-ledger {
  height: calc(100% - 75px);

  &__tableblock {
    height: calc(100% - 50px);
  }

  &__table {
    @include table();
    margin-top: 10px;

    &--slno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-slno {
      width: calc(100% - 92%);
    }

    &--actions {
      width: calc(100% - 88%);
    }
  }

  &__total {
    text-align: right;
  }
}

.delete-account-ledger__table::-webkit-scrollbar {
  @include scrollbar();
}

.delete-account-ledger__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.delete-account-ledger__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}