@use "../../../scss/common//mixins" as *;
@use "../../../scss/common/fonts" as fonts;
@use "../../../scss/common/colors" as colors;
.transport-masters {
    height: calc(100% - 160px);
    margin: 0px 20px;

    &__datablock {
        margin-top: 10px;
        height: calc(100% - 60px);
        column-gap: 20px;
        display: flex;
        justify-content: space-between;

    }

    &__left {
        height: 100%;
        width: 280px;
        box-shadow: 2px 2px 7px rgba(80, 80, 80, 0.5);
        padding: 5px;

        &--ul {
            height: calc(100% - 80px);
            overflow: auto;
            margin-bottom: 0px !important;
            margin-top: 10px;

            li {
                padding: 8px;
                font-family: fonts.$eduate-font-family;
                font-size:fonts.$eduate-default-font-size;
                margin-top: 5px;
                border-radius: 6px;

                &:hover {
                    background-color: var(--level-1);
                }
            }

            &--active {
                font-weight: 700;
                color: var(--level-7);
                background-color: var(--level-2);
            }
        }
    }

    &__right {
        width: calc(100% - 280px);
        border: 1.8px dashed var(--border-color);
        padding: 5px;

        &--header {
            display: flex;
            justify-content: space-between;
        }

        &--input {
            width: 240px;
            column-gap: 10px;

            .container__list--addnew {
                margin-right: 0px !important;
            }
        }
    }

    &__tableblock {
        height: calc(100% - 50px);
        margin-top: 10px;

        &--modal {
            height: calc(100% - 95px);
            margin-top: 10px;
        }
    }

    &__table {
        @include table();

        &--slno {
            width: calc(100% - 93%);
            font-family: fonts.$eduate-number-font;
        }

        &--actions {
            width: calc(100% - 93%);
        }

        &--model {
            td {
                background-color: var(--bg-color);
                color: var(--level-5);
                font-weight: 700;
            }
        }
    }
}

.transport-masters__left--ul::-webkit-scrollbar {
    @include scrollbar();
}

.transport-masters__left--ul::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.transport-masters__left--ul::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}