@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.recreate-rights {
  @include mainFrameWithTitle();

  &__select-options {
    column-gap: 20px;
    height: 100px;

    .MuiAutocomplete-root {
      margin-top: 8px;
    }
    border-bottom: 1.8px dashed var(--border-color);

    &--image-flex {
      display: flex;

      .data-fetch-icon {
        margin-top: 8px !important;
      }
    }
  }

  &__textfield {
    @include textfield();
    margin: 10px 0px 0px 0px !important;

    &--date {
      @extend .recreate-rights__textfield;
      width: fit-content !important;
    }
  }

  &__colspan {
    column-count: 2;
    column-gap: 20px;
    height: 100%;
  }

  &__datablock {
    border: 1px solid var(--border-color);
    border-radius: 6px;
    height: calc(100% - 155px);
    margin-top: 10px;
  }
}
