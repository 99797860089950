
@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.pendingdemand-view {
  height: calc(100% - 150px);
  border: 2px solid #dedede;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  overflow: auto;
  &__buttons {
    margin-left: 10px;
  }
  &__table {
    @include table();
  }
  &__block1 {
    background-color:  colors.get-color(colors.$pink, level_1);
    border-radius: 20px;
    padding: 10px;
    tr:nth-child(even) {
      background-color:  colors.get-color(colors.$pink, level_1);
    }
  }
  &__block2 {
    background-color:  colors.get-color(colors.$purple, level_1);
    border-radius: 20px;
    padding: 10px;
    margin-top: 10px;
    tr:nth-child(even) {
      background-color:  colors.get-color(colors.$purple, level_1);
    }
  }
  &__block3 {
    background-color:  colors.get-color(colors.$sky, level_1);
    border-radius: 20px;
    padding: 10px;
    margin-top: 10px;
    tr:nth-child(even) {
      background-color:  colors.get-color(colors.$sky, level_1);
    }
  }
  &__block4 {
    background-color:  colors.get-color(colors.$violet, level_2);
    border-radius: 20px;
    padding: 10px;
    margin-top: 10px;
    tr:nth-child(even) {
      background-color:  colors.get-color(colors.$violet, level_2);
    }
  }
  &__block5 {
    background-color:  colors.get-color(colors.$yellow, level_1);
    border-radius: 20px;
    padding: 10px;
    margin-top: 10px;
    tr:nth-child(even) {
      background-color:  colors.get-color(colors.$yellow, level_1);
    }
  }
}
.pendingdemand-view::-webkit-scrollbar {
  @include scrollbar();
}
.pendingdemand-view::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.pendingdemand-view::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
