@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.feed-back {
 @include mainFrameWithTitle();
  &__details {
    height: 190px;
    column-gap: 40px;
    margin-top: 10px;
    &--left {
      height: 100%;
      border: 1px solid var(--border-color);
      padding: 5px;
      border-radius: 10px;

      &--grid {
        display: grid;
        grid-template-columns: 0.4fr 1fr;
      }
      &--flex{
        display: flex;
        column-gap: 10px;
        margin-top: 10px;
        .MuiSwitch-root{
          margin-left: 10px !important;
        }
      }
    }
    &--right {
      @extend .feed-back__details--left;
    }
  }
  &__total {
    padding: 5px;
    height: 40px;
    background-color: var(--level-1);
    display: flex;
    justify-content: center;
    column-gap: 15px;
    width: 120px;
    align-items: center;
    margin-top: 10px;
    &--left {
      background-color:  colors.get-color(colors.$Green, level_2);
      padding: 4px 10px;
      border-radius: 6px;
      color: var(--text-color);
    }
    &--right {
      @extend .feed-back__total--left;
      background-color: colors.get-color(colors.$red, level_5);
      color: white;
    }
  }
  &__tableblock {
    height: calc(100% - 305px);
    margin-top: 10px;
  }
  &__table {
    @include table();
    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--action {
      width: calc(100% - 96%);
    }
    &--input {
      @include inputReportsTable();
      input {
        background-color:  colors.get-color(colors.$yellow, level_1);
        &:focus {
          color: colors.get-color(colors.$colors, black) !important;
          filter: none !important;
          background-color:colors.get-color(colors.$colors, white);
        }
      }
    }
    &--select {
      @include selectTableRow();
    }
  }
}
