@use "../common/fonts" as fonts;
.menus {
  height: 30px;
  background-color: var(--level-6);

  .active {
    color: var(--level-6) !important;
    &:hover {
      text-decoration: none !important;
    }
  }
  .border-right {
    border-right: 2px dashed var(--border-color);
    height: 100%;
  }
  hr {
    width: 50px;
    border: 1px solid var(--level-7);
    border-radius: 6px;
    margin: 0px;
    opacity: 1 !important;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }

  li {
    float: left;
  }

  .dropbtn {
    display: inline-block;
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 14px;
    font-family: fonts.$eduate-font-family;
    padding: 5px 10px;
    height: 30px;
    background-color: var(--level-6);
    border-radius: 0px;
    .MuiSvgIcon-root {
      font-size: 14px;
    }
    &:hover {
      background-color: white;
      color: var(--level-6) !important;
      .MuiSvgIcon-root {
        transform: rotate(90deg);
      }
      .menus__dropdown-content {
        background-color: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(16.133333206176758px);
      }
    }
  }

  li.menus__dropdown {
    display: inline-block;
  }
  .image-white {
    filter: invert(100%);
  }

  &__dropdown-content {
    display: none;
    position: absolute;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    padding: 5px 10px;
    overflow: hidden;
    height: fit-content;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(16.133333206176758px);
    &:hover {
      .dropbtn {
        background-color: white;
        color: var(--level-6) !important;
        .MuiSvgIcon-root {
          transform: rotate(90deg);
        }
      }
    }
    &--flex {
      display: flex;
      column-gap: 10px;
    }

    &--title {
      display: flex;
      align-items: center;
      column-gap: 10px;
      h6 {
        font-family: fonts.$eduate-font-family;
        font-size: 18px;
        color: var(--text-color);
        font-weight: 700;
      }
    }
    &--image {
      background-color: var(--level-6);
      border-radius: 6px;

      img {
        width: 40px;
        height: 40px;
        padding: 2px;
      }
    }

    &--routes {
      margin-top: 5px;
      padding:3px 5px;
      display: block !important;
      text-align: left !important;
      color: var(--text-color) !important;
      font-family: fonts.$eduate-font-family;
      font-size: 16px;
      &:hover {
        color: var(--level-5) !important;
        text-decoration: underline;
      }
    }
  }
}
.menus__dropdown:hover .menus__dropdown-content {
  display: block;
}
.menus__dropdown-content--routes {
  color: black !important;
  padding: 5px;
  text-decoration: none;

  border-radius: 2px;
}
li .menus__dropdown-content--routes:hover,
.menus__dropdown:hover .dropbtn {
  background-color: white;
  color: var(--text-color);
}
