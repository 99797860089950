@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.student-demand {
  height: calc(100% - 70px);

  &__details {
    height: calc(100% - 105px);
    gap: 30px;

    &--autocomplete {
      display: grid;
      grid-template-columns: 0.8fr 2fr;
      gap: 20px;
      margin-top: 10px;

      .MuiFormLabel-root {
        z-index: 6 !important;
      }


      &--buttons {
        Button {
          margin-top: 0px;
        }
      }
    }

    &--options {
      height: 100px;
    }

    &--frames {
      height: 100%;
    }
  }

  &__options {
    display: grid;
    grid-template-columns: 0.5fr 3.5fr;
    padding: 5px;

    Label {
      margin-top: 5px !important;
    }

    .MuiAutocomplete-root {
      width: 100%;
    }



    &--button {
      display: block;
      margin-left: auto;
      margin-right: 0px;
    }
  }

  .MuiFormControl-root {
    margin-top: 3px !important;
  }

  &__pendinglist {
    padding-top: 2px;
  }

  &__tableblock {
    height: 100%;
  }

  &__title {
    color: var(--level-5);
    display: flex;
    justify-content: center;
    margin-top: 0px;
  }

  &__pending {
    height: 115px;
    overflow: hidden;

    &--table {
      @include Accountstable();
      height: calc(100% - 20px);

      &--slno {
        width: 10%;
        font-family: fonts.$eduate-number-font;
      }

      &__class {
        width: 45%;
      }

      &__pending {
        width: 45%;
      }
    }

    &--view {
      height: calc(100% - 30px);
    }
  }

  &__table {
    @include Accountstable();
    height: calc(100% - 30px);

    &--th-slno {
      width: 4%;
    }

    &--th-amt {
      width: 8%;
    }

    &--slno {
      width: 4%;
      font-family: fonts.$eduate-number-font;
    }

    &--amt {
      width: 8%;
      font-family: fonts.$eduate-number-font;
    }

    &--desc {
      width: calc(100% - 88%);
    }

    &--status {
      width: calc(100% - 95%);
    }
  }

  &__gridradio {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__tabledata-unraised {
    height: calc(100% - 30px);
  }
}