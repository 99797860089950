
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.fee-receipt-book {
  height: calc(100% - 215px);
  display: flex;
  gap: 20px;
  margin: 5px 20px 0px 20px;

  &__typesblock {
    width: 250px;
    box-shadow: 2px 2px 7px rgba(80, 80, 80, 0.5);

    &--table {
      @include table();

      &--input {
        @include inputReportsTable();
      }

      tr:hover {
        .bank-book__table--ledger {
          @include tdContainsOnclick();
        }
      }
    }
  }

  &__tableblock {
    padding: 5px;
    width: calc(100% - 270px);
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);

    &--title {
      display: flex;
      gap: 10px;

      .subtitle1 {
        text-align: left;
        margin-left: 20px;
      }

      &--datefield {
        display: flex;
        justify-content: flex-end;
      }

      &--textfield {
        @include textfield();
        margin: 0px 10px 0px 0px !important;

        input {
          color: var(--text-color) !important;
          font-weight: 400 !important;
        }

        &--date {
          @include textfield();
          margin: 0px 10px 0px 0px !important;
          width: fit-content !important;

          input {
            color: var(--text-color) !important;
            font-weight: 400 !important;
          }
        }
      }
    }

    &--table {
      @include table();

      tr:hover {
        .fee-receipt-book__tableblock--table--name {
          @include tdContainsOnclick();
        }
      }

      margin-top: 10px;
      height: calc(100% - 85px);

      &--rcptno {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--regno {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--date {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }

      &--amount {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }

      &--paidthrough {
        width: calc(100% - 88%);
      }

      &--th-rcptno {
        width: calc(100% - 92%);
      }

      &--th-regno {
        width: calc(100% - 92%);
      }

      &--th-date {
        width: calc(100% - 90%);
      }

      &--th-amount {
        width: calc(100% - 88%);
      }
    }

    &--buttons {
      display: flex;
      justify-content: flex-end;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;

    &--total {
      text-align: right;
    }


  }
}