
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.feecollected {
  height: calc(100% - 45px);
  &--modal {
    height: calc(100% - 70px);
  }
  &__tableblock {
    width: 200px;
    border-right: 1px solid white;
    box-shadow: 2px 2px 7px rgba(80, 80, 80, 0.5);
    height: 100%;
  }

  &__reportblock {
    height: 100%;
    width: calc(100% - 200px);

    #search {
      margin-top: 0px !important;
    }

    &--table {
      tr:hover {
        background-color: var(--level-2);

        td {
          color: var(--level-7) !important;
        }

        .feecollected__reportblock--table--name {
          @include tdContainsOnclick();
        }
      }
    }

    &--details {
      border: 2px dashed colors.get-color(colors.$grey, level_3);
      margin: 10px 0px 0px 10px;
      height: calc(100% - 40px);
      padding: 10px;
    }

    &--filter-options {
      margin: 5px 0px 0px 10px;
      column-gap: 10px;

      &--search {
        margin-top: 0px;
      }
    }

    &--date {
      display: flex;
      gap: 10px;

      .MuiFormControl-root {
        width: fit-content !important;
      }
    }

    &--textfield {
      @include textfieldInForms();
      margin-top: 0px !important;
    }
  }
  &__table{
    margin-top: 10px;
    height: calc(100% - 45px);
  }



  &__select-options {
    column-gap: 10px;
    .storybook-input{
      margin-top: 0px !important;
    }
  }

  &__filter-columns {
    @include filterColumns();
  }

  &__total {
    text-align: right;
    .student-total-count{
      margin-right: 0px !important;
    }
  }
}
