
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.balance-sheet {
  height: calc(100% - 220px);
  margin: 0px 20px 0px 20px;

  &__modal {
    height: calc(100% - 85px);
    margin: 0px;

    &--textfield {
      width: fit-content !important;
      @include textfield();
    }

    &--tableblock {
      margin-top: 10px;
      height: calc(100% - 85px);
      column-gap: 30px;
    }

    &--year {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      margin-right: 0px;
    }
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 60px);
    column-gap: 30px;

    &--table {
      @include table();

      tr:hover {
        .balance-sheet__tableblock--table--desc {
          @include tdContainsOnclick();
        }
      }

      &--amount {
        width: 200px !important;
        font-family: fonts.$eduate-number-font;
      }

      &--th-amount {
        width: 200px !important;
      }

      &--groupheader {
        @include groupHeader();

        Input {
          margin: auto;
          height: 28px;
        }

        th {
          text-align: left !important;
          padding-left: 10px;
        }
      }

      &--subheader {
        @include subHeader();
      }

      &--rowheader {
        background-color: var(--bg-color);

        td {
          text-align: center !important;
          border-bottom: 2px dashed var(--border-color) !important;
          border-right: none !important;
          padding: 6px;
          color: var(--level-6) !important;
          font-weight: 600;

          &:hover {
            background-color: var(--bg-color) !important;
          }
        }
      }

      &--total {
        background-color: var(--bg-color);

        td {
          border-style: none !important;
        }

        &:hover td {
          background-color: var(--bg-color) !important;
        }
      }
    }
  }

  &__footer {
    height: 25px;
    column-gap: 30px;
    padding-top: 10px;

    &--total {
      border-top: 1.8px dashed var(--border-color);
      display: flex;
      justify-content: flex-end;
      padding-top: 5px;

      &--count {
        width: 220px;
        color: var(--text-color);
        text-align: right;
        font-family: fonts.$eduate-number-font;
        font-size:fonts.$eduate-default-font-size;
        font-weight: 600;
        padding-right: 5px;
        background: colors.get-color(colors.$Green, level_5);
      }

      &--text {
        width: 180px;
        font-family: fonts.$eduate-font-family;
        @extend .balance-sheet__footer--total--count;
        background: var(--bg-color);
      }
    }
  }

  &__difference-amount {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size:fonts.$eduate-default-font-size;
    margin-right: 18px;
    border-top: 1px dashed var(--border-color);
    background-color: var(--level-1);
    padding: 5px;

    span {
      font-family: fonts.$eduate-font-family;

    }

    b {
      font-family: fonts.$eduate-number-font;

    }

    &--column {
      column-gap: 20px;
    }
  }
}