@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.daily-fee-collected {
  display: flex;
  margin-left: 20px;
  gap: 10px;
  &__textfield {
    @include textfield();
    width: fit-content;
    input {
      font-weight: 400 !important;
    }
  }
  &__frame {
    column-gap: 20px;
    margin: 10px 20px 0px 20px;
    height: calc(100% - 175px);
    overflow: auto;
    &--configure {
      display: flex;
      padding-left: 10px;
      Label {
        margin-top: 5px;
      }
    }
  }
  &__block {
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);

    &--details {
      padding-right: 10px;
    }
  }
}

.daily-fee-collected__frame::-webkit-scrollbar {
  @include scrollbar();
}

.daily-fee-collected__frame::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.daily-fee-collected__frame::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

//cash balance report
.cash-balance-report {
  margin-right: 15px;
  &__finyr {
    display: flex;
    justify-content: flex-end;
    margin: 0px 10px 0px 0px;
    border-right: 4px solid var(--level-5);
    padding-right: 10px;
    Label {
      color: var(--level-5);
      margin-top: 0px;
      font-weight: bold !important;
    }
    input {
      margin-top: 5px !important;
    }
  }
  &__tableblock {
    margin: 10px 20px 0px 20px;
    height: calc(100% - 171px);
  }
  &__table {
    @include Accountstable();
    &--date {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--amount {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-date {
      width: calc(100% - 96%);
    }
    &--th-amount {
      width: calc(100% - 92%);
    }
  }
}

.cash-balance-report__table::-webkit-scrollbar {
  @include scrollbar();
}

.cash-balance-report__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.cash-balance-report__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
//cash flow details

.cash-flow-details {
  &__tableblock {
    column-gap: 20px;
    height: calc(100% - 171px);
    margin: 10px 20px 0px 20px;
  }
  &__table {
    @include Accountstable();
    &--slno {
      width: calc(100% - 93%);
      font-family: fonts.$eduate-number-font;
    }
    &--amount {
      width: calc(100% - 85%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-slno {
      width: calc(100% - 93%);
    }
    &--th-amount {
      width: calc(100% - 85%);
    }
    &--group-header {
      th {
        font-size: 16px;
        font-weight: bold;
        background-color: var(--level-2);
        color: colors.get-color(colors.$colors, black);
        padding: 8px;
        height: 40px;
      }
    }
    &--sub-header {
      th {
        top: 40px;
      }
    }
  }
}

.cash-flow-details__table::-webkit-scrollbar {
  @include scrollbar();
}

.cash-flow-details__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.cash-flow-details__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
