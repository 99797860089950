@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.feedemand-arrearfee-individual {
  height: calc(100% - 140px);
  margin: 10px 20px 0px 20px;
  &__details {
    height: 120px;
    gap: 20px;

    &--imagefield {
      display: flex;
      .MuiAutocomplete-root {
        margin-top: 5px;
      }
    }
    &--textfield {
      @include textfield();
      margin-top: 8px !important;
    }
    &--new-fee {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
    &--image {
      @include studentProfile();
    }
    &--button {
      margin: 0px 0px 0px 0px;
      width: fit-content;
    }
  }
  &__tableblock {
    height: calc(100% - 150px);
    &--table {
      @include table();
      &--slno {
        font-family: fonts.$eduate-number-font;
        width: calc(100% - 96%);
      }
      &--amount {
        font-family: fonts.$eduate-number-font;
        width: calc(100% - 88%);
      }
      &--th-slno {
        width: calc(100% - 96%);
      }
      &--th-amount {
        width: calc(100% - 88%);
      }
    }
  }
}
