@use "../../../../scss/common/mixins" as*;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.academics__exam {
  height: 100%;
  padding: 5px 10px;

  &--constants {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;

    &--cards {
      @include blocksInDashboard();
      height: 70px;

      &--test-conducted {
        font-family: fonts.$eduate-number-font;
        font-weight: 700;
        font-size: 16px;
        color:colors.get-color(colors.$fuchsia, level_5);
        display: flex;
        justify-content: center;
      }

      &--me-completed {
        @extend .academics__exam--constants--cards--test-conducted;
        color: colors.get-color(colors.$Green, level_5);
      }

      &--pending-me {
        @extend .academics__exam--constants--cards--test-conducted;
        color: colors.get-color(colors.$red, level_6);
      }
    }
  }

  &--filters {
    column-gap: 10px;

    &--textfield {
      width: fit-content !important;
      @include textfieldInForms();
    }

    &--flex {
      display: flex;
      column-gap: 10px;
      justify-content: flex-end;

      img {
        margin-top: 5px;
      }
    }
  }

  &--details {
    height: calc(100% - 220px);
    margin-top: 10px;

    overflow: auto;

    &--li {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      height: 205px;
    }

    &--cards {
      @include blocksInDashboard();
      height: 100% !important;

      &--bg-green {
        @extend .academics__exam--details--cards;
        background-color: colors.get-color(colors.$Green, level_1);
      }

      &--bg-red {
        @extend .academics__exam--details--cards;
        background-color: colors.get-color(colors.$red, level_1);
      }

      &--derivative {
        @include derivativeSpan();
        display: block;
        margin-top: 2px;
        margin-left: auto;
        margin-right: auto;
      }
      &--nonacademic {
        @include nonAcademicSpan();
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: fit-content;
      }

      .derivative-logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 40px;
        height: 40px;
      }

      &--title {
        display: flex;
        justify-content: center;
        color: var(--text-color);
      }

      &--sub-code {
        font-size: 12px;
        font-family: fonts.$eduate-font-family;
        display: block;
        text-align: center;
      }

      &--flex {
        display: flex;
        justify-content: space-between;
      }

      &--subjects {
        text-align: left;

        &--total {
          font-family: fonts.$eduate-font-family;
          font-size: 12px;
          color: var(--text-color);
          height: 20px;
        }

        &--number {
          font-family: fonts.$eduate-number-font;
          font-size: 10px;
          color: var(--text-color);
          display: block;
          text-align: center !important;
          font-weight: 700;
        }
      }

      &--marks {
        text-align: right;

        &--number {
          color: colors.get-color(colors.$Green, level_5);
          @extend .academics__exam--details--cards--subjects--number;
        }
      }

      &--date {
        font-size: 12px;
        font-family: fonts.$eduate-number-font;
        background-color: colors.get-color(colors.$cool_grey, level_1);
        padding: 2px 10px;
        border-radius: 5px;
      }

      &--button {
        display: flex;
        justify-content: center;

        .storybook-button {
          margin-top: 5px !important;
        }
      }

      &--icons {
        display: flex;
        justify-content: center;
        column-gap: 10px;
        height: 25px;
      }

      Label {
        margin-top: 5px !important;
        padding: 0px !important;
      }
    }
  }

  &--choose-sub {
    height: 100%;

    &--tableblock {
      height: calc(100% - 70px);
      column-gap: 30px;

      .nodata {
        height: 50px;
        font-size: 12px;
        font-weight: 500 !important;
        margin-top: 5px;
      }
    }

    &--table {
      @include table();
      @include tableCheckbox();
      margin-top: 5px;
      height: calc(100% - 60px);

      &--parent-row {
        display: flex !important;
        justify-content: space-between !important;
        color: var(--level-6) !important;
        font-size: 16px !important;
        font-weight: 600;
        background-color: var(--bg-color) !important;

        &--image {
          display: flex;
          gap: 10px;

          b {
            font-family: fonts.$eduate-number-font;
            background-color: var(--disabled-color);
            padding: 3px 5px;
            font-size: 12px;
            border-radius: 30px;
            margin: 2px;
            color: var(--text-color);
          }

          img {
            width: 20px;
          }
        }
      }

      &--disabled-row {
        opacity: 0.5;
      }

      .academics__exam--choose-sub--table--link:hover {
        @include tdContainsOnclick();
      }

      &--subjects {
        display: flex !important;
        justify-content: space-between !important;

        img {
          width: 20px;
        }
      }
    }
  }

  &--marks-entry {
    margin: 5px 20px 0px 20px;
    height: calc(100% - 170px);

    &--options {
      column-gap: 10px;
    }

    &--textfield {
      @include textfield();

      &--date {
        @include textfield();
        width: fit-content !important;
      }
    }

    &--table {
      @include table();
      @include inputReportsTable();
      &--textarea {
        display: flex !important;
        align-items: center;
        .storybook-textarea {
          margin-top: 0px !important;
          background-color: var(--level-1) !important;
          &:focus {
            background-color: var(--bg-color) !important;
          }
        }
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
    &--select-sub {
      margin-top: 10px;
      column-gap: 20px;

      @include muiMultiSelect();
    }

    &--date {
      display: flex;
      gap: 10px;
      justify-content: flex-end;
    }

    &--subtitle {
      margin-top: 15px;

      .subtitle1 {
        text-align: left !important;
      }
    }

    &--tableblock {
      margin-top: 10px;
      height: calc(100% - 176px);
    }

    &--table {
      @include table();

      &--grade {
        width: calc(100% - 92%);
        .MuiFormControl-root {
          margin-top: 0px !important;
        }
        .MuiAutocomplete-root {
          margin-top: 0px !important;
        }
      }
      &--select-row {
        @include selectInTable();
      }

      &--groupheader {
        height: 30px;
      }

      &--subheader {
        height: 25px;

        th {
          top: 30px;
          background-color: var(--level-8);
        }
      }

      &--subheader1 {
        th {
          top: 55px;
          background-color: var(--level-8);
        }
      }

      &--marks {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }

      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }

      &--regno {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }

      &--input {
        width: calc(100% - 88%);
        @include inputReportsTable();
        padding: 0px !important;
      }

      &--switch {
        width: calc(100% - 88%);
      }
    }
  }
  &--remarks {
    height: 100%;
    &--title {
      img {
        @include closeIconInModal();
      }
    }
    &--subtitle {
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-family: fonts.$eduate-font-family;
      color: var(--level-4);
    }
    .storybook-textarea {
      height: calc(100% - 255px) !important;
    }
    &--details {
      column-gap: 10px;
    }
  }
}

.academics__exam--details::-webkit-scrollbar {
  @include scrollbar();
}

.academics__exam--details::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.academics__exam--details::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
