@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.book-reservation {
  &__frame {
    height: calc(100% - 175px);
    margin: 0px 10px 10px 10px;
    &--image-grid {
      display: grid;
      grid-template-columns: 0.8fr 2.75fr 0.1fr;
      img {
        background-color: var(--level-5);
        padding: 4px;
        border-radius: 6px;
        margin-left: 10px;
        margin-top: 10px;
        height: 30px;
      }
    }
    &--label-grid {
      display: grid;
      grid-template-columns: 1.47fr 2fr;
    }
    &--list-header {
      float: right;
      Input {
        margin-top: 0px;
      }
    }
    &--data {
      height: 100%;
      overflow: auto;
    }
  }
  &__date {
    margin: 0px 10px 0px 20px;
    gap: 10px;
    &--filter {
      display: flex;
      justify-content: flex-end;
      Label {
        color: var(--level-5);
        margin-top: 5px;
      }
      Input {
        margin-right: 20px;
      }
    }

    &--textfield {
      display: flex;
      justify-content: flex-end;
      border-left: 4px solid var(--level-4);
    }
  }
  &__textfield {
    @include textfield();
    margin-top: 0px;
    width: fit-content;
  }
  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 40px);
  }
  &__table {
    @include table();
    &--slno {
      width: calc(100% - 95%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-slno {
      width: calc(100% - 95%);
    }
    &--clsno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-clsno {
      width: calc(100% - 92%);
    }
    &--number {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-number {
      width: calc(100% - 90%);
    }
    &--rsvid {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-rsvid {
      width: calc(100% - 88%);
    }
  }
  &__label-grid {
    display: grid;
    grid-template-columns: 0.8fr 3fr;
  }
}

.book-reservation__table::-webkit-scrollbar,
.book-reservation__frame--data::-webkit-scrollbar {
  @include scrollbar();
}

.book-reservation__table::-webkit-scrollbar-thumb,
.book-reservation__frame--data::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.book-reservation__table::-webkit-scrollbar-thumb:hover,
.book-reservation__frame--data::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
