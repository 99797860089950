@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.formtype-form {
    height: calc(100% - 135px);
    margin: 0px 20px;
    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        img {
            width: 40px;
            height: 40px;
        }
    }
    &__flex {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        &--header {
            width: fit-content;
            background-color: colors.get-color(colors.$cool_grey, level_2);
            padding: 10px;
            font-size: 16px;
            font-family: fonts.$eduate-font-family;
            display: flex;
            border-radius: 6px;

            &--acd {
                font-family: fonts.$eduate-font-family;
                font-size: 14px;
                background-color: var(--level-4);
                color: white;
                font-weight: 600;
                padding: 2px 10px;
                border-radius: 2px;
                width: fit-content;
            }
        }
        &--dates {
            display: flex;
            column-gap: 5px;
            align-items: center;
            img {
                width: 25px;
            }
        }
        &--textfield {
            @include textfield();
            width: fit-content !important;
        }
    }
    &__datablock {
        height: calc(100% - 160px);
        margin-top: 10px;
        overflow: auto;
        ul {
            margin-bottom: 0px !important;
        }
        li {
            display: flex;
            margin-top: 15px;
            justify-content: space-between;
            box-shadow:
                0px 10px 15px -3px rgba(0, 0, 0, 0.1),
                0px 4px 6px -2px rgba(0, 0, 0, 0.05);
            align-items: center;
            border: 1px solid var(--border-color);
            padding: 10px;
            align-items: center;
            border-radius: 6px;
        }
        padding: 5px 10px 5px 5px;
        &--flex {
            display: grid;
            column-gap: 30px;
            grid-template-columns: repeat(3, 1fr);
        }
        &--s {
            display: flex;
            column-gap: 5px;
            font-family: fonts.$eduate-font-family;
            &--name {
                font-family: fonts.$eduate-font-family;
                font-size: 16px;
                color: var(--level-6);
                display: block;
            }
            &--code {
                font-size: 14px;
                color: colors.get-color(colors.$cool_grey, level_6);
                background-color: colors.get-color(colors.$cool_grey, level_2);
                padding: 2px 5px;
            }
        }
        &--emp {
            width: 200px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: flex;
            column-gap: 10px;
            align-items: center;
            img{
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }

            &--block {
                display: block;
            }
            &--name {
                font-weight: 700;
                font-family: fonts.$eduate-font-family;
                font-size: 14px;
                color: var(--text-color);
            }
            &--id {
                color: colors.get-color(colors.$cool_grey, level_6);
                font-size: 14px;
                display: block;
            }
        }
        &--status {
            border-right: 1px dashed var(--border-color);
            padding-right: 15px;
            &--comp {
                display: flex;
                column-gap: 10px;
                align-items: center;
                font-family: fonts.$eduate-font-family;
                font-size: 14px;
                background-color: colors.get-color(colors.$Green, level_1);
                padding: 5px;
                img {
                    width: 40px;
                    height: 40px;
                }
            }
            &--not-comp {
                @extend .formtype-form__datablock--status--comp;
                background-color: colors.get-color(colors.$red, level_1);
                img {
                    width: 40px;
                    height: 40px;
                }
            }
        }
        &--arrow{
            display: flex;
            align-items: center;
            img{
                width:25px;
                height: 25px;
            }
        }
    }
}

.formtype-form__datablock::-webkit-scrollbar {
    @include scrollbar();
}

.formtype-form__datablock::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.formtype-form__datablock::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}
