.view-daily-att-report {
    height: calc(100% - 170px);
    margin: 0px 20px;
    display: flex;
    column-gap: 30px;
    &--left {
        height: 100%;
        width: 280px;
        border: 1px solid var(--border-color);
        border-radius: 6px;
    }
    &--right {
        width: calc(100% - 280px);
        padding: 10px;
        height: 100%;
        border: 1px solid var(--border-color);
        border-radius: 6px;
        &--tableblock{
            height: calc(100% - 200px);
            margin-top: 10px;
        }
        &--filter{
            margin-top: 10px;
        }
        &--filters{
            column-gap: 10px;
        }
    }
}
