@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.book-entry {
  margin-right: 20px;
  &__filter-columns {
    margin-top: 10px;
    @include filterColumns();
  }
  &__tableblock {
    margin: 10px 20px 0px 20px;
    height: calc(100% - 260px);
    &--modal {
      margin: 10px 0px 0px 0px;
      height: calc(100% - 123px);
    }
  }
  &__table {
    @include table();
    img {
      margin: 2px;
    }
    &--slno {
      width: calc(100% - 95%);
      font-family: fonts.$eduate-number-font;
    }
    &--accessno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-slno {
      width: calc(100% - 95%);
    }
    &--th-accessno {
      width: calc(100% - 92%);
    }
    &--publication {
      width: calc(100% - 92%);
    }
    &--authors {
      width: calc(100% - 90%);
    }
    &--subjects {
      width: calc(100% - 96%);
    }
    &--total {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--actions {
      width: calc(100% - 95%);
    }
  }
  &--modal {
    #search {
      margin-left: 0px;
    }
  }
}
.library-book-icon {
  filter: invert(0%);
}

.book-entry__table::-webkit-scrollbar {
  @include scrollbar();
}

.book-entry__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.book-entry__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
