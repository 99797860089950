@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.non-demandfee-payable {
  margin: 10px 20px 0px 20px;
  height: calc(100% - 175px);
  &__select {
    column-gap: 20px;
    height: 120px;

  }
  &--modal {
height: calc(100% - 30px);
  }

  &__tableblock {
    margin-top: 10px ;
    height: calc(100% - 320px);

    &--deposit-ob {
      margin-top: 10px;
      height: calc(100% - 180px);
    }
  }


  &__table {
    @include Accountstable();
    height: 100%;

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-slno {
      width: calc(100% - 96%);
    }
    &--th-balance {
      width: calc(100% - 93%);
    }
    &--actions {
      width: calc(100% - 93%);
      img {
        width: 20px;
      }
    }
    &--balance {
      width: calc(100% - 93%);
      font-family: fonts.$eduate-number-font;
      input {
        &:focus {
          background-color:colors.get-color(colors.$colors, white);
        }
      }
      img {
        width: 20px;
      }
    }
    select {
      @include selectInTable();
    }

    &__borderbottom {
      border-bottom: 1px solid colors.get-color(colors.$cool_grey, level_5) !important;
      background-color: colors.get-color(colors.$yellow, level_3);
      position: sticky;
      position: -webkit-sticky;
    }
    &--select-row {
      @include selectTableRow();
      @include autoCompleteInTableRow();
      .MuiAutocomplete-root,
      .auto-complete:focus {
        background-color: var(--bg-color) !important;
      }
    }
    tfoot td {
      position: sticky;
      position: -webkit-sticky;
    }
  }
  &__image {
    @include studentProfile();
  }
  &__receiptblock {
    margin-top: 10px;
    height: 135px;
    column-gap: 20px;
    &--narration {
      margin-top: 0px;
    }
    .account-frames {
      margin: 0px 0px 0px 0px !important;
    }
    Label {
      margin-top: 0px;
    }
    Input {
      margin-top: 3px !important;
    }

    .MuiFormControl-root {
      margin-top: 3px !important;
    }

  }

  &__grid {
    display: flex;

    .MuiAutocomplete-root {
      width: 100% !important;
      margin: 5px 0px 3px 0px;
    }
  }

  &__label-gridbtn {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    gap: 5px;
    Button {
      padding: 8px;
      font-size: 12px;
      margin: 8px 0px 0px 0px;
    }
  }
}

.non-demandfee-payable__table::-webkit-scrollbar {
  @include scrollbar();
}
.non-demandfee-payable__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.non-demandfee-payable__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
.MuiInputBase-root {
  font-family: fonts.$eduate-number-font;
  font-size: 14px !important;
}
