@use "../../../scss/common/mixins" as *;
@use "../../../scss/common/fonts" as fonts;
@use "../../../scss/common/colors" as colors;

.prs {
  width: 100%;
  height: 100%;

  &__top {
    height: 245px;

    &--inst {
      display: flex;
      column-gap: 10px;
    }
    &--logo {
      height: 120px;
      width: 120px;

      img {
        height: 100%;
        width: 100%;
      }
    }

    &--inst-details {
      display: block;
      margin: auto;

      &--name {
        color: colors.get-color(colors.$sky, level-7);
        font-size: 18px;
        display: flex;
        justify-content: center;
      }

      &--address {
        font-family: fonts.$eduate-font-family;
        font-size: fonts.$eduate-default-font-size;
        display: flex;
        justify-content: center;
      }

      b {
        display: block;
        text-align: center;
        font-size: 22px;
        text-transform: uppercase;
      }

      &--logo {
        height: 100%;
        width: 20%;
        border-right: 1px solid var(--text-color);

        img {
          height: 100%;
          width: 100%;
        }
      }

      &--label-grid {
        display: flex;
        column-gap: 10px;

        .Label {
          margin-top: 1px !important;
          padding: 0px !important;
        }
      }

      &--data {
        width: calc(100% - 20%);
        padding: 5px;
      }
    }

    &--receipt {
      display: flex;
      justify-content: space-between !important;
      border-bottom: 1px solid var(--text-color);
      padding-bottom: 5px;
      &--number {
        font-size: fonts.$eduate-default-font-size;
      }

      &--date {
        font-size: fonts.$eduate-default-font-size;
      }
      &--name {
        text-align: center;
        display: block;
        font-size: 16px;
        font-family: fonts.$eduate-font-family;
      }
    }

    &--std-details {
      padding: 5px;
      border-left: 1px solid var(--text-color);
      border-right: 1px solid var(--text-color);
      border-bottom: 1px solid var(--text-color);
      font-family: fonts.$eduate-number-font;

      &--data {
        display: flex;
        column-gap: 5px;
        font-size: fonts.$eduate-default-font-size;
      }

      &--grids {
        display: flex;
        justify-content: space-between;
      }

      &--date {
        span {
          display: block;
          font-size: fonts.$eduate-default-font-size;
        }
      }
    }
  }

  &__tableblock {
    border: 1px solid var(--text-color) !important;
    height: calc(100% - 425px);
  }

  &__table {
    empty-cells: show !important;
    height: calc(100% - 30px) !important;

    th {
      padding: 0px;
      text-align: center;
      border-bottom: 1px solid var(--text-color) !important;
      font-family: fonts.$table-font-family;
      font-weight: 700;
      td{
        height: 30px !important;
      }
    }

    &--th-slno {
      border-right: 1px solid var(--text-color) !important;
    }

    &--th-fee {
      border-right: 1px solid var(--text-color) !important;
    }

    td {
      padding: 0px;
      font-style: normal;
      font-size: 18px !important;
      font-family: fonts.$table-font-family;
      border-bottom: 1px solid var(--text-color) !important;
      padding-left: 3px;
      padding-right: 3px;
      color: var(--text-color);
      height:30px !important
    }

    tbody {
      border-bottom: 1px solid var(--text-color);

      td {
        border-bottom: 1px solid var(--text-color) !important;
      }
    }

    &--slno {
      width: 80px;
      border-right: 1px solid var(--text-color) !important;
    }

    &--fee {
      width: calc(100% - 230px);
      border-right: 1px solid var(--text-color) !important;
    }
    &--total {
      height: 30px;
      display: flex;
      width: 100%;
      border-top: 1px solid var(--text-color);
      align-items: center;
      &--text {
        width: calc(100% - 150px);
        font-size: 18px;
        font-family: fonts.$eduate-number-font;

        text-align: right;
        border-right: 1px solid var(--text-color);
        padding-right: 5px;
      }
    }
    &--amount {
      span {
        font-size: 18px;
        font-family: fonts.$eduate-number-font;
        padding-right: 5px;
        font-weight: 700;
        padding-top: 3px;
      }

      width: 150px;
      text-align: right;
    }
  }
  &__bottom {
    width: 100%;
    height: 165px;
    &--flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      b {
        font-size: 10px;
        font-family: fonts.$eduate-font-family;
      }
    }

    &--amount-in-words {
      font-size: fonts.$eduate-default-font-size;
      display: block;
    }

    &--signature {
      text-align: right;
      margin-top: 10px;
    }

    &--payment-method {
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
    }

    &--narration {
      font-size: 12px !important;
      display: block;
      font-family: fonts.$eduate-font-family;
    }
    &--simplify {
      font-size: 14px !important;
      font-family: fonts.$eduate-font-family;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 5px;
      img {
        width: 40px;
        height: 40px;
      }
    }

    &--copy {
      text-align: center;
      font-size: 12px !important;
      display: block;
    }

    &--table-footer-balance {
      padding-top: 3px;
      padding-right: 5px;
      font-size: 14px;
      font-weight: 700;
      font-family: fonts.$eduate-font-family;
    }

    &--table-footer-balance-amount {
      font-size: 14px;
      font-family: fonts.$eduate-number-font;
      padding-right: 5px;

      padding-top: 3px;
    }
  }
}
.prs-a5-l {
  width: 100%;
  height: 100%;

  .prs__top--logo {
    height: 75px;
    width: 75px;
  }
  .prs__top--inst-details--address,
  .prs__top--inst-details--name {
    font-size: 16px !important;
  }
  .prs__bottom {
    font-size: 12px !important;
  }
  th td{
    height: 25px;
    font-size: 16px !important;
  }
  td{
    height: 25px;
    font-size: 16px !important;
  }
  .prs__tableblock{
    height: calc(100% - 380px) !important;
  }
  .prs__top{
    height: 207px !important;
  }
}
