
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.page-config {
  height: calc(100% - 285px);
  overflow: auto;
  width: 100%;
  &__data {
    column-count: 4;
    margin: 10px 20px 0px 40px;
    column-gap: 20px;
    column-rule: 4px double colors.get-color(colors.$colors, cloud-white);

    .MuiButtonBase-root {
      padding: 0px !important;
    }
  }
  &__label-grid {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
    margin-left: 20px;
  }
}

//pageconfig index
.pageconfig {
  &__select-grid {
    display: grid;
    grid-template-columns: 1.2fr 3.4fr;
  }
}

.eduate-pageconfig {
  height: calc(100% - 220px);
  margin: 0px 10px 10px 10px;
  &__details {
    height: 100%;
    &--list {
      height: calc(100% - 40px);
      overflow-y: auto;
      .select-all {
        margin-left: 10px !important;
        .MuiButtonBase-root,
        .MuiCheckbox-root {
          padding: 0px !important;
        }
      }
      &--flex {
        column-count: 2;
        margin: 10px 20px 0px 40px;
        column-gap: 20px;
        column-rule: 4px double colors.get-color(colors.$colors, cloud-white);
        Label {
          margin-top: 5px !important;
        }
        &--data {
          border-bottom: 1px dashed var(--level-2);
          display: flex;
        }
        .Mui-checked {
          color: var(--level-5) !important;
        }
        .MuiButtonBase-root,
        .MuiCheckbox-root {
          padding: 0px !important;
        }
      }
    }
  }
}

.eduate-pageconfig__details--list::-webkit-scrollbar,
.page-config::-webkit-scrollbar {
  @include scrollbar();
}

.eduate-pageconfig__details--list::-webkit-scrollbar-thumb,
.page-config::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.eduate-pageconfig__details--list::-webkit-scrollbar-thumb:hover,
.page-config::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
