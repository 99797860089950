:root,
:root.blue {
  --level-1: #ebf8ff;
  --level-2: #bee3f8;
  --level-3: #90cdf4;
  --level-4: #63b3ed;
  --level-5: #4299e1;
  --level-6: #3182ce;
  --level-7: #2b6cb0;
  --level-8: #2c5282;
  --level-9: #2a4365;
  --level-10: #1a365d;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.pink {
  --level-1: #fff5f7;
  --level-2: #fed7e2;
  --level-3: #fbb6ce;
  --level-4: #f687b3;
  --level-5: #ed64a6;
  --level-6: #d53f8c;
  --level-7: #b83280;
  --level-8: #97266d;
  --level-9: #702459;
  --level-10: #521b41;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.amber {
  --level-1: #fffbeb;
  --level-2: #fef3c7;
  --level-3: #fde68a;
  --level-4: #fcd34d;
  --level-5: #fbbf24;
  --level-6: #f59e0b;
  --level-7: #d97706;
  --level-8: #b45309;
  --level-9: #92400e;
  --level-10: #78350f;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.grey {
  --level-1: #f7fafc;
  --level-2: #edf2f7;
  --level-3: #edf2f7;
  --level-4: #cbd5e0;
  --level-5: #a0aec0;
  --level-6: #718096;
  --level-7: #4a5568;
  --level-8: #2d3748;
  --level-9: #1a202c;
  --level-10: #171923;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.cyan {
  --level-1: #edfdfd;
  --level-2: #c4f1f9;
  --level-3: #9decf9;
  --level-4: #76e4f7;
  --level-5: #0bc5ea;
  --level-6: #00b5d8;
  --level-7: #00a3c4;
  --level-8: #0987a0;
  --level-9: #086f83;
  --level-10: #065666;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.emarald {
  --level-1: #ecfdf5;
  --level-2: #d1fae5;
  --level-3: #a7f3d0;
  --level-4: #6ee7b7;
  --level-5: #34d399;
  --level-6: #10b981;
  --level-7: #059669;
  --level-8: #047857;
  --level-9: #065f46;
  --level-10: #064e3b;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.fuchsia {
  --level-1: #fdf4ff;
  --level-2: #fae8ff;
  --level-3: #f5d0fe;
  --level-4: #f0abfc;
  --level-5: #e879f9;
  --level-6: #d946ef;
  --level-7: #c026d3;
  --level-8: #a21caf;
  --level-9: #86198f;
  --level-10: #701a75;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.Green {
  --level-1: #f0fff4;
  --level-2: #c6f6d5;
  --level-3: #9ae6b4;
  --level-4: #68d391;
  --level-5: #48bb78;
  --level-6: #38a169;
  --level-7: #25855a;
  --level-8: #276749;
  --level-9: #22543d;
  --level-10: #1c4532;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.indigo {
  --level-1: #eef2ff;
  --level-2: #e0e7ff;
  --level-3: #c7d2fe;
  --level-4: #a5b4fc;
  --level-5: #818cf8;
  --level-6: #6366f1;
  --level-7: #4f46e5;
  --level-8: #4338ca;
  --level-9: #3730a3;
  --level-10: #312e81;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.lime {
  --level-1: #f7fee7;
  --level-2: #ecfccb;
  --level-3: #d9f99d;
  --level-4: #bef264;
  --level-5: #a3e635;
  --level-6: #84cc16;
  --level-7: #65a30d;
  --level-8: #4d7c0f;
  --level-9: #3f6212;
  --level-10: #365314;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.orange {
  --level-1: #fffaf0;
  --level-2: #feebcb;
  --level-3: #fbd38d;
  --level-4: #f6ad55;
  --level-5: #ed8936;
  --level-6: #dd6b20;
  --level-7: #c05621;
  --level-8: #9c4221;
  --level-9: #7b341e;
  --level-10: #652b19;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.pink {
  --level-1: #fdf2f8;
  --level-2: #fce7f3;
  --level-3: #fbcfe8;
  --level-4: #f9a8d4;
  --level-5: #f472b6;
  --level-6: #ec4899;
  --level-7: #db2777;
  --level-8: #be185d;
  --level-9: #9d174d;
  --level-10: #831843;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.purple {
  --level-1: #faf5ff;
  --level-2: #e9d8fd;
  --level-3: #d6bcfa;
  --level-4: #b794f4;
  --level-5: #9f7aea;
  --level-6: #805ad5;
  --level-7: #6b46c1;
  --level-8: #553c9a;
  --level-9: #44337a;
  --level-10: #322659;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.red {
  --level-1: #fff5f5;
  --level-2: #fed7d7;
  --level-3: #feb2b2;
  --level-4: #fc8181;
  --level-5: #f56565;
  --level-6: #e53e3e;
  --level-7: #c53030;
  --level-8: #9b2c2c;
  --level-9: #822727;
  --level-10: #63171b;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.rose {
  --level-1: #fff1f2;
  --level-2: #ffe4e6;
  --level-3: #fecdd3;
  --level-4: #fda4af;
  --level-5: #fb7185;
  --level-6: #f43f5e;
  --level-7: #e11d48;
  --level-8: #be123c;
  --level-9: #9f1239;
  --level-10: #881337;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.sky {
  --level-1: #f0f9ff;
  --level-2: #e0f2fe;
  --level-3: #bae6fd;
  --level-4: #7dd3fc;
  --level-5: #38bdf8;
  --level-6: #0ea5e9;
  --level-7: #0284c7;
  --level-8: #0369a1;
  --level-9: #075985;
  --level-10: #0c4a6e;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.trueGrey {
  --level-1: #fafafa;
  --level-2: #f5f5f5;
  --level-3: #e5e5e5;
  --level-4: #d4d4d4;
  --level-5: #a3a3a3;
  --level-6: #737373;
  --level-7: #525252;
  --level-8: #3f4e4f;
  --level-9: #262626;
  --level-10: #171717;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.violet {
  --level-1: #f5f3ff;
  --level-2: #ede9fe;
  --level-3: #ddd6fe;
  --level-4: #c4b5fd;
  --level-5: #a78bfa;
  --level-6: #8b5cf6;
  --level-7: #7c3aed;
  --level-8: #6d28d9;
  --level-9: #5b21b6;
  --level-10: #4c1d95;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.yellow {
  --level-1: #fffff0;
  --level-2: #fefcbf;
  --level-3: #faf089;
  --level-4: #f6e05e;
  --level-5: #ecc94b;
  --level-6: #d69e2e;
  --level-7: #b7791f;
  --level-8: #975a16;
  --level-9: #744210;
  --level-10: #5f370e;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
}

:root.teal {
  --level-1: #e6fffa;
  --level-2: #b2f5ea;
  --level-3: #81e6d9;
  --level-4: #4fd1c5;
  --level-5: #38b2ac;
  --level-6: #319795;
  --level-7: #2c7a7b;
  --level-8: #285e61;
  --level-9: #234e52;
  --level-10: #1d4044;
  --bg-color: white;
  --text-color: black;
  --border-color: #e2e8f0;
  --modal-bg-color: rgb(255, 255, 255, 255);
  --disabled-color: #f3f4f6;
  --disabled-border: white;
}
:root.dark {
  --level-1: #404258;
  --level-2: #6b728e;
  --level-3: #edf2f7;
  --level-4: #cbd5e0;
  --level-5: #a0aec0;
  --level-6: #474e68;
  --level-7: #404258;
  --level-8: #2d3748;
  --level-9: #1a202c;
  --level-10: #171923;
  --bg-color: #282c35;
  --text-color: #cbd5e0;
  --img-color: invert(100%);
  --border-color: #fff1f1f1;
  --modal-bg-color: #303030;
  --disabled-color: #1a202c;
}

.theme-image {
  width: 25px !important;
  filter: invert(0%);
}
.theme-option {
  margin-top: -10px !important;
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .MuiSvgIcon-root,
  .MuiSvgIconn-root {
    display: none !important;
  }
  .MuiSelect-select,
  .MuiInputBase-input,
  .MuiOutlinedInput-input {
    padding: 5px !important;
  }
}
.theme-menu {
  display: inline-flex !important;
}
