@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.class-wise-std-count {
  @include mainFrameWithTitle();

  &__select {
    column-gap: 10px;
    margin-top: 5px;
  }

  &__tableblock {
    height: calc(100% - 90px);
    margin-top: 10px;
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--total {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }
  }
}
