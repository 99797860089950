@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.progress-report {
  height: calc(100% - 45px);
  overflow: auto;

  &__inst {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;

    &--logo {
      width: 80px;
      height: 80px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &--details {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      .progress-report__inst--logo {
        display: block;
        margin: auto;
      }
      &--cust-name {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        color: var(--text-color);
        display: block;
      }

      &--inst-name {
        @extend .progress-report__inst--details--cust-name;
        font-size: 24px;
        color: var(--text-color);
      }

      &--address {
        @extend .progress-report__inst--details--cust-name;
      }
    }
    &--logos {
      display: flex;
      padding-bottom: 10px;
    }
  }

  &__test {
    display: flex;
    justify-content: center;
    margin-top: 10px;

    &--name {
      font-size: 16px;
      font-family: fonts.$eduate-font-family;
      color: var(--level-6);
      font-weight: 700;
    }

    &--duration {
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
      color: colors.get-color(colors.$grey, level_7);
    }
  }

  &__group {
    font-size: fonts.$eduate-default-font-size;
    font-family: fonts.$eduate-font-family;
    color: colors.get-color(colors.$grey, level_7);
    text-align: center;
  }

  &__table {
    @include tableWithCompleteBorders();

    &--groupheader {
      height: 30px;
    }

    &--subheader {
      background-color: var(--bg-color) !important;
      top: 30px;
    }

    &--slno {
      width: calc(100% - 94%);
      font-family: fonts.$eduate-number-font;
    }

    &--marks {
      width: calc(100% - 94%);
      font-family: fonts.$eduate-number-font;
    }
  }

  &__table2 {
    @include table();
    width: 40% !important;
    margin-left: auto;
    margin-right: auto;

    th {
      background-color: colors.get-color(colors.$cool_grey, level_6) !important;
    }

    &--slno {
      width: calc(100% - 94%);
      font-family: fonts.$eduate-number-font;
    }

    &--marks {
      width: calc(100% - 94%);
      font-family: fonts.$eduate-number-font;
    }
  }

  &__percentage {
    &--details {
      display: flex;
      column-gap: 10px;
      height: 40px;
      align-items: center;

      img {
        width: 30px;
      }
      span {
        font-family: fonts.$eduate-font-family;
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  &__remarks {
    display: flex;
    column-gap: 10px;
    margin-top: 20px;
    // border: 1px solid var(--border-color);
    border-radius: 6px;
    padding: 5px;

    span {
      font-family: fonts.$eduate-font-family;
      font-size: fonts.$eduate-default-font-size;
      color: colors.get-color(colors.$grey, level_6);
    }

    b {
      font-family: fonts.$eduate-font-family;
      font-size: fonts.$eduate-default-font-size;
      color: var(--text-color);
    }
  }

  &__signature {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    &--text {
      color: colors.get-color(colors.$grey, level_7);
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
      border-top: 2px dashed var(--border-color);
    }
    img {
      width: 80px;
      height: 30px;
    }
    &--image {
      height: 30px;
    }
  }
  &__title {
    display: flex;
    justify-content: center;
    font-family: fonts.$eduate-font-family;
    font-size: 18px;
  }
  &__settings {
    margin-left: 5px;
  }
  &__print {
    height: 100%;
  }
}

.page-break {
  page-break-after: always;
}

.progress-report::-webkit-scrollbar {
  @include scrollbar();
}

.progress-report::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.progress-report::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
