@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.organizational-chart {
  @include mainFrameWithTitle();

  &__tabs {
    margin-top: 10px;

    @include muiTabs();
  }

  &__swipable {
    height: calc(100% - 65px);
    margin-top: 10px;

    &--tabs {
      height: 100% !important;
    }

    .react-swipeable-view-container,
    .MuiTypography-body1 {
      height: 100% !important;
    }
  }

  &__tree-view {
    height: calc(100% - 45px);
    overflow: auto;

    &--level-1 {
      padding: 5px;
      display: flex;
      justify-content: space-between;
      column-gap: 5px;
      border-radius: 6px;
      border: 1px dashed var(--border-color);

      img {
        margin-right: 10px;
      }

      &:hover {
        background-color: var(--level-1);
      }

      &--images {
        display: flex;
      }
    }

    &--level-2 {
      padding: 5px;
      display: flex;
      justify-content: space-between;
      column-gap: 5px;
      border-radius: 6px;
      border-left: 1px dashed var(--border-color);
      border-right: 1px dashed var(--border-color);
      border-bottom: 1px dashed var(--border-color);

      img {
        margin-right: 10px;
      }

      &:hover {
        background-color: var(--level-1);
      }
    }

    &--ol {
      &--level-1 {
        padding-left: 0px !important;
      }

      &--level-2 {
        border-left: 1px dashed var(--border-color);
        padding-left: 30px !important;
      }
    }

    &--menu {
      li {
        padding: 5px;
        color: var(--text-color);
        font-weight: 700;

        &:hover {
          background-color: var(--level-2);
        }
      }
    }

    &--delete-image {
      width: 25px;
      height: 25px;

      &:hover {
        background-color: colors.get-color(colors.$red, level_5);
        border-radius: 50%;
        padding: 3px;

        img {
          filter: invert(100%);
        }
      }
    }

    &--add-image {
      width: 25px;

      &:hover {
        background-color: colors.get-color(colors.$Green, level_5);
        border-radius: 50%;
        padding: 3px;

        img {
          filter: invert(100%);
        }
      }
    }
  }

  &__add {
    height: calc(100% - 75px);
    margin: 10px 0px 10px 10px;

    &--title {
      display: flex;
    }

    &--grid {
      display: grid;
      grid-template-columns: 1fr 1fr 0.2fr;

      .data-fetch-icon {
        margin-top: 10px;
      }
    }

    &--buttons {
      margin-left: 10px;
    }
  }
}

.organizational-chart__tree-view::-webkit-scrollbar {
  @include scrollbar();
}

.organizational-chart__tree-view::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.organizational-chart__tree-view::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
