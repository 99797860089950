@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.add-predefined-salary-ledger {
  height: calc(100% - 75px);
  &__data {
    margin-top: 10px;
    height: calc(100% - 110px);
    .booktype-right,
    .booktype-left {
      margin: 0px !important;
    }
  }
  &__tableblock {
    height: calc(100% - 40px);
    margin-top: 10px;
    &--swap-image {
      width: 60px;
      text-align: center;
      margin: auto;
      img {
        background-color:colors.get-color(colors.$colors, white);
        padding: 5px;
        width: 40px;
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
          0px 2px 4px -1px rgba(0, 0, 0, 0.06);
        border-radius: 50%;
      }
      .opacity {
        opacity: 0.3;
      }
    }
  }
  &__salary-types {
    gap: 70px;
    margin-top: 10px;
    .MuiFormControlLabel-root {
      display: flex !important;
      width: calc(100% - 10px) !important;
      justify-content: space-between !important;
      margin-top: 10px;
      margin-left: 5px !important;
    }
  }
  &__table {
    @include table();
    @include tableCheckbox();
    height: 100%;
    &--checkbox {
      width: calc(100% - 92%);
    }
    &--slno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }
    &--desc {
      width: calc(100% - 86%);
    }
    &--type {
      width: calc(100% - 90%);
    }
  }
}
