.Modal {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  &__image {
    > img {
      width: 140px;
      height: 140px;
      border: 1px solid #e2e8f0;
      margin: 100px;

      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
        inset 4px 3px 3px rgba(0, 0, 0, 0.1);
    }
  }
  &__input--address {
    width: 350px;
    height: 80px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-sizing: border-box;
    border-radius: 6px;
  }

  &__input {
    width: 350px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #e2e8f0;
    box-sizing: border-box;
    border-radius: 6px;
  }
  &__input--banner {
    width: 350px;
    height: 100px;
    background: #ffffff;

    border: 1px solid #e2e8f0;
    box-sizing: border-box;
    border-radius: 6px;
  }
  &__label {
    width: 326px;
    height: 24px;
    font-size: 16px;
    color: #2d3748;
    margin: 2.5px 2px 5px;
    margin-top: 6px;
  }
}

.Moadal__heading {
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  line-height: 20px;

  color: #2d3748;
}
.Modal__btn {
  padding: 10px 24px;

  background: #319795;
  border-radius: 6px;
  color: whitesmoke;
  margin: 25px 250px;
  outline: none;
  border: none;
  float: right;
}
.modal {
  font-size: 1.5rem;
  display: flex;
  margin-top: -20px;
  margin-left: -9px;
  color: var(--level-5);
}
