@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.daily-diary-view {
  @include mainFrameWithTitle();

  &__title {
    img {
      @include closeIconInModal();
    }
  }

  &__frame {
    margin-top: 10px;
    display: flex;
    column-gap: 20px;
    height: calc(100% - 55px);

    &--left {
      height: 100%;
      padding: 5px;
      border: 1px solid var(--border-color);

      &--calendar {
        height: calc(100% - 50px);

        &--flex {
          display: flex;
          justify-content: center;
          column-gap: 20px;
        }
      }
    }

    &--right {
      height: 100%;
      padding: 5px;
      border: 1px solid var(--border-color);

      &--textfield {
        margin: 5px 0px 0px 0px !important;
        @include textfield();
        width: fit-content !important;
      }

      &--block {
        height: calc(100% - 45px);
        margin-top: 5px;
        overflow: auto;

        &--ul {
          height: 100%;

          li {
            padding: 5px;
            border-bottom: 1px dashed var(--border-color);
          }

          b {
            font-family: fonts.$eduate-font-family;
            font-size: 16px;
            font-family: fonts.$eduate-font-family;
            color: colors.get-color(colors.$sky, level_6);
            display: block;
          }

          span {
            font-family: fonts.$eduate-font-family;
            font-size: fonts.$eduate-default-font-size;
          }
        }
      }
    }
  }
}

.daily-diary-view__frame--right--block::-webkit-scrollbar {
  @include scrollbar();
}

.daily-diary-view__frame--right--block::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.daily-diary-view__frame--right--block::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
