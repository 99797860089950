@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.adjust-deposit-fee {
  @include mainFrameWithTitle();
  overflow: auto;
  padding-left: 10px;
  padding-right: 10px;
  @include muiTabs();

  .MuiButtonBase-root {
    min-width: fit-content !important;
  }
  &__tabs {
    display: flex;
    justify-content: space-between;
  }
  &__swipable {
    height: calc(100% - 55px);

    .MuiTypography-root {
      height: 100%;
      position: relative;
    }

    &--data {
      height: 100%;
    }
  }

  &__individual {
    height: 100%;
    &--modal {
      height: calc(100% - 30px);
    }
    &--details {
      margin-top: 10px;
      height: 120px;
      column-gap: 20px;

      &--image-flex {
        display: flex;
        margin-top: 8px;
        .data-fetch-icon {
          margin-top: 0px !important;
        }
      }

      &--textfield {
        @include textfield();
        margin: 10px 10px 0px 0px !important;

        &--date {
          @include textfield();
          width: fit-content !important;
          margin: 10px 10px 0px 0px !important;
        }
      }

      &--inputs {
        display: flex;
        margin: 10px 0px 0px 0px;
        gap: 20px;
      }

      &--label-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
        margin: 10px 0px 0px 0px;
        .MuiInputBase-input {
          padding: 6px !important;
        }

        Button {
          padding: 0px !important;
          margin: 0px;
        }
      }

      &--image {
        @include studentProfile();
        height: 100%;
        margin-top: 11px !important;
        img {
          height: calc(100% - 10px);
          width: 100%;
        }
      }
    }

    &--data {
      border: 1px solid var(--border-color);
      border-radius: 6px;
      height: calc(100% - 175px);
      margin-top: 10px;
      column-gap: 20px;
      p,
      .MuiTypography-root {
        height: fit-content !important;
      }

      &--deposit-details {
        max-width: 400px !important;
        border: 1px solid var(--border-color);
        border-radius: 6px;
        margin: 10px 0px 10px 10px;
        padding: 20px 10px;

        &--totalamtblock {
          border: 1px solid var(--border-color);
          border-radius: 6px;
          height: calc(100% - 50px);
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          background-color: var(--level-1);
          label {
            font-size: 25px !important;
            font-weight: bold !important;
          }
          b {
            font-size: 25px !important;
          }
        }
      }
      &--total-deposits {
        width: calc(50% - 300px);
        border-radius: 6px;
        background-color: var(--level-1);
        margin: 10px 0px;
        padding: 20px 10px;
        &--table {
          height: calc(100% - 50px);
        }
      }
      &--adjust-deposit {
        width: calc(50% - 300px);
        padding: 30px 10px;
        &--label-grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          &--total-count {
            @include totalCountShadowEffect();
          }
        }
        .nodata {
          justify-content: flex-end !important;
        }
      }
      &--modal {
        @extend .adjust-deposit-fee__individual--data;
        height: calc(100% - 185px);
      }
    }
    &--block {
      background-color: var(--level-1);
      padding: 10px 30px;
      border-radius: 6px;
      height: fit-content;

      &--image {
        height: 40px;
        width: 40x;
        display: block;
        margin: auto;
      }
      &--span {
        font-size: 40px;
        display: flex;
        justify-content: center;
      }
    }
  }

  &__group {
    height: 100%;
    &--modal {
      height: calc(100% - 30px);
    }
    &--details {
      margin-top: 10px;
      column-gap: 20px;
      height: 130px;

      &--frames {
        height: 100%;
        border: 1px solid var(--border-color);

        &--label-grid {
          .MuiAutocomplete-root.MyAutocomplete-inputRoot {
            margin-top: 10px !important;
          }
          .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-r8hk9l-MuiAutocomplete-root
            .MuiOutlinedInput-root {
            padding: 0px !important;
          }
          .css-r8hk9l-MuiAutocomplete-root .MuiOutlinedInput-root {
            padding: 0px !important;
          }
          display: grid;
          grid-template-columns: 0.8fr 2fr;
          margin: 0px 10px;
        }
      }

      &--table {
        margin-top: 10px;
        height: calc(100% - 185px);
        &--modal {
          @extend .adjust-deposit-fee__group--details--table;
          height: calc(100% - 195px);
        }
        &--slno {
          display: flex;
          gap: 15px;
        }
      }
   
    }
    .student-total-count {
    
      margin: 12px 0px 0px 20px !important;
      b{
        margin-left: 5px;
      }
    }
  }
}
