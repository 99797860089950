// additional details heading in form
@use "./colors" as colors;
@use "./fonts" as fonts;
@mixin details {
  background: var(--level-5);
  width: 100%;
  height: 35px;
  color: white;
  border-radius: 6px;
  font-family: fonts.$eduate-font-family;
  font-weight: 500;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

// additional details h4 in form

@mixin heading {
  font-family: fonts.$eduate-font-family !important;
  font-size: 18px;
  text-align: center;
  line-height: 36px;
  font-weight: 500;
}

// master-data table

@mixin table {
  max-height: 100%;

  th {
    background-color: var(--level-8);
    padding: 0px 2px;
    color: white;
    text-align: center;
    border-right: 1px solid var(--border-color) !important;
    font-family: fonts.$eduate-font-family;
  }

  td {
    padding: 5px 3px !important;
    font-style: normal;
    font-size: 12px;
    font-family: fonts.$table-font-family;
    border-right: 1px solid var(--border-color) !important;
    border-bottom: 1px solid var(--border-color) !important;

    color: var(--text-color);

    img,
    a {
      &:hover {
        cursor: pointer;
      }
    }
  }

  tbody {
    background-color: var(--level-1);

    tr {
      &:hover {
        background-color: var(--level-2);

        td {
          color: var(--level-7);
        }
      }
    }
  }

  tfoot {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
    overflow: hidden;
    text-align: center !important;
    background-color: var(--bg-color) !important;
    color: var(--text-color) !important;

    td {
      border-style: none !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      font-family: fonts.$table-font-family;
      font-size: 12px;
    }
  }
}

@mixin tableInPrint {
  max-height: 100%;

  th {
    padding: 0px;
    border-top: 1px solid var(--text-color) !important;
    border-bottom: 1px solid var(--text-color) !important;
    font-family: fonts.$table-font-family;
    font-weight: 700;

    td {
      border-right: 1px solid var(--text-color) !important;
    }
  }

  td {
    padding: 0px;
    font-style: normal;
    font-size: fonts.$eduate-default-font-size;
    font-family: fonts.$table-font-family;
    padding-left: 3px;
    padding-right: 3px;
    color: var(--text-color);
    border-right: 1px solid var(--text-color) !important;
  }

  tbody {
    border-style: none;
    height: calc(100% - 25px) !important;

    td {
      border-style: none;
    }
  }

  tfoot {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
    overflow: hidden;
    text-align: center !important;
    background-color: var(--bg-color) !important;
    color: var(--text-color) !important;

    .total {
      border-left: 1px solid var(--text-color);
    }

    td {
      border-top: 1px solid var(--text-color) !important;
      border-bottom: 1px solid var(--text-color) !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      font-family: fonts.$table-font-family;
      font-size: fonts.$eduate-default-font-size;
      font-weight: 700;
    }
  }
}

@mixin tableWithCompleteBorders {
  max-height: 100%;

  th {
    padding: 0px;
    border: 1px solid var(--text-color) !important;
    font-family: fonts.$table-font-family;
    font-weight: 700;

    text-align: center;
  }

  td {
    padding: 0px;
    font-style: normal;
    font-size: fonts.$eduate-default-font-size;
    font-family: fonts.$table-font-family;
    padding-left: 3px;
    padding-right: 3px;
    color: var(--text-color);
    border: 1px solid var(--text-color) !important;
  }

  tbody {
    border-style: none;
    height: calc(100% - 25px) !important;
  }
}

//Receipts Table
@mixin Accountstable {
  max-height: 100%;

  th {
    background-color: var(--level-8);
    padding: 0px;
    color: colors.get-color(colors.$colors, white);
    text-align: center;
    border-right: 1px solid colors.get-color(colors.$grey, level_4);
    font-size: fonts.$eduate-default-font-size;
    font-family: fonts.$table-font-family;
  }

  td {
    padding: 0px;
    font-size: 12px;
    white-space: nowrap !important;
    border-right: 1px solid colors.get-color(colors.$grey, level_4);
    border-bottom: 1px solid colors.get-color(colors.$grey, level_4);
    padding-left: 3px;
    padding-right: 3px;
    font-family: fonts.$table-font-family;
    color: var(--text-color);

    // font-weight: 900;
  }

  tbody {
    background-color: var(--level-1);

    tr {
      &:hover {
        background-color: var(--level-2);

        td {
          color: var(--level-7) !important;
        }
      }
    }
  }

  tfoot {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
    overflow: hidden;
    background-color: var(--bg-color);

    td {
      border-bottom: none;
      border-right: none;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      color: var(--text-color);
    }
  }
}

// //Srollbar styles
@mixin scrollbar {
  width: 18px;
  background-color: colors.get-color(colors.$cool_grey, level_2);
}

@mixin scrollbarthumb {
  border: 5px solid colors.get-color(colors.$cool_grey, level_2);
  background-color: colors.get-color(colors.$cool_grey, level_4);
  border-radius: 30px;
  padding: 5px;
}

@mixin scrollbarthumb-hover {
  border: 3px solid colors.get-color(colors.$cool_grey, level_2);
}

//Select in Table
@mixin selectInTable {
  width: 100% !important;
  margin: 0px;
  padding-left: 0px !important;
  color: colors.get-color(colors.$colors, black) !important;
  border: none !important;
  border-radius: 3px !important;

  &:focus {
    background: colors.get-color(colors.$colors, white);
    filter: none !important;
    border: 1px solid var(--level-5) !important;
  }
}

@mixin selectTableRow {
  background: hsl(55, 79%, 94%);

  &:hover {
    background-color: hsl(55, 79%, 94%) !important;
  }

  .MuiAutocomplete-root:focus {
    background-color: white !important;
  }

  .MuiInputBase-input {
    height: 15px !important;
  }

  td {
    padding: 0px !important;

    .MuiAutocomplete-root {
      padding: 0px !important;
    }
  }
}

// Checkbox in Table
@mixin tableCheckbox {
  .css-1q1u3t4-MuiTableRow-root.Mui-selected {
    background-color: var(--level-4) !important;
  }

  th {
    .MuiSvgIcon-root {
      fill: colors.get-color(colors.$colors, white) !important;
      width: 24px !important;
      height: 24px !important;
      font-size: 14px !important;
    }
  }

  td {
    .MuiSvgIcon-root {
      width: 22px !important;
      height: 22px !important;
      font-size: 14px !important;
    }
  }
}

//total Count with ShadowEffect

@mixin totalCountShadowEffect {
  background: var(--level-5);
  border-radius: 50px;
  width: 40px;
  height: 40px;
  border: 8px solid var(--level-2);
  text-align: center;
  padding: 3px;
  color: colors.get-color(colors.$colors, white);
  flex: none;
  order: 2;
  font-size: 12px;
  flex-grow: 0;
}

//TextFields
@mixin textfield {
  margin: 6px 10px 0px 0px;
  height: 30px;
  width: 100%;

  .MuiInputBase-input {
    padding: 5px !important;
  }

  input {
    font-size: 13px !important;
    font-family: fonts.$eduate-font-family;
    padding-left: 5px !important;
    color: var(--text-color) !important;
    font-weight: bold !important;
  }

  label {
    font-family: fonts.$eduate-font-family;
    font-size: 14px !important;
    color: colors.get-color(colors.$grey, level_6) !important;
    z-index: 8;
  }

  .Mui-disabled {
    input {
      font-size: 14px !important;
      font-family: fonts.$eduate-font-family;
      padding-left: 5px !important;
      -webkit-text-fill-color: var(--text-color) !important;
      font-weight: bold !important;
    }
  }
  .MuiInputBase-input{
    height:1.3em !important;
  }
}

@mixin textfieldInForms {
  height: 30px;
  width: 100%;
  margin-top: 10px !important;

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--level-3) !important;
  }

  .MuiInputBase-input {
    padding: 5px !important;
  }

  input {
    font-size: 14px !important;
    font-family: fonts.$eduate-font-family;
    padding-left: 5px !important;
    color: colors.get-color(colors.$grey, level_8) !important;
    font-weight: 400 !important;
  }

  label {
    font-family: fonts.$eduate-font-family;
    font-size: 16px !important;
    color: var(--text-color) !important;
    z-index: 8;
  }
}

//Student Profile
@mixin studentProfile {
  img {
    height: calc(100% - 10px);
    width: 100%;
    border-radius: 6px;
    border: 1px solid var(--border-color);
    padding: 2px;
    width: 100%;
  }
}

// Table Column Grouping Top Header
@mixin groupHeader {
  th {
    font-size: 16px;
    font-weight: bold;
    background-color: var(--level-2);
    color: colors.get-color(colors.$colors, black);
    height: 30px;

    img {
      width: 20px;
      float: right;
      margin-right: 10px;
      filter: invert(0%);
    }
  }
}

// Table Column Grouping Sub Header

@mixin subHeader {
  th {
    top: 30px;
  }
}

//Select in Reports Table
@mixin selectReportsTable {
  th {
    background: colors.get-color(colors.$colors, white);

    select {
      width: 100% !important;
      background: colors.get-color(colors.$colors, white);
      margin: 0px;
      padding-left: 0px !important;
      color: colors.get-color(colors.$colors, black) !important;
      border: none !important;
      border-radius: 3px !important;
      font-weight: 500;
      height: 20px;

      &:focus {
        background: colors.get-color(colors.$colors, white);
        color: colors.get-color(colors.$colors, black) !important;
        filter: none !important;
        border: 1px solid var(--level-5) !important;
      }
    }
  }
}

// Input in Reports Table
@mixin inputReportsTable {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;

  input {
    margin: 0px;
    border-radius: 0px !important;
    width: 100%;

    &:focus {
      color: colors.get-color(colors.$colors, black) !important;
      filter: none !important;
      border: 1px solid var(--level-5) !important;
    }
  }
}

// Input in Salary Process Table
@mixin inputSalaryTable {
  padding-top: 0px !important;

  Input {
    margin: 0px;
    border-radius: 0px !important;
    background-color: colors.get-color(colors.$yellow, level_2);
    border-style: none;

    &:focus {
      color: colors.get-color(colors.$colors, black) !important;
      filter: none !important;
      background-color: colors.get-color(colors.$colors, white);
    }
  }

  input {
    margin: 0px;
    border-radius: 0px !important;
    background-color: colors.get-color(colors.$yellow, level_2);
    border-style: none;

    width: 100%;
    text-align: right;

    &:focus {
      color: colors.get-color(colors.$colors, black) !important;
      filter: none !important;
      background-color: colors.get-color(colors.$colors, white);
    }
  }
}

// filter Columns table
@mixin filterColumns {
  padding-right: 8px;
  padding-left: 8px;
  border: 1px solid #cbd5e0;
  border-radius: 3px;
  float: right;
  color: var(--text-color);
  font-family: fonts.$eduate-font-family;
  font-size: 12px !important;
}

//in tablerow
@mixin autoCompleteInTableRow {
  .MuiAutocomplete-root {
    margin-top: 0px !important;
    border-radius: 6px;
  }

  .MuiFormControl-root {
    margin-top: 0px !important;
  }

  .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
    background: hsl(55, 79%, 94%) !important;
  }

  .Mui-disabled .MuiOutlinedInput-notchedOutline {
    background: hsl(55, 79%, 94%) !important;
  }

  .MuiInputBase-root .Mui-disabled {
    background: hsl(55, 79%, 94%) !important;
  }
}

@mixin calendarEventsList {
  li {
    display: flex;
    column-gap: 10px;
  }

  &--events {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    background-color: var(--level-2);
    border-radius: 6px;
    width: 100%;
    padding: 5px;
    font-size: 12px;
    border-left: 5px solid colors.get-color(colors.$red, level_7);

    span {
      font-family: fonts.$eduate-number-font;
    }

    b {
      font-family: fonts.$eduate-font-family;
    }

    .Label {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

//browse files button
@mixin browseFiles {
  background: linear-gradient(90deg, #ec008c 0%, #fc6767 100%);
  font-family: "Josefin Sans" !important;
  font-style: normal;
  font-weight: 400;
  font-size: fonts.$eduate-default-font-size;
  border: 0;
  border-radius: 6px;
  margin: 10px 0px 0px 0px;
  color: white;
  padding: 5px;
  text-transform: capitalize;
  height: 32px;
  width: 110px;

  input {
    display: none;
  }

  img {
    padding-right: 5px;
  }
}

//text overflow in table
@mixin textOverFlow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

//table cell contains Route
@mixin tdContainsOnclick {
  text-decoration: underline;

  cursor: pointer;
}

//dashboard blocks

@mixin blocksInDashboard {
  height: fit-content;
  // box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
  //   0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: 1px solid colors.get-color(colors.$grey, level_3);
  transition: transform 0.2s;
  /* Animation */
  padding: 5px;

  &:hover {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
      0px 4px 6px -1px rgba(0, 0, 0, 0.1);
  }
}

// mui-tabs
@mixin muiTabs {
  .MuiTab-root {
    font-family: fonts.$eduate-font-family;
    font-size: 14px !important;
    text-transform: capitalize !important;
    min-height: 20px !important;
    padding: 8px !important;
    color: var(--text-color) !important;
    font-weight: 700;
    border-radius: 30px;
  }

  .MuiTabs-root {
    min-height: 20px !important;
  }

  .Mui-selected {
    color: var(--level-6) !important;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
      0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 30px;
    background-color: var(--bg-color);
  }

  .MuiTabs-indicator {
    background-color: var(--level-1) !important;
  }

  .MuiTabs-flexContainer {
    background: var(--level-1);
    padding: 5px;
    border-radius: 30px;
    width: fit-content !important;
  }
}

@mixin muiTabsInDashboard {
  .MuiTab-root {
    font-family: fonts.$eduate-font-family;
    font-size: 14px !important;
    text-transform: capitalize !important;
    min-height: 20px !important;
    padding: 3px !important;
    color: var(--text-color) !important;
    font-weight: 700;
  }

  .MuiTabs-root {
    min-height: 20px !important;
  }

  .Mui-selected {
    color: var(--level-6) !important;
    box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
      0px 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    background-color: var(--bg-color);
  }

  .MuiTabs-indicator {
    background-color: var(--level-1) !important;
  }

  .MuiButtonBase-root {
    min-width: 60px !important;
  }

  .MuiTabs-flexContainer {
    background: var(--level-1);
    padding: 5px;
    border-radius: 6px;
    width: fit-content !important;
  }
}

//to show details in calendar
@mixin CalendarForAttendance {
  &--grid-container {
    height: calc(100% - 45px);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: auto auto auto auto auto auto auto;
    /* Rows will adjust to fit content */
    gap: 10px;

    &--day {
      text-align: center;
      font-family: fonts.$eduate-font-family;
      font-size: fonts.$eduate-default-font-size;
      height: 20px;
    }
  }

  &--grid-item {
    border-radius: 6px;
    border: 1px solid var(--border-color);
    height: 55px;

    &:hover {
      background-color: var(--level-2);
    }

    &--date {
      font-family: fonts.$eduate-number-font;
      font-size: 12px;
      padding-left: 5px;
    }

    &--font {
      display: flex !important;
      justify-content: flex-end !important;
      margin-left: auto !important;
      margin-right: 0px !important;
      width: fit-content;
      border-radius: 20px 0px 0px 0px;
      padding: 2px 8px;
      background-color: colors.get-color(colors.$warm_Grey, level_2);
      font-family: fonts.$eduate-font-family;
      font-size: 17px;
    }

    &--number {
      font-family: fonts.$eduate-number-font;
      font-size: 17px;
      display: flex !important;
      justify-content: flex-end !important;
      margin-left: auto !important;
      margin-right: 0px !important;
      width: fit-content;
      border-radius: 20px 0px 0px 0px;
      padding: 2px 8px;
      background-color: colors.get-color(colors.$warm_Grey, level_2);
    }

    &--disabled {
      background-color: var(--disabled-color);
      border-radius: 6px;
      border: 1px solid var(--border-color);
      height: 55px;
    }
  }
}

@mixin CalendarShowPresent {
  background-color: colors.get-color(colors.$Green, level_5) !important;
}

@mixin CalendarshowAbsent {
  background-color: colors.get-color(colors.$red, level_7) !important;
}

@mixin CalendarGeneralHolidays {
  border-left: 3px solid colors.get-color(colors.$red, level_7) !important;
}

@mixin CalendarWeekends {
  color: colors.get-color(colors.$red, level_7);
  font-weight: 700;
}

@mixin CalendartoShowOnlyAttendance {
  &--grid-container {
    height: calc(100% - 45px);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: 30px 30px 30px 30px 30px 30px;
    /* Rows will adjust to fit content */
    gap: 5px;

    &--day {
      text-align: center;
      font-family: fonts.$eduate-font-family;
      font-size: fonts.$eduate-default-font-size;
      height: 20px;
    }
  }

  &--grid-item {
    border-radius: 6px;
    border: 1px solid var(--border-color);
    height: 30px;
    text-align: center;

    &:hover {
      background-color: var(--level-2);
    }

    &--date {
      font-family: fonts.$eduate-number-font;
      font-size: 12px;
      padding-left: 5px;
    }

    &--disabled {
      background-color: var(--disabled-color);
      border-radius: 6px;
      border: 1px solid var(--border-color);
      height: 55px;
    }
  }

  &--legends {
    li {
      display: flex;
      gap: 10px;
      margin-top: 10px;
    }

    &--grid-item {
      width: 35px;
      height: 30px;
      border: 1px solid var(--border-color);
      border-radius: 6px;
      text-align: center;
      font-size: 12px;
      padding: 5px;
      font-family: fonts.$eduate-number-font;
    }

    span {
      font-family: fonts.$eduate-font-family;
      font-size: fonts.$eduate-default-font-size;
    }
  }
}

// used in academics to display buttons in the cards
@mixin assignButton {
  padding: 2px 10px;
  background-color: colors.get-color(colors.$Green, level_5);
  color: colors.get-color(colors.$colors, white);
  font-size: fonts.$eduate-default-font-size;
  border-radius: 20px;
  font-family: fonts.$eduate-font-family;

  img {
    width: 15px;
    margin-left: 10px;
  }
}

@mixin removeButton {
  padding: 2px 10px;
  border-radius: 20px;
  font-family: fonts.$eduate-font-family;
  border: 1px solid colors.get-color(colors.$red, level_7);
  color: colors.get-color(colors.$red, level_7);
  font-size: fonts.$eduate-default-font-size;
  background-color: var(--bg-color);

  img {
    width: 15px;
    margin-left: 10px;
  }
}

@mixin replaceButton {
  padding: 2px 10px;
  border-radius: 20px;
  font-family: fonts.$eduate-font-family;
  border: 1px solid colors.get-color(colors.$amber, level_7);
  color: colors.get-color(colors.$amber, level_7);
  font-size: fonts.$eduate-default-font-size;
  background-color: var(--bg-color);

  img {
    width: 15px;
    margin-left: 10px;
  }
}

@mixin setButton {
  padding: 2px 10px;
  border-radius: 20px;
  font-family: fonts.$eduate-font-family;
  color: colors.get-color(colors.$colors, white);
  font-size: fonts.$eduate-default-font-size;
  background-color: colors.get-color(colors.$amber, level_7);

  img {
    width: 15px;
    margin-left: 10px;
  }
}

@mixin updateButton {
  padding: 2px 10px;
  border-radius: 20px;
  font-family: fonts.$eduate-font-family;
  background-color: colors.get-color(colors.$sky, level_5);
  color: colors.get-color(colors.$colors, white);
  font-size: fonts.$eduate-default-font-size;

  img {
    width: 15px;
    margin-left: 10px;
  }
}

@mixin viewButton {
  background-color: colors.get-color(colors.$fuchsia, level_7);
  color: colors.get-color(colors.$colors, white);
  border-radius: 30px;
  font-size: 12px;
  font-family: fonts.$eduate-font-family;
  padding: 2px 10px;

  img {
    width: 20px;
  }
}

@mixin deleteButton {
  background-color: colors.get-color(colors.$red, level_7);
  color: colors.get-color(colors.$colors, white);
  border-radius: 30px;
  font-size: 12px;
  font-family: fonts.$eduate-font-family;
  padding: 2px 10px;

  img {
    width: 20px;
  }
}

@mixin muiMultiSelect {
  .MuiChip-label {
    font-family: fonts.$eduate-font-family;
    font-size: 12px !important;
  }

  .MuiChip-root {
    height: 20px !important;
  }

  .MuiAutocomplete-tag {
    margin: 0px 3px !important;
  }
}

@mixin gridsInExamPlannerAndSubjectDetails {
  background-color: colors.get-color(colors.$grey, level_2);
  height: 100%;
  width: 230px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  padding: 0px 8px;

  img {
    width: 30px;
  }

  span {
    width: calc(100% - 50px);
    font-family: fonts.$eduate-font-family;
    font-size: fonts.$eduate-default-font-size;
    color: var(--text-color);
  }

  b {
    font-family: fonts.$eduate-number-font;
    font-size: fonts.$eduate-default-font-size;
  }
}

@mixin muiChipToShowDates {
  .MuiChip-root {
    background-color: var(--bg-color) !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
      0px 4px 6px -1px rgba(0, 0, 0, 0.1);
    color: var(--level-5);
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
  }
}

@mixin closeIconInModal {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 5px;
  right: 0px;

  &:hover {
    background-color: colors.get-color(colors.$cool_grey, level_2);
  }
}

@mixin settingsIconInModal {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 35px;
  right: 15px;

  &:hover {
    background-color: colors.get-color(colors.$cool_grey, level_2);
  }
}

@mixin derivativeSpan {
  border: 1px solid var(--level-4);
  color: var(--level-4);
  font-size: 12px;
  font-family: fonts.$eduate-font-family;
  border-radius: 2px;
  width: fit-content;
  padding: 0px 5px;
}

@mixin nonAcademicSpan {
  color: var(--text-color);
  font-size: 12px;
  font-family: fonts.$eduate-font-family;
  border-radius: 2px;
  width: fit-content;
  padding: 0px 5px;
  background-color: var(--disabled-color);
}
@mixin mainFrame {
  height: calc(100% - 130px);
  display: flex;
}
@mixin layout {
  margin: 0px 20px 0px 20px;
  width: calc(100% - 210px);
}

@mixin doc-upload {
  &__docs_upload {
    height: calc(100% - 180px);
  }
}

@mixin mainFrameWithTitle {
  margin: 0px 20px;
  height: calc(100% - 165px);
}
