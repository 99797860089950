@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
.library-report {
  margin: 10px 20px 0px 20px;
  height: calc(100% - 220px);
  column-gap: 20px;
  display: flex;
  &__transaction-list {
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
    height: 100%;
    width: 250px;
    &--tableblock {
      height: 100%;
    }
    &--table {
      @include table();
      &--input {
        @include inputReportsTable();
      }
    }
  }
  &__selected-data {
    height: 100%;
    width: calc(100% - 250px);
    &--title {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
    }
    &--table {
      @include table();
      &--slno {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-slno {
        width: calc(100% - 92%);
      }
      &--accessno {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-accessno {
        width: calc(100% - 88%);
      }
      &--number {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-number {
        width: calc(100% - 90%);
      }
      &--date {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-date {
        width: calc(100% - 90%);
      }
      &--amount {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-amount {
        width: calc(100% - 90%);
      }
      &--dept {
        width: calc(100% - 86%);
      }
      &--status {
        width: calc(100% - 95%);
      }

      tr:hover {
        .library-report__selected-data--table--name {
          @include tdContainsOnclick();
        }
      }
    }
    &--tableblock {
      height: calc(100% - 80px);
      margin: 5px 5px 0px 0px;
    }
    &--select-flex {
      // @include autoCompleteInList();
      margin-left: 0px;
      column-gap: 10px;
    }
    &--filterdate {
      display: flex;
      gap: 20px;
    }

    &--textfield {
      @include textfield();
      margin: 5px 5px 0px 0px !important;
    }
  }
  &__issued-books {
    &--tableblock {
      height: calc(100% - 75px);
    }
    &--table {
      @include table();
    }
  }
}
