@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.fill-form {
    @include mainFrameWithTitle();
    &__flex {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        &--header {
            width: fit-content;
            background-color: colors.get-color(colors.$cool_grey, level_2);
            padding: 10px;
            font-size: 16px;
            font-family: fonts.$eduate-font-family;
            display: flex;
            border-radius: 6px;

            &--acd {
                font-family: fonts.$eduate-font-family;
                font-size: 14px;
                background-color: var(--level-4);
                color: white;
                font-weight: 600;
                padding: 2px 10px;
                border-radius: 2px;
                width: fit-content;
            }
        }
        &--sub {
            display: flex;
            font-family: fonts.$eduate-font-family;
            font-size: 12px;
            align-items: center;
            padding: 10px;
            background-color: var(--level-1);
            column-gap: 10px;
            border-radius: 6px;

            b {
                color: var(--level-5);
                font-size: 16px;
            }
            span {
                color: colors.get-color(colors.$cool_grey, level_6);
                background-color:colors.get-color(colors.$cool_grey, level_2);
                padding: 2px;
                border-radius: 2px;
            }
        }
        &--dates {
            display: flex;
            column-gap: 5px;
            align-items: center;
            img {
                width: 25px;
            }
        }
        &--textfield {
            @include textfield();
            width: fit-content !important;
        }
    }
    &__datablock {
        height: calc(100% - 112px);
        margin-top: 10px;
        overflow: auto;
        border: 1px solid var(--border-color);
        border-radius: 6px;
        padding: 10px;
        &--preview{
            height: calc(100% - 110px);
            margin-top: 10px;
            border: 1px solid var(--border-color);
            border-radius: 6px;
            padding: 10px;
            .storybook-textarea {
                height: calc(100% - 240px) !important;
                            }
        }

        &--emp {
            width: 200px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: flex;
            column-gap: 10px;
            align-items: center;
            padding: 5px;
            border: 1px solid var(--border-color);
            border-radius: 6px;

            box-shadow:
                0px 10px 15px -3px rgba(0, 0, 0, 0.1),
                0px 4px 6px -2px rgba(0, 0, 0, 0.05);
            img {
                width: 40px;
                height: 40px;
                border-radius: 50%;
            }

            &--block {
                display: block;
            }
            &--name {
                font-weight: 700;
                font-family: fonts.$eduate-font-family;
                font-size: 14px;
                color: var(--text-color);
            }
            &--id {
                color: colors.get-color(colors.$cool_grey, level_6);
                font-size: 14px;
                display: block;
            }
        }

        &--numbers {
            margin-top: 10px;
            height: 50px;
            width: 100%;
            border-radius: 6px;
            background-color: colors.get-color(colors.$cool_grey, level_2);
            border-color: var(--border-color);

            padding: 10px;

            ul {
                margin-bottom: 0px !important;
                display: flex;
                column-gap: 10px;
            }

            li {
                height: 35px;
                width: 35px;
                border: 1px solid var(--border-color);
                background-color: transparent;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--text-color);
                border-radius: 5px;
                background-color: var(--bg-color);
            }

            .active {
                border: 1px solid var(--level-5);
                color: var(--text-color);
                font-weight: 700;
            }
        }
        &--question {
            margin-top: 20px;
            color: var(--level-5);
            font-weight: 700;
            font-size: 16px;
            font-family: fonts.$eduate-font-family;
        }
        &--remarks{
            display: flex;
            margin-top: 15px;
            column-gap: 10px;
            b{
                font-size: 16px;
                font-family: fonts.$eduate-font-family;
                font-weight: 700;
            }
            span{
                font-size: 14px;
                font-family: fonts.$eduate-font-family;
                color: colors.get-color(colors.$cool_grey, level_5);
            }
          
&--button{
    margin-left: -10px !important;
}
        }
     
    }
}

.form-type__datablock::-webkit-scrollbar {
    @include scrollbar();
}

.form-type__datablock::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.form-type__datablock::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}
