@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.daily-activities {
  @include mainFrameWithTitle();
.assignments__view-details{
  height: calc(100% - 45px) !important;
} 
.assignments__view-details--data{
  height:calc(100% - 50px) !important;
}

  &__select-block {
    &--teacher-details {
      margin-top: 10px;
      height: 60px;
      background-color: colors.get-color(colors.$cool_grey, level_2);
      border-radius: 15px;
      display: flex;
      column-gap: 5px;
      padding: 5px;

      img {
        width: 50px;
        border-radius: 50%;
        display: block;
        margin: auto;
      }

      &--name {
        width: calc(100% - 60px);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        font-size:fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;

        span {
          display: block;
          font-family: fonts.$eduate-number-font;
        }

      }


    }

    &--title {
      display: flex;
      margin-top: 10px;
      justify-content: center;
      font-family: fonts.$eduate-font-family;
      font-size: 16px;
      color: var(--text-color);
    }
  }

  &__select {
    column-gap: 10px;
    margin-top: 10px;
  }

  &__textfield {
    @include textfield();
    margin: 0px 0px 0px 0px !important;
  }

  &__tabs {
    margin-top: 10px;

    @include muiTabs();
  }

  &__swipable {
    height: calc(100% - 95px);

    &--tabs {
      height: 100% !important;
    }

    .react-swipeable-view-container,
    .MuiTypography-body1 {
      height: 100% !important;
    }
  }

  &__home {
    @include mainFrameWithTitle();

    &--select {
      column-gap: 10px;
      margin-top: 10px;
    }

    &--tableblock {
      height: calc(100% - 95px);
      margin-top: 10px;
    }

    &--table {
      @include table();

      &--view-button {
        @include viewButton();
      }
      &--upload-button {
        @include viewButton();
        img{
          filter: invert(100%);
        }
      }
      &--actions {
        width: calc(100% - 90%);
      }
    }
  }
}