@use "../common/mixins" as *;
@use "../common/fonts" as fonts;
@use "../common/colors" as colors;
.container__table {
  @include table();

  tr:hover .container__table--name {
    @include tdContainsOnclick();
  }

  &--slno {
    width: calc(100% - 95%);
    font-family: fonts.$eduate-number-font;
  }

  &--mobile {
    width: calc(100% - 93%);
    font-family: fonts.$eduate-number-font;
  }

  &--action {
    width: calc(100% - 92%);

    &--image {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  &--cperson {
    width: calc(100% - 90%);
  }

  &--place {
    width: calc(100% - 85%);
  }

  &--email {
    width: calc(100% - 90%);
  }

  &--th-slno {
    width: calc(100% - 95%);
  }

  &--th-mobile {
    width: calc(100% - 93%);
  }
}

.container__list {
  height: calc(100% - 265px);
  margin: 10px 20px 0px 20px;

  &--addnew {
    display: flex;
    margin-left: auto !important;
    justify-content: flex-end;
    margin-right: 20px !important;
  }
}

#customerlist {
  height: calc(100% - 155px);
}

#staff-list {
  height: calc(100% - 150px);
}

//table scroll
.MuiTableContainer-root::-webkit-scrollbar,
.MuiDataGrid-columnsManagement::-webkit-scrollbar,
.MuiDataGrid-panelContent::-webkit-scrollbar,
.MuiDataGrid-scrollbar::-webkit-scrollbar {
  @include scrollbar();
}

.MuiTableContainer-root::-webkit-scrollbar-thumb,
.MuiDataGrid-columnsManagement::-webkit-scrollbar-thumb,
.MuiDataGrid-panelContent::-webkit-scrollbar-thumb,
.MuiDataGrid-scrollbar::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.MuiTableContainer-root::-webkit-scrollbar-thumb:hover,
.MuiDataGrid-columnsManagement::-webkit-scrollbar-thumb:hover,
.MuiDataGrid-panelContent::-webkit-scrollbar-thumb:hover,
.MuiDataGrid-scrollbar::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

//autocomplete listbox
.MuiAutocomplete-listbox::-webkit-scrollbar,
.country-list::-webkit-scrollbar,
.MuiInputBase-inputMultiline::-webkit-scrollbar {
  @include scrollbar();
}

.MuiAutocomplete-listbox::-webkit-scrollbar-thumb,
.country-list::-webkit-scrollbar-thumb,
.MuiInputBase-inputMultiline::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.MuiAutocomplete-listbox::-webkit-scrollbar-thumb:hover,
.country-list::-webkit-scrollbar-thumb:hover,
.MuiInputBase-inputMultilineMuiInputBase-inputMultiline::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
