@use "../../../scss/common/mixins" as *;
@use "../../../scss/common/colors" as colors;
@use "../../../scss/common/fonts" as fonts;
.header {
  background-color: var(--bg-color);
  height: 50px;
  padding: 10px;

  img {
    width: 30px;
    filter: invert(0%);
    cursor: pointer;
  }

  .logo-1 {
    width: 30px;
    height: 30px;
    filter: none !important;
  }

  &__icon {
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;

    &--li {
      width: 250px;
    }
  }

  &__search-icon {
    width: 18px !important;
  }

  &__logo {
    display: inline-flex;

    p {
      padding-left: 5px;
      font-size: 16px;
      font-weight: 500;
      font-family: fonts.$eduate-font-family;
    }

    img {
      margin-top: -10px;
    }
  }

  &__student-details {
    &--navigation {
      background: var(--level-2);
      padding: 10px 2px;
      border-radius: 5px 0px;
      display: flex;
      justify-content: space-between;

      &--info {
        filter: invert(100%);
      }

      img {
        width: 30px;
      }
    }

    &--data {
      padding: 10px;

      &--label-grid {
        display: grid;
        grid-template-columns: 1fr 0.2fr 1fr;

        Label {
          margin-top: 0px !important;
        }
      }

      &--student-profile {
        height: 120px;
        width: 120px;
      }
    }
  }
}

.addressbar {
  background: var(--level-2);
  height: 30px;
  // color: var(--text-color) !important;
  padding: 2px 0px 0px 0px;

  &__slide-in {
    white-space: nowrap;
    overflow: hidden;
    width: 100%;

    span {
      display: inline-block;
      animation: addressbar__slide-in 35s linear infinite;
      font-family: fonts.$eduate-font-family;
      font-size: 16px;
      color: var(--text-color);
      font-weight: 700;
      width: 100%;
    }
  }

  @keyframes addressbar__slide-in {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(-100%);
    }
  }
}

.logo-admin {
  cursor: pointer;
}

.main {
  height: 100%;
}

footer {
  background-color: var(--level-6);
  text-align: center;
  margin-top: 10px;
  color:colors.get-color(colors.$colors, white);
  font-family: fonts.$eduate-font-family;
}

.sidebar {
  width: 60px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1),
    0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 15px rgba(0, 0, 0, 0.4);
  border-radius: 0px 0px 0px 0px;
  height: 100%;
  overflow: auto;
  text-align: center;
  padding-top: 10px;
  border-right: 1px solid var(--border-color);

  // background-color: var(--level-2);
  span {
    opacity: 0;
    display: none;
  }

  img {
    width: 25px;
    padding-bottom: 5px;
  }

  &__menu {
    &--myeduate {
      color: #01366e !important;
      font-size: 16px !important;
      font-weight: 700 !important;
    }
  }
}

.sidebar li {
  padding-bottom: 15px;

  .active {
    background-color: var(--level-8);
    border-radius: 6px;
    width: 210px;
    padding: 8px;
    margin-left: -5px;
    text-align: left;

    img {
      filter: invert(100%);
      cursor: pointer;
    }

    span {
      color:colors.get-color(colors.$colors, white) !important;
      font-weight: bold;
      font-family: fonts.$eduate-font-family;
    }
  }
}

#menu {
  display: none;
}

#menu:checked ~ .sidebar {
  width: 210px;
  border-right: 1px solid white;
  box-shadow: 6px 4px 4px rgba(0, 0, 0, 0.25);
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

#menu:checked ~ .sidebar::-webkit-scrollbar {
  display: none;
}

#menu:checked ~ .sidebar li {
  padding: 0px 0px 15px 10px;
  display: flex;
  margin: 0;
}

#menu:checked ~ .sidebar span {
  font-size: 12px;
  color: colors.get-color(colors.$colors, black);
  opacity: 1;
  box-shadow: none;
  margin-left: 0px;
  display: inline-block;
  font-family: fonts.$eduate-font-family;

  img {
    margin-right: 10px;
    width: 25px;
  }
}

.hamburger:hover {
  cursor: pointer;
}

#menu:checked ~ .sidebar .hamburger {
  margin-left: 150px;
}

#menu:checked ~ .sidebar .tooltip-hide {
  display: none;
}

.h-80 {
  height: calc(100% - 80px);
  overflow: auto;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.responsive-layout {
  height: 100%;
  overflow: auto;
}

.footer_version {
  display: flex;
  justify-content: flex-end;

  span {
    margin-right: 20px;
    font-family: fonts.$eduate-number-font;
    font-size:fonts.$eduate-default-font-size;
  }
}

footer {
  span {
    position: relative;
    right: 20px;
  }
}

.footer__lost-login {
  display: flex;
  justify-content: flex-start;

  span {
    font-family: fonts.$eduate-font-family;
    font-size:fonts.$eduate-default-font-size;
    position: relative;
    left: 80px;
  }
}

.home-page {
 @include mainFrameWithTitle();

  &__details {
    height: 160px !important;
    column-gap: 20px;

    &--cards {
      @include blocksInDashboard;
      height: 100% !important;

      &--title {
        display: flex;
        justify-content: space-between;
      }

      &--data {
        display: flex;
        justify-content: space-around;

        img {
          width: 100px;
          height: 100px;
        }

        span {
          margin: auto;
          font-family: fonts.$eduate-number-font;
          font-weight: 700;
          color: colors.get-color(colors.$grey, level_8);
        }
      }
    }
  }

  &__tableblock {
    height: calc(100% - 220px);
    margin-top: 10px;
    border: 1px solid var(--border-color);
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--images {
      img {
        width: 30px;
        margin-right: 40px;
      }

      &--disabled {
        opacity: 0.3;
      }
    }

    &--title {
      img {
        margin-left: auto;
        margin-right: 10px !important;
      }
    }
  }

  &__search {
    margin-top: 10px;
  }
}
