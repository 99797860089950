@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.teacher-dashboard {
  height: calc(100% - 175px);
  margin: 10px 20px;

  &__grids {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 20px;
    height: 175px;

    &--cards {
      @include blocksInDashboard();
      height: 100% !important;
    }
  }

  &__flex {
    display: flex;
    justify-content: space-between;
    height: 50px;

    &--title {
      font-size: 16px;
      font-family: fonts.$eduate-font-family;
      color: var(--text-color);
      width: calc(100% - 100px);
    }

    &--image {
      display: flex;
      width: 100px;
      column-gap: 5px;
      justify-content: flex-end;

      img {
        width: 25px;
      }
    }

    &--mark {
      @include assignButton();
      height: 30px;
    }

    img {
      width: 25px;
      height: 25px;
    }
  }

  &__details {
    height: calc(100% - 50px);

    &--flex {
      margin-top: 2px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--image-flex {
      display: flex;
      column-gap: 5px;
      align-items: center;
    }

    img {
      width: 35px;
    }
  }
  &__bottom-block {
    margin-top: 15px;
    height: calc(100% - 195px);
    column-gap: 30px;
  }
  &__block {
    height: 100%;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    padding: 5px;

    &--flex {
      display: flex;
      justify-content: space-between;
    }

    &--title {
      @extend .teacher-dashboard__flex--title;
    }

    &--images {
      display: flex;
      column-gap: 10px;
      margin-right: 10px;
      height: 40px;

      .active-image {
        background-color: var(--level-4);

        border-radius: 6px;
      }

      img {
        &:hover {
          background-color: var(--level-2);
          padding: 5px;
          border-radius: 6px;
        }
      }
    }
  }

  &__tableblock {
    height: calc(100% - 45px);
    margin-top: 5px;
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--view {
      @include viewButton();
    }

    &--actions {
      width: calc(100% - 88%);
    }
  }

  &__grid-view {
    height: calc(100% - 45px);
    margin-top: 5px;

    &--list {
      height: 100px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      gap: 10px;

      &--title {
        display: block;
        font-family: fonts.$eduate-font-family;
        font-size: fonts.$eduate-default-font-size;
        text-align: center;
      }

      &--button {
        display: flex;
        justify-content: center;
        margin-top: 5px;
      }

      &--card {
        @include blocksInDashboard();
        height: 100% !important;
        background-color: var(--level-1);

        &:hover {
          .teacher-dashboard__grid-view--list--view {
            opacity: 1;
            @include viewButton();
          }
        }

        .teacher-dashboard__grid-view--list--view {
          opacity: 0;
        }
      }
    }
  }

  &__daily-dairy {
    background-color: var(--level-1);
    height: calc(100% - 30px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;

    &--flex {
      display: flex;
      justify-content: space-between;

      &--title {
        font-size: 16px;
        font-family: fonts.$eduate-font-family;
        color: colors.get-color(colors.$grey, level_6);
        width: calc(100% - 100px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      img {
        width: 20px;
      }

      &--image {
        display: flex;
        column-gap: 5px;
      }
    }

    &--date {
      color: colors.get-color(colors.$cool_grey, level_6);
      font-size: 16px;
      font-family: fonts.$eduate-number-font;
    }

    &--image {
      img {
        width: 60px;
        height: 60px;
      }
    }

    &--view {
      @include viewButton();
      width: fit-content;
    }

    &--button {
      img {
        width: 25px;
        margin-right: 5px;
      }
    }
  }
}
