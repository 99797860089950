@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.derivative-plan {
  margin: 0 20px;
  height: calc(100% - 175px);
  &__title {
    display: flex;
    column-gap: 10px;
    align-items: center;
    margin-top: 5px;
    &--derivativespan {
      @include derivativeSpan();
    }
  }
  &__details {
    margin-top: 10px;
    height: calc(100% - 80px);
    width: 100%;
    display: flex;
    &--image {
      width: 350px;
      background: colors.get-color(colors.$cool_grey, level_3);
      height: 100%;
      display: flex;
      align-items: center;
      img {
        display: block;
        margin: auto;
        width: 300px;
        height: calc(100% - 20%);
      }
    }
    &--add {
      width: calc(100% - 350px);
      height: 100%;
      &--form {
        display: block;
        margin: auto;
        height: calc(100% - 30px);
        width: 800px;
        font-size: 30px;
        padding: 30px;

        .multi-select {
          .MuiAutocomplete-root {
            @include muiMultiSelect();
          }
        }
        .number {
          label {
            span {
              color: colors.get-color(colors.$cool_grey, level_5);
            }
          }
          input {
            width: 50px !important;
          }
        }
        label {
          font-size: 18px !important;
        }
        .MuiAutocomplete-root {
          margin-top: 10px;
        }
      }
    }
  }
}
