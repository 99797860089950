@use "../../../scss/common/mixins" as *;
@use "../../../scss/common/colors" as colors;
@use "../../../scss/common/fonts" as fonts;
.logout-modal {
  text-align: center;

  Label {
    &:hover {
      text-decoration: underline;
      font-weight: 700 !important;
    }
  }
}

.change-password {
  &__textfield {
    @include textfieldInForms();
    margin: 10px 0px 0px 0px !important;
    width: 100% !important;

    &--captcha {
      height: 50px;
      width: 100%;
      display: flex;
      border: 1px solid var(--border-color);
      border-radius: 6px;

      &--text {
        width: calc(100% - 30px);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--level-1);

        span {
          color: var(--level-5);
          font-weight: 700;
          font-size: 16px;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          -o-user-select: none;
          user-select: none;
        }
      }

      img {
        width: 18px;
        display: block;
        margin: auto;
      }
    }
  }
}
