@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
.advance-fee-list {
  height: calc(100% - 180px);
  margin: 10px 20px 0px 20px;
  &--modal {
    margin-top: 10px;
    height: calc(100% - 40px);
  }
  &__select {
    column-gap: 10px;

    #search {
      margin-top: 0px;
    }
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 83px);
  }

  &__table {
    @include table();

    &--th-slno {
      width: calc(100% - 96%);
    }

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--amount {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-amount {
      width: calc(100% - 92%);
    }

    &--desc {
      width: calc(100% - 90%);
    }

    &--admno {
      width: calc(100% - 94%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-admno {
      width: calc(100% - 94%);
    }

    tfoot td {
      border-right: 1px solid var(--border-color) !important;
    }
  }

  &__total {
    text-align: right;
  }
}

.advance-fee-list__table::-webkit-scrollbar {
  @include scrollbar();
}

.advance-fee-list__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.advance-fee-list__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
