@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.enquiry-allocation {
    height: 100%;

    &__filters {
        column-gap: 10px;
        margin-top: 5px;

        #search {
            margin-top: 0px !important;
        }
    }

    &__tableblock {
        height: calc(100% - 90px);
        margin-top: 10px;
    }

    &__table {
        @include table();
        @include tableCheckbox()
    }

    &__move-to {
        height: 100%;
        padding: 15px 10px 10px 10px;
   
        .student-total-count{
            text-align: right;
        }

        &--tableblock {
            height: calc(100% - 85px);
            margin-top: 10px;
        }

        &--table {
            @include table();
            @include tableCheckbox();

            &--slno {
                width: calc(100% - 92%);
                font-family: fonts.$eduate-number-font;
            }

            &--desc {
                width: calc(100% - 90%);
            }

            &--inst {
                width: calc(100% - 88%);
            }

            &--mobile {
                width: calc(100% - 90%);
                font-family: fonts.$eduate-number-font;
            }

            &--email {
                width: calc(100% - 90%);
            }

            &--status {
                width: calc(100% - 96%)
            }
        }
    }

    &__total {
        text-align: right;

        .student-total-count {
            margin: 10px 0px 0px 0px !important;
        }
    }
}