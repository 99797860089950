@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
.student-demand-receipt {
  &__textfield {
    @include textfield();

    &--date {
      width: fit-content !important;
      @include textfield();
    }
  }

  &__select-options {
    display: flex;
    gap: 10px;
  }

  &__tableblock {
    margin-top: 10px;
  }

  &__table {
    @include table();

    tr:hover {
      .student-demand-receipt__table--name {
        @include tdContainsOnclick();
      }
    }

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--rcptno {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--date {
      width: calc(100% - 94%);
      font-family: fonts.$eduate-number-font;
    }

    &--admno {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--class {
      width: calc(100% - 92%);
    }

    &--parent {
      width: calc(100% - 92%);
    }

    &--regno {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--amount {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-slno {
      width: calc(100% - 96%);
    }

    &--th-rcptno {
      width: calc(100% - 90%);
    }

    &--th-date {
      width: calc(100% - 94%);
    }

    &--th-admno {
      width: calc(100% - 90%);
    }

    &--th-regno {
      width: calc(100% - 90%);
    }

    &--th-amount {
      width: calc(100% - 92%);
    }

    tfoot {
      background-color:colors.get-color(colors.$colors, white);
    }
  }

  &__total {
    text-align: right;
  }
}

.student-demand-receipt__table::-webkit-scrollbar {
  @include scrollbar();
}

.student-demand-receipt__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.student-demand-receipt__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}