@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.book-return {
  height: calc(100% - 218px);
  margin: 0px 20px 0px 20px;

  &__modal {
    height: calc(100% - 80px);
  }

  &__details {
    height: 130px;
    column-gap: 20px;

    .MuiAutocomplete-root {
      width: 100%;
      margin-top: 10px;
    }

    &--colspan {
      column-count: 2;
      column-gap: 20px;
      margin-right: 10px;
    }

    &--imageflex {
      display: flex;
      margin-top: 5px;

      .MuiAutocomplete-root {
        width: 100%;
        margin-top: 3px !important;
      }
    }

    &--textflex {
      display: grid;
      margin-right: 10px;
      grid-template-columns: 1.4fr 1fr 0.6fr 1fr;

      span {
        margin: auto;
        font-weight: bold;
        font-size: 20px;
      }
    }

    &--image {
      @include studentProfile();
    }

    &--textfield {
      @include textfield();
      margin: 10px 0px 0px 0px !important;

      &--date {
        @extend .book-return__details--textfield;
        width: fit-content;
      }
    }
  }

  &__tableblock {
    height: calc(100% - 155px);

    &--issued-books-table {
      @include table();
      @include tableCheckbox();
      height: 40%;

      th {
        img {
          filter: invert(100%);
          width: 30px;
        }
      }

      tbody {
        img {
          width: 20px;
          filter: invert(0%);

          &:hover {
            background:colors.get-color(colors.$colors, white);
            border-radius: 50%;
          }
        }
      }

      &--tooltip {
        li {
          padding: 0px;

          &:hover {
            background-color: var(--level-2);
            border-radius: 6px;

            Label {
              color:colors.get-color(colors.$colors, white) !important;
            }
          }
        }

        Label {
          margin-top: 2px !important;
        }
      }

      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-slno {
        width: calc(100% - 96%);
      }

      &--accno {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-accno {
        width: calc(100% - 88%);
      }

      &--publication {
        width: calc(100% - 85%);
      }

      &--date {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-date {
        width: calc(100% - 92%);
      }

      &--amount {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-amount {
        width: calc(100% - 92%);
      }

      &--actions {
        width: calc(100% - 95%);
      }
    }

    &--damaged-books {
      &--textfield {
        @include textfield();
        width: 100%;

        ::placeholder {
          text-align: end;
          font-size:fonts.$eduate-default-font-size;
          font-weight: 600;
        }

        &__remarks {
          margin-top: 10px;
          height: 90px !important;
          @include textfield();

          label {
            color: var(--text-color) !important;
          }
        }
      }

      &--percentage {
        margin-top: 20px;
        column-gap: 10px;
      }

      &--tableblock {
        margin-top: 10px;
        height: calc(60% - 100px);
      }

      &--table {
        @include table();
        height: calc(100% - 25px);

        td {
          img {
            width: 20px;
            filter: invert(0%);

            &:hover {
              background:colors.get-color(colors.$colors, white);
              border-radius: 50%;
            }
          }
        }

        &--slno {
          width: calc(100% - 92%);
          font-family: fonts.$eduate-number-font;
        }

        &--accno {
          width: calc(100% - 82%);
          font-family: fonts.$eduate-number-font;
        }

        &--amount {
          width: calc(100% - 86%);
          font-family: fonts.$eduate-number-font;
        }
      }

      &--frame {
        border: 2px dotted colors.get-color(colors.$grey, level_5);
        padding: 5px;
        height: calc(100% - 5px);
        border-radius: 6px;
      }

      &--fineblock {
        display: flex;
        height: 75px;
        gap: 5px;

        Label {
          margin-top: 5px !important;
          padding: 4px !important;
        }

        span {
          margin-top: 25px;
          font-weight: bold;
          font-size: 20px;
        }

        &--buttons {
          display: flex;
          justify-content: flex-end;
        }
      }

      &--fines {
        .Label {
          margin-top: 0px;
        }

        .MuiAutocomplete-root,
        .MuiFormControl-root {
          margin-top: 0px !important;
        }
      }

      &--label-grid {
        display: grid;
        grid-template-columns: 0.25fr 1.4fr;
      }
    }
  }

  &__fetch--book-details {
    margin-top: 10px;
    display: flex;
    gap: 5px;

    &__media {
      @extend .book-return__fetch--book-details;
      justify-content: flex-end;
      margin-right: -20px;
    }

    &__filter-columns {
      @include filterColumns();
      margin-top: 10px;
    }

    &__table {
      margin-top: 10px;
      @include table();
      height: calc(100% - 160px);
    }
  }

  &__date {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
  }
}

#bg-green {
  background-color: colors.get-color(colors.$Green, level_5) !important;
  color:colors.get-color(colors.$colors, white);
  font-weight: 600;
}

#bg-red {
  background-color: colors.get-color(colors.$red, level_7) !important;
  color:colors.get-color(colors.$colors, white);
  font-weight: 600;
}

#fine-amount {
  color: colors.get-color(colors.$red, level_6) !important;
}

#waive-off-amount {
  color: colors.get-color(colors.$Green, level_7) !important;
}