@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.demand-summary {
 @include mainFrameWithTitle();

  &__modal {
    margin: 0px;
    height: calc(100% - 30px);
  }

  &__tableblock {
    height: calc(100% - 105px);
    margin-top: 10px;

    &--modal {
      height: calc(100% - 90px);
    }

    tr:hover .demand-summary__tableblock--table--name {
      @include tdContainsOnclick();
    }

    &--table {
      @include table();
      border-radius: 20px 20px 0px 0px;

      &--slno {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--type {
        width: calc(100% - 92%);
      }

      &--number {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--amount {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--admno {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--status {
        width: calc(100% - 96%);
      }

      &--th-number {
        width: calc(100% - 92%);
      }

      &--th-amount {
        width: calc(100% - 92%);
      }

      &--th-admno {
        width: calc(100% - 92%);
      }

      &--th-status {
        width: calc(100% - 96%);
      }
    }
  }

  &__std-complete-fee-receipt {
    height: calc(100% - 45px);
  }
}
