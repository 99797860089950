@use "../../../../scss/common/mixins" as *;

.teacher-wise-allocated-reports{
    @include mainFrameWithTitle();
    &__details{
        column-gap: 20px;
        margin-top: 5px;
    }
    &__tableblock{
        margin-top: 10px;
        height: calc(100% - 170px);
    }
    &__view {
        @include viewButton();
      }
}