.docs_preview {
  margin: 0px 20px 0px 20px;
  height: calc(100% - 210px);

  &__modal {
    height: calc(100% - 125px);
    &__docs_upload {
      height: calc(100% - 180px);
    }
    &__tableblock {
      height: calc(100% - 120px);
    }
  }
  &__docs_upload {
    height: calc(100% - 180px);
  }
  &__tableblock {
    height: calc(100% - 180px);
  }
}
.student_docs__body {
  height: calc(100% - 10px);
}
