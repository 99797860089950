.std-login-campus {
    height: calc(100% - 170px);
    margin: 0px 20px;

    &__container {
        height: calc(100% - 40px);

        &--column {
            height: 100%;
        }

        &--activities {
            margin-top: 20px;
            height: calc(100% - 55px);
        }
    }
}