@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;

.masters-dashboard {
  height: calc(100% - 165px);
  margin: 0px 10px 0px 10px;
  overflow: auto;
  padding-left: 10px;
  padding-right: 10px;

  &__details {
    gap: 20px;
    margin-top: 10px;
    height: 160px;

    &--block {
      padding: 5px;
      @include blocksInDashboard();
      height: 100%;

      &--title {
        display: flex;
        justify-content: space-between;
        padding-left: 10px;

        .subtitle1 {
          width: calc(100% - 35px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          text-align: left;

          &:hover {
            text-overflow: clip;
            overflow: visible;
          }
        }
      }

      &--details {
        display: flex;
        justify-content: space-between;
        column-gap: 30px;

        img {
          width: 60px;
          height: 60px;
        }

        &--total {
          display: block;
          text-align: center;
          margin: auto;
          font-family: fonts.$eduate-number-font;
          font-weight: bold;
          font-size: 18px;
          color: colors.get-color(colors.$grey, level_6);
        }
      }

      &--g-count {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-top: 1px solid var(--border-color);
        gap: 5px;

        &--flex {
          display: flex;
          justify-content: center;
          column-gap: 10px;
          padding: 5px;
        }

        .separator {
          border-right: 1px solid var(--border-color);
        }

        &--block {
          display: flex;
          flex-direction: column;

          // margin-top: auto;
          // margin-bottom: auto;
          &--text {
            font-family: fonts.$eduate-font-family;
            color: colors.get-color(colors.$grey, level_6);
            font-weight: 700;
            font-size: 12px;
          }

          &--total {
            @extend .masters-dashboard__details--block--g-count--block--text;
            color: colors.get-color(colors.$grey, level_6);
            font-size: fonts.$eduate-default-font-size;
            font-weight: 700;
          }
        }
      }

      &--vacant-seats {
        display: flex;
        column-gap: 30px;

        &--total {
          display: block;
          text-align: center;
          margin: auto;
          font-family: fonts.$eduate-number-font;
          font-weight: bold;
          font-size: 16px;
          color: colors.get-color(colors.$grey, level_6);
        }
      }

      &--total-seats {
        display: flex;
        column-gap: 30px;
        border-top: 1px dashed var(--border-color);

        &--text {
          font-family: fonts.$eduate-font-family;
          color: colors.get-color(colors.$grey, level_6);
          font-weight: 700;
          font-size: 12px;
          text-align: center;
          margin: auto;
        }

        &--margin {
          display: block;
          margin: auto;
        }
      }
    }
  }

  &__datablock {
    height: calc(100% - 195px);
    gap: 20px;
    margin-top: 20px;

    .vacant-block {
      height: 150px;
    }

    .total-seats-block {
      height: calc(100% - 160px);
      margin-top: 10px;
    }

    &--tableblock {
      height: calc(100% - 75px);
    }

    &--table {
      @include table();
      height: calc(100% - 45px);

      tbody {
        tr:hover {
          .masters-dashboard__datablock--table--name {
            @include tdContainsOnclick();
          }
        }
      }

      &--slno {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }

      &--count {
        width: calc(100% - 85%);
        font-family: fonts.$eduate-number-font;
      }
    }
    &--admissions {
      @extend .masters-dashboard__datablock--table;
      height: 100% !important;
    }
    &--services {
      height: calc(100% - 50px);
      overflow: auto;

      li {
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid var(--border-color);

        .Label {
          margin-top: auto !important;
          margin-bottom: auto !important;
        }
      }
    }
  }

  &__demand-summary {
    @include mainFrameWithTitle();

    &--total {
      text-align: right;
    }

    &__modal {
      height: calc(100% - 35px);
      margin-top: 10px;
    }

    &__tableblock {
      height: calc(100% - 75px);
      margin-top: 10px;
    }

    &__tableblock--allstudents {
      height: calc(100% - 45px);
      margin-top: 10px;
    }

    &__breadcrumbs {
      height: 20px;
      font-size: 12px;
      color: var(--level-4);
      font-family: fonts.$eduate-font-family;
    }

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
      }

      &--count {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      tbody {
        tr:hover {
          .masters-dashboard__demand-summary--table--desc {
            @include tdContainsOnclick();
          }
        }
      }
    }
  }
}

.masters-dashboard::-webkit-scrollbar,
.masters-dashboard__datablock--services::-webkit-scrollbar {
  @include scrollbar();
}

.masters-dashboard::-webkit-scrollbar-thumb,
.masters-dashboard__datablock--services::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.masters-dashboard::-webkit-scrollbar-thumb:hover,
.masters-dashboard__datablock--services::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
