@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.particular-student-results {
  height: calc(100% - 30px);

  &__select {
    column-gap: 10px;
    height: 120px;
    @include muiMultiSelect();

    &--image-flex {
      display: flex;

      .MuiAutocomplete-root {
        margin-top: 8px !important;
      }

      .data-fetch-icon {
        margin-top: 5px;
      }
    }

    &--student-profile {
      @include studentProfile();
      height: 100% !important;
    }

    &--textfield {
      @include textfield();
      margin: 8px 0px 0px 0px !important;
    }
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 210px);

    .echarts-for-react {
      height: 100% !important;
    }
  }

  &__table {
    @include table();

    &--groupheader {
      @include groupHeader();
    }

    &--subheader {
      @include subHeader();
    }

    &--totalcount {
      color:colors.get-color(colors.$colors, white) !important;
      text-align: center !important;
      background: colors.get-color(colors.$Green, level_5) !important;
      font-family: fonts.$eduate-number-font;
    }

    &--marks {
      font-family: fonts.$eduate-number-font;
      width: calc(100% - 90%);
    }

    &--code {
      width: calc(100% - 94%);
    }

    &--sub {
      width: calc(100% - 92%);
    }

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--sticky{
      position: sticky !important;
      top: 25px !important;
      z-index: 998 !important;
      height: 25px  !important ;
      overflow: hidden;
    }
    tfoot{
      td{
        border-bottom: 1px solid var(--border-color) !important;
        border-right: 1px solid var(--border-color) !important;
      }
    }
  }

  &__swipable {
    border-top: 1.8px dashed var(--border-color);
    height: calc(100% - 230px);
    margin-top: 10px;

    &--select {
      @include muiMultiSelect();
      height: 60px;
      background-color: colors.get-color(colors.$cool_grey, level_2);
      border-radius: 6px;
      padding: 5px;
      align-items: center;
      column-gap: 10px;
      &--images{
        display: flex;
        column-gap: 10px;
        justify-content: flex-end;
        img{
          height: 30px;
          width: 30px;
        }
        .active-image{
          background-color: var(--level-3);
          padding: 2px;
          border-radius: 6px;
        }
      }

    }

    &--right {
      border-right: 1.8px dashed var(--border-color);
      padding: 10px 20px 5px 5px;
      height: 100%;
    }

    &--left {
      padding: 10px 5px 5px 20px;
      height: 100%;
    }

    &--tableblock {
      margin-top: 10px;
      height: calc(100% - 45px);

      .total {
        padding: 0px !important;
      }
    }

    &--title {
      display: flex;
      justify-content: center;
      height: 45px;

      b,
      span {
        font-family: fonts.$eduate-font-family;
        font-size:fonts.$eduate-default-font-size;
        margin-top: auto;
      }
    }

    &--charts {
      height: 100%;

      .echarts-for-react {
        height: 100% !important;
      }
    }
  }
}