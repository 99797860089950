@use "../common/colors" as colors;
@use "../common/mixins" as *;
//mainframe
.main-frame {
  border-radius: 15px;
  padding: 10px;
  margin: 10px 10px 0px 10px;
  height: calc(100% - 100px);
}

.frame-space {
  margin-top: 20px;
}

//booktype-frames

.booktype-right {
  border: 1px solid colors.get-color(colors.$colors, cloud-white);
  border-radius: 40px 0px 0px 0px;
  margin: 10px 10px 0px 10px;
  padding: 10px 10px 5px 10px;
}
.booktype-left {
  @extend .booktype-right;
  border-radius: 0px 40px 0px 0px;
}

.account-frames {
  border: 1px solid colors.get-color(colors.$colors, cloud-white);
  margin: 10px 10px 0px 10px;
  padding: 5px 10px 10px 5px;
  border-radius: 6px;
}

.h-30 {
  height: calc(100% - 30px);
}
.h-60 {
  height: calc(100% - 60px);
}
.h-70 {
  height: calc(100% - 70px);
}
.h-90 {
  height: calc(100% - 90px);
}
.h-95 {
  height: calc(100% - 95px);
}
.h-120 {
  height: calc(100% - 120px);
}
.h-125 {
  height: calc(100% - 125px);
}
.h-130 {
  height: calc(100% - 130px);
}

.h-140 {
  height: calc(100% - 140px);
}
.h-150 {
  height: calc(100% - 150px);
}
.h-155 {
  height: calc(100% - 155px);
}
.h-160 {
  height: calc(100% - 160px);
}

.h-165 {
  height: calc(100% - 165px);
}
.h-170 {
  height: calc(100% - 170px);
}
.h-175 {
  height: calc(100% - 175px);
}
.h-180 {
  height: calc(100% - 180px);
}
.h-185 {
  height: calc(100% - 185px);
}
.h-190 {
  height: calc(100% - 190px);
}
.h-200 {
  height: calc(100% - 200px);
}
.h-215 {
  height: calc(100% - 215px);
}
.h-220 {
  height: calc(100% - 220px);
}
.h-235 {
  height: calc(100% - 235px);
}
.h-240 {
  height: calc(100% - 240px);
}
.h-225 {
  height: calc(100% - 225px);
}
.h-265 {
  height: calc(100% - 265px);
}
.h-270 {
  height: calc(100% - 270px);
}
.h-275 {
  height: calc(100% - 275px);
}
.h-290 {
  height: calc(100% - 290px);
}
.h-300 {
  height: calc(100% - 300px);
}
.h-340 {
  height: calc(100% - 340px);
}
.h-430 {
  height: calc(100% - 430px);
}
