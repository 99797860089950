@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.import-excel-data {
  @include mainFrameWithTitle();
  &__marks {
    height: calc(100% - 30px);
    &--modal {
      height: calc(100% - 30px);
      .select-all__checkboxes {
        margin-left: 35px;
      }
      &--title {
        img {
          @include closeIconInModal();
        }
      }
    }
  }

  &__select {
    column-gap: 10px;

    &--tableblock {
      margin-top: 10px;
      height: calc(100% - 110px);
    }
  }

  &__blocks {
    margin-top: 10px;
    gap: 10px;
    height: 75px;

    &--cards {
      @include blocksInDashboard();

      img {
        width: 35px;
      }

      b {
        margin-left: 10px;
        font-family: fonts.$eduate-font-family;
        font-size: 16px;
      }

      &--total-students {
        font-family: fonts.$eduate-number-font;
        font-size: 16px;
        font-weight: 700;
        color: var(--text-color);
        display: flex;
        justify-content: center;
      }

      &--ready-to-import {
        @extend .import-excel-data__blocks--cards--total-students;
        color: colors.get-color(colors.$Green, level_6);
      }

      &--contains-error {
        @extend .import-excel-data__blocks--cards--total-students;
        color: colors.get-color(colors.$red, level_6);
      }
    }
  }

  &__datablock {
    height: calc(100% - 140px);
    margin-top: 10px;
    @include tableCheckbox();

    &--title {
      display: flex;
      justify-content: space-between;
      height: 50px;
      border-radius: 6px;
      border: 1px solid var(--border-color);
      margin-top: 10px;
      padding: 0px 10px 0px 10px;

      img {
        width: 25px;

        &:hover {
          background-color: colors.get-color(colors.$colors, white-smoke);
          border-radius: 6px;
          width: 25px;
          height: 25px;
          margin-top: auto;
          margin-bottom: auto;
        }
      }
    }

    &--records-count {
      display: flex;
      gap: 10px;

      span {
        font-family: fonts.$eduate-number-font;
        padding: 8px;
        background-color: colors.get-color(colors.$Green, level_5);
        color: colors.get-color(colors.$colors, white);
        font-size: 12px;
        border-radius: 50%;
        font-weight: 700;
        margin: auto;
      }

      b {
        font-family: fonts.$eduate-font-family;
        font-size: 16px;
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    &--errors-count {
      @extend .import-excel-data__datablock--records-count;

      span {
        background-color: colors.get-color(colors.$red, level_6);
      }

      b {
        color: colors.get-color(colors.$red, level_7);
      }
    }
    &--error {
      background-color: colors.get-color(colors.$red, level_6) !important;
    }

    &--tableblock {
      height: calc(100% - 120px);
      margin-top: 10px;

      &--error-message {
        display: flex;
        justify-content: space-between;
      }
    }
    &--table {
      @include table();
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
  }

  &__file-upload {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    &--browse {
      @include browseFiles();
      width: fit-content;
      margin-top: 20px;
    }

    &--input {
      display: none;
    }

    &--file-name {
      padding: 5px;
      border: 1px solid var(--border-color);
      border-radius: 6px;
      font-family: fonts.$eduate-font-family;
      font-size: fonts.$eduate-default-font-size;
      width: 100%;
      height: 35px;
      background-color: var(--disabled-color);
      margin-top: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &__receipts{
    height: 100%;
    &--title{
      img{
        @include closeIconInModal();
      }
    }
    &--block{
      height: calc(100% - 70px);
      overflow: auto;
      .select-all{
        margin-left: 0px !important;
      }
    }
  }
}


.import-excel-data__receipts--block::-webkit-scrollbar {
  @include scrollbar();
}

.import-excel-data__receipts--block::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.import-excel-data__receipts--block::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}