@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.assign-sal-structure-for-grp {
  height: calc(100% - 95px);
  margin: 10px 20px 0px 20px;
  &__select {
    column-gap: 10px;
  }
  &__tableblock {
    height: calc(100% - 80px);
    &--employee {
      height: calc(50% - 35px);
      margin-top: 10px;
    }
    &--salary {
      height: calc(50% - 55px);
      margin-top: 10px;
    }
  }
  &__emp-table {
    @include table();
    @include tableCheckbox();
    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-slno {
      width: calc(100% - 96%);
    }
    &--empid {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-empid {
      width: calc(100% - 90%);
    }
    &--status {
      width: calc(100% - 90%);
    }
    &--degn {
      width: calc(100% - 88%);
    }
  }
  &__salary-table {
    @include table();

    &--amount {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-amount {
      width: calc(100% - 88%);
    }
    &--desc {
      width: calc(100% - 88%);
    }
  }
  &__view {
    height: calc(100% - 80px);
    display: flex;
    gap: 30px;
    margin-top: 10px;
    &--leftblock {
      width: 250px;
      border: 1px solid #edf2f7;
      height: 100%;
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 4px 6px -2px rgba(0, 0, 0, 0.05);

      &--table {
        @include table();
      }
    }
    &--rightblock {
      height: 100%;
      width: calc(100% - 250px);
      &--select {
        column-gap: 10px;
      }
    }
    &--tableblock {
      height: calc(100% - 50px);
      margin-top: 10px;
    }
    &--table {
      @include table();
      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }
      &--slno {
        width: calc(100% - 96%);
      }
      &--empid {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-empid {
        width: calc(100% - 90%);
      }
      &--gender {
        width: calc(100% - 92%);
      }
      &--degn {
        width: calc(100% - 86%);
      }
      &--qualification {
        width: calc(100% - 92%);
      }
      &--status {
        width: calc(100% - 94%);
      }
    }
  }
}
