@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.birthday-list {
  height: 100%;
  &__title {
    display: flex;

    justify-content: space-between;

    .subtitle1 {
      width: calc(100% - 30px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
  }
  &__data {
    overflow: hidden;
    height: calc(100% - 30px);
    background-image: url("../../../../../images/Birthday_Celebration_Gif.gif");
    background-position: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;

    &--subtitle {
      display: flex;
      justify-content: center;
      img {
        width: 25px;
      }
      b {
        color: var(--level-6);
        font-size: 16px;
        font-family: fonts.$eduate-number-font;
      }
    }
    &--total {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 5px;
      background: var(--level-3) !important;
      border-radius: 10px 10px 0px 0px;
      padding: 5px;
      font-family: fonts.$eduate-font-family;
      &--count {
        padding: 5px;
        font-size: 14px;
        background-color: var(--level-6);
        color: white !important;
        font-family: fonts.$eduate-number-font;
        border-radius: 2px;
      }
      &--image {
        display: flex;
        column-gap: 5px;
      }
    }
    &--list {
      margin-top: 5px;
      overflow: auto;
      height: calc(100% - 170px);
      li {
        font-size: 14px;
        font-family: fonts.$eduate-font-family;
        color: var(--text-color);
        margin-top: 5px;
        padding: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1.3px dashed var(--border-color);
        background: colors.get-color(colors.$cool_grey, level_1);
        opacity: 1;
        z-index: 999;
        span {
          color: colors.get-color(colors.$cool_grey, level_7);
          font-family: fonts.$eduate-number-font;
          display: block;
          font-size: 14px;
        }
        img {
          width: 30px;
          height: 30px;
        }
        &:hover {
          background-color: var(--level-1);
        }
      }

      &--flex {
        display: flex;
        column-gap: 5px;
      }
      &--image {
        display: block;
        height: 60%;
        width: 60%;
        margin: auto;
      }
    }
    &--button {
      position: sticky;
      bottom: 0px;
      .storybook-button {
        width: 100% !important;
      }
    }
    &--gif {
      height: 50px;
      width: 50px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.more-birthday-list {
  height: 100%;
  &__title {
    img {
      @include closeIconInModal();
    }
  }
  &__data {
    margin-top: 10px;
    height: calc(100% - 80px);
    column-gap: 20px;
    &--left {
      height: 100%;
      padding: 5px;
      border: 1px solid var(--border-color);
      border-radius: 6px;

      background-image: url("../../../../../images/Birthday_Celebration_Gif.gif");
      background-position: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      z-index: 0;

      &--list {
        overflow: auto;
        height: calc(100% - 185px);
        margin-top: 10px;

        @extend .birthday-list__data--list;
        margin-bottom: 0px;
        li {
          z-index: 10;
          background-color: colors.get-color(colors.$cool_grey, level_1);
          // opacity: 1;
        }
      }
      &--gif {
        height: 80px;
        width: 80px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    &--right {
      @extend .more-birthday-list__data--left;
      background-image: none !important;

      &--list {
        overflow: auto;
        height: calc(100% - 80px);
        margin-top: 10px;
        @extend .birthday-list__data--list;
      }
      &--li {
        background-color: var(--disabled-color);
        margin-top: 0px !important;
      }
      &--flex {
        display: flex;
        font-family: fonts.$eduate-font-family;
        font-size: 12px;
      }
    }
  }
}

.more-birthday-list__data--left--list::-webkit-scrollbar,
.birthday-list__data--list::-webkit-scrollbar,
.more-birthday-list__data--right--list::-webkit-scrollbar {
  @include scrollbar();
}

.more-birthday-list__data--left--list::-webkit-scrollbar-thumb,
.birthday-list__data--list::-webkit-scrollbar-thumb,
.more-birthday-list__data--right--list::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.more-birthday-list__data--left--list::-webkit-scrollbar-thumb:hover,
.birthday-list__data--list::-webkit-scrollbar-thumb:hover,
.more-birthday-list__data--right--list::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
