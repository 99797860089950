.academic-subject-details {
  margin: 10px 20px 0px 20px;
  height: calc(100% - 265px);

  &__toggle-buttons {
    display: flex;
    justify-content: space-between;
  }
  &__form-labels {
    .MuiFormControlLabel-root {
      display: flex !important;
      width: calc(100% - 10px) !important;
      justify-content: space-between !important;
      margin-top: 10px;
      margin-left: 5px !important;
    }
  }
}
