
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.receipt-payment-account {
  height: calc(100% - 220px);
  margin: 0px 20px 0px 20px;

  &__modal {
    height: calc(100% - 75px);
    margin: 0px;

    &--textfield {
      @include textfield();
      width: fit-content !important;
    }

    &--tableblock {
      margin-top: 10px;
      height: calc(100% - 70px);
      column-gap: 30px;
    }

    &--year {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      margin-right: 0px;
    }
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 40px);
    column-gap: 30px;

    &--table {
      @include table();

      tr:hover {
        .receipt-payment-account__tableblock--table--desc {
          @include tdContainsOnclick();
        }
      }

      &--amount {
        width: 160px !important;
        font-family: fonts.$eduate-number-font;
      }

      &--th-amount {
        width: 160px !important;
      }

      &--groupheader {
        @include groupHeader();

        Input {
          margin: auto;
          height: 28px;
        }

        th {
          text-align: left !important;
          padding-left: 10px;
        }
      }

      &--subheader {
        @include subHeader();
      }

      &--rowheader {
        background-color: var(--bg-color);

        td {
          text-align: center !important;
          border-bottom: 2px dashed var(--border-color) !important;
          border-right: none !important;
          padding: 6px;
          color: var(--level-6) !important;
          font-weight: 600;

          &:hover {
            background-color: var(--bg-color) !important;
          }
        }
      }

      &--total {
        background-color: var(--bg-color);

        td {
          border-style: none !important;
        }

        &:hover td {
          background-color: var(--bg-color) !important;
        }
      }
    }
  }

  &__footer {
    height: 25px;
    column-gap: 30px;
    padding-top: 10px;

    &--total {
      border-top: 1.8px dashed var(--border-color);
      display: flex;
      justify-content: flex-end;
      padding-top: 5px;

      &--count {
        width: 180px;
        color: var(--text-color);
        text-align: right;
        font-family: fonts.$eduate-number-font;
        font-size:fonts.$eduate-default-font-size;
        font-weight: 600;
        padding-right: 5px;
        background: colors.get-color(colors.$Green, level_5);
      }

      &--text {
        width: 140px;
        font-family: fonts.$eduate-font-family;
        @extend .receipt-payment-account__footer--total--count;
        background: var(--bg-color);
      }
    }
  }

  &__difference-amount {
    font-size:fonts.$eduate-default-font-size;
    font-weight: normal;
    font-family: fonts.$eduate-font-family;
    margin-top: 5px;
  }
}