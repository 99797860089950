@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/colors" as colors;
@use "../../../../../scss/common/fonts" as fonts;
.report-card-create {
    @include mainFrameWithTitle();

    &__frame {
        height: calc(100% - 55px);
        margin-top: 10px;

        column-gap: 40px;

        &--left {
            height: 100%;
            border: 1px solid colors.get-color(colors.$colors, cloud-white);
            border-radius: 0px 40px 0px 0px;
            padding: 20px 5px 5px 5px;
        }

        &--right {
            @extend .report-card-create__frame--left;
            border-radius: 40px 0px 0px 0px;

        }
    }

    &__table {
        height: calc(100% - 35px);
    }
}