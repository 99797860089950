@use "./colors"  as colors;

$eduate-font-family: "Josefin Sans" !important;
$table-font-family: "Josefin Sans";
$eduate-number-font: "Spline Sans Mono", monospace !important;
$eduate-default-font-size: 14px;
//Font-size
$label-font-style: normal;
$label-font-weight: 400;
$label-font-size: $eduate-default-font-size;

.font-green {
  color: colors.get-color(colors.$Green, level_8) !important;
  font-weight: 700 !important;
}

.font-grey {
  color: colors.get-color(colors.$grey, level_6) !important;
}

.font-blue {
  color: colors.get-color(colors.$sky, level_6) !important;
  font-weight: 700 !important;
}

.font-pink {
  color: colors.get-color(colors.$pink, level_8) !important;
  font-weight: 700 !important;
}

.font-purple {
  color: colors.get-color(colors.$fuchsia, level_8) !important;
  font-weight: 700 !important;
}

.font-red {
  color: colors.get-color(colors.$red, level_6) !important;
  text-align: right;
}

.font-amber {
  color: colors.get-color(colors.$amber, level_6) !important;
  text-align: right;
}

.font-selected-theme {
  color: var(--level-8);
  font-weight: 700 !important;
}