
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.bulk-data-alter {
  @include mainFrameWithTitle();

  .react-swipeable-view-container,
  .MuiTypography-root {
    height: 100% !important;
  }

  &__tabs {
    @include muiTabs();

    margin-top: 10px;
  }

  &__select {
    column-gap: 10px;
  }

  &__file-upload {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    &--browse {
      @include browseFiles();
      width: fit-content;
      margin-top: 20px;
    }

    &--input {
      display: none;
    }

    &--file-name {
      padding: 5px;
      border: 1px solid var(--border-color);
      border-radius: 6px;
      font-family: fonts.$eduate-font-family;
      font-size:fonts.$eduate-default-font-size;
      width: 100%;
      height: 35px;
      background-color: var(--disabled-color);
      margin-top: 20px;
    }
  }

  &__swipable {
    height: calc(100% - 40px);

    &--tabs {
      height: 100% !important;
    }
  }

  &__tableblock {
    height: calc(100% - 125px);
    margin-top: 10px;
  }
  &__table{
    @include table();
  }
}