@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/colors" as colors;
@use "../../../../../scss/common/fonts" as fonts;
.std-login-transport {
  margin: 0px 20px;
  height: calc(100% - 170px);

  &__modal {
    height: calc(100% - 35px);
  }

  &__details {
    column-gap: 20px;
    height: 120px;

    &--textfield {
      @include textfield();
      margin: 8px 0px 0px 0px !important;

      &--date {
        @include textfield();
        margin: 8px 0px 0px 0px !important;
        width: fit-content !important;
      }
    }

    &--profile {
      height: 100%;
      @include studentProfile();
    }
  }

  &__year {
    border-top: 1.8px dashed var(--border-color);
    padding-top: 10px;
  }

  &__blocks {
    height: 60px;
    column-gap: 20px;
    margin-top: 10px;

    img {
      width: 30px;
    }

    &--cards {
      @include blocksInDashboard();

      &--total-fee {
        font-family: fonts.$eduate-number-font;
        font-size:fonts.$eduate-default-font-size;
        display: flex;
        justify-content: center;
        margin-top: 5px;
        color: colors.get-color(colors.$fuchsia, level_6);
        font-weight: 700;
      }

      &--paid {
        @extend .std-login-transport__blocks--cards--total-fee;
        color: colors.get-color(colors.$Green, level_6);
      }

      &--balance {
        @extend .std-login-transport__blocks--cards--total-fee;
        color: colors.get-color(colors.$red, level_6);
      }
    }

    &--button {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      margin-right: 0px;
      height: 45px;
    }
  }

  &__datablock {
    margin-top: 20px;
    height: calc(100% - 230px);
    column-gap: 20px;
  }

  &__route {
    height: 100%;

    &--details {
      height: calc(100% - 40px);
      overflow: auto;
    }

    &--textfield {
      @include textfield();
      margin: 8px 0px 0px 0px !important;

      input {
        -webkit-text-fill-color: var(--level-4) !important;
      }

      &--date {
        @extend .std-login-transport__route--textfield;
        width: fit-content !important;
        margin: 8px 10px 0px 0px !important;
      }
    }

    &--title {
      display: flex;
      justify-content: center;
      gap: 10px;

      img {
        width: 30px;
      }
    }
  }

  &__tableblock {
    height: 100%;
  }

  &__table {
    @include table();
    height: calc(100% - 30px);

    &--slno {
      width: calc(100% - 94%);
      font-family: fonts.$eduate-number-font;
    }

    &--date {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--rcptno {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--amount {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }
  }
}

.std-login-transport__route--details::-webkit-scrollbar {
  @include scrollbar();
}

.std-login-transport__route--details::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.std-login-transport__route--details::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}