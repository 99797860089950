@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.accounts-dashboard {
@include mainFrameWithTitle();
  overflow: auto;
  padding-left: 10px;
  padding-right: 10px;
  @include muiTabs();

  .MuiButtonBase-root {
    min-width: fit-content !important;
  }

  .subtitle1 {
    width: calc(100% - 35px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    text-align: left !important;
    font-size: 14px !important;

    &:hover {
      text-overflow: clip;
      overflow: visible;
    }
  }
  &__tabs {
    display: flex;
    justify-content: space-between;
    &--view {
      background-color: var(--bg-color);
      border-radius: 20px;
      border: 1px solid var(--border-color);
      font-family: fonts.$eduate-font-family;
      padding: 3px 5px;
      display: flex;
      align-items: center;
      color: colors.get-color(colors.$cool_grey, level_8);
      img {
        width: 30px;
      }
    }
  }

  &__details {
    column-gap: 20px;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: calc(100% - 320px);

    &--block {
      padding: 5px;
      @include blocksInDashboard();
      height: 175px;

      &--title {
        display: flex;
        justify-content: space-between;
        padding-left: 10px;

        &-tabs {
          display: grid;
          grid-template-columns: 0.7fr 1fr 0.1fr;
        }
      }

      &--details {
        display: flex;
        column-gap: 30px;

        img {
          width: 70px;
          height: 70px;
        }

        &--total {
          font-family: fonts.$eduate-number-font;
          font-weight: bold;
          font-size: 18px;
          color: colors.get-color(colors.$grey, level_8);
          text-align: center !important;
          position: relative;
        }

        &--receipts {
          @extend .accounts-dashboard__details--block--details--total;
          color: colors.get-color(colors.$grey, level_6);
          font-size: fonts.$eduate-default-font-size;
        }
      }

      &--g-count {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-top: 1px solid var(--border-color);
        gap: 5px;

        img {
          margin: auto;
        }

        &--flex {
          display: flex;
          column-gap: 10px;
          padding: 5px;
          img {
            width: 35px;
            height: 35px;
          }
        }

        .separator {
          border-right: 1px solid var(--border-color);
        }

        &--block {
          display: flex;
          flex-direction: column;
          margin: auto;

          &--text {
            font-family: fonts.$eduate-font-family;
            color: colors.get-color(colors.$grey, level_6);
            font-weight: 700;
            font-size: 12px;
          }

          &--total {
            @extend .accounts-dashboard__details--block--g-count--block--text;
            font-size: 12px;
            font-weight: 700;
            color: var(--text-color);
            font-family: fonts.$eduate-number-font;
          }

          &--receipts {
            @extend .accounts-dashboard__details--block--g-count--block--text;
            color: colors.get-color(colors.$grey, level_6);
            font-size: 12px;
          }
        }
      }

      &--receipts-block {
        height: 140px !important;
        @include blocksInDashboard();
        width: calc(100% - 320px);

        &--data {
          display: grid;
          grid-template-columns: repeat(4, 1fr);

          .accounts-dashboard__details--block--g-count--block {
            margin: 0px !important;
          }
        }
      }

      &--table-block {
        height: calc(100% - 150px) !important;
        margin-top: 10px;
        @include blocksInDashboard();
      }
    }
  }

  &__datablock {
    height: calc(100% - 200px);
    column-gap: 20px;
    margin-top: 20px;
    display: flex;
    width: 100%;
    .accounts-dashboard__details--block {
      width: 36%;
    }
    &--frame {
      display: flex;
      width: 64%;
      flex-direction: column;
    }
    &--graph {
      height: calc(100% - 20px);
    }

    &--tableblock {
      height: calc(100% - 40px);

      &--table {
        @include table();
      }
    }

    &--services {
      height: calc(100% - 50px);
      overflow: auto;

      li {
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        padding-bottom: 5px;
        border-bottom: 1px solid var(--border-color);

        .Label {
          margin-top: auto !important;
          margin-bottom: auto !important;
        }
      }
    }

    &--chart {
      height: 100% !important;
    }
  }

  &__swipable {
    height: calc(100% - 45px);

    .react-swipeable-view-container,
    .MuiTypography-root {
      height: 100% !important;
      position: relative;
    }

    .swipable-tabs {
      height: calc(100% - 10px) !important;
    }

    &--data {
      height: 100%;
    }
  }

  &__voucher-checker {
    margin-top: 10px;
    height: calc(100% - 150px) !important;
    @include blocksInDashboard();
    width: 100% !important;

    &--li {
      height: calc(100% - 50px);
      overflow: auto;

      img {
        display: block;
        margin: auto;
        width: 40px;
      }

      li {
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
        padding: 5px;
        border-bottom: 1px solid var(--border-color);
        border-radius: 3px;

        &:hover {
          background-color: var(--level-1);
        }
      }
    }

    &--view {
      height: 20px;
      font-size: fonts.$eduate-default-font-size;
      color: colors.get-color(colors.$grey, level_6);
      font-family: fonts.$eduate-font-family;
      text-align: center;

      &:hover {
        text-decoration: underline;
        font-weight: 700;
      }
    }
  }
  &__historic {
    height: 100%;
    padding: 0px 20px;
    &--title {
      img {
        @include closeIconInModal();
      }
    }
    &--data {
      height: calc(100% - 100px);
      margin-top: 10px;
    }
    &--b {
      font-weight: 700;
      font-family: fonts.$eduate-font-family;
      font-size: 18px;
      color: colors.get-color(colors.$blue, level_7);
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    &--block {
      display: block;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      padding: 5px 20px;
      background-color: var(--disabled-color);
      border-radius: 6px;
      margin-top: 10px;
      height: 70px;
      &--yr {
        text-align: center;
        font-size: 16px;
        font-family: fonts.$eduate-number-font;
        color: colors.get-color(colors.$cool_grey, level_8);
      }
      &--span {
        display: block;
        font-size: 14px;
        background-color: colors.get-color(colors.$blue, level_7);
        padding: 2px 5px;
        color: white;
        font-family: fonts.$eduate-font-family;
        border-radius: 6px;
      }
    }
    &--ul {
      margin-bottom: 0px;
      height: calc(100% - 120px);
      margin-top: 10px;
      li {
        display: flex;
        justify-content: space-between;
        border: 1px solid var(--border-color);
        margin-top: 10px;
        padding: 8px 5px;
        border-radius: 6px;
        span {
          font-size: 16px;
          font-family: fonts.$eduate-number-font;
        }
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
    &--view {
      height: 100%;
      &--inst-name {
        height: 60px;
        display: flex;
        background-color: var(--disabled-color);
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
      }
      &--flex {
        display: flex;
        column-gap: 10px;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
        }
        b {
          font-size: 16px;
          font-family: fonts.$eduate-font-family;
        }
      }
      &--year {
        @include textfield();
        width: fit-content !important;
      }
      &--graph {
        margin-top: 10px;
        border: 1px soli var(--border-color);
        height: calc(100% - 330px);
      }
      .accounts-dashboard__details {
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
        width: 100% !important;
      }
    }
  }
  &__demand-details {
    width: 320px;
    height: 335px !important;
    position: absolute;
    top: 0;
    right: 0;
    &--data {
      border: 1px solid var(--border-color);
      padding: 5px;
      margin-left: 20px;
      height: 100%;
      border-radius: 10px;
    }
    &--flex {
      display: flex;
      justify-content: space-between;
      padding: 4px;
      align-items: center;
    }
    &--image {
      display: flex;
      column-gap: 10px;
      img {
        height: 30px;
        width: 30px;
      }
      span {
        font-size: 14px;
        font-family: fonts.$eduate-font-family;
      }
      b {
        font-family: fonts.$eduate-number-font;
        font-size: 14px;
      }
    }
    &--subtitle {
      background-color: var(--level-1);
      text-align: center;
      padding: 5px;
      border-radius: 5px;
      color: var(--level-5);
    }
  }
}

.accounts-dashboard::-webkit-scrollbar,
.accounts-dashboard__voucher-checker--li::-webkit-scrollbar,
.accounts-dashboard__datablock--services::-webkit-scrollbar {
  @include scrollbar();
}

.accounts-dashboard::-webkit-scrollbar-thumb,
.accounts-dashboard__voucher-checker--li::-webkit-scrollbar-thumb,
.accounts-dashboard__datablock--services::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.accounts-dashboard::-webkit-scrollbar-thumb:hover,
.accounts-dashboard__voucher-checker--li::-webkit-scrollbar-thumb:hover,
.accounts-dashboard__datablock--services::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

.font-14 {
  font-size: 14px !important;
}

.font-10 {
  font-size: 10px !important;
}
