
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.purchase-entry {
    @include mainFrameWithTitle();

    &__filters {
        height: 120px;
        column-gap: 20px;

        .storybook-button {
            display: flex !important;
            justify-content: flex-end !important;
            margin-right: 0px !important;
            margin-left: auto !important;
        }

        .data-fetch-icon {
            margin-top: 8px;
        }

        .MuiAutocomplete-root {
            margin-top: 8px;
        }

        &--flex {
            display: flex;
            column-gap: 10px;

        }

        &--textfield {
            @include textfield();
            width: 100%;
            margin: 8px 0px 0px 0px !important;
        }



        &--textfields {
            @include textfield();
            width: 100%;
            margin: 8px 0px 0px 0px !important;

            input {
                font-weight: 400 !important;
            }

            label {
                color: var(--text-color) !important;
            }
        }

        &--date {
            @include textfield();
            width: fit-content !important;
            margin: 8px 0px 0px 0px !important;

            input {
                font-weight: 400 !important;
            }

            label {
                color: var(--text-color) !important;
            }
        }
    }

    &__tableblock {
        height: calc(100% - 300px);
        margin-top: 10px;
    }

    &__datablock {
        height: 115px;
        margin-top: 10px;
        column-gap: 20px;

        &--frame {
            border: 1px solid var(--border-color);
            border-radius: 6px;
            padding: 0px 5px 5px 5px;
            height: 100%;

            .MuiInputBase-root {
                padding: 5px !important;
            }
        }

        &--details {
            text-align: right;

            &--label-grid {
                display: grid;
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    &__table {
        @include table();

        &--slno {
            width: calc(100% - 96%);
            font-family: fonts.$eduate-number-font;
        }

        &--row {
            @include selectTableRow();
            @include autoCompleteInTableRow();

            .MuiAutocomplete-root {
                margin-top: 0px !important;
            }

            .MuiFormControl-root {
                margin-top: 0px !important;
            }

            .MuiAutocomplete-root,
            .auto-complete:focus {
                background-color: var(--bg-color) !important;
            }
        }

        &--number {
            width: calc(100% - 94%);
            @include inputReportsTable();
        }

        &--desc {
            width: calc(100% - 90%);
            @include inputReportsTable();
        }

        &--actions {
            width: calc(100% - 96%);
        }
    }

    &__ledger-list {
        height: calc(100% - 30px);

        &--filters {
            column-gap: 10px;

            .MuiAutocomplete-root {
                margin-top: 10px !important;
            }
        }

        &--tableblock {
            margin-top: 10px;
            height: calc(100% - 95px)
        }

        &--table {
            @include table();

            &--slno {
                width: calc(100% - 94%);
                font-family: fonts.$eduate-number-font;
            }

            &--mobile {
                width: calc(100% - 90%);
                font-family: fonts.$eduate-number-font;
            }

            &--number {
                width: calc(100% - 86%);
                font-family: fonts.$eduate-number-font;
            }

            &--type {
                width: calc(100% - 92%);
            }
        }
    }
}