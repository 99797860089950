@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.interchange-category{
    height:calc(100% - 160px);
    margin :0px 20px 0px 20px;
    &--select{
        column-gap: 10px;
        margin-top: 10px;
    }
    &--tableblock{
        height: calc(100% - 100px);
        margin-top:10px;
    }
    &--table{
        @include table();
        @include tableCheckbox();
    }
    &--drawer{
        height: 100%;
        margin:0px 10px;
        &--title{
            img{
                @include closeIconInModal();
            }
        }
        &--tableblock{
            margin-top: 10px;
            height: calc(100% - 120px);
        }
    }

}