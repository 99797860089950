@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.holiday-entry {
  height: calc(100% - 170px);
  margin: 0px 20px 0px 20px;

  &--modal {
    height: calc(100% - 35px);
  }

  &__details {
    height: 40px;

    .storybook-button {
      display: flex;
      justify-content: flex-end;
      margin: 0px 0px 0px auto !important;
    }
  }

  &__data {
    height: calc(100% - 90px);
    margin-top: 10px;
    column-gap: 30px;

    &--modal {
      @extend .holiday-entry__data;
      height: calc(100% - 50px);
    }

    &--left {
      height: 100%;
      border: 1px solid var(--border-color);
      padding: 5px;
      border-radius: 6px;
    }

    &--right {
      height: 100%;
      border: 1px solid var(--border-color);
      padding: 5px;
      border-radius: 6px;

      .MuiAutocomplete-root {
        margin-top: 10px;
      }

      &--details {
        column-gap: 10px;
      }

      &--icons {
        display: flex;
        justify-content: center;
        column-gap: 20px;
        border-top: 1px dashed var(--border-color);
        padding: 5px;

        img {
          border: 1px solid var(--border-color);
          border-radius: 50%;
          padding: 5px;
        }
      }
    }

    &--holiday-list {
      height: calc(100% - 80px);
      overflow: auto;
      @include calendarEventsList();

      &--desc {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
      }
    }
  }

  &__new {
    height: calc(100% - 40px);

    &--details {
      height: calc(100% - 50px);
      margin: 10px 0px 0px 0px;
      column-gap: 20px;
    }

    &--block {
      height: 100%;
      border: 1.8px dashed var(--border-color);
      padding: 10px;

      .subtitle1 {
        height: 50px;
      }

      .select-all {
        margin-left: 0px !important;
      }

      &--weekend-list {
        height: calc(100% - 110px);
        margin-left: 20px;
        overflow: auto;

        .MuiButtonBase-root {
          padding: 0px !important;
        }
      }

      &--list {
        height: calc(100% - 55px);
        margin-top: 10px;
      }

      &--table {
        @include table();

        &--day {
          background-color: var(--bg-color);

          td {
            border-bottom: 1.8px dashed var(--border-color) !important;
            color: var(--level-5) !important;
            font-weight: bold;
          }
        }
      }

      &--holiday-list {
        height: calc(100% - 20px);
        overflow: auto;
        @include calendarEventsList();
      }
    }
  }

  &__add {
    .MuiAutocomplete-root {
      margin-top: 10px;
    }

    &--textfield {
      @include textfield();
      margin: 10px 0px 0px 0px !important;

      &--date {
        @include textfield();
        width: fit-content;
        margin: 10px 0px 0px 0px !important;
      }
    }

    &--select-options {
      margin-top: 15px;
    }
  }

  &__calendar {
    height: 100%;
    border: 1px solid #edf2f7;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 5px;

    &--grid-container {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      height: calc(100% - 45px);
      margin-bottom: 0px !important;
      gap: 10px;
    }

    &--month {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 40px;

      img {
        width: 20px;
      }
    }

    &--day {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
    }

    &--date {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: fonts.$eduate-number-font;
      font-size: fonts.$eduate-default-font-size;
      border: 1px solid var(--border-color);
      border-radius: 6px;
      position: relative;

      &:hover {
        background-color: var(--level-1);
      }
    }
  }
}

.holiday-entry__new--block--holiday-list::-webkit-scrollbar,
.calendar-holiday-list--data::-webkit-scrollbar,
.holiday-entry__new--block--weekend-list::-webkit-scrollbar,
.holiday-entry__data--holiday-list::-webkit-scrollbar {
  @include scrollbar();
}

.holiday-entry__new--block--holiday-list::-webkit-scrollbar-thumb,
.calendar-holiday-list--data::-webkit-scrollbar-thumb,
.holiday-entry__new--block--weekend-list::-webkit-scrollbar-thumb,
.holiday-entry__data--holiday-list::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.holiday-entry__new--block--holiday-list::-webkit-scrollbar-thumb:hover,
.calendar-holiday-list--data::-webkit-scrollbar-thumb:hover,
.holiday-entry__new--block--weekend-list::-webkit-scrollbar-thumb:hover,
.holiday-entry__data--holiday-list::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

.calendar-holiday-list {
  height: 100%;

  &--title {
    display: flex;
    justify-content: space-between;
  }

  &--data {
    height: calc(100% - 120px);
    overflow: auto;
    margin-top: 10px;

    &--month {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      font-weight: 700;
      font-family: fonts.$eduate-font-family;
      font-size: 16px;
    }

    &--holidays {
      height: calc(100% - 140px);
    }

    &--holiday-list {
      @include calendarEventsList();
    }

    &--vacations-list {
      li {
        display: flex;
        column-gap: 10px;
      }

      &--events {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        border-radius: 6px;
        width: 100%;
        padding: 5px;
        font-size: 12px;
        border: 1px solid var(--border-color);

        span {
          font-family: fonts.$eduate-number-font;
        }

        b {
          font-family: fonts.$eduate-font-family;
        }

        .Label {
          margin-top: auto;
          margin-bottom: auto;
        }
      }

      span {
        column-gap: 10px;
      }
    }

    &--events-list {
      li {
        display: flex;
        column-gap: 10px;
      }

      &--events {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        border-radius: 6px;
        width: 100%;
        padding: 5px;
        font-size: 12px;
        border: 1px solid var(--border-color);

        b {
          font-family: fonts.$eduate-number-font;
        }

        span {
          font-family: fonts.$eduate-font-family;
          color: var(--level-6);
          display: block;
        }

        .Label {
          margin-top: auto;
          margin-bottom: auto;
        }
      }

      span {
        column-gap: 10px;
      }
    }

    &--weekends-list {
      &--li {
        display: flex;
        justify-content: space-between;
        border: 1px solid var(--border-color);
        padding: 5px;
        border-radius: 6px;
      }

      &--date {
        color: colors.get-color(colors.$red, level_6);
        font-family: fonts.$eduate-number-font;
        font-size: 12px;
      }
    }

    &--desc {
      font-family: fonts.$eduate-font-family;
      font-size: fonts.$eduate-default-font-size;
    }
  }

  &__add-holiday {
    .MuiAutocomplete-root {
      margin-top: 10px;
    }

    height: calc(100% - 70px);

    .MuiSwitch-root {
      margin-left: 10px;
    }
  }
}
