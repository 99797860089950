@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.document-create {
  @include mainFrameWithTitle();

  &__frame {
    height: calc(100% - 55px);
    margin-top: 10px;
    display: flex;
    column-gap: 40px;

    &--left {
      height: 100%;
      border: 1px solid colors.get-color(colors.$colors, cloud-white);
      border-radius: 0px 40px 0px 0px;
      padding: 20px 5px 5px 5px;
      width: 50%;
    }

    &--right {
      @extend .document-create__frame--left;
      border-radius: 40px 0px 0px 0px;
    }
  }

  &__table {
    height: calc(100% - 35px);
  }
}
