@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.sub-wise-att {
  height: calc(100% - 35px);

  &--report {
    height: calc(100% - 160px);
  }

  &__select {
    column-gap: 10px;
    margin-top: 10px;
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    height: 80px;
    margin: 10px 0px 10px 0px;

    &--grid {
      background-color: var(--disabled-color);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      border-radius: 10px;

      span {
        color: colors.get-color(colors.$grey, level_9);

        font-family: fonts.$eduate-font-family;
        font-size: 16px;
        text-align: center;
      }

      b {
        font-size: 16px;
        font-family: fonts.$eduate-number-font;
      }
    }
  }

  &__subtitle {
    img {
      width: 20px;
      margin: 0px 5px;
    }
  }

  &__tableblock {
    height: calc(100% - 185px);
    margin-top: 10px;

    img {
      width: 15px;
      margin-right: 5px;
    }
  }

  &__subject-list {
    height: calc(100% - 160px);
    overflow: auto;

    &--block {
      width: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      b {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        width: 80px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      span {
        font-family: fonts.$eduate-number-font;
        color: colors.get-color(colors.$grey, level_4);
        font-size: 12px;
        width: 80px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .sub-wise-att__cards {
      width: calc(100% - 100px);
    }

    .sub-wise-att__cards--grid {
      background-color: transparent !important;

      span {
        text-align: center;
        font-size: fonts.$eduate-default-font-size;
      }

      b {
        font-size: 12px;
      }
    }

    li {
      display: flex;
      margin-top: 10px;
      padding: 5px;
      border-bottom: 1px dashed var(--border-color);

      &:hover {
        background-color: var(--level-1);
        border-radius: 10px;
      }

      img {
        filter: invert(100%);
        width: 20px !important;
      }
    }
  }

  &__weekend {
    background-color: var(--bg-color) !important;
    color: colors.get-color(colors.$colors, red);
  }

  &__vacation {
    background-color: colors.get-color(colors.$amber, level_5) !important;
    color: colors.get-color(colors.$colors, white) !important;
  }

  &__holiday {
    background-color: colors.get-color(colors.$colors, red);
    color: colors.get-color(colors.$colors, white) !important;
  }
}

.sub-wise-att__subject-list::-webkit-scrollbar {
  @include scrollbar();
}

.sub-wise-att__subject-list::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.sub-wise-att__subject-list::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
