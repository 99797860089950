@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.associate-elective-subjects {
  height: calc(100% - 140px);

  &__select-flex {
    margin: 10px 20px 0px 20px;
    column-gap: 10px;
    .MuiChip-root {
      height: 18px !important;
      font-size: 12px !important;
      font-family: fonts.$eduate-font-family;
    }
    .container__list--addnew {
      margin-right: 0px;
      margin-top: 0px;
    }
  }
  &__tableblock {
    height: calc(100% - 50px);
    margin: 10px 10px 0px 10px;

    &--title {
      border-bottom: 1.5px dashed var(--border-color);
      margin-right: 5px;
      padding-bottom: 5px;
      height: 40px;
      #search {
        margin-top: 0px;
      }
      .subtitle1 {
        text-align: left;
      }
    }
    &--title2 {
      border-bottom: 1.5px dashed var(--border-color);
      padding-bottom: 5px;
      height: 40px;
    }

    &--swap-image {
      width: 40px;
      text-align: center;
      margin: auto;
      img {
        background-color:colors.get-color(colors.$colors, white);
        padding: 5px;
        width: 40px;
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
          0px 2px 4px -1px rgba(0, 0, 0, 0.06);
        border-radius: 50%;
      }
    }
    &--table {
      @include table();
      @include tableCheckbox();

      margin-top: 10px;
      height: calc(100% - 50px);
      &--slno {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-slno {
        width: calc(100% - 92%);
      }
      &--code {
        width: calc(100% - 86%);
      }
    }
  }
}
