@use "../../../../scss/common/mixins" as *;

.predefined {
  &__tableblock {
    height: calc(100% - 265px);
    margin: 10px 20px 0px 20px;
  }
  &__table {
    @include table();
  }
}

.predefined__table::-webkit-scrollbar {
  @include scrollbar();
}

.predefined__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.predefined__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
