@use "../../../../scss/common/mixins" as *;

.agents-list {
  @include mainFrameWithTitle();
  &--modal {
    height: calc(100% - 30px);
  }
  &__title {
    img {
      @include closeIconInModal();
    }
  }
  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 95px);
  }
}
