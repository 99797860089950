@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.acd-posting {
  height: calc(100% - 160px);
  margin: 0px 20px;

  &__data {
    height: calc(100% - 50px);
    display: flex;
    column-gap: 30px;
    justify-content: space-between;

    &--dates-block {
      padding: 15px;
      height: 100%;

      //shiva
      .choose-acd-yrs {
        display: flex;
        flex-direction: column;
        width: 100%;
        row-gap: 10px;
        overflow: auto;
        height: calc(100% - 120px);
        &__grid {
          display: grid;
          grid-template-columns: 0.1fr 2fr;
          .MuiFormControlLabel-root {
            margin-top: 5px !important;
          }
          .MuiAutocomplete-root{
            margin-top: 10px !important;
          }
        }
        &__previous {
          border: 1px solid var(--border-color);
          border-radius: 6px;
          padding: 5px;

        }
        &__current {
          @extend .choose-acd-yrs__previous;
          margin-bottom: 10px;
        }
      }
    }
    .choose-acd-yrs::-webkit-scrollbar {
      display: none;
    }

    &--warning-block {
      background-color: var(--level-1);
      height: calc(100% - 315px);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px;

      ul {
        height: calc(100% - 45px);
        margin-bottom: 0px !important;
        margin-top: 10px;

        li {
          font-size: fonts.$eduate-default-font-size;
          font-family: fonts.$eduate-font-family;
          color: colors.get-color(colors.$colors, red);
        }
      }
    }

    &--left {
      width: 350px;
      border: 1px solid var(--border-color);
      border-radius: 10px;
      height: 100%;
    }

    &--right {
      width: calc(100% - 350px);
      box-shadow: 2px 2px 7px rgba(80, 80, 80, 0.5);
      border-radius: 6px;
      height: 100%;
      overflow: auto;

      li {
        padding: 10px;
        font-family: fonts.$eduate-font-family;
      }

      &--title {
        background-color: var(--level-2);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        color: var(--level-7);

        button {
          margin-top: 0px !important;
        }
      }

      &--sublist {
        display: grid;
        grid-template-columns: 1fr 0.3fr 1fr;
        border-bottom: 2px dashed var(--border-color);
      }

      &--moving-sec {
        display: flex;
        flex-direction: column;

        span {
          color: var(--level-5);
          text-align: right;
        }

        b {
          font-family: fonts.$eduate-font-family;
          color: colors.get-color(colors.$colors, red);
          font-size: 16px;
          text-align: right;
        }

        button {
          padding: 4px 10px;
          border-radius: 20px;
          color: colors.get-color(colors.$grey, level_9);
          width: fit-content;
          font-size: 12px;
          font-family: fonts.$eduate-font-family;
          display: block;
          margin-left: auto;
          margin-right: 0px;
          background-color: var(--bg-color);
          border: 1px solid var(--border-color);
        }
      }
    }

    &--label-grid {
      display: grid;
      grid-template-columns: 1.2fr 1fr;
      height: 120px;
    }

    &--textfield {
      @include textfield();
      margin: 10px 0px 0px 0px !important;
      width: fit-content !important;
    }
  }

  &__reorder {
    height: calc(100% - 35px);

    &--tableblock {
      height: calc(100% - 40px);
    }

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }
    }
  }

  &__view-section {
    height: calc(100% - 35px);

    &--data {
      height: calc(100% - 40px);
      overflow: auto;
    }
  }
}

.acd-posting__data--right::-webkit-scrollbar,
.acd-posting__view-section--data::-webkit-scrollbar {
  @include scrollbar();
}

.acd-posting__data--right::-webkit-scrollbar-thumb,
.acd-posting__view-section--data::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.acd-posting__data--right::-webkit-scrollbar-thumb:hover,
.acd-posting__view-section--data::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
