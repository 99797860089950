@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.classification-list {
  &__data {
    column-gap: 20px;
  }
  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 120px);
  }
  &__table {
    @include table();
    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-slno {
      width: calc(100% - 96%);
    }
    &--clsno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-clsno {
      width: calc(100% - 92%);
    }
    &--publication {
      width: calc(100% - 90%);
    }
    &--total {
      width: calc(100% - 95%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-total {
      width: calc(100% - 95%);
    }
    &--category {
      width: calc(100% - 92%);
    }
  }
}

.classification-list__table::-webkit-scrollbar {
  @include scrollbar();
}

.classification-list__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.classification-list__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
