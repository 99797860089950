@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.allocatesubjects {
  margin: 10px 20px 0px 20px;
  height: calc(100% - 95px);
  &__select-flex {
    column-gap: 10px;

    &--button {
      display: flex;
      margin-left: auto !important;
      justify-content: flex-end;
      margin-right: 0px !important;
      margin-top: 0px !important;
    }
  }
  &__table {
    @include table();
    @include tableCheckbox();
    .MuiFormControl-root {
      margin-top: 0px !important;
    }

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-slno {
      width: calc(100% - 96%);
    }
    &--code {
      width: calc(100% - 86%);
    }

    &--actions {
      width: calc(100% - 90%);
    }
    &--row {
      background-color: colors.get-color(colors.$yellow, level_3);
      @include selectTableRow();
      @include autoCompleteInTableRow();
      .MuiAutocomplete-root,
      .auto-complete:focus {
        background-color: var(--bg-color) !important;
      }

      input {
        &:focus {
          background-color:colors.get-color(colors.$colors, white);
        }
      }
      td {
        color: colors.get-color(colors.$colors, black) !important;
      }
    }
  }
  &__table-block {
    margin-top: 10px;
    height: calc(100% - 90px);
  }

  &__copy {
    height: calc(100% - 45px);
    margin-top: 10px;
    &--details {
      column-gap: 10px;
    }
    &--tableblock {
      height: calc(100% - 90px);
      margin: 10px 0px 10px 0px;
    }
    &--table {
      @include table();
    }
  }
}
.enter-image {
  width: 25px;
  height: 20px;
}
