@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.emp-login-user {
  margin: 0px 20px;
  height: calc(100% - 175px);

  &__modal {
    height: calc(100% - 40px);
  }

  &__select {
    column-gap: 10px;
    @include muiMultiSelect();

    .MuiAutocomplete-root {
      margin-top: 10px;
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end !important;
    margin-left: auto !important;
    margin-right: 0px !important;
  }

  &__tableblock {
    height: calc(100% - 90px);
    margin-top: 10px;
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--mobile {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--email {
      width: calc(100% - 88%);
    }

    &--actions {
      width: calc(100% - 96%);
    }
  }
}
