@use "../../../scss/common//mixins" as *;
@use "../../../scss/common/fonts" as fonts;
@use "../../../scss/common/colors" as colors;
.vms-fuel-indent {
    @include mainFrameWithTitle();

    &__basic-details {
        height: 120px;
        column-gap: 20px;
        margin-top: 10px;

        &--flex {
            display: flex;

            .MuiAutocomplete-root {
                margin-top: 10px;
            }

            .data-fetch-icon {
                margin-top: 10px;
            }
        }

        &--column-span {
            column-count: 2;
            column-gap: 20px;
        }

        &--textfield {
            @include textfield();
            margin: 10px 0px 0px 0px !important;
        }
    }

    &__data {
        height: calc(100% - 228px);
        border-top: 1.6px dashed var(--border-color);
        margin-top: 10px;

        &--supplier-details {
            height: 160px;
            column-gap: 40px;

            .storybook-input,
            .Label {
                margin-top: 5px !important;
            }

            &--flex {
                display: flex;

                .MuiFormControl-root {
                    margin-top: 5px !important;
                }
            }


            &--frame {
                border: 1px solid var(--border-color);
                padding: 5px;
                border-radius: 10px;
            }
        }

        &--details {
            column-gap: 17px;
            height: calc(100% - 160px);
        }
    }

    &__view {
        height: calc(100% - 35px);

        &--select {
            column-gap: 10px;

            .MuiAutocomplete-root {
                margin-top: 10px;
            }
        }

        &--tableblock {
            height: calc(100% - 90px);
            margin-top: 10px;
        }

        &--table {
            @include table();

            &--slno {
                width: calc(100% - 96%);
                font-family: fonts.$eduate-number-font;
            }

            &--number {
                width: calc(100% - 90%);
                font-family: fonts.$eduate-number-font;
            }

            &--actions {
                width: calc(100% - 96%);
            }
        }

    }
}