@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
.student-fee-enquiry {
  height: calc(100% - 193px);
  margin: 0px 20px 0px 20px;

  &--modal {
    height: calc(100% - 70px);
  }

  &__details {
    height: 100%;

    &--select-options {
      height: 120px;
      column-gap: 20px;
    }

    &--textfield {
      @include textfield();
      margin: 8px 10px 0px 0px !important;

      &--date {
        @include textfield();
        width: fit-content !important;
        margin: 8px 10px 0px 0px !important;
      }
    }

    &--imagefield {
      display: flex;

      .MuiAutocomplete-root {
        width: 100% !important;
        margin-top: 8px !important;
      }

      .data-fetch-icon {
        margin-top: 8px;
      }
    }
  }

  &__receiptblock {
    height: 135px;
    column-gap: 20px;

    Label {
      margin-top: 0px;
    }

    Input {
      margin-top: 3px !important;
    }

    &--frame {
      border: 1px solid colors.get-color(colors.$colors, cloud-white);
      padding: 5px 10px 5px 10px;
      border-radius: 6px;
      height: 100%;

      &--modal {
        @extend .student-fee-enquiry__receiptblock--frame;
        margin: 0px 0px 0px 10px;
      }

      &--bankdetails {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      .MuiFormControl-root {
        margin-top: 5px !important;
      }
    }

    &--edit {
      .MuiAutocomplete-root {
        margin-top: 10px;
      }
    }
  }

  &__data {
    height: calc(100% - 255px);

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 95%);
        font-family: fonts.$eduate-number-font;
      }

      &--amount {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }
    }
  }
  &__tableblock {
    height: calc(100% - 265px);
  }
  &__table {
    @include table();
    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--input {
      @include inputSalaryTable();
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }
    &--select-row {
      @include selectTableRow();
      @include autoCompleteInTableRow();

      input {
        &:focus {
          background-color:colors.get-color(colors.$colors, white);
        }
      }

      td {
        color: colors.get-color(colors.$colors, black) !important;
      }
    }
    &--actions {
      width: calc(100% - 96%);
    }
  }
  &__receiptblock {
    height: 135px;
    column-gap: 20px;
    .account-frames {
      margin: 10px 0px 0px 0px !important;
    }
    &--narration {
      margin-top: 0px;
    }
    Label {
      margin-top: 0px;
    }
    Input {
      margin-top: 3px !important;
    }

    .MuiFormControl-root {
      margin-top: 3px !important;
    }
    &--modal {
      @extend .student-fee-enquiry__receiptblock;
      margin: 0px 0px 10px 0px;
      .account-frames {
        margin: 10px 0px 0px 0px !important;
      }
    }
  }
}
