
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.transport-route {
  margin: 0px 20px 0px 20px;
  height: calc(100% - 170px);
  &__details {
    height: calc(100% - 40px);
    column-gap: 40px;
    .booktype-left,
    .booktype-right {
      margin: 0px !important;
    }
    &--title {
      #search {
        margin-top: 0px !important;
      }
    }
    &--table {
      @include table();
      &--slno {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-slno {
        width: calc(100% - 92%);
      }
      &--date {
        width: calc(100% - 85%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-date {
        width: calc(100% - 85%);
      }
      &--actions {
        width: calc(100% - 90%);
      }
    }
    &--tableblock {
      margin-top: 10px;
      height: calc(100% - 40px);
    }
    &--list {
      height: 100%;
    }
  }
}
