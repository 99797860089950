@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.student-export {
  height: calc(100% - 165px);
  margin: 10px 20px 0px 20px;
}

.export-student {
  height: calc(100% - 25px);

  &__options {
    position: relative;
    top: 10px;

    column-gap: 10px;

    .student-clear-button {
      margin-top: 0px !important;
      margin-right: 0px !important;
    }
  }

  &__button {
    display: flex !important;
    justify-content: flex-end !important;
  }

  &__tableblock {
    height: calc(100% - 128px);
    margin-top: 10px;
  }

  &__select-columns {
    @include filterColumns();
    margin-top: 20px !important;

    img {
      margin-left: 0px;
    }

    Label {
      margin-top: 0px;
    }
  }

  &__table {
    @include table();
  }

  &__total-count {
    margin-top: 10px;
    height: 40px;

    .student-total-count {
      margin-left: 0px !important;
    }
  }
}
