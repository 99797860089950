@use "../../../scss/common/mixins" as *;
@use "../../../scss/common/fonts" as fonts;
@use "../../../scss/common/colors" as colors;
.module-wise-configuration {
  height: calc(100% - 170px);
  margin: 0px 20px 0px 20px;

  &__modal {
    @extend .module-wise-configuration;
    margin: 0px !important;
    height: calc(100% - 35px);
  }

  .MuiFormControlLabel-root {
    margin-right: 0px !important;
  }

  .MuiTypography-root {
    color:colors.get-color(colors.$colors, white);
    margin-top: 3px;
  }

  .MuiSwitch-root {
    margin-top: 8px;
  }

  .Label {
    margin-top: 5px;
  }

  .select {
    margin-top: 5px !important;
  }

  &__block {
    height: calc(100% - 50px);
    margin-top: 10px;
    overflow: auto;

    &--parameters {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-bottom: 1px dashed var(--border-color);
      padding-bottom: 5px;
    }

    .MuiAccordion-region {
      height: 100%;
    }

    .MuiCollapse-wrapperInner {
      height: 100% !important;
    }

    &--details {

      column-count: 3;

      column-gap: 50px;
      column-rule: 4px dotted var(--border-color);
      column-fill: auto;
      height: 100%;
    }
  }
}

.module-wise-configuration__block::-webkit-scrollbar {
  @include scrollbar();
}

.module-wise-configuration__block::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.module-wise-configuration__block::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}