@use "../../../scss/common//mixins" as *;
@use "../../../scss/common/fonts" as fonts;
@use "../../../scss/common/colors" as colors;
.vms-staff-details-preview {
  margin: 0px 20px;
  height: calc(100% - 160px);

  &__menu {
    display: flex;
    column-gap: 20px;
    margin-bottom: 0px !important;
    margin-top: 10px;

    .active {
      background-color: var(--level-5);
      color:colors.get-color(colors.$colors, white);
      font-weight: 700;
    }

    li {
      border: 1px solid var(--border-color);
      padding: 5px 8px;
      border-radius: 6px;
      font-family: fonts.$eduate-font-family;
      font-size:fonts.$eduate-default-font-size;
      color: colors.get-color(colors.$grey, level_7);
    }
  }

  &__data {
    margin-top: 15px;
    height: calc(100% - 108px);
    overflow: auto;

    &--list {
      column-gap: 20px;
      column-count: 2;
    }
  }

  &__documents {
    margin-top: 15px;
    height: calc(100% - 108px);

    &--upload {
      @include browseFiles();
      margin-right: 10px;
      height: 32px;
    }

    &--common {
      height: 120px;
      column-gap: 20px;

      &--list {
        column-count: 3;
        column-gap: 20px;
      }

      &--profile {
        height: 100%;

        img {
          @include studentProfile();
          width: calc(100% - 10px);
        }
      }

      &--textfield {
        @include textfield();
        margin: 8px 0px 0px 0px !important;
      }
    }

    &--list {
      border-top: 1px dashed var(--border-color);

      height: calc(100% - 120px);

      &--title {
        font-weight: 700;
        font-family: fonts.$eduate-font-family;
        padding: 5px;
        font-size: 16px;
      }
    }

    &--uploaded-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      overflow: auto;
      padding-top: 20px;
      height: calc(100% - 30px);
      gap: 20px;
    }

    &--file {
      background-color: var(--level-2);
      border-radius: 6px;
      border: 1px solid var(--border-color);
      height: 130px;
      padding-top: 12px;

      img {
        display: block;
        margin: auto;
      }

      b {
        font-family: fonts.$eduate-font-family;
        text-align: center;
        font-size:fonts.$eduate-default-font-size;
        display: block;
      }

      span {
        font-family: fonts.$eduate-font-family;
        text-align: center;
        font-size:fonts.$eduate-default-font-size;
        display: block;
      }

      &--icons {
        position: relative;
        display: flex;
        top: 10px;
        padding: 5px;
        justify-content: space-between;
        background-color: var(--bg-color);
        border-radius: 0px 0px 6px 6px;
      }
    }
  }
}
