@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.reorder-route-stages {
  height: calc(100% - 30px);
  &__tableblock {
    height: calc(100% - 80px);
    margin-top: 10px;
    &--table {
      @include table();
      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }
    }
  }
}
