@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.student-services {
  margin: 0px 20px 0px 20px;
  height: calc(100% - 170px);

  &__details {
    height: 110px;
    column-gap: 20px;

    &--colspan {
      column-count: 2;
      column-gap: 20px;
    }

    &--textfield {
      @include textfield();
      margin: 8px 0px 0px 0px !important;

      &--date {
        @extend .student-services__details--textfield;
        width: fit-content !important;
      }
    }

    &--image-flex {
      display: flex;

      .MuiAutocomplete-root {
        margin-top: 8px;
      }

      .data-fetch-icon {
        margin-top: 5px;
      }
    }

    &--student-profile {
      @include studentProfile();
      height: 100%;
    }
  }

  &__data {
    margin-top: 10px;
    height: calc(100% - 160px);
    column-gap: 20px;
    padding-top: 10px;
    border-top: 1.8px dashed var(--disabled-color);



    &--frame {
      height: 100%;
    }

    &--li {
      height: calc(100% - 30px);
      overflow: auto;
      border-radius: 1px solid var(--border-color);

      li {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 10px;
        border: 1px solid var(--border-color);
        margin-top: 10px;
        border-radius: 6px;

        .Label {
          margin-top: 0px !important;
        }

      }

      &--images {
        display: flex;
        column-gap: 5px;
      }

      &--view {
        @extend .student-services__data--li;

        label {
          margin-top: 0px;
        }

        li {
          background-color: var(--level-1);
        }
      }
    }


  }

  &__transport {
    height: 100%;
    border: 1px dashed var(--border-color);
    padding-top: 5px;
    border-radius: 3px;

    &--view {
      height: calc(100% - 65px);
      padding: 0px 5px 0px 5px;
    }

    &--textfield {
      @include textfield();
      margin: 10px 0px 0px 0px !important;

      input {
        color: var(--level-4) !important;
      }

      &--date {
        @extend .student-services__transport--textfield;
        width: fit-content !important;
        margin: 10px 5px 0px 0px !important;

      }
    }

    &--choosed-route {
      height: 40px;
      background-color: colors.get-color(colors.$cool_grey, level_2);
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 5px;
      font-size:fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
      font-weight: 700;
      border-radius: 5px;

      img {
        width: 30px;
      }
    }
  }

  &__assign-route {
    height: calc(100% - 30px);

    &--data {
      height: calc(100% - 45px);

      .MuiAutocomplete-root {
        margin-top: 10px !important;
      }
    }

    &--textfield {
      @include textfield();
      margin: 10px 0px 0px 0px !important;
    }

    &--date {
      @include textfieldInForms();
      width: fit-content !important;
    }
  }
}

//table scroll
.student-services__data--li::-webkit-scrollbar,
.student-services__data--li--view::-webkit-scrollbar {
  @include scrollbar();
}

.student-services__data--li::-webkit-scrollbar-thumb,
.student-services__data--li--view::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.student-services__data--li::-webkit-scrollbar-thumb:hover .student-services__data--li--view::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}