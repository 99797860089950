@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.repaired-books {
  height: calc(100% - 88px);

  &__details {
    column-gap: 20px;

    &--textfield {
      width: 100%;

      img {
        width: 18px;
      }

      .MuiInputBase-input {
        padding: 6.5px !important;
        font-family: fonts.$eduate-font-family;
        font-size: fonts.$eduate-default-font-size;
      }

      .MuiInputBase-root {
        padding-left: 5px !important;
      }
    }
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 110px);
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-slno {
      width: calc(100% - 96%);
    }

    &--clsno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-clsno {
      width: calc(100% - 92%);
    }

    &--shelf {
      width: calc(100% - 92%);
    }

    tr:hover {
      td {
        @include tdContainsOnclick();
      }
    }
  }

  &__allocate {
    column-gap: 20px;

    &--textfield {
      width: 100%;
      @include textfield();
      margin: 10px 0px 0px 0px !important;

      &--edit {
        @include textfield();
        margin: 10px 0px 0px 0px !important;

        input {
          font-weight: 400 !important;
        }
      }
    }
  }
}
