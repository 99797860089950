
@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.feedemandview {
  height: calc(100% - 85px);
  overflow: auto;

  &__rightblock {
    height: 100%;
    overflow: auto;
  }

  &__button {
    margin-left: 20px;
    padding: 5px;
  }

  .total {
    background-color: inherit !important;
  }

  &__generate {
    Button {
      margin: -2px 10px 10px 10px;
    }
  }

  &__block1 {
    border-radius: 15px;
    padding: 10px;
  }

  &__block2 {
    margin-top: 10px;
    @extend .feedemandview__block1;
  }

  &__table--pink {
    @include Accountstable();
  }

  &__table--green {
    @include Accountstable();
  }

  &__table {
    @include table();
  }

  &__total {
    text-align: right;
  }
}

.feedemandview__rightblock::-webkit-scrollbar {
  @include scrollbar();
}

.feedemandview__rightblock::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.feedemandview__rightblock::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

.feedemandview::-webkit-scrollbar {
  @include scrollbar();
}

.feedemandview::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.feedemandview::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}