@use "../../scss/common/fonts" as fonts;
@use "../../scss/common/colors" as colors;

.Heading {
  font-family: fonts.$eduate-font-family;
  font-style: normal;
  font-weight: 900 !important;
  font-size: 22px !important;
  color: var(--text-color) !important;
  text-align: center;
  height: 30px;
  position: sticky;
  position: -webkit-sticky;
}

.subtitle2 {
  font-size: 14px !important;
  margin-top: 0px !important;
  font-weight: 500 !important;
  height: 22px;
  color: colors.get-color(colors.$grey, level_6) !important;
}
.subtitle1 {
  text-align: center;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  font-size: 16px !important;
  color: colors.get-color(colors.$grey, level_7) !important;
  font-weight: 400px !important;
  font-family: fonts.$eduate-font-family;
  font-style: normal;
  height: 25px;
  b {
    color: var(--level-4) !important;
  }
}
