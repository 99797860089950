@use "./colors" as colors;
@use "./fonts" as fonts;

* {
  box-sizing: border-box;
  list-style-type: none;
  text-decoration: none;
}

html,
body,
.h100,
#root {
  margin: 0;
  height: 100%;
  overflow: auto;
  background-color: var(--bg-color);
}

ul {
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

button,
select,
i,
input {
  border: none;
  outline: none;
  border-radius: 6px;
}

.error {
  color: #c53030;
}

.skip {
  display: none;
}

img {
  filter: var(--img-color);
}

// Margins
$mb-1: 0.5rem;
$mb-2: 1rem;
$mb-3: 1.5rem;
$mb-4: 2rem;
$mb-5: 2.5rem;
$mb-6: 3rem;
$mb-7: 5rem;
$mb-8: 7rem;
$mb-9: 8rem;

//Media font-size

$fs-1: 0.5rem;
$fs-2: 1rem;
$fs-3: 1.5rem;
$fs-4: 2rem;
$fs-5: 2.5rem;
$fs-6: 3rem;

//table Cell

#td-center {
  text-align: center;
}

#td-right {
  text-align: right;
  padding-right: 5px;
  font-family: fonts.$eduate-number-font;
}

//eduate-logo

.logo {
  width: 40px;
  filter: none !important;
}

.logo-title {
  text-align: center;
}

//horizontal line

hr.solid {
  border-bottom: 1px solid var(--border-color);
}

hr.dotted {
  border-bottom: 2px dotted colors.get-color(colors.$colors, cloud-white);
  margin: 5px 0px 0px 0px !important;
}

hr.dashed {
  border-bottom: 1px dashed var(--level-2);
  margin: 5px 0px 0px 0px !important;
}

//token

.token {
  width: 450px;
  margin: 10px;
}

//search-field

#search {
  &::placeholder {
    padding-left: 20px;
    background-image: url(../../images/Search.svg);
    background-repeat: no-repeat;
    background-size: 15px;
    color: var(--text-color);
  }
}

#delete-profile {
  width: 16px;
  margin-left: 10px;
}

.activefinancialyear {
  background-color: colors.get-color(colors.$Green, level_4);

  td {
    color: colors.get-color(colors.$Green, level_9) !important;
    font-weight: 500;
    font-family: fonts.$eduate-number-font;
  }

  &:hover {
    background-color: colors.get-color(colors.$Green, level_4) !important;
  }
}

.activefinancialyear-tree {
  background: linear-gradient(82.84deg, #4acf8c 0%, #75eda6 100%);
  border-radius: 30px;
  padding: 6px !important;
  color: white;
  font-weight: 500;
  font-family: fonts.$eduate-font-family;

  img {
    filter: invert(100%);
  }
}

.txt_uppercase {
  text-transform: uppercase;
}

.Skip-img {
  filter: invert(100%);
}

//student-modal

.modal-flex {
  display: flex;

  &__image {
    width: 20px;
    filter: var(--img-color) !important;

    img:hover {
      background-color: colors.get-color(colors.$colors, white-smoke);
      border-radius: 6px;
    }
  }

  &__data {
    width: calc(100% - 20px);
  }
}

.modal-close-icon {
  display: block;
  margin-left: auto;
  margin-top: 3px;
}

//delete-tooltip

.delete-icon {
  width: 18px;
}

textarea:focus-visible {
  outline: none !important;
}

.delete-tooltip-icon {
  fill: colors.get-color(colors.$red, level_7) !important;
}

.date-grid {
  display: grid;
  grid-template-columns: 0.6fr 0.8fr;
}

//to show error
.nodata {
  color: colors.get-color(colors.$red, level_8);
  display: flex;
  justify-content: center;
  padding-bottom: 0px !important;
  font-family: fonts.$eduate-font-family;
}

//documents upload
.upload-icon {
  width: 50px;
}

.browse-icon {
  filter: invert(0%);
}

.span-4 {
  column-count: 4;
}

.configuration-settings {
  width: 30px;
  padding-top: 5px;
  margin-right: 15px;

  &--modal {
    width: 30px;
    padding-top: 5px;
  }
}

#settings-icon {
  animation: rotation 3s infinite linear !important;
  -webkit-animation: rotation 3s infinite linear;
  height: 20px;
  width: 20px;
  display: block !important;
  margin-left: 5px;
  margin-top: 10px;
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }
}

//total balance
.totalcount {
  color: colors.get-color(colors.$warm_Grey, level_8) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  text-align: right !important;
  background: colors.get-color(colors.$Green, level_5) !important;
  border-right: 1px solid var(--border-color) !important;
  border-bottom: 1px solid var(--border-color) !important;
  font-family: fonts.$eduate-number-font;
  padding-right: 5px !important;
}

//total label
.total {
  text-align: right;
  padding-right: 10px;
  font-size: fonts.$eduate-default-font-size;
  font-weight: 700 !important;
  font-family: fonts.$eduate-font-family;
  background-color: var(--bg-color) !important;
}

//partial count
.totalcountcell {
  background-color: colors.get-color(colors.$yellow, level_3);
  color: colors.get-color(colors.$colors, black);
  text-align: right;
  width: 100%;

  &:focus {
    background-color: colors.get-color(colors.$colors, white);
  }
}

.editCell {
  background-color: colors.get-color(colors.$yellow, level_2) !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

//balance amount
.balance-amount {
  color: colors.get-color(colors.$colors, red);
  font-weight: 500 !important;
}

.balance-count {
  background-color: colors.get-color(colors.$red, level_6) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: colors.get-color(colors.$colors, white) !important;
  border-bottom: 1px solid colors.get-color(colors.$colors, cloud-white) !important;
}

.taxableamount {
  background-color: colors.get-color(colors.$colors, blue) !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: colors.get-color(colors.$colors, white) !important;
}

//Select All
.select-all {
  display: flex;
  margin-left: 30px;
  border-bottom: 1px dashed var(--level-2);

  &--checkbox {
    width: 20px;
    height: 20px;
  }

  Label {
    color: var(--level-5) !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    margin: 2px 10px 0px 10px !important;
    font-family: fonts.$eduate-font-family;
  }

  &__checkboxes {
    position: relative;
    display: flex;
    border-bottom: 1px dashed var(--level-2);
    padding: 5px;

    input {
      width: 18px !important;
      height: 18px !important;
      margin-top: 0px !important;
    }

    label {
      font-size: 12px !important;
      margin: 0px 10px 0px 10px !important;
      color: var(--text-color);
      font-family: fonts.$eduate-font-family;
    }
  }

  .MuiFormControlLabel-label {
    color: var(--level-5) !important;
    font-weight: bold !important;
    font-size: 18px !important;
    margin: 2px 10px 0px 10px !important;
    font-family: fonts.$eduate-font-family;
  }

  .Mui-checked {
    color: var(--level-5) !important;
  }
}

//data Fetch image

.data-fetch-icon {
  background-color: var(--level-5);
  padding: 4px;
  border-radius: 6px;
  margin-left: 10px;
  margin-top: 3px;
  height: 30px;

  &:hover {
    cursor: pointer;
  }
}

//Right alignment of input box
#right-align {
  text-align: right;
  padding-right: 10px;
}

//Multi Step Icon

.MuiStepLabel-iconContainer .Mui-active {
  color: var(--level-5) !important;

  .MuiStepIcon-text {
    fill: colors.get-color(colors.$colors, white) !important;
  }
}

.MuiStepIcon-root {
  color: colors.get-color(colors.$grey, level_3) !important;
}

.MuiStepIcon-text {
  fill: colors.get-color(colors.$colors, black) !important;
}

.MuiStepLabel-iconContainer .Mui-completed {
  color: var(--level-5) !important;
}

.MuiStepConnector-line {
  border-color: var(--level-6) !important;
}

.MuiStepLabel-alternativeLabel {
  color: var(--text-color) !important;
  font-family: fonts.$eduate-font-family;
}

.MuiTypography-root {
  font-family: fonts.$eduate-font-family;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: var(--text-color);
}

.loading-time {
  text-align: center;

  span {
    font-weight: 500;
    font-family: fonts.$eduate-font-family;
    font-size: fonts.$eduate-default-font-size;
  }

  h4 {
    color: colors.get-color(colors.$Green, level_8);
    font-family: fonts.$eduate-font-family;
    font-weight: 500;
  }

  .error-heading {
    color: colors.get-color(colors.$colors, red);
    font-family: fonts.$eduate-font-family;
    font-weight: 500;
  }

  &__btn {
    display: flex;
    justify-content: flex-end;
  }
}

.loading-gif {
  width: 150px;
}

//vertiacl line separtor

.vertical-line {
  border-left: 4px solid var(--level-4);
  margin-top: 5px;
}

//react-modal
.ReactModal__Content,
.ReactModal__Content--after-open,
.feedemand__table .total,
.demand-fee__table .total,
.student-fee-receipt__table .total,
.non-demandfee-payable__table .total,
.refund-academic-fee__table .total,
.std-complete-fee-receipt__frame--table .total {
  background: var(--modal-bg-color) !important;
  color: var(--text-color) !important;
}

.activefinancialyearIcon,
.theme-image,
.transport-icon,
.modal-close-icon,
.data-fetch-icon {
  filter: invert(0%);
}

// mui dropdown icon
.MuiSvgIcon-root .MuiSelect-icon {
  fill: var(--text-color) !important;
}

//mui-select-fieldset label
.MuiFormLabel-root,
.MuiInputLabel-root {
  color: var(--text-color) !important;
  font-family: fonts.$eduate-font-family;
}

.MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--border-color) !important;
}

//input base

.MuiInputBase-input {
  color: var(--text-color);
  font-family: fonts.$eduate-font-family;
}

.inst-name {
  font-family: fonts.$eduate-font-family;
}

// selected table-row
.selected-row {
  background-color: var(--level-4) !important;

  .pointer-icon {
    width: 12px;
  }

  td {
    color: colors.get-color(colors.$colors, white) !important;
  }

  &:hover {
    td {
      background-color: var(--level-4) !important;
    }
  }
}

.MuiTableRow-root {
  .Mui-selected {
    background-color: var(--level-4) !important;

    td {
      color: colors.get-color(colors.$colors, white) !important;
    }
  }
}

.LedgerEditWarning {
  padding-top: 2%;
  text-align: right;
  color: red;
  font-family: fonts.$eduate-font-family;
}

.down-arrow-autocomplete {
  width: 18px;
}

.admission-no {
  color: var(--level-7);
  font-weight: bold;
}

.MuiSnackbar-anchorOriginTopCenter {
  top: 50% !important;
}

.chat-icon {
  width: 30px !important;
}

.danger {
  background: rgb(211, 110, 110);
}

.MuiStepLabel-label {
  font-family: fonts.$eduate-font-family;
}

//Added by Shivashankar for table rows in OverAllStructure HR Salary
.td-green {
  color: #079e52 !important;
  font-weight: bold !important;
}

.td-red {
  color: rgb(162, 17, 17) !important;
  font-weight: bold !important;
}

.MuiAutocomplete-listbox {
  font-size: 12px !important;
  font-family: fonts.$eduate-font-family;
}

.MuiAutocomplete-option {
  &:hover {
    background-color: var(--level-2) !important;
  }

  &:focus {
    background-color: var(--level-2) !important;
  }
}

//progress bar
progress {
  margin-top: 15px;
  width: 100%;
  height: 12px;
}
.none {
  display: none !important;
}
progress::-webkit-progress-bar {
  background: var(--border-color) !important;
  border-radius: 6px;
}
progress::-webkit-progress-value {
  background-color: #079e52 !important;
  border-radius: 6px;
}
.sticky-table-no-border {
  border-style: none !important;
  padding-left: 0px !important;
}

//preview image in registration forms
.image-preview {
  height: 120px;
  width: 120px;
  margin: auto;
  display: block;
  margin-top: 10px;
}

.events-none {
  pointer-events: none;
  opacity: 0.3;
}

// to restrict window resize
.desktop-error {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

// toast success message
.eduate-success-message {
  .MuiSvgIcon-root {
    fill: white !important;
  }
}

.MuiInputBase-root .Mui-disabled {
  background-color: var(--disabled-color) !important;
  opacity: 1;
  -webkit-text-fill-color: var(--text-color) !important;
  font-weight: bold;
  z-index: 5;
}

//autocomplete disabled
.MuiAutocomplete-root {
  width: 100%;
}

.Mui-checked {
  color: var(--level-5) !important;
}

.MuiCheckbox-root {
  padding: 0px !important;
}

.MuiAutocomplete-endAdornment {
  right: 10px !important;
}

.MuiTooltip-popper {
  z-index: 9999 !important;
}

// documents-upload
.documents-upload {
  display: grid;
  grid-template-columns: 0.85fr 1.5fr 0.6fr;

  &--filename {
    display: flex;
    column-gap: 5px;
    height: 30px;
    border: 1px solid var(--border-color);
    background-color: var(--disabled-color);
    margin-top: 10px;
    border-radius: 6px;
    margin-right: 10px;

    img {
      width: 25px;
    }

    span {
      font-size: fonts.$eduate-default-font-size;
      color: var(--text-color);
      width: calc(100% - 60px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: flex;
      align-items: center;
      font-family: fonts.$eduate-font-family;
    }
  }
}

//for calender by shiva
.cal-bg-green {
  background-color: colors.get-color(colors.$Green, level_5) !important;
}

.cal-bg-red {
  background-color: colors.get-color(colors.$red, level_7) !important;
}

.cal-bg-amber {
  background-color: colors.get-color(colors.$amber, level_6) !important;
}

//To Be changed By Padma: Shiva
.max_error {
  background-color: red !important;
  color: white !important;
}

//To Be changed By Padma: Shiva
.image-opacity {
  opacity: 0.3;
}

ol {
  padding-left: 1.6rem !important;
}

//To Be changed By Padma: Shiva
.flex_error {
  height: calc(100% - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.rotate-img {
  transform: rotate(180deg) !important;
}

.months-list {
  display: grid;
  grid-template-columns: auto auto auto;
}

.month_name {
  margin: 5px;
  padding: 5px;
  border: 2px solid var(--level-5);
  border-radius: 10px;
  box-shadow: black;

  &:hover {
    background-color: var(--level-5);
    color: white;
  }
}

// for mobile number

.react-tel-input {
  top: 10px;
  font-family: fonts.$eduate-number-font;
  height: 30px;

  .form-control {
    width: 100% !important;
    height: 30px !important;
    border: 1px solid var(--border-color) !important;
    &:required {
      box-shadow: 3px 0px 0px red;
    }
    &:focus {
      border: 1px solid var(--level-3) !important;
    }
  }

  input {
    height: 30px;

    &:focus {
      box-shadow: none !important;
    }
  }

  .flag-dropdown {
    height: 30px;
    padding-right: 15px;
    font-family: fonts.$eduate-font-family;
    font-size: fonts.$eduate-default-font-size;
    font-weight: 400;
  }
}

.swipable-tabs {
  height: 100% !important;
}

// message formatter

.ql-toolbar {
  position: sticky;
  top: 0;
  background-color: #fff;
  border-style: none !important;
  z-index: 1;
  padding: 0px !important;
  border-top: none !important;
  border-bottom: 1px solid var(--border-color) !important;
  border-left: none !important;
  border-right: none !important;
}

.ql-container {
  border-style: none !important;
  height: calc(100% - 25px) !important;
}

.ql-editor {
  padding: 5px !important;
  height: 105px !important;

  p {
    font-family: fonts.$eduate-font-family;
    font-size: fonts.$eduate-default-font-size;
  }

  &::placeholder {
    font-family: fonts.$eduate-font-family;
    font-size: fonts.$eduate-default-font-size;
  }
}

.student-autocomplete {
  display: flex;

  .MuiAutocomplete-root,
  .data-fetch-icon {
    margin-top: 10px !important;
  }
}
.bg-grey {
  background-color: grey !important;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.time-input {
  border: 1px solid var(--border-color);
  width: 65px;
  display: flex;
  height: 30px;
  color: black;
  font-family: fonts.$eduate-font-family;
  font-size: fonts.$eduate-default-font-size;
  align-items: center;
  input {
    height: 100%;
    width: 30px;
    caret-color: transparent;
    text-align: center;
    border-radius: 0;
  }
  input:focus {
    background-color: var(--level-2);
  }
}
.hide-td {
  button,
  input,
  span,
  .storybook-input,
  .MuiFormControl-root,
  .MuiInputBase-root,
  img {
    display: none !important;
  }
}
