@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.att-report {
    margin: 0px 20px;
    height: calc(100% - 170px);
    &__block {
        display: flex;
        column-gap: 10px;
        height: 100%;
        &--left {
            height: 100%;
            border: 1px solid var(--border-color);
            width: 200px;
            border-radius: 6px;

        }
        &--right {
            height: 100%;
            border: 1px solid var(--border-color);
            padding: 10px;
            border-radius: 6px;
            width: calc(100% - 200px);
            &--select{
                column-gap: 10px;
                .storybook-input{
                    margin-top: 0px !important;
                }
            }
            &--tableblock{
                margin-top: 10px;
                height: calc(100% - 80px);
            }
            &--textfield{
                @include textfield();
                width:fit-content !important;
            }
        }
    }
}
