@use "../../scss/common/fonts" as fonts;
@use "../../scss/common/colors" as colors;
@use "../../scss/common/mixins" as *;

.storybook-textarea {
    padding-left: 1rem;
    border-radius: 6px;
    border: 1px solid var(--border-color);
    background-color: var(--bg-color);
    width: 100%;
    height: 65px !important;
    margin-top: 5px;
    padding: 5px;
    font-size:fonts.$eduate-default-font-size;
    font-family: fonts.$eduate-font-family;
    color: var(--text-color);
}

.storybook-textarea:focus {
    border: 1px solid var(--level-3);
}

.storybook-textarea:disabled {
    background-color: var(--disabled-color);
    color: var(--text-color);

}

.storybook-textarea::-webkit-scrollbar {
    @include scrollbar();
}

.storybook-textarea::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.storybook-textarea::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}