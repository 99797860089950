@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.salary-structure {
  height: calc(100% - 175px);
  margin: 10px 20px 0px 20px;
  &__frame {
    column-gap: 30px;
    height: calc(100% - 45px);
    &--leftblock {
      &--datablock {
        height: 40px;
        &--label-grid {
          display: grid;
          grid-template-columns: 1fr 1fr 0.2fr;
          column-gap: 5px;
          Input {
            margin-top: 0px;
          }
          .Label {
            margin-top: 0px;
          }
        }
        &--image {
          margin-top: -5px;
        }
      }
      &--tableblock {
        height: calc(100% - 130px);
        padding: 5px;
        border: 1px solid var(--border-color);
        &--textfield {
          text-align: center;
        }
        &--table {
          @include table();
          margin-top: 10px;
          height: calc(100% - 80px);
          padding: 5px;
          tfoot {
            tr td {
              border-style: none !important;
            }
          }
          .totalcount,
          .balance-count {
            border-bottom: 1px solid var(--border-color) !important;
          }
          &--amount {
            width: calc(100% - 75%);
            font-family: fonts.$eduate-number-font;
          }
          &--th-amount {
            width: calc(100% - 75%);
          }
        }
      }
      &--selectblock {
        margin-top: 10px;
        padding: 10px;
        height: 80px;
        border: 1px solid var(--border-color);
        &--autocomplete {
          column-gap: 10px;
        }
      }
      &--amount {
        background:  colors.get-color(colors.$amber, level_2) !important;
        @include textfield();
        input {
          text-align: right !important;
          font-weight: 400 !important;
        }
      }
      &--form-labels {
        .MuiFormControlLabel-root {
          display: flex !important;
          width: calc(100% - 10px) !important;
          justify-content: space-between !important;
          margin-top: 10px;
          margin-left: 5px !important;
        }
        .MuiTypography-root {
          color: var(--level-7) !important;
          font-weight: 700 !important;
        }
      }
    }
    &--rightblock {
      padding: 5px;
      border: 1px solid var(--border-color);
      &--tableblock {
        height: calc(50% - 10px);
        &__choose_ledger {
          height: calc(100% - 75px) !important;
          @extend .salary-structure__frame--rightblock--tableblock--table;
        }
        &--table {
          @include table();
          @include tableCheckbox();
          @include autoCompleteInTableRow();
          height: calc(100% - 30px);
          .MuiAutocomplete-root,
          .auto-complete:focus {
            background-color: var(--bg-color) !important;
            padding: 0px !important;
            border-style: none !important;
          }
          Input {
            border-style: none;
            margin-top: 0px;
          }

          tbody {
            background-color: var(--level-1);

            tr {
              &:hover {
                background-color: var(--level-1);
                td {
                  color: var(--level-7);
                }
              }
            }
          }
          &--slno {
            width: calc(100% - 94%);
            font-family: fonts.$eduate-number-font;
          }
          &--actions {
            width: calc(100% - 90%);
          }
          &--input {
            @include inputSalaryTable();
            width: calc(100% - 88%);
            input {
              width: 100%;
            }
          }
          &--select {
            width: calc(100% - 82%);
          }
          &--deduction {
            width: calc(100% - 72%);
          }
        }
      }
      &--title {
        display: flex;
        justify-content: flex-start;
      }
    }
  }
  &__choose-ledger {
    column-gap: 40px;
    height: calc(100% - 79px);
    margin-top: 10px;
    &--table {
      @include table();
      @include tableCheckbox();
    }
  }
  &__additional-payments {
    height: calc(100% - 140px);
    &--table {
      @include table();
      @include autoCompleteInTableRow();

      .MuiAutocomplete-root,
      .auto-complete:focus {
        background-color: var(--bg-color) !important;
        padding: 0px !important;
        border-style: none !important;
      }
      Input {
        border-style: none;
        margin-top: 0px;
      }
      &--amount {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }
      &--input {
        @include inputSalaryTable();
        width: calc(100% - 70%);
        input {
          width: 100%;
          height: 30px;
        }
      }
    }
    &--autocomplete {
      column-gap: 10px;
    }
    &--deduction {
      height: calc(100% - 170px);
    }
  }
}
