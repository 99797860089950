@use "../../scss/common/fonts" as fonts;
@use "../../scss/common/colors" as colors;

.select {
  border-radius: 6px;
  border: 1px solid var(--border-color) !important;
  background-color: var(--bg-color);
  width: 100%;
  height: 32px;
  margin-top: 10px;
  font-size:fonts.$eduate-default-font-size;
  font-family: fonts.$eduate-font-family;
  color: var(--text-color);
  padding-left: 8px;
}

.select:focus {
  border: 1px solid var(--level-3);
}

.large {
  height: 4.5rem;
  font-size: 1.25rem;
  width: 100%;
}

.medium {
  width: 70%;
}

.small {
  height: 25px;
  margin-top: 2px;
  padding: 0px;
  padding-left: 3px;
  font-size:fonts.$eduate-default-font-size;
}

//disabled mui-select

.select:disabled {
  background-color: var(--disabled-color) !important;
  -webkit-text-fill-color: var(--text-color) !important;
  color: var(--text-color);

  cursor: not-allowed !important;
}