@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.emp-preview {
  margin: 0px 20px 0px 20px;
  height: calc(100% - 200px);

  Label {
    margin-top: 0px;
  }
  Input {
    margin-top: 0px;
  }
  Select {
    margin-top: 0px;
  }
  &__imagegrid {
    display: flex;
    img {
      padding-left: 10px;
    }
  }
  &__image {
    @include studentProfile();
    margin-top: 0px !important;
  }
  &__textfield {
    @include textfield();
    margin: 10px 10px 0px 0px !important;
  }
  &__span-4 {
    column-count: 4;
    column-gap: 20px;
  }
  &__details {
    height: 120px;
    column-gap: 20px;
    margin-top: 10px;
    border-bottom: 1.8px dashed var(--border-color);
    &--textfield {
      @include textfield();
      margin-left: 0px !important;
      margin: 6px 0px 0px 0px !important;
      &--date {
        @include textfield();
        margin-left: 0px !important;
        margin: 6px 0px 0px 0px !important;
        width: fit-content !important;
      }
    }
  }
  &__frame {
    margin-top: 10px;
    height: calc(100% - 20px);
    &--personal {
      margin-top: 10px;
      height: calc(100% - 140px);
    }

    &--data {
      height: calc(100% - 45px);
      overflow: auto;
    }

    &--parent {
      display: flex;
      .data-fetch-icon {
        margin-top: 10px;
      }
    }
    &--parent-info {
      height: fit-content;
      column-count: 4;
      column-gap: 20px;
      &--image {
        height: 120px;
        margin-left: 20px;
        @include studentProfile();
      }
    }
  }
}

.emp-preview__frame--data::-webkit-scrollbar {
  @include scrollbar();
}

.emp-preview__frame--data::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.emp-preview__frame--data::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
