@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.student-reset-password {
@include mainFrameWithTitle();
  &__details {
    height: 120px;
    column-gap: 20px;
    &--image {
      height: 100%;
      @include studentProfile();
    }
    &--imagefield {
      display: flex;
      .MuiAutocomplete-root {
        margin-top: 10px !important;
      }
      .data-fetch-icon {
        margin-top: 8px;
      }
    }
  }

  &__form {
    border-top: 1.8px dashed var(--border-color);
    height: calc(100% - 130px);
    margin-top: 10px;
    &--details {
      height: calc(100% - 45px);
      &--textfield {
        @include textfield();
        margin: 10px 0px 0px 0px !important;
        width: 100%;
        &--date {
          @extend .student-reset-password__form--details--textfield;
          width: fit-content !important;
        }
      }
      &--pwd {
        @include textfield();
        margin: 10px 0px 0px 0px !important;
        width: 100%;
        input {
          font-weight: 400 !important;
        }
        .MuiOutlinedInput-input {
          padding: 6.5px 14px !important;
        }
        .MuiSvgIcon-root {
          font-size: 20px !important;
        }
      }
    }
  }
}
