@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.teacher-dashboard__marks-entry {
  height: calc(100% - 30px);

  &--filters {
    column-gap: 10px;

    &--textfield {
      @include textfield();
      margin: 10px 10px 0px 0px !important;
      width: fit-content !important;
    }

    &--images {
      display: flex;
      column-gap: 10px;
      margin-right: 10px;
      height: 40px;

      .active-image {
        background-color: var(--level-4);

        border-radius: 6px;
      }

      img {
        &:hover {
          background-color: var(--level-2);
          padding: 5px;
          border-radius: 6px;
        }
      }
      justify-content: right !important;
      margin-right: 0px;
    }
  }

  &--card-view {
    margin-top: 10px;
    height: calc(100% - 95px);
    overflow: auto;

    .marked {
      background-color: colors.get-color(colors.$Green, level_1);
    }

    .not-marked {
      background-color: colors.get-color(colors.$red, level_1);
    }

    &--gridlist {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      gap: 10px;
    }

    &--grid {
      @include blocksInDashboard();
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &--level-1 {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        display: block;
        text-align: center;
        color: var(--text-color);
      }

      &--level-2 {
        @extend .teacher-dashboard__marks-entry--card-view--grid--level-1;
      }

      &--level-3 {
        @extend .teacher-dashboard__marks-entry--card-view--grid--level-1;
      }

      &--flex {
        display: flex;
        column-gap: 10px;
        justify-content: space-around;
      }

      &--flex2 {
        text-align: center;
      }

      &--text {
        @extend .teacher-dashboard__marks-entry--card-view--grid--level-1;
        font-size: 12px;
        white-space: wrap !important;
      }

      &--number {
        font-size: 12px;
        font-family: fonts.$eduate-number-font;
        display: flex;
        justify-content: center;
      }

      &--button {
        display: flex;
        justify-content: center;
      }

      &--update {
        @include updateButton();
        margin-top: 5px;
      }

      &--view {
        @include viewButton();
        margin-top: 5px;
      }
    }
  }

  &--tableblock {
    margin-top: 10px;
    height: calc(100% - 95px);
  }

  &--table {
    @include table();

    &--slno {
      width: calc(100% - 94%);
      font-family: fonts.$eduate-number-font;
    }

    &--number {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--status {
      width: calc(100% - 85%);
    }

    &--view {
      @include viewButton();
    }

    &--update {
      @include updateButton();
    }
  }
}

.teacher-dashboard__marks-entry--card-view::-webkit-scrollbar {
  @include scrollbar();
}

.teacher-dashboard__marks-entry--card-view::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.teacher-dashboard__marks-entry--card-view::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
