
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
.feeledger {
  height: calc(100% - 150px);
  margin: 0px 10px 0px 10px;
  .label-grid {
    display: grid;
    grid-template-columns: 1fr 2fr !important;
  }

  &__block {
    height: calc(100% - 60px);
    &--tree {
      height: calc(100% - 10px);
    }
  }
  &__buttons {
    margin-left: 10px;
  }
}

.reorder-feeledger {
  &__tableblock {
    height: calc(100% - 122px);
    margin-top: 10px;
    &--table {
      @include table();
      &--slno {
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
      }
    }
  }
}
