@use "../../scss/common/mixins" as *;
@use "../../scss/common/fonts" as fonts;
@use "../../scss/common/colors" as colors;
.global-search {
  @include mainFrameWithTitle();
  &__tableblock {
    height: calc(100% - 100px);
    margin-top: 10px;
    &--image {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
    .no-data {
      height: 350px;
      width: 350px;
    }
  }
  &__flex {
    column-gap: 10px;
  }
  &__table {
    @include table();
    img {
      height: 35px;
      width: 35px;
      margin-right: 5px;
    }
  }
  .image-white {
    filter: invert(100%);
  }
}
