@use "../../../scss/common//mixins" as *;
@use "../../../scss/common/fonts" as fonts;
@use "../../../scss/common/colors" as colors;
.view-vehicle-details {
  margin: 0px 20px;
  height: calc(100% - 160px);

  &__menu {
    display: flex;
    column-gap: 20px;
    margin-bottom: 0px !important;
    margin-top: 10px;

    .active {
      background-color: var(--level-5);
      color: colors.get-color(colors.$colors, white);
      font-weight: 700;
    }

    li {
      border: 1px solid var(--border-color);
      padding: 5px 8px;
      border-radius: 6px;
      font-family: fonts.$eduate-font-family;
      font-size: fonts.$eduate-default-font-size;
      color: colors.get-color(colors.$grey, level_7);
    }
  }

  &__data {
    margin-top: 15px;
    height: calc(100% - 108px);
    overflow: auto;

    &--list {
      column-gap: 20px;
      column-count: 2;
    }
  }

  &__purchase-details {
    column-gap: 30px;
    margin-top: 12px;
    height: calc(100% - 240px);
    overflow: auto;
  }

  &__current-details {
    margin-top: 12px;
    height: calc(100% - 240px);
    overflow: auto;
  }

  &__previous-details {
    margin-top: 10px;
    height: calc(100% - 268px);

    .view-vehicle-details__menu {
      justify-content: center !important;
      margin-top: 0px;
    }

    &--tableblock {
      margin-top: 10px;
      height: calc(100% - 40px);
    }

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--date {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }

      .font-blue,
      .font-green {
        font-weight: 400 !important;
      }

      &--number {
        width: calc(100% - 85%);
        font-family: fonts.$eduate-number-font;
      }
    }
  }
}

.view-vehicle-details__data::-webkit-scrollbar,
.view-vehicle-details__purchase-details::-webkit-scrollbar,
.view-vehicle-details__current-details::-webkit-scrollbar {
  @include scrollbar();
}

.view-vehicle-details__data::-webkit-scrollbar-thumb,
.view-vehicle-details__purchase-details::-webkit-scrollbar-thumb,
.view-vehicle-details__current-details::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.view-vehicle-details__data::-webkit-scrollbar-thumb:hover,
.view-vehicle-details__purchase-details::-webkit-scrollbar-thumb:hover,
.view-vehicle-details__current-details::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
