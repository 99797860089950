@use "../../../../scss/common/mixins" as *;
.sem-config{
    height: calc(100% - 30px);
    &__select{
        column-gap: 10px;
    }
    &__tableblock{
        height:calc(100% - 45px);
        margin-top: 10px;
        &--right{
            height:calc(100% - 90px);

        }
    }
    &__table{
        @include table();
        @include tableCheckbox();
    }
    
}