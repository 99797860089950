@use "../../../../scss/common/mixins" as *;
.multiplereceipts {
  &__container {
    @include table();
    height: calc(100% - 75px);
  }
  &--sl {
    width: calc(100% - 95%);
  }
  &--actions {
    img {
      filter: invert(100%);
      width: 20px;
    }
  }
}
