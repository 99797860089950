@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.trial-balance {
  height: calc(100% - 215px);
  margin: 5px 20px 0px 20px;
  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 42px);
    &--table {
      @include table();
      &--first-row {
        td {
          font-size: 14px !important;
          font-weight: 500;
        }
      }

      &--first-level-row {
        background-color: var(--bg-color);
        &--name {
          padding-left: 30px !important;
        }
        td {
          font-size: 14px !important;
          border-bottom: none !important;
        }
      }
      &--second-level-row {
        background-color: var(--bg-color);
        td {
          color: var(--level-6) !important;
          border-bottom: none !important;
          font-size: 12px !important;
          font-weight: 500;
        }
        &--name {
          padding-left: 80px !important;
        }
      }
      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }
      &--amount {
        font-family: fonts.$eduate-number-font;
        width: calc(100% - 90%);
      }
      &--th-amount {
        width: calc(100% - 90%);
      }
      &--th-slno {
        width: calc(100% - 96%);
      }
      &--status {
        width: calc(100% - 94%);
      }
      tfoot {
        td {
          font-size: 14px !important;
          font-weight: 500 !important;
          border-right: 1px solid var(--border-color) !important;
        }
      }
    }
  }

  &__datefield {
    display: flex;
    gap: 10px;
    &--filter-date {
      border-right: 4px solid var(--level-4);
      margin-right: 20px;
      height: 30px;
      .LabelPrimary {
        margin: 5px 10px 0px 0px;
      }
      Input[type="checkbox"] {
        margin-right: 30px;
      }
    }

    &--textfield {
      @include textfield();
      width: fit-content !important;
    }
  }
  &__textfield {
    @include textfield();
    width: fit-content !important;
  }
}
