@use "../../../../scss/common/mixins" as *;

.std-list-for-doc {
    @include mainFrameWithTitle();

    &__options {
        column-gap: 10px;

        .MuiAutocomplete-root {
            margin-top: 10px;
        }
    }

    &__tableblock {
        margin-top: 10px;
        height: calc(100% - 135px);
    }
}