@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.enquiry-dashboard {
  height: calc(100% - 165px);
  margin: 0px 20px 10px 20px;
  &__menu{
    &--tabs {
 
      margin-top: 10px;
      @include muiTabs();
    }
    &--filters{
      display: flex;
      column-gap: 10px;
      justify-content: flex-end;
      align-items: center;
      margin-top: 10px;
    }
  }

  &__tabpanel {
    height: calc(100% - 55px);
    p {
      height: 100%;
    }
  }
  &__details {
    column-gap: 20px;
    height: 175px;

    img {
      width: 30px;
    }

    ul {
      margin-bottom: 0px !important;
    }

    &--block {
      padding: 5px;
      margin-top: 10px;
      @include blocksInDashboard();
      height: 100%;
&--tabs{
  @include muiTabsInDashboard();
}
&--tabpanel{
  height: calc(100% - 40px);
}
      &--title {
        display: flex;
        justify-content: space-between;

        .subtitle1 {
          width: calc(100% - 35px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          text-align: left;

          &:hover {
            text-overflow: clip;
            overflow: visible;
          }
        }
      }

      &--li {
        display: grid;
        grid-template-columns: 0.3fr 1fr 0.5fr;
        padding: 0px 5px 0px 5px;
        border-radius: 6px;

        &:hover {
          background-color: var(--level-1);
        }
      }

      &--enquired-student {
        color: colors.get-color(colors.$violet, level_7);
        text-align: right;
        margin-top: auto;
        margin-bottom: auto;
        font-family: fonts.$eduate-number-font;
        font-weight: bold;
        font-size: 16px;
      }

      &--admitted-students {
        @extend .enquiry-dashboard__details--block--enquired-student;
        color: colors.get-color(colors.$pink, level_5);
      }

      &--to-follow-up {
        @extend .enquiry-dashboard__details--block--enquired-student;
        color: colors.get-color(colors.$red, level_6);
      }

      &--called-today {
        @extend .enquiry-dashboard__details--block--enquired-student;
        color: colors.get-color(colors.$blue, level_7);
      }

      &--today-follow-up {
        @extend .enquiry-dashboard__details--block--enquired-student;
        color: colors.get-color(colors.$cool_grey, level_6);
      }

      &--conversion {
        @extend .enquiry-dashboard__details--block--enquired-student;
        color: colors.get-color(colors.$sky, level_5);
        font-size: 20px;
        &--li {
          display: flex;
          column-gap: 10px;
          align-items: center;
          img {
            width: 40px;
          }
          span {
            font-size: 18px !important;
          }
        }
      }

      &--application {
        @extend .enquiry-dashboard__details--block--enquired-student;
        color: colors.get-color(colors.$sky, level_5);
      }

      &--interested {
        @extend .enquiry-dashboard__details--block--enquired-student;
        color: colors.get-color(colors.$Green, level_5);
      }

      &--not-interested {
        @extend .enquiry-dashboard__details--block--enquired-student;
        color: colors.get-color(colors.$red, level_5);
      }

      &--not-decided {
        @extend .enquiry-dashboard__details--block--enquired-student;
        color:colors.get-color(colors.$amber, level_5);
      }
    }

    &--conversion {
      @extend .enquiry-dashboard__details--block;
      height: calc(50% - 5px);
      display: flex;
      justify-content: space-between;
      column-gap: 30px;
    }

    &--qrcode {
      @extend .enquiry-dashboard__details--block;
      height: calc(50% - 5px);
      margin-top: 10px;
      display: flex;
      justify-content: space-between;

      &:hover {
        // .enquiry-dashboard__details--qrcode--text {
        //   text-decoration: underline;
        //   color: var(--level-5);
        // }
        background-color: var(--level-1);
      }

      &--url {
        height: 60px !important;
        width: 60px !important;
        display: block;
        margin-top: auto;
        margin-bottom: auto;
      }

      &--text {
        font-size: 16px;
        display: block;
        margin: auto;
        color: var(--text-color);
        font-family: fonts.$eduate-font-family;
      }
    }
  }

  &__data {
    height: calc(100% - 195px);
    column-gap: 20px;
    margin-top: 10px;

    &--calendar {
      height: calc(100% - 40px);

      &--grid-container {
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: repeat(7, 1fr);
        /* Rows will adjust to fit content */
        gap: 10px;
        overflow: auto;

        &--day {
          text-align: center;
          font-family: fonts.$eduate-font-family;
          font-size:fonts.$eduate-default-font-size;
          height: 20px;
        }
      }

      &--grid-item {
        border-radius: 6px;
        border: 1px solid var(--border-color);
        height: 55px;

        &:hover {
          background-color: var(--level-2);

          .enquiry-dashboard__data--calendar--grid-item--number {
            background-color: var(--level-6);
            color:colors.get-color(colors.$colors, white);
          }
        }

        .font-green,
        .font-red {
          font-weight: 700 !important;
        }

        &--date {
          font-family: fonts.$eduate-number-font;
          font-size: 12px;
          padding-left: 5px;
        }

        &--number {
          font-family: fonts.$eduate-number-font;
          font-size: 17px;
          display: flex !important;
          justify-content: flex-end !important;
          margin-left: auto !important;
          margin-right: 0px !important;
          width: fit-content;
          border-radius: 20px 0px 0px 0px;
          padding: 2px 8px;
          background-color: colors.get-color(colors.$warm_Grey, level_2);
        }

        &--disabled {
          background-color: var(--disabled-color);
          border-radius: 6px;
          border: 1px solid var(--border-color);
          height: 55px;
        }
      }
    }

    &--title {
      display: flex;
      justify-content: space-between;

      &--month {
        display: flex;
        justify-content: space-between;
        gap: 20px;

        img {
          width: 25px;
          height: 25px;
        }
      }

      &--total {
        display: flex;
        justify-content: flex-end;

        Label {
          margin-top: 0px;
        }
      }

      &--totalcount {
        @include totalCountShadowEffect();
        margin-top: -3px;
      }
    }
  }

  &__charts {
    height: 100% !important;
    margin-top:10px;
    width: calc(100% - 20px) !important;
  }
  &__tableblock{
    height: calc(100% - 75px);
    
  }
}

.enquiry-dashboard__data--calendar--grid-container::-webkit-scrollbar {
  @include scrollbar();
}

.enquiry-dashboard__data--calendar--grid-container::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.enquiry-dashboard__data--calendar--grid-container::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
