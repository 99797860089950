@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.deposit-details {
    height: 100%;
    &__title {
        img {
            @include closeIconInModal();
        }
    }
    &__block {
        background-color: var(--disabled-color);
        height: 175px;
        width: 350px;
        display: block;
        margin-left: auto;
        margin-right: auto;
        border-radius: 6px;
        padding: 10px;
        margin-top: 10px;
        &--title {
            font-size: 20px;
            text-align: center;
            color: colors.get-color(colors.$sky, level_7);
            font-family: fonts.$eduate-font-family;
        }
        &--list {
            margin-bottom: 0px !important;
            li {
                display: flex;
                justify-content: space-between;
                span {
                    font-family: fonts.$eduate-font-family;
                    color: colors.get-color(colors.$grey, level_7);
                    font-size:fonts.$eduate-default-font-size;
                }
                b {
                    font-family: fonts.$eduate-number-font;
                    font-size:fonts.$eduate-default-font-size;
                }
            }
        }
    }
    &__tabs {
        @include muiTabs();
        margin-top: 10px;
        display: flex;
        justify-content: center;
    }
    &__tabpanel {
        margin-top: 10px;
        height: calc(100% - 320px);
        &--table {
            @include table();
            &--slno {
                width: calc(100% - 90%);
                font-family: fonts.$eduate-number-font;
            }
            &--date {
                width: calc(100% - 87%);
                font-family: fonts.$eduate-number-font;
            }
            &--amount {
                font-family: fonts.$eduate-number-font;
                width: calc(100% - 80%);
            }
        }
    }
}
