@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
.payment-voucher-modal {
  height: calc(100% - 30px);

  &__tableblock {
    height: calc(100% - 225px);
    margin-top: 10px;

    select {
      margin-top: 0px !important;
    }
  }

  &__block {
    &--payment-number {
      display: flex;
    }

    Input {
      width: fit-content !important;
    }

    Label {
      margin-right: 5px;

      span {
        background-color: colors.get-color(colors.$red, level_6);
        border-radius: 3px;
        color: colors.get-color(colors.$colors, white);
        font-weight: 500;
        padding: 5px;
      }
    }

    &--date {
      display: flex;
      justify-content: flex-end;
      column-gap: 10px;

      Input {
        width: fit-content !important;
      }
    }

    &--day {
      margin-top: 10px !important;
      margin-left: 5px;
    }
  }

  &__lableblock {
    height: 120px;
    margin-top: 10px;

    Label,
    .storybook-input,
    .MuiFormControl-root,
    .storybook-input:disabled,
    select {
      margin-top: 5px !important;
    }

    &--details {
      column-gap: 20px;
    }

    &--top {
      margin-top: 35px;
    }

    &--frames {
      border: 1px solid var(--border-color);
      padding: 0px 5px 5px 5px;
      border-radius: 3px;
      height: 100%;

      .label-grid {
        display: grid;
        grid-template-columns: 1fr 2fr;
      }
    }
  }

  &__icon {
    display: flex;
    column-gap: 10px;
    justify-content: flex-end;

    img {
      width: 35px;
      margin-top: 10px;
    }
  }
}
