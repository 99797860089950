@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.academic-test-creation {
  margin: 0px 10px 0px 10px;
  height: calc(100% - 170px);

  &--modal {
    height: 100%;
  }

  &__data {
    height: calc(100% - 50px);
    column-gap: 20px;

    &--details {
      height: calc(100% - 50px);
      overflow: auto;
    }

    .MuiFormControlLabel-root {
      display: flex !important;
      width: calc(100% - 10px) !important;
      justify-content: space-between !important;
      margin-top: 10px;
      margin-left: 5px !important;
    }
  }

  &__table {
    @include table();
    height: calc(100% - 40px);
    margin-top: 10px;

    &--slno {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--marks {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-slno {
      width: calc(100% - 90%);
    }

    &--th-marks {
      width: calc(100% - 88%);
    }
  }

  &__buttons {
    margin: 10px 0px 10px 10px;
  }

  &__grid {
    display: grid;
    grid-template-columns: 0.215fr 0.8fr;

    Input {
      width: 30%;
    }
  }

  &__max-grid {
    display: grid;
    grid-template-columns: 0.8fr 1fr;
  }
}

.academic-test-creation__table::-webkit-scrollbar,
.academic-test-creation__data--details::-webkit-scrollbar {
  @include scrollbar();
}

.academic-test-creation__table::-webkit-scrollbar-thumb,
.academic-test-creation__data--details::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.academic-test-creation__table::-webkit-scrollbar-thumb:hover,
.academic-test-creation__data--details::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}