@use "../../../../scss/common/mixins" as *;

.active-session{
    @include mainFrameWithTitle();
    &__select{
        column-gap: 10px;
    }
    &__tableblock{
        margin-top: 10px;
        height: calc(100% - 100px);
    }
}