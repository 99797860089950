@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.add-test {
  margin: 0px 10px 0px 10px;
  &__label-grid {
    display: grid;
    grid-template-columns: 0.8fr 3fr;
  }
}
.view-allocated-subjects {
  &__select-flex {
    column-gap: 10px;
    margin-top: 10px;
  }
  &__tableblock {
    height: calc(100% - 125px);
    margin-top: 10px;
  }
  &__table {
    @include table();
    &--slno {
      width: calc(100% - 95%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-slno {
      width: calc(100% - 95%);
    }
    &--code {
      width: calc(100% - 90%);
    }
  }
}
.view-allocated-subjects__table::-webkit-scrollbar {
  @include scrollbar();
}

.view-allocated-subjects__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.view-allocated-subjects__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
