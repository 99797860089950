@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.books-allocation {
  margin: 0px 20px 0px 20px;
  height: calc(100% - 213px);
  .MuiAutocomplete-root {
    margin-top: 5px;
  }
  &__details {
    column-gap: 10px;
    margin-top: 3px;
    &--textfield {
      @include textfield();
      margin: 5px 10px 0px 0px !important;
      width: 100%;
      img {
        width: 12px;
        height: 12px;
      }
    }
    &--shelf-textfield {
      @include textfield();
      margin: 10px 10px 0px 0px !important;
      width: 100%;
    }
    &--autocomplete {
      .MuiAutocomplete-root {
        margin-top: 5px;
      }
    }
    &--span-2 {
      column-count: 2;
      column-gap: 30px;
    }
    &--book {
      width: 100%;
      img {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
  &__tableblock {
    height: calc(100% - 85px);
    .subtitle1 {
      height: 40px;
    }
    &--table {
      @include table();
      @include tableCheckbox();
      height: calc(100% - 50px);
      tr:hover {
        .books-allocation__tableblock--table--title {
          @include tdContainsOnclick();
        }
      }

      &--slno {
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-slno {
        width: calc(100% - 94%);
      }
      &--accessno {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-accessno {
        width: calc(100% - 94%);
      }
      &--desc {
        width: calc(100% - 90%);
      }
      &--modal {
        height: calc(100% - 120px);
        @include table();
        margin-top: 10px;
      }
    }
    .booktype-left {
      margin: 10px 0px 0px 0px;
    }
    .booktype-right {
      margin: 10px 0px 0px 0px;
    }
    &--swap-image {
      width: 60px;
      text-align: center;
      margin: auto;
      img {
        background-color:colors.get-color(colors.$colors, white);
        padding: 5px;
        width: 40px;
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
          0px 2px 4px -1px rgba(0, 0, 0, 0.06);
        border-radius: 50%;
      }
      .opacity {
        opacity: 0.3;
      }
    }
    &--books-count {
      display: flex;
      justify-content: space-between;
      &--total {
        display: flex;
        margin-top: -7px;
      }
      &--totalcount {
        @include totalCountShadowEffect();
      }
    }
  }
}
.td-link {
  @include tdContainsOnclick();
}
