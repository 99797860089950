@use "../../../scss/common//mixins" as *;
@use "../../../scss/common/fonts" as fonts;
@use "../../../scss/common/colors" as colors;
.emp-logs {
  margin: 0px 20px;
  height: calc(100% - 160px);

  &__select {
    column-gap: 10px;

    .MuiAutocomplete-root {
      margin-top: 10px;
    }

    &--flex {
      display: flex;
      justify-content: flex-end;
      column-gap: 10px;
    }

    &--textfield {
      @include textfieldInForms();
      width: fit-content !important;
      margin: 10px 0px 0px 0px !important;
    }
  }

  &__block {
    height: calc(100% - 102px);
    margin-top: 10px;
    display: flex;
    column-gap: 40px;

    &--list {
      height: calc(100% - 80px);
      overflow: auto;
      margin-top: 10px;
    }

    &--grid {
      display: flex;
      justify-content: space-between;
      padding: 5px;
      border-bottom: 1px solid var(--border-color);

      &:hover {
        background-color: var(--level-1);
      }

      &--active {
        @extend .emp-logs__block--grid;
        background-color: var(--level-2);

        font-weight: 700;
      }

      &--profile {
        display: flex;
        column-gap: 5px;

        img {
          width: 25px;
        }

        &--details {
          font-size: fonts.$eduate-default-font-size;
          color: var(--text-color);
          font-family: fonts.$eduate-font-family;

          span {
            display: block;
          }
        }

        &--count {
          display: block;
          margin-top: auto;
          margin-bottom: auto;
          font-size: fonts.$eduate-default-font-size;
          color: var(--text-color);
          border: 1px solid var(--border-color);
          border-radius: 6px;
          height: fit-content;
          padding: 3px 10px;
        }
      }
    }

    &--left {
      width: 350px;
      height: 100%;
      border: 1px solid var(--border-color);
      border-radius: 10px;
    }

    &--right {
      width: calc(100% - 350px);
      height: 100%;
    }

    &--tabs {
      @include muiTabs();

      &--flex {
        display: flex;
        column-gap: 5px;

        img {
          width: 20px;
        }

        span {
          color: colors.get-color(colors.$grey, level_7) !important;
        }
      }
    }

    &--tabpanel {
      margin-top: 10px;
      overflow: auto;
      height: calc(100% - 90px);
    }
  }

  &__modules {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    &--grid {
      border: 1px solid var(--border-color);
      border-radius: 10px 10px 0px 0px;
      position: relative;
      padding-top: 15px;

      img {
        display: block;
        width: 40px;
        height: 40px;
        margin-left: auto;
        margin-right: auto;
      }

      &--title {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        display: block;
        position: relative;
        text-align: center;
        top: 10px;
        bottom: 10px;
      }

      &--total {
        @extend .emp-logs__modules--grid--title;
        font-family: fonts.$eduate-number-font;
      }

      &--list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 20px;
        height: fit-content;
        background-color: colors.get-color(colors.$grey, level_2);
        padding-top: 10px;

        &--add {
          display: block;
          text-align: center;
          font-size: fonts.$eduate-default-font-size;

          span {
            font-family: fonts.$eduate-font-family;
            display: block;
          }

          b {
            font-family: fonts.$eduate-number-font;
          }
        }

        &--sop {
          @extend .emp-logs__modules--grid--list;
          display: flex;
          justify-content: center;
        }

        &--dop {
          @extend .emp-logs__modules--grid--list;
          display: flex;
          justify-content: space-between;
          padding: 5px 20px;
        }
      }
    }
  }

  &__filtered-data {
    height: calc(100% - 75px);

    &--select {
      column-gap: 10px;

      .MuiAutocomplete-root {
        margin-top: 10px;
      }

      &--flex {
        column-gap: 10px;
        display: flex;

        img {
          margin-top: 5px;
        }
      }
    }

    &--profile {
      display: flex;
      column-gap: 5px;
      height: 60px;
      width: 100%;
      border: 1px solid var(--border-color);
      border-radius: 5px;

      padding: 5px;

      img {
        width: 30px;
        display: block;
        margin-top: auto;
        margin-bottom: auto;
      }

      &--details {
        font-size: fonts.$eduate-default-font-size;
        color: var(--text-color);
        font-family: fonts.$eduate-font-family;

        span {
          display: block;
        }
      }
    }

    &--textfield {
      @include textfield();
      margin: 10px 0px 0px 0px !important;
      width: fit-content;
    }

    &--tableblock {
      margin-top: 10px;
      height: calc(100% - 70px);
    }

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
      }

      &--number {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--desc {
        width: calc(100% - 92%);
      }

      &--date {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--actions {
        width: calc(100% - 96%);

        img {
          width: 20px;
        }
      }
    }
  }
}

.emp-logs__block--tabpanel::-webkit-scrollbar,
.emp-logs__block--list::-webkit-scrollbar {
  @include scrollbar();
}

.emp-logs__block--tabpanel::-webkit-scrollbar-thumb,
.emp-logs__block--list::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.emp-logs__block--tabpanel::-webkit-scrollbar-thumb:hover,
.emp-logs__block--list::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
