@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.form-type {
    height: 100%;
    &__title {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 40px;
            height: 40px;
        }
    }
    &__datablock {
        height: calc(100% - 50px);
        margin-top: 10px;
        border: 1px solid var(--border-color);
        border-radius: 6px;
        padding: 10px;
        overflow: auto;
      
        &--form {
            height: fit-content;
            width: fit-content;
            padding: 10px;
            background-color: var(--level-1);
            margin-top: 10px;
            b {
                font-family: fonts.$eduate-font-family;
                font-size: 18px;
                text-align: center;
                display: block;
            }
            &--acd {
                font-family: fonts.$eduate-font-family;
                font-size: 14px;
                background-color: var(--level-4);
                color: white;
                font-weight: 600;
                padding: 3px 10px;
                border-radius: 2px;
                display: block;
                margin: 15px auto;
                width: fit-content;
            }
            &--flex {
                display: flex;
                column-gap: 10px;
                justify-content: center;
                align-items: center;
                margin-top: 15px;
                .MuiInputBase-root .Mui-disabled {
                    background-color: var(--bg-color) !important;
                }
            }
     
            &--textfield {
                @include textfield();
                width: fit-content !important;
            }
            &--button {
                @include assignButton();
                display: block;
                margin: 15px auto;
                background-color: colors.get-color(colors.$Green, level_8);
            }
            &--update {
                @include updateButton();
                display: block;
                margin: 15px auto;
            }
        }
        &--grid {
            display: grid;
            column-gap: 10px;
            grid-template-columns: repeat(5, 1fr);
            align-items: center;
            .MuiInputBase-root .Mui-disabled {
                background-color: var(--bg-color) !important;
            }
        }
        &--flex {
            display: flex;
            column-gap: 30px;
            justify-content: center;
        }
        &--event {
            height: fit-content;
            width: fit-content;
            padding: 10px;
            background-color:  colors.get-color(colors.$cool_grey, level_1);
            margin-top: 10px;
            b {
                font-family: fonts.$eduate-font-family;
                font-size: 18px;
                text-align: center;
                display: block;
            }
            &--acd {
                @extend .form-type__datablock--form--acd;
                background-color:  colors.get-color(colors.$cool_grey, level_3);
                color: colors.get-color(colors.$cool_grey, level_6);
            }
        }
    }
}

.form-type__datablock::-webkit-scrollbar {
    @include scrollbar();
}

.form-type__datablock::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.form-type__datablock::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}
