@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.cashier-dashboard {
 @include mainFrameWithTitle();

  &--navigation {
    width: 350px;
    border: 1px solid colors.get-color(colors.$grey, level_3);
    height: 100%;

    &--sub {
      margin-left: 12px;
      font-size: 12px !important;
      img{
        width:30px;
        height: 30px;
      }
li{

  &:hover {
    background-color: var(--level-1);
    border-radius: 6px;
  }
}
    }

    &--ul {
      height: calc(100% - 35px);
      overflow: auto;

      &--li {
        display: flex;

        justify-content: space-between;
        align-items: center;
        padding: 5px;
        margin-top: 5px;
        img{
          width:30px;
          height: 30px;
        }

        &--left {
          transform: rotate(-90deg);
        }

        &:hover {
          background-color: var(--level-1);
          border-radius: 6px;

        }

        &--active {
          background-color: var(--level-4);
          margin-top: 5px;
      .Label{
        color: var(--bg-color) !important;
        font-weight: 700;
      }
        }
      }
    }
  }

  &--receipt-cards {
    height: calc(100% - 165px);
    margin: 0px 20px 0px 20px;
    overflow: auto;
    padding-left: 10px;
    padding-right: 10px;

    &--block {
      padding: 5px;
      @include blocksInDashboard();
      height: 100%;

      &--title {
        display: flex;
        justify-content: space-between;
        padding-left: 10px;

        &-tabs {
          display: grid;
          grid-template-columns: 0.7fr 1fr 0.1fr;
        }
      }

      &--details {
        display: flex;
        column-gap: 30px;

        img {
          width: 70px;
          height: 70px;
        }

        &--total {
          font-family: fonts.$eduate-number-font;
          font-weight: bold;
          font-size: 18px;
          color: colors.get-color(colors.$grey, level_8);
          text-align: center !important;
          position: relative;
        }

        &--receipts {
          @extend .cashier-dashboard--receipt-cards--block--details--total;
          color: colors.get-color(colors.$grey, level_6);
          font-size:fonts.$eduate-default-font-size;
        }
      }

      &--g-count {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-top: 1px solid var(--border-color);
        gap: 5px;

        img {
          margin: auto;
        }

        &--flex {
          display: flex;
          column-gap: 10px;
          padding: 5px;
        }

        .separator {
          border-right: 1px solid var(--border-color);
        }

        &--block {
          display: flex;
          flex-direction: column;
          margin: auto;

          &--text {
            font-family: fonts.$eduate-font-family;
            color: colors.get-color(colors.$grey, level_6);
            font-weight: 700;
            font-size: 12px;
          }

          &--total {
            @extend .cashier-dashboard--receipt-cards--block--g-count--block--text;
            font-size: 12px;
            font-weight: 700;
            color: var(--text-color);
            font-family: fonts.$eduate-number-font;
          }

          &--receipts {
            @extend .cashier-dashboard--receipt-cards--block--g-count--block--text;
            color: colors.get-color(colors.$grey, level_6);
            font-size: 12px;
          }
        }
      }

      &--receipts-block {
        height: 140px !important;
        @include blocksInDashboard();

        &--data {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;

          .cashier-dashboard--receipt-cards--block--g-count--block {
            margin: 0px !important;
          }
        }
      }

      &--table-block {
        height: calc(100% - 150px) !important;
        margin-top: 10px;
        @include blocksInDashboard();
      }
    }
  }
}

.cashier-dashboard--navigation--ul::-webkit-scrollbar {
  @include scrollbar();
}

.cashier-dashboard--navigation--ul::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.cashier-dashboard--navigation--ul::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}