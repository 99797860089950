@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
.interchange-fee-receipt {
  margin: 0px 20px 0px 20px;
  height: calc(100% - 170px);

  .MuiAutocomplete-root {
    margin-top: 5px !important;
  }

  &--modal {
    height: calc(100% - 30px);

    .account-frames {
      margin: 0px !important;
    }
  }

  &__details {
    column-gap: 20px;
    height: 90px;
    margin-top: 5px;
  }

  &__block {
    border: 1px solid colors.get-color(colors.$colors, cloud-white);
    border-radius: 6px;

    &--image {
      display: flex;
    }

    &--textfield {
      @include textfield();
      width: 100%;
      margin: 5px 0px 3px 0px !important;

      &--date {
        @include textfield();
        width: fit-content;
        margin: 5px 0px 0px 0px !important;
      }
    }

    &--remarks {
      Label {
        padding: 0px !important;
        margin-top: 0px !important;
        color: colors.get-color(colors.$cool_grey, level_6);

      }

      .storybook-textarea {
        margin-top: 0px !important;
        height: 50px !important;
      }
    }



    &--date {
      margin-top: 7px;
    }
  }

  &__block2 {
    margin: 10px 0px 0px 0px;
    display: flex;
    column-gap: 100px;
    height: 180px;

    &--interchange-image {
      width: 100px;

      Button {
        margin: 20px 0px 0px 0px;
      }
    }

    &--image {
      @include studentProfile();
    }

    &--current {
      background-color: colors.get-color(colors.$red, level_5);
    }

    &--changed {
      background-color: colors.get-color(colors.$Green, level_5);
    }

    &--subblock {
      margin: 10px 5px 0px 0px;
    }

    &--textfield {
      @include textfield();
      margin: 5px 10px 3px 0px !important;
    }

    &--details {
      column-gap: 10px;
    }
  }

  &__table {
    @include Accountstable();

    // margin-left: 10px !important;
    &--slno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--amount {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--rcptamt {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-slno {
      width: calc(100% - 92%);
    }

    &--th-amount {
      width: calc(100% - 90%);
    }

    &--th-rcptamt {
      width: calc(100% - 90%);
    }

    &--input {
      @include inputReportsTable();
      padding-right: 2px !important;
      padding-left: 2px !important;

      Input {
        height: 20px !important;
        font-size: 12px !important;
        padding-right: 0px !important;
        border-radius: 6px !important;
      }
    }
  }

  &__tableblock {
    margin: 10px 0px 0px 0px;
    height: calc(100% - 335px);
    display: flex;
    gap: 20px;
  }

  .account-frames {
    margin: 0px !important;
  }
}

//view

.view-interchange-fee-receipt {
  &__tableblock {
    height: calc(100% - 80px);
    margin-top: 10px;
  }

  &__table {
    @include Accountstable();

    &--column-group {
      th {
        font-size: 16px;
        font-weight: bold;
        background-color: var(--level-2);
        color: colors.get-color(colors.$colors, black);
        padding: 8px;
        height: 40px;
      }
    }

    &--sub-header {
      th {
        top: 40px;
      }
    }

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--receipt {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--desc {
      width: calc(100% - 92%);
    }

    &--th-slno {
      width: calc(100% - 96%);
    }

    &--th-receipt {
      width: calc(100% - 92%);
    }

    &--class {
      width: calc(100% - 96%);
    }

    &--name {
      &:hover {
        @include tdContainsOnclick();
      }
    }
  }

  &__total {
    text-align: right;
  }
}