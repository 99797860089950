@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.sub-allocation {
    @include mainFrameWithTitle();

    &__cards {
        height: 50px;

        &--list {
            height: 100%;
            display: flex;
            column-gap: 10px;
        }

        &--grid {
            @include gridsInExamPlannerAndSubjectDetails();
        }
    }

    &__filters {
        margin-top: 15px;
        column-gap: 10px;
    }

    &__tableblock {
        margin-top: 10px;
        height: calc(100% - 150px);
        &--elective {
            margin-top: 10px;
            height: calc(100% - 100px);
        }
    }

    &__table {
        @include table();

        &--actions {
            width: calc(100% - 85%);
        }

        &--number {
            width: calc(100% - 90%);
            font-family: fonts.$eduate-number-font;
        }

        .allocate-button {
            @include assignButton();
        }

        .view-button {
            @include viewButton();
        }

        .update-button {
            @include updateButton();
            margin: 5px;
        }

        .delete-button {
            @include deleteButton();
        }
    }

    &__view {
        height: calc(100% - 35px);

        &--details {
            column-gap: 10px;

            &--textfield {
                @include textfield();
                margin: 10px 0px 0px 0px !important;
            }
        }

        &--tableblock {
            height: calc(100% - 85px);
            margin-top: 10px;
        }

        &--table {
            @include table();

            &--slno {
                width: calc(100% - 96%);
                font-family: fonts.$eduate-number-font;
            }

            &--code {
                width: calc(100% - 92%);
                font-family: fonts.$eduate-number-font;
            }

            &--type {
                width: calc(100% - 94%);
            }
        }
    }
    &__types {
        height: 100%;
        &--title {
            img {
                @include closeIconInModal();
            }
        }
        &--image {
            width: 50px;
            height: 50px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            img {
                width: 100%;
                height: 100%;
            }
        }
        &--ul {
            margin-bottom: 0px !important;
            li {
                display: flex;
                align-items: center;
                padding: 8px 2px;
                border: 1px solid var(--border-color);
                margin-top: 5px;
                img {
                    width: 40px;
                    height: 40px;
                }
                font-size: 16px;
                font-family: fonts.$eduate-font-family;
                color: var(--level-5);
                &:hover {
                    background-color: var(--level-1);
                    border-radius: 6px;
                }
            }
        }
    }
    &__warning {
        height: 100%;
        width: 100%;
        &--title {
            display: flex;
            justify-content: space-between;
            column-gap: 5px;
            width: 100%;
        }
        &--close {
            img {
                @include closeIconInModal();
            }
        }
    }
}
