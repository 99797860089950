
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.outstandingbalance_oneline {
  display: grid;
  grid-template-columns: 0.38fr 2.75fr 1fr;
  column-gap: 5px;
}

.accountingledger {
  height: calc(100% - 175px);
  margin: 10px 20px 0px 20px;

  &__modal {
    height: calc(100% - 40px);
    margin-top: 10px;
  }

  &__block {
    height: calc(100% - 45px);
    column-gap: 20px;

    &--frames {
      border: 1px solid colors.get-color(colors.$colors, cloud-white);
      padding: 5px 10px 10px 5px;
      border-radius: 6px;
      height: 100%;

      &--data {
        height: 100%;
        overflow: auto;
      }

    }

    &--view {
      border: 1px solid colors.get-color(colors.$colors, cloud-white);
      padding: 5px 10px 10px 5px;
      border-radius: 6px;
      height: 100%;

      .tree-view {
        margin-top: 0px !important;
      }
    }


  }

  &__options {
    margin: 10px;
  }

}

.accountingledger__block--frames--data::-webkit-scrollbar {
  @include scrollbar();
}

.accountingledger__block--frames--data::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.accountingledger__block--frames--data::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

.reorder-accountledger {
  &__tableblock {
    height: calc(100% - 80px);
    margin-top: 10px;

    &--modal {
      height: calc(100% - 122px);
      margin-top: 10px;
    }

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
      }
    }
  }
}

.eduate-account-ledger {
  margin: 0px 20px;
  height: calc(100% - 185px);

  &__modal {
    height: calc(100% - 40px);
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 80px);

    td {
      height: 30px;
    }
  }

  &__table {
    @include table();
    @include tableCheckbox();

    &--actions {
      width: calc(100% - 92%)
    }

    &--slno {
      width: calc(100% - 94%);
      font-family: fonts.$eduate-number-font;
    }



  }

  &__select {
    column-gap: 10px;
    margin-top: 10px;

    .storybook-input {
      margin-top: 0px !important;
    }

    .storybook-button {
      margin-top: 0px !important;
      display: flex !important;
      justify-content: flex-end !important;
      margin-right: 0px !important;
      margin-left: auto !important;
    }
  }

  &__total {
    text-align: right;

    .student-total-count {
      margin: 10px 0px 0px 0px !important;
    }
  }
}