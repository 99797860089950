.form-not-filled{
    height: 100%;
    &__title{
        display: flex;
        justify-content: space-between;
    }
    &__tableblock{
        margin-top: 20px;
        height: calc(100% - 95px);
    }
}