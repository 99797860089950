@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.test-planner {
  &__subject-allocation {
    @include mainFrameWithTitle();
    &--elective {
      height: calc(100% - 30px);
      &--tableblock {
        height: calc(100% - 90px);
        margin-top: 10px;
      }
    }
    &--sub-list {
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
    }

    &--select {
      column-gap: 10px;
      margin-top: 10px;
    }

    &--buttons {
      display: flex;
      justify-content: flex-end;
      right: 0px;

      .storybook-button {
        margin-top: 0px !important;
      }
    }

    &--textfield {
      @include textfield();
    }

    &--tableblock {
      height: calc(100% - 100px);
      margin-top: 10px;
    }

    &--tableblock3 {
      margin-top: 15px;
      height: calc(100% - 75px);
    }

    &--table {
      @include table();

      &--row {
        @include selectTableRow();
        @include autoCompleteInTableRow();
        td{
          padding: 0px 0px  !important;
        }
      }

      &--elective-row {
        background-color: colors.get-color(colors.$red, level_6) !important;

        &:hover {
          background-color: colors.get-color(colors.$red, level_6) !important;

          td {
            color: white !important;
          }
        }

        td {
          color: white;
        }
      }

      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-slno {
        width: calc(100% - 96%);
      }

      &--code {
        width: calc(100% - 90%);
      }

      &--type {
        width: calc(100% - 90%);
      }

      &--actions {
        width: calc(100% - 96%);

        &--more {
          width: 20px;
          filter: invert(100%);

          &:hover {
            background-color: black;
            padding: 2px;
            border-radius: 50%;
          }
        }

        &--add {
          filter: invert(100%);
          width: 20px;
        }

        &--li {
          margin-bottom: 10px;
          padding: 5px;

          &:hover {
            background-color: var(--level-2) !important;
            border-radius: 3px;
          }
        }
      }
    }

    &--tableblock1 {
      height: calc(100% - 135px);
      margin-top: 10px;
    }
  }
}
