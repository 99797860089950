@use "../../../scss/common/mixins" as *;
@use "../../../scss/common/fonts" as fonts;
@use "../../../scss/common/colors" as colors;
.choose-message-template {
  height: calc(100% - 30px);
  &__tableblock {
    height: calc(100% - 55px);
    margin-top: 10px;
  }
  &__table {
    @include table();
  }
}
