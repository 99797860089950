@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.salary-ledger {
  column-gap: 20px;
  margin: 0px 10px 0px 10px;
  height: calc(100% - 170px);
  &__block {
    height: calc(100% - 50px);
    column-gap: 20px;
    &--title {
      height: 30px;
      #search {
        margin-top: 0px;
      }
    }
    &--data {
      height: calc(100% - 30px);
      overflow: auto;
      .MuiAutocomplete-root
        .MuiOutlinedInput-root
        .MuiAutocomplete-endAdornment {
        right: 10px !important;
      }
      .MuiFormControlLabel-root {
        display: flex !important;
        width: calc(100% - 10px) !important;
        justify-content: space-between !important;
        margin-top: 10px;
        margin-left: 5px !important;
      }
      &--table {
        @include table();
        margin-top: 10px;
        height: calc(100% - 55px);
      }
    }
  }
  &__buttons {
    margin: 10px 0px 0px 10px;
  }
}

.salary-ledger__block--data::-webkit-scrollbar {
  @include scrollbar();
}

.salary-ledger__block--data::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.salary-ledger__block--data::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
