.online-transaction {
    &__logdata {
        height: calc(100% - 75px);

        &--image {
            display: flex;
            justify-content: center;

            img {
                width: 30px;
            }
        }

        &--tableblock {
            margin-top: 10px;
            height: calc(50% - 60px);
        }
    }
}