@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.std-device-details {
    @include mainFrameWithTitle();
    &__select {
        column-gap: 20px;
        .MuiAutocomplete-root {
            margin-top: 10px;
        }
    }
    &__tableblock {
        height: calc(100% - 100px);
        margin-top: 10px;
        &--cell {
            img {
                width: 25px;
                height: 25px;
            }
        }
    }
    &__drawer {
        height: 100%;
        &--title {
            img {
                @include closeIconInModal();
            }
        }
        &--details {
            column-gap: 10px;
            height: 140px;
            border-bottom: 1px dashed var(--border-color);
        }
        &--total {
            background-color: var(--level-1);
            border-radius: 6px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            margin-top: 10px;
            span {
                font-size: 16px;
                font-family: fonts.$eduate-font-family;
            }
            &--t {
                display: flex;
                column-gap: 5px;
                align-items: center;
                b {
                    font-size: 14px;
                    font-family: fonts.$eduate-number-font;
                    padding: 5px 7px;
                    color:colors.get-color(colors.$colors, white);
                    background-color: colors.get-color(colors.$Green,  level_4);
                    border-radius: 2px;
                }
            }
        }
        &--ul {
            margin-top: 10px;
            margin-bottom: 0px;
            height: calc(100% - 295px);
            overflow: auto;
            li{
                display: flex;
                align-items: center;
                font-family: fonts.$eduate-font-family;
                column-gap: 5px;
                border: 1px solid var(--border-color);
                border-radius: 6px;
                padding: 10px;
                box-shadow: 6px 4px 4px rgba(0, 0, 0, 0.25);
                margin-top: 10px;

                img{
                    width: 25px;
                    height: 25px;
                }

                b{
                    font-size: 16px;
                    color: colors.get-color(colors.$blue, level_7);
                }
                span{
                    font-size: 14px;
                    color: colors.get-color(colors.$cool_grey, level_5);
                }
            }
        }
    }
}


.std-device-details__drawer--ul::-webkit-scrollbar {
    @include scrollbar();
  }
  
  .std-device-details__drawer--ul::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
  }
  
  .std-device-details__drawer--ul::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
  }
