@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.refund-academic-fee {
  margin: 0px 20px 0px 10px;
  height: 125px;

  &__frame {
    padding: 0px 15px 0px 5px;
    margin: 5px 0px 5px 5px;

    .MuiAutocomplete-root {
      margin: 5px 0px 3px 0px;
    }

    &--textfield {
      @include textfield();
      margin: 5px 0px 3px 0px !important;

      &--date {
        @include textfield();
        margin: 5px 0px 3px 0px !important;
        width: fit-content !important;
      }
    }

    &--imagefield {
      display: flex;

      .MuiAutocomplete-root {
        width: 100% !important;
        margin-top: 3px;
      }
    }

    &--image {
      margin: 5px 0px 5px 0px;
      @include studentProfile();
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1.8fr 0.35fr;
  }

  &__tableblock {
    height: calc(100% - 525px);
    margin: 10px 20px 0px 20px;

    &--modal {
      @extend .refund-academic-fee__tableblock;
      height: calc(100% - 395px);
    }
  }

  &__table {
    @include Accountstable();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--fee {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
      @include inputSalaryTable();
    }

    &--th-slno {
      width: calc(100% - 96%);
    }

    &--th-fee {
      width: calc(100% - 92%);
    }
  }

  &__btns {
    margin-left: 20px;
  }

  &__receiptblock {
    margin: 0px 10px 10px 10px;
    height: 170px;

    Label {
      margin-top: 0px;
    }

    .storybook-input,
    select {
      margin-top: 5px !important;
    }

    .MuiFormControl-root {
      margin-top: 5px !important;
    }
  }

  &__view {
    &--details {
      #search {
        margin-top: 0px;
      }

      column-gap: 20px;

      &--textfield {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
      }

      &--date {
        @include textfield();
        width: fit-content !important;
      }
    }

    &--tableblock {
      height: calc(100% - 115px);
      margin-top: 10px;
    }

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
      }

      &--receipt {
        width: calc(100% - 85%);
        font-family: fonts.$eduate-number-font;
      }

      &--amount {
        width: calc(100% - 85%);
        font-family: fonts.$eduate-number-font;
      }
    }
  }
}

.view-fee-concession-ledgers {
  &__tableblock {
    height: calc(100% - 85px);
    margin-top: 10px;

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 80%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-slno {
        width: calc(100% - 85%);
      }

      &--amount {
        width: calc(100% - 70%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-amount {
        width: calc(100% - 80%);
      }
    }
  }
}
