@use "../../../../scss/common/mixins" as*;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.submission-details {
    height: calc(100% - 30px);

    &__title {
        text-align: center;
        font-family: fonts.$eduate-number-font;
        font-size:fonts.$eduate-default-font-size;
    }

    &__block {
        height: 80px;
        display: flex;
        border: 1px solid var(--border-color);
        border-radius: 10px;

        &--details {
            width: 45%;
            padding: 5px;
            border-right: 1px dashed var(--border-color);

            span {
                display: block;
                font-size: 12px;
                font-family: fonts.$eduate-font-family;
            }
        }

        &--images {
            height: 100%;
            padding: 5px;
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        &--dates {
            display: flex;
            justify-content: flex-end;

            &--textfield {
                @include textfield();
                margin: 10px 10px 0px 0px !important;
                width: fit-content !important;
            }
        }

        &--show-all {
            height: calc(100% - 80px);
            overflow: auto;

            li {
                display: flex;
                justify-content: space-between;
                padding: 5px;
                background-color: colors.get-color(colors.$cool_grey, level_2);
                border-radius: 6px;
                margin-top: 10px;

                img {
                    width: 30px;
                    margin-right: 10px;
                }

                span {
                    font-size:fonts.$eduate-default-font-size;
                    font-family: fonts.$eduate-font-family;
                    color: colors.get-color(colors.$grey, level_7);
                    width: calc(100% - 70px);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;


                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        &--icons {
            display: flex;
            column-gap: 5px;

            img {
                width: 30px;
            }
        }

        &--view-more {
            color: var(--level-5);
            font-family: fonts.$eduate-font-family;
            font-size:fonts.$eduate-default-font-size;
            margin-top: 10px;

            &:hover {
                text-decoration: underline;
                font-weight: 700;
            }
        }


    }

    &__tableblock {
        height: calc(100% - 215px);
        margin-top: 10px;

    }

    &__table {
        @include table();

        &--slno {
            width: calc(100% - 94%);
            font-family: fonts.$eduate-number-font;
        }

        &--admno {
            width: calc(100% - 92%);
            font-family: fonts.$eduate-number-font;
        }

        &--regno {
            width: calc(100% - 92%);
            font-family: fonts.$eduate-number-font;
        }

        &--date {
            width: calc(100% - 92%);
            font-family: fonts.$eduate-number-font;
        }

        &--status {
            width: calc(100% - 85%);

        }

        &--actions {
            width: calc(100% - 93%)
        }
    }
}

.submission-details__block--show-all::-webkit-scrollbar {
    @include scrollbar();
}

.submission-details__block--show-all::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.submission-details__block--show-all::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}