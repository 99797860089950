@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.test-planner__details {
  @include mainFrameWithTitle();

  &--create-test {
    margin: 0px 20px;
    height: calc(100% - 190px);

    &--warning {
      .subtitle1 {
        text-align: left !important;
      }
    }

    &--textfield {
      @include textfield();
    }

    &--select {
      column-gap: 10px;
      margin-top: 10px;
    }

    &--details {
      border-top: 1.8px dashed var(--border-color);
      margin-top: 10px;
    }

    &--table {
      @include table();
      @include tableCheckbox();

      &--groupheader {
        font-size: 16px;
        font-weight: bold;
        height: 30px;
      }

      &--subheader {
        @include subHeader();
      }

      &--open-elective {
        td {
          background-color: var(--disabled-color);
          height: 30px;
          font-weight: 700;
        }
      }

      &--th-slno {
        width: calc(100% - 96%);
      }

      &--slno {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--marks {
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
      }

      &--input {
        @include inputReportsTable();
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
        padding: 0px !important;

        input {
          text-align: center !important;
        }
      }

      &--code {
        width: calc(100% - 92%);
      }

      &--date {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
        padding: 0px !important;
      }
    }

    &--tableblock {
      margin-top: 10px;
      height: calc(100% - 95px);
    }
  }
}
