@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.rp-template-3 {
  height: 100%;
  &__std {
    display: flex;
    column-gap: 10px;
    width: 100%;
    input{
        border-bottom: 1px solid var(--text-color);
        border-radius: 0px !important;
        width: 100% !important;
      }
    &--details {
      font-family: fonts.$eduate-font-family;
      width: calc(100% - 130px);
     
    }
    &--image {
      height: 120px;
      width: 120px;
      border: 1px solid var(border-color);
      border-radius: 6px;
      img {
        height: 100%;
        width: 100%;
      }
    }
  }
  &__page1 {
    border: 2px solid var(--text-color);
    padding: 10px;
    .bgs-pn-pr__inst-details--logo {
      height: 180px;
      width: 180px;
    }
    .progress-report__inst--logo {
      height: 180px;
      width: 180px;
    }
    .progress-report__inst--details--inst-name {
      font-size: 28px;
    }
    .progress-report__inst--details--cust-name {
      font-size: 16px;
    }
  }
  &__page2{
    padding:10px;
    &--flex{
        display: flex;
        column-gap: 10px;
    }
    &--grade{
        width:50%;
    }
    &--other-details{
        width: 50%;
    }
  }
  .bgs-pn-pr__std-details--grid {
    padding: 5px;
    font-size: 16px !important;
  }
}
