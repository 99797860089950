@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.payroll-masterdata {
  height: calc(100% - 170px);
  margin: 0px 20px 0px 20px;

  &--organization {
    height: calc(100% - 40px);
  }

  &--modal {
    height: calc(100% - 35px);
  }

  &__frame {
    column-gap: 40px;
    margin-top: 10px;
    height: calc(100% - 50px);

    .booktype-left,
    .booktype-right {
      margin: 0px !important;
    }
  }

  &__title {
    height: 30px;
    padding-left: 10px;

    #search {
      margin-top: 0px;
    }
  }

  &__block {
    height: calc(100% - 40px);
    overflow-y: auto;

    .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
      right: 10px !important;
    }

    &--table {
      @include table();
      margin-top: 10px;
      height: calc(100% - 10px);

      &--th-slno {
        width: calc(100% - 88%);
      }

      &--slno {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }

      &--actions {
        width: calc(100% - 88%);
      }
    }

    &--form-labels {
      margin-top: 15px !important;
    }

    &--academics {
      height: calc(100% - 40px);

      &--table {
        margin-top: 10px;
        height: calc(100% - 45px);
        @include table();

        &--date {
          font-family: fonts.$eduate-number-font;
        }

        &--slno {
          font-family: fonts.$eduate-number-font;
        }
      }
    }
  }

  &__leave-ledger {
    height: calc(100% - 168px);
    margin: 0px 20px 0px 20px;

    &--tableblock {
      height: calc(100% - 95px);
      margin-top: 10px;
    }

  

    &--add {
      .MuiFormControlLabel-root {
        display: flex !important;
        width: calc(100% - 10px) !important;
        justify-content: space-between !important;
        margin-top: 10px;
        margin-left: 5px !important;
      }
    }

    &--button {
      display: flex !important;
      justify-content: flex-end !important;
      margin-left: auto !important;
      margin-right: 0px !important;
    }
  }
}

.payroll-masterdata__block::-webkit-scrollbar {
  @include scrollbar();
}

.payroll-masterdata__block::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.payroll-masterdata__block::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}