@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
.payment-voucher {
@include mainFrameWithTitle();

  &__modal {
    height: calc(100% - 30px);
  }

  &__block {
    &--payment-number {
      display: flex;
    }

    Input {
      width: fit-content !important;
    }

    Label {
      margin-right: 5px;

      span {
        background-color: colors.get-color(colors.$red, level_6);
        border-radius: 3px;
        color:colors.get-color(colors.$colors, white);
        font-weight: 500;
        padding: 5px;
      }
    }

    &--date {
      display: flex;
      justify-content: flex-end;
      column-gap: 10px;

      Input {
        width: fit-content !important;
      }
    }

    &--day {
      margin-top: 10px !important;
      margin-left: 5px;
    }
  }

  &__tableblock {
    height: calc(100% - 215px);
    margin-top: 5px;
  }

  &__table {
    @include table();

    &--row {
      @include selectTableRow();

      td {
        background-color: var(--bg-color);
      }

      .MuiAutocomplete-root {
        margin-top: 0px !important;
      }

      .MuiFormControl-root {
        margin-top: 0px !important;
      }

      .MuiAutocomplete-root,
      .auto-complete:focus {
        background-color: var(--bg-color) !important;
      }
    }

    &--slno {
      width: calc(100% - 95%);
      font-family: fonts.$eduate-number-font;
    }

    &--select {
      padding-left: 0px !important;
      padding-right: 0px !important;
      width: calc(100% - 92%);
    }

    &--desc {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    &--amount {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-amount {
      width: calc(100% - 88%);
    }

    input {
      height: 30px;
      border-radius: 0px;
      margin: 0px;
      padding-right: 3px;
      width: 100%;
    }

    &--actions {
      width: calc(100% - 96%);

      &--save {
        filter: invert(100%);
        width: 25px;
        height: 25px;
        padding: 0px;
      }
    }
  }

  &__lableblock {
    height: 125px;

    Label,
    .storybook-input,
    .MuiFormControl-root,
    select {
      margin-top: 5px !important;
    }

    &--details {
      column-gap: 20px;
      display: flex;
    }

    &--top {
      margin-top: 35px;
    }

    &--label-grid {
      display: grid;
      grid-template-columns: 0.85fr 0.5fr 1.9fr;

      .label-grid {
        display: grid;
        grid-template-columns: 0fr 2.8fr;
      }

      &--cheque {
        .label-grid {
          display: grid;
          grid-template-columns: 1.1fr 1fr !important;
        }
      }
    }

    &--bill-number {
      display: flex;
      gap: 10px;
    }

    &--frames {
      border: 1px solid var(--border-color);
      padding: 0px 5px 5px 5px;
      border-radius: 3px;
      height: 100%;
      width: calc(50% - 10px);

      .documents-upload {
        grid-template-columns: 0.45fr 1.5fr 0.5fr !important;
      }

      .documents-upload--filename {
        margin-top: 5px !important;
      }
    }
  }

  &__image {
    margin-right: 10px;
  }

  &__label-grid2 {
    display: grid;
    grid-template-columns: 0.225fr 1fr;
  }

  &__icons {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    column-gap: 15px;

    img {
      width: 35px;
    }
  }

  &__voucher {
    &-flex {
      display: grid;
      grid-template-columns: 0.7fr 1fr;
      font-size:fonts.$eduate-default-font-size;
    }

    &-v {
      &-cb {
        color: colors.get-color(colors.$red, level_7);
      }

      &-groupledger {
        color: colors.get-color(colors.$sky, level_7);
      }
    }
  }
}

.contra-voucher {
  &__lableblock {
    @extend .payment-voucher__tableblock;
    height: 120px;
  }

  &__tableblock {
    height: calc(100% - 215px);
    margin-top: 5px;
  }
}