@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.student-dashboard {
  height: calc(100% - 160px);
  margin: 0px 20px 10px 20px;

  &__nav {
    height: 30px;
    background-color: var(--level-6);
    border-radius: 0px 0px 25px 0px;
  }

  &__frame {
    height: calc(100% - 10px);

    &--std-details {
      display: flex;
      column-gap: 10px;
      height: calc(100% - 50px);

      .student-profile {
        @include studentProfile();
        height: 60px !important;
        width: 60px !important;
        display: block;
        margin-bottom: auto;
        margin-top: 10px;
      }

      &--button {
        display: flex;
        justify-content: center;
        column-gap: 10px;

        &--view {
          @include viewButton();
        }

        &--swap {
          @include viewButton();
          background-color: colors.get-color(colors.$amber, level_5);
        }
      }
    }

    &--multiple-std-details {
      margin-bottom: 0px !important;

      li {
        display: flex;
        align-items: center;
        column-gap: 10px;
        padding: 5px;
        border-bottom: 1.2px dashed var(--border-color);

        &:hover {
          background-color: var(--level-2);
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }

        span {
          font-family: fonts.$eduate-font-family;
          font-size: fonts.$eduate-default-font-size;
        }
      }
    }

    &--std-data {
      width: calc(100% - 65px);
      margin-left: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &--flex {
        display: flex;
        column-gap: 5px;
        padding: 2px;
        border-bottom: 1px dashed var(--border-color);
        font-size: 13px;
        font-family: fonts.$eduate-font-family;
      }
    }

    &--grid-list {
      height: 165px;
      margin-top: 10px;
    }

    &--grids {
      @include blocksInDashboard();
      height: 100% !important;
      margin-right: 10px;

      &--title {
        display: flex;
        justify-content: space-between;

        img {
          width: 25px;
        }

        button {
          color: colors.get-color(colors.$colors, white);
          background-color: colors.get-color(colors.$Green, level_7);
          border-radius: 20px;
          padding: 0px 10px;
          font-size: fonts.$eduate-default-font-size;
          font-family: fonts.$eduate-font-family;

          img {
            width: 15px;
          }
        }
      }

      &--details {
        display: flex;
        column-gap: 10px;

        img {
          width: 60px;
          height: 60px;
        }

        &--total {
          font-family: fonts.$eduate-number-font;
          font-weight: bold;
          font-size: 18px;
          color: colors.get-color(colors.$grey, level_8);
          text-align: center !important;
          position: relative;
        }
      }

      &--g-count {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-top: 10px;
        border-top: 1px solid var(--border-color);
        gap: 5px;

        img {
          margin: auto;
        }

        &--flex {
          display: flex;
          column-gap: 10px;
          padding: 5px;
        }

        .separator {
          border-right: 1px solid var(--border-color);
        }

        &--block {
          display: flex;
          flex-direction: column;
          margin: auto;

          &--text {
            font-family: fonts.$eduate-font-family;
            color: colors.get-color(colors.$grey, level_6);
            font-weight: 700;
            font-size: 12px;
          }

          &--total {
            @extend .student-dashboard__frame--grids--g-count--block--text;
            font-size: 12px;
            font-weight: 700;
            color: var(--text-color);
            font-family: fonts.$eduate-number-font;
          }
        }
      }
    }

    &--flex {
      display: flex;
      justify-content: space-between;

      &--title {
        display: flex;
        align-items: center;
        font-family: fonts.$eduate-font-family;
        font-size: fonts.$eduate-default-font-size;
        color: var(--text-color);
        margin-top: 10px;

        img {
          width: 35px;
        }
      }

      &--number {
        color: var(--text-color);
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-number-font;
      }
    }

    &--bottom-details {
      margin-top: 10px;
      height: calc(100% - 185px);
    }

    &--calendar {
      @include blocksInDashboard();
      height: 100% !important;
      margin-right: 10px;
    }

    &--graph {
      @include blocksInDashboard();
      height: 100% !important;
      padding: 0px 5px;
      margin-right: 10px;

      .echarts-for-react {
        height: calc(100% - 30px) !important;
      }
    }

    &--activity {
      @include blocksInDashboard();
      height: 100% !important;
      margin-right: 10px;
    }

    &--tabs {
      @include muiTabsInDashboard();
      margin-bottom: 10px;
    }

    &--tab-panel {
      height: calc(100% - 80px);

      .MuiTypography-root {
        height: 100%;
      }

      .customized-calendar {
        height: calc(100% - 35px);
      }
    }
  }

  &__announcements {
    height: calc(100% - 40px);

    &--slideshow {
      overflow: hidden;
      width: 100%;
      height: 100%;
      margin-top: 10px;
    }

    &--slider {
      display: flex;
      column-gap: 2px;
      transition: ease 2s;
      height: calc(100% - 25px);
      width: calc(100% - 4px);
    }

    &--slide {
      flex: 0 0 100%;
      height: 100%;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      width: 100%;

      b {
        font-family: fonts.$eduate-font-family;
        font-size: fonts.$eduate-default-font-size;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      span {
        margin-top: 10px;
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-number-font;
      }
    }

    &--dots {
      text-align: center;
    }

    &--dot {
      display: inline-block;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      cursor: pointer;
      margin: 10px 5px 0px;
      background-color: #c4c4c4;
    }
  }

  &__attendance {
    height: 100%;
    &--view {
      @include viewButton();
    }

    &--month {
      display: flex;
      justify-content: space-between;

      img {
        width: 18px;
      }

      .subtitle1 {
        font-size: 14px !important;
      }
    }

    &--grid-container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-template-rows: auto auto auto auto auto auto auto;
      gap: 5px;
      margin-top: 10px;
      height: calc(100% - 90px);

      &--day {
        font-family: fonts.$eduate-font-family;
        font-size: 12px;
        text-align: center;
      }

      &--grid-item {
        border-radius: 6px;
        border: 1px solid var(--border-color);
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-size: 10px;
          font-family: fonts.$eduate-number-font;
        }
      }
    }

    &--legends {
      display: flex;
      padding-top: 5px;
      justify-content: center;
      gap: 5px;
      border-top: 2px dashed var(--border-color);

      Label {
        font-size: 12px !important;
        padding: 5px 0px 0px 0px;
        margin-top: 0px !important;
      }

      &--data {
        text-align: center;
        border-radius: 6px;
        color: colors.get-color(colors.$colors, white);
      }

      span {
        font-size: 12px;
        font-weight: 500;
        padding-top: 5px;
        height: 30px;
        width: 30px;
      }
    }

    &--full-view {
      height: 100%;

      &--datablock {
        height: calc(100% - 85px);
        margin-top: 10px;
        column-gap: 30px;

        &--frame {
          border: 1px solid var(--border-color);
          padding: 5px;
          height: 100%;

          &--graph {
            height: calc(100% - 135px);
            margin-top: 10px;

            .echarts-for-react {
              height: 100% !important;
            }
          }

          &--cards {
            height: 80px;
            gap: 10px;
          }

          &--blocks {
            @include blocksInDashboard();
            border-radius: 6px;

            &--attendance {
              display: flex;
              justify-content: center !important;
              font-family: fonts.$eduate-number-font;
              font-size: fonts.$eduate-default-font-size;
              color: colors.get-color(colors.$fuchsia, level_6);
              font-weight: 700;
            }

            &--header {
              display: block;
              text-align: center;
              color: colors.get-color(colors.$grey, level_5);
              font-family: fonts.$eduate-font-family;
              font-size: 12px;
              font-weight: 700;
              height: 50px !important;
            }

            &--present {
              @extend .student-dashboard__attendance--full-view--datablock--frame--blocks--attendance;
              color: colors.get-color(colors.$Green, level_6);
            }

            &--absent {
              @extend .student-dashboard__attendance--full-view--datablock--frame--blocks--attendance;
              color: colors.get-color(colors.$red, level_6);
            }

            &--percentage {
              @extend .student-dashboard__attendance--full-view--datablock--frame--blocks--attendance;
              color: colors.get-color(colors.$grey, level_6);
            }
          }

          &--button {
            margin: 0px 0px 0px 0px !important;
            display: flex;
            justify-content: center !important;
          }
        }
        &--report {
          height: calc(100% - 215px);
          margin-top: 10px;
          column-gap: 30px;
        }
      }

      &--subjects-count {
        padding: 8px 12px;
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        background-color: var(--level-2);
        column-gap: 10px;
        font-size: fonts.$eduate-default-font-size;
        width: fit-content;
        border-radius: 20px;
        margin-top: 5px;

        span {
          color: var(--text-color);
          font-family: fonts.$eduate-font-family;
        }

        b {
          color: var(--level-5);
          font-family: fonts.$eduate-number-font;
        }
      }

      &--subjects-list {
        height: calc(100% - 135px);
        margin-top: 10px;
        overflow: auto;
        margin-bottom: 0px !important;

        li {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          border-bottom: 1px solid var(--border-color);
        }
      }

      &--subjects {
        display: flex;
        column-gap: 5px;
        width: calc(100% - 100px);

        &--name {
          font-size: fonts.$eduate-default-font-size;
          font-family: fonts.$eduate-font-family;
          color: var(--text-color);
        }

        &--code {
          font-size: fonts.$eduate-default-font-size;
          font-family: fonts.$eduate-number-font;
          color: colors.get-color(colors.$cool_grey, level_5);
        }
      }

      &--sessions {
        width: 100px;

        &--flex {
          display: flex;
          justify-content: space-between;
        }

        span {
          display: block;
          font-family: fonts.$eduate-font-family;
          font-size: fonts.$eduate-default-font-size;
          color: var(--text-color);
        }

        &--status {
          display: flex;
        }
      }

      &--dot {
        display: inline-block;
        height: 10px;
        margin-right: 2px;
        border-radius: 5px;
      }

      &--present {
        height: 10px;
        width: 100%;
        background-color: colors.get-color(colors.$Green, level_5);
      }

      &--absent {
        height: 10px;
        width: 100%;
        background-color: colors.get-color(colors.$colors, red);
      }

      &--button {
        display: flex;
        justify-content: center;
      }

      &--graph {
        height: calc(100% - 100px);

        .echarts-for-react {
          height: calc(100% - 30px) !important;
        }
      }
    }

    &--absenties {
      height: 100%;
      &--title {
        img {
          @include closeIconInModal();
        }
      }

      &--data {
        margin-top: 10px;
        height: calc(100% - 135px);
        overflow: auto;
        &--t {
          display: flex;
          justify-content: space-between;
          background-color: var(--disabled-color);
          padding: 10px;
          b {
            color: var(--level-5);
            font-family: fonts.$eduate-number-font;
          }
        }
        li {
          display: flex;
          justify-content: space-between;
          padding: 3px 5px;
          color: colors.get-color(colors.$cool_grey, level_5);
        }
        ul {
          border: 1px solid var(--border-color);
        }
      }

      &--table {
        @include table();
        height: calc(100% - 35px);
      }

      &--slno {
        font-family: fonts.$eduate-number-font;
      }

      &--date {
        font-family: fonts.$eduate-number-font;
      }
      &--total {
        display: flex;
        background-color: colors.get-color(colors.$red, level_5);
        align-items: center;
        padding: 10px;
        border-radius: 6px;
        color: colors.get-color(colors.$colors, white);
        font-size: 18px;
        font-family: fonts.$eduate-font-family;
        justify-content: space-between;
        b {
          padding: 3px 8px;
          background-color: colors.get-color(colors.$colors, white);
          border-radius: 6px;
        }
      }
      &--flex {
        display: flex;
        column-gap: 10px;
      }
    }
  }

  &__feeds {
    margin-top: 10px;
    height: calc(100% - 45px);
    overflow: auto;

    &--general {
      @extend .student-dashboard__feeds;
      border: 1px solid var(--border-color);
      border-radius: 6px;
      padding: 10px;
    }

    &--images {
      height: 80px;
      display: flex;
      justify-content: center !important;

      &--block {
        height: 100%;
        overflow: auto;
        align-items: center;
        display: flex !important;
        justify-content: center !important;
        column-gap: 10px;
        width: 250px;

        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
        }
      }
    }

    &--details {
      border: 1px solid var(--border-color);
      margin-top: 10px;
      padding: 2px;

      &--subtitle {
        color: colors.get-color(colors.$grey, level_6) !important;
        font-size: 15px !important;
        font-family: fonts.$eduate-font-family;
      }

      &--flex {
        display: flex;
        justify-content: space-between;

        span {
          color: var(--text-color);
          font-size: 12px;
          font-family: fonts.$eduate-number-font;
        }

        .subtitle1 {
          color: colors.get-color(colors.$grey, level_7) !important;
        }
      }

      &--single-image {
        img {
          width: 100%;
          height: 200px;
        }
      }

      &--multiple-image {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;

        img {
          width: 100%;
          height: 100px;
        }
      }
    }

    &--view-more {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;

      button {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        padding: 5px 20px;
        border-radius: 30px;
        border: 1px solid var(--border-color);
        background: var(--bg-color);
        color: var(--text-color);

        &:hover {
          background-color: var(--level-2);
        }

        &:focus {
          background-color: var(--level-5);
          color: colors.get-color(colors.$colors, white);
        }
      }
    }
  }
}

.student-dashboard__feeds--general::-webkit-scrollbar,
.student-dashboard__attendance--absenties--data::-webkit-scrollbar,
.student-dashboard__attendance--full-view--subjects-list::-webkit-scrollbar,
.student-dashboard__calendar--notifications::-webkit-scrollbar,
.student-dashboard__attendance--full-view--subjects-list
  .student-dashboard__calendar--holidays-list::-webkit-scrollbar {
  @include scrollbar();
}

.student-dashboard__feeds--general::-webkit-scrollbar-thumb,
.student-dashboard__attendance--absenties--data::-webkit-scrollbar-thumb,
.student-dashboard__attendance--full-view--subjects-list::-webkit-scrollbar-thumb,
.student-dashboard__calendar--notifications::-webkit-scrollbar-thumb,
.student-dashboard__calendar--holidays-list::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.student-dashboard__feeds--general::-webkit-scrollbar-thumb:hover,
.student-dashboard__attendance--absenties--data::-webkit-scrollbar-thumb:hover,
.student-dashboard__attendance--full-view--subjects-list::-webkit-scrollbar-thumb:hover,
.student-dashboard__calendar--notifications::-webkit-scrollbar-thumb:hover,
.student-dashboard__calendar--holidays-list::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

.student-dashboard__feeds::-webkit-scrollbar {
  @include scrollbar();
}
.student-dashboard__feeds::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.student-dashboard__feeds::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
.weekend-dates {
  color: colors.get-color(colors.$red, level_6) !important;
  font-weight: 600;
}

.vacations-dates {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  border-top: 2px solid red;

  span {
    position: relative;
    z-index: 1;
  }
}

.border-left-for-holidays {
  border-left: 3px solid colors.get-color(colors.$red, level_6) !important;
}

.calendar-show-events {
  span {
    border-bottom: 4px solid colors.get-color(colors.$sky, level_6);
  }
}

.attendance-present {
  background-color: colors.get-color(colors.$Green, level_6);
}

.attendance-absent {
  background-color: colors.get-color(colors.$red, level_6);
}

.student-dashboard__announcements--dot.active {
  background-color: var(--level-5);
  width: 30px;
  border-radius: 30px;
  height: 15px;
}
