.bill-book-config {
  height: calc(100% - 170px);
  margin: 0px 20px;
  &__datablock {
    margin-top: 10px;
    height: calc(100% - 50px);
    column-gap: 30px;
    &--frame {
      padding: 0px 10px;
      border: 1px solid var(--border-color);
      border-radius: 6px;
      height: 100%;
      &--grids {
        display: grid;
        grid-template-columns: 0.8fr 0.6fr 0.8fr 0.6fr;
      }
      &--label-grid {
        display: grid;
        grid-template-columns: 0.9fr 0.6fr 0.4fr;
      }
    }
    &--form-labels {
      .MuiFormControlLabel-root {
        display: flex !important;
        width: calc(100% - 10px) !important;
        justify-content: space-between !important;
        margin-top: 10px;
        margin-left: 5px !important;
      }
      &--grids {
        display: grid;
        grid-template-columns: 1fr 0.5fr 0.5fr 0.6fr;
        gap: 20px;
        .storybook-input {
          margin-top: 0px !important;
        }
        .MuiFormControl-root {
          margin-top: 0px !important;
        }
      }
    }
  }
}
