@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.document-config {
  height: 100%;
  padding: 5px;

  &__parameters {
    height: calc(100% - 75px);
    overflow: auto;

    &--list {
      display: grid;
      grid-template-columns: 2fr 1fr;
      border-bottom: 1px dashed var(--border-color);
      padding-bottom: 5px;

      .MuiSwitch-root {
        margin-top: 10px;
      }

      .MuiFormControlLabel-root {
        margin-right: 0px !important;
      }
    }
  }
}

.document-config__parameters::-webkit-scrollbar {
  @include scrollbar();
}

.document-config__parameters::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.document-config__parameters::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
