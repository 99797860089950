@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/colors" as colors;
@use "../../../../../scss/common/fonts" as fonts;
.combination-report-view {
 @include mainFrameWithTitle();
  &__select {
    justify-content: space-between;
    margin-top: 10px;
    b {
      font-family: fonts.$eduate-font-family;
      color: var(--level-6);
      text-align: center;
      font-size: 16px;
    }
    &--flex {
      column-gap: 10px;
      display: flex;
    }
    &--textfield {
      @include textfield();
      margin: 10px 0px 0px 0px !important;
    }
  }
  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 270px);
    &--view {
      @include viewButton();
    }
  }
  &__table {
    @include table();
    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--admno {
      width: calc(100% - 88%);

      font-family: fonts.$eduate-number-font;
    }
    &--marks {
      width: calc(100% - 88%);
    }
  }

  &__sub-wise {
    height: 100%;
    &--tableblock {
      height: 100%;
      &--view {
        @include viewButton();
      }
    }
  }
}
