@use "../common/fonts" as fonts;
@use "../common/mixins" as *;
.print-receipts {
 height: 100%;
 width:100%;
  

  span,
  b {
    color: var(--text-color);
    font-family: fonts.$eduate-font-family;
  }

  &__ss {
    display: flex;
    gap: 30px;
    width: 100%;
    height:calc(100% - 45px);

  }
  &__ff{
    height:calc(100% - 50px);
    width:100%;

  }

  &__dd {
    width: 100%;
    height:calc(100% - 10px);
  }
}

.print-receipts__ff::-webkit-scrollbar {
  @include scrollbar();
}

.print-receipts__ff::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.print-receipts__ff::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}


.configurations-image{
  @include settingsIconInModal();
  }