@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.daily-attendance{
    height: calc(100% - 170px);
    margin: 0px 20px;
    display: flex;
    column-gap: 10px;
    &__left{
        height: 100%;
        border-radius: 6px;
        border: 1px solid var(--border-color);
        width: 200px;
    }
    &__right{
        height: 100%;
        padding: 10px;
        border-radius: 6px;
        border: 1px solid var(--border-color);
        width: calc(100% - 200px);
        &--cards {
            margin-top: 10px;
            height: 60px;
        
            &--list {
              display: flex;
              height: 100%;
              column-gap: 15px;
        
              &--grid {
                height: 100%;
                width: 230px;
                display: flex;
                align-items: center;
                column-gap: 5px;
                background-color: colors.get-color(colors.$grey, level_2);
                border-radius: 6px;
                padding: 0px 10px;
        
                img {
                  width: 30px;
                }
        
                span {
                  font-family: fonts.$eduate-font-family;
                  font-size:fonts.$eduate-default-font-size;
                  color: var(--text-color);
                  width: calc(100% - 50px);
                }
        
                b {
                  font-family: fonts.$eduate-number-font;
                  font-size:fonts.$eduate-default-font-size;
                }
              }
            }
        
            &--filter {
              text-align: right;
            }
        
            &--textfield {
              @include textfieldInForms();
              width: fit-content !important;
              text-align: right;
            }
          }
          &--filters {
            column-gap: 10px;
            margin-top: 10px;
        
            &--textfield {
              @include textfieldInForms();
            }
          }
          &--tableblock {
            height: calc(100% - 235px);
            margin-top: 10px;
          }
        &--overall{
            height: calc(100% - 90px);
            margin-top: 10px;
        }
          &--table {
            @include table();
        
            &--desc {
              display: flex;
              justify-content: space-between;
            }
        
            &--actions {
              width: calc(100% - 85%);
            }
        
            &--number {
              width: calc(100% - 94%);
              font-family: fonts.$eduate-number-font;
            }
        
            .view-button {
              @include viewButton();
            }
        
          
          }
    }
}