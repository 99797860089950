@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.std-enable-login {
  height: calc(100% - 165px);
  margin: 10px 20px 0px 20px;

  &__textfield {
    @include textfield();
    margin: 10px 0px 0px 0px !important;
    width: 100%;

    &--email {
      @include textfieldInForms();
    }
  }

  &__options {
    column-gap: 10px;
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 100px);
    &--for-emp {
      margin-top: 10px;
      height: calc(100% - 110px);
    }
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--admno {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--mobile {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--desc {
      width: calc(100% - 88%);
    }

    &--status {
      width: calc(100% - 94%);
    }
  }

  &__total {
    text-align: right;
  }
}
