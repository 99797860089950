@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;

.lesson-planner {
    @include mainFrameWithTitle();

    &__select {
        column-gap: 10px;
        margin-top: 10px;
    }

    &__tableblock {
        height: calc(100% - 95px);
        margin-top: 10px;
    }

    &__table {
        @include table();

        &--assign-button {
            @include assignButton();
        }

        &--total {
            width: calc(100% - 95%);
            font-family: fonts.$eduate-number-font;
        }

        &--actions {
            width: calc(100% - 88%);
        }
    }
}