@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
$YELLOW_COLOR: "FFF200";
$PINK_COLOR: "EC008C";
.bgs-pn-pr {
    width: 100%;
    height: calc(100% - 75px);
    overflow: auto;
    .page-break {
        page-break-after: always !important;
    }

    &__block {
        width: 100%;
        height: 100%;
    }
    &__inst-details {
        display: flex;
        align-items: center;
        column-gap: 10px;
        border-bottom: 1px dashed var(--text-color);
        padding-bottom: 5px;
        column-gap: 20px;
        &--logo {
            width: 85px;
            height: 85px;
        }
        &--inst-name {
            font-size: 18px;
            font-family: fonts.$eduate-font-family;
            display: flex;
        }
        &--cust-name {
            font-size: 13px;
            font-family: fonts.$eduate-font-family;
            display: flex;
        }
        &--trust {
            font-size: 13px;
            font-family: fonts.$eduate-font-family;
            display: flex;
        }
    }
    &__std-details {
        display: flex;
        font-family: fonts.$eduate-font-family;
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 5px;
        column-gap: 20px;
        justify-content: space-between;
        border-top: 1px solid var(--text-color);
        &--grid {
            display: grid;
            column-gap: 20px;
            grid-template-columns: 1fr 0.1fr 2fr;
            input {
                font-weight: 700;
            }
        }
        &--image {
            width: 60px;
            height: 60px;
            img {
                @include studentProfile();
                height: 100%;
                width: 100%;
            }
        }
    }
    &__annual-results {
        margin-top: 10px;
        b {
            font-size: 22px;
            font-family: fonts.$eduate-font-family;
            display: flex;
            justify-content: center;
        }
        &--status {
            font-size: 18px;
            font-family: fonts.$eduate-font-family;
            display: flex;
            justify-content: center;
        }
        &--to {
            display: flex;
            justify-content: center;
            column-gap: 10px;
            font-size: 14px;
            font-family: fonts.$eduate-font-family;
            margin-top: 5px;

            input {
                font-weight: 700;
                font-size: 16px;
                border-bottom: 1px solid var(--text-color);
                border-radius: 0px !important;
            }
        }
    }
    &__signatures {
        td {
            font-size: 16px !important;
        }
        &--principal {
            height: 28px;
            width: fit-content;
        }
        &--sign{
            width:20%  !important;

        }
    }
    &__non-acd {
        margin-bottom: 20px;
        &--row {
            th {
                font-size: 20px !important;
            }
        }
        &--table {
            border-left: 1px solid var(--text-color) !important;

            th {
                border-top: none !important;
                border-right: 1px solid var(--text-color) !important;
                padding: 2px 5px !important;
            }

            td {
                border-bottom: 1px solid var(--text-color) !important;
                padding: 2px 5px !important;
            }

            @include tableInPrint();
        }
    }
    &__acd-details {
        &--table {
            border-left: 1px solid var(--text-color) !important;

            th {
                border-top: none !important;
                border-right: 1px solid var(--text-color) !important;
                padding: 2px 5px !important;
            }

            td {
                border-bottom: 1px solid var(--text-color) !important;
                padding: 2px 5px !important;
            }

            @include tableInPrint();
        }
    }
}


.bgs-pn-pr::-webkit-scrollbar {
  @include scrollbar();
}


.bgs-pn-pr::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}


.bgs-pn-pr::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}