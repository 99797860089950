@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.create-tags {
  @include mainFrameWithTitle();

  &__block {
    height: calc(100% - 55px);
    column-gap: 30px;
    margin-top: 10px;

    .booktype-left,
    .booktype-right {
      margin: 0px !important;
    }
  }

  &__tableblock {
    height: calc(100% - 45px);
    margin-top: 10px;
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--desc {
      width: calc(100% - 70%);
    }
  }
}
