@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.allot-regno {
  height: calc(100% - 165px);
  margin: 10px 20px 0px 20px;

  &__options {
    column-gap: 10px;

    &--button {
      margin-top: 0px !important;
      display: flex;
      justify-content: flex-end;
      margin-right: 0px !important;
      margin-left: auto !important;
  
    }
  }

  &__search {
    input {
      margin-top: 0px;
    }
  }

  &__radio-buttons {
    column-gap: 10px;

    .MuiFormControl-root {
      margin-top: 0px !important;
    }

    .storybook-input,
    .Label {
      margin-top: 0px !important;
    }

    &--group {
      margin-top: 12px;
   
    }
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 160px);
  }
  &__tableblock-for-rollno{
    margin-top: 10px;
    height: calc(100% - 100px);
  }

  &__table {
    @include table();

    &--admno {
      font-family: fonts.$eduate-number-font;
      width: calc(100% - 90%);
    }

    &--th-admno {
      width: calc(100% - 90%);
    }

    &--desc {
      width: calc(100% - 90%);
    }

    &--input {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
      @include inputSalaryTable();
    }
  }

  &__total {
    text-align: right;
  }
}

.allot-regno__table::-webkit-scrollbar {
  @include scrollbar();
}

.allot-regno__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.allot-regno__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}