@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.anonymous-std {
  @include mainFrameWithTitle();

  &__select {
    column-gap: 10px;

    &--textfield {
      @include textfieldInForms();
    }
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 97px);
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--actions {
      width: calc(100% - 95%);
    }

    &--ledger {
      width: calc(100% - 80%);
    }
  }
}
