@use "../../../../scss/common/mixins" as*;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.per-session-details {
 @include mainFrameWithTitle();

  .subtitle1 {
    margin-top: 10px !important;
  }

  &__data {
    height: calc(100% - 135px);
    overflow: auto;
    margin-top: 10px;

    &--grids {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 10px;
    }

    &--grid {
      background-color: var(--disabled-color);
      border-radius: 10px;
      height: 100px;
      padding: 5px;
    }

    span,
    b {
      display: block;
      text-align: center;
      font-size:fonts.$eduate-default-font-size;
      color: var(--text-color);
      margin-top: 3px;
    }

    &--sub {
      font-family: fonts.$eduate-font-family;
    }

    &--subcode {
      font-family: fonts.$eduate-number-font;
    }

    &--desn {
      font-family: fonts.$eduate-font-family;

      &--multiple {
        @extend .per-session-details__data--desn;

        &:hover {
          font-weight: 700;
          text-decoration: underline;
          color: var(--level-5);
        }
      }
    }

    &--profile {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 35px;
    }

    &--multiple-images {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;

      img {
        width: 35px;
        margin-left: -10px;
      }

      b {
        font-family: fonts.$eduate-number-font;
        font-size:fonts.$eduate-default-font-size;
      }
    }

    &--multiple-codes {
      display: flex;
      justify-content: center;
    }

    &--view {
      display: flex;
      justify-content: center;

      button {
        @include viewButton();
      }
    }

    &--list {
      margin-bottom: 0px !important;

      li {
        display: flex;
        column-gap: 5px;
      }
    }
  }

  &__list {
    height: 100%;

    &--data {
      width: 70%;
      display: block;
      margin-left: auto;
      margin-right: auto;
      height: calc(100% - 90px);
      overflow: auto;

      li {
        margin-top: 10px;
      }

      &--flex {
        display: flex;
        column-gap: 5px;
        margin-top: 20px;
        align-items: center;
      }

      &--textfield {
        @include textfield();
        width: fit-content !important;
      }

      &--grid {
        background-color: var(--level-1);
        border-radius: 10px;
        height: 220px;
        padding: 15px 5px;
      }

      span,
      b {
        display: block;
        text-align: center;
        font-size:fonts.$eduate-default-font-size;
        color: var(--text-color);
        margin-top: 3px;
      }

      &--sub {
        font-family: fonts.$eduate-font-family;
      }

      &--subcode {
        font-family: fonts.$eduate-number-font;
      }

      &--desn {
        font-family: fonts.$eduate-font-family;

        &--multiple {
          @extend .per-session-details__data--desn;

          &:hover {
            font-weight: 700;
            text-decoration: underline;
            color: var(--level-5);
          }
        }
      }

      &--profile {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 35px;
      }

      &--multiple-images {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;

        img {
          width: 35px;
          margin-left: -10px;
        }

        b {
          font-family: fonts.$eduate-number-font;
          font-size:fonts.$eduate-default-font-size;
        }
      }

      &--multiple-codes {
        display: flex;
        justify-content: center;
      }

      &--view {
        display: flex;
        justify-content: center;

        button {
          @include viewButton();
        }
      }

      &--list {
        margin-bottom: 0px !important;

        li {
          display: flex;
          column-gap: 5px;
          margin-top: 10px;
        }
      }
    }

    &--details {
      padding: 10px;
      border: 1px solid var(--border-color);
      border-radius: 6px;

      span {
        color: var(--level-5) !important;
        padding: 5px 20px;
        display: flex;
        justify-content: center;
        background-color: var(--disabled-color);
        border-radius: 20px;
        font-weight: 700;
      }
    }
  }
}

.per-session-details__data::-webkit-scrollbar,
.per-session-details__list--data::-webkit-scrollbar {
  @include scrollbar();
}

.per-session-details__data::-webkit-scrollbar-thumb,
.per-session-details__list--data::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.per-session-details__data::-webkit-scrollbar-thumb:hover,
.per-session-details__list--data::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}