@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.social-welfare {
  @include mainFrameWithTitle();

  &__select {
    column-gap: 10px;

    &--textfield {
      width: fit-content !important;
      @include textfield();
      margin: 10px 10px 0px 0px !important;
    }

    &--button {
      .storybook-button {
        display: flex !important;
        margin-left: auto !important;
        margin-right: 0px !important;
      }
    }
  }

  &__tableblock {
    height: calc(100% - 100px);
    margin-top: 10px;
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--date {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--number {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--amount {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--type {
      width: calc(100% - 92%);
    }

    &--actions {
      width: calc(100% - 94%);

      img {
        width: 20px;
      }
    }
  }

  &__add {
    @include mainFrameWithTitle();

    &--button {
      .MuiFormControl-root {
        display: flex !important;
        margin-left: auto !important;
        margin-right: 0px !important;
      }
    }

    &--tableblock {
      height: calc(100% - 255px);
      margin-top: 10px;
    }

    &--receiptblock {
      height: 150px;
      column-gap: 20px;
      margin-top: 10px;

      &--frame {
        border: 1px solid var(--border-color);
        height: 100%;
        border-radius: 6px;
        padding: 5px;

        &--label-grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      }

    }

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }

      &--row {
        @include selectTableRow();
        @include autoCompleteInTableRow();
      }

      &--amount {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
        @include inputSalaryTable();
      }

      &--actions {
        width: calc(100% - 94%);
      }
    }
  }

  &__view {
    height: calc(100% - 30px);

    &--search {
      column-gap: 10px;
    }

    &--tableblock {
      height: calc(100% - 90px);
      margin-top: 10px;
    }

    &--table {
      @include table();

      &--groupheader {
        @include groupHeader();
      }

      &--subheader {
        @include subHeader();
      }

      &--type {
        width: calc(100% - 92%);
      }

      &--number {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }

      &--date {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }

      &--slno {
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
      }

      &--amount {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }

      &--desc {
        width: calc(100% - 85%);
      }
    }
  }

  &__adjustments {
    height: calc(100% - 30px);

    &--select {
      column-gap: 20px;
      height: 120px;

      .MuiAutocomplete-root,
      .data-fetch-icon {
        margin-top: 8px;
      }

      &--flex {
        display: flex;
      }

      &--image {
        height: 100%;
        @include studentProfile();
      }

      &--textfield {
        @include textfield();
        width: 100%;
        margin: 8px 0px 0px 0px !important;

        &--date {
          @include textfield();
          width: fit-content;
          margin: 8px 0px 0px 0px !important;
        }
      }

      &--button {
        column-gap: 10px;
      }
    }

    &--tableblock {
      height: calc(100% - 280px);
      margin-top: 10px;
    }

    &--receiptblock {
      height: 110px;
      column-gap: 20px;

      .Label,
      Input {
        margin-top: 0px !important;
      }

      &--frame {
        height: 100%;
        border: 1px solid var(--border-color);
        padding: 10px;
      }
    }

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }

      &--amount {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
        @include inputSalaryTable();
      }
    }
  }
}