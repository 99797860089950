@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.grade {
    height: calc(100% - 30px);

    &__scale {
        height: 40px;
        width: 100%;
        background-color: var(--level-1);
        border-radius: 3px;
        color: var(--level-7);
        font-weight: 700;
        font-family: fonts.$eduate-font-family;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__block {
        height: calc(100% - 120px);
        margin-bottom: 0px !important;
        overflow: auto;
        li {
            display: flex;
            align-items: center;
            column-gap: 10px;
            justify-content: space-evenly;
            margin-top: 10px;
        }
        &--grade {
            height: 40px;
            width: 100px;
            background-color: var(--disabled-color);
            border-radius: 6px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-family: fonts.$eduate-number-font;
        }
        &--perc {
            @extend .grade__block--grade;
            display: flex;
            justify-content: center;
            img {
                width: 20px;
                margin-right: 0px;
                margin-left: 20px;
            }
        }
    }
    &__edit {
        height: calc(100% - 170px);
        margin: 0px 20px;
        &--modal {
            height: calc(100% - 30px);
        }
        &--frame {
            column-gap: 40px;
            margin-top: 10px;
            height: calc(100% - 50px);
            &--left {
                height: 100%;
                border-radius: 0px 30px 0px 0px;
                border: 1px solid var(--border-color);
                
            }
            &--right {
                height: 100%;
                border-radius: 30px 0px 0px 0px;
                border: 1px solid var(--border-color);
                
            }
        }
        &--list{
            padding: 10px;
            height: 100%;
            &--tableblock{
                margin-top: 10px;
                height: calc(100% - 30px);
            }
            &--table{
                @include table();
            }
        }
        &--add{
            padding:10px;
            .storybook-input{
                width:80px !important;
            }
        }
      
    }
    &__subtitle{
        display: flex;
        height:30px;
        align-items: center;
        margin-top: 10px;
        justify-content: space-evenly;


    b{
        font-size: 16px;
        font-family: fonts.$eduate-font-family;
        color: colors.get-color(colors.$cool_grey, level_6);
        width:150px;
        text-align: center;
    }
    }
}
.grade__block::-webkit-scrollbar {
    @include scrollbar();
}

.grade__block::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.grade__block::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}
