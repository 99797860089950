
@use "../../../../scss/common/mixins" as *;

.monthly-wise-ledger {
  &__textfield {
    @include textfield();
    &--edit {
      @include textfield();
      margin-top: 10px !important;
      width: 100%;
    }
    &--date {
      @include textfield();
      margin: 6px 10px 0px 0px !important;
      width: fit-content;
    }
    &--select {
      margin-top: 5px !important;
    }
  }
}
