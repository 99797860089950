@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.daily-diary-update {
  height: 100%;

  &__title {
    img {
      @include closeIconInModal();
    }
  }

  &__datablock {
    height: calc(100% - 85px);
    margin-top: 10px;

    b {
      font-family: fonts.$eduate-font-family;
      color: var(--text-color);
      font-weight: 700;
      font-size: 16px;
    }

    .storybook-textarea {
      height: calc(100% - 70px) !important;
    }
  }
}
