@use "../../../scss/common//mixins" as *;
@use "../../../scss/common/fonts" as fonts;
@use "../../../scss/common/colors" as colors;
.vms-staff-details {
  margin: 0px 20px;
  height: calc(100% - 160px);

  &__select {
    column-gap: 10px;

    .MuiAutocomplete-root {
      margin-top: 10px;
    }

    &--addnew {
      display: flex !important;
      justify-content: flex-end !important;
      margin-left: auto !important;
      margin-right: 0px !important;
    }
  }

  &__tableblock {
    height: calc(100% - 105px);
    margin-top: 10px;
  }

  &__table {
    @include table();

    tr:hover {
      .vms-staff-details__table--emp-id {
        @include tdContainsOnclick();
      }
    }

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--emp-id {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--number {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--date {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--degn {
      width: calc(100% - 94%);
    }

    &--license-no {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }
    &--mobile-no {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--actions {
      width: calc(100% - 96%);
    }
  }
}
