@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.detained-student-list {
  height: calc(100% - 130px);
  margin: 0px 20px 0px 20px;
  &__details {
    height: 130px;
    column-gap: 20px;

    &--image-field {
      display: flex;
      .MuiAutocomplete-root {
        margin-top: 6px !important;
      }
      .data-fetch-icon {
        margin-top: 7px !important;
      }
    }
    &--textfield {
      @include textfield();
      margin: 8px 0px 0px 0px !important;
      &--year {
        @extend .detained-student-list__details--textfield;
        input.Mui-disabled {
          -webkit-text-fill-color: var(--level-4) !important;
        }
      }
    }
    &--profile {
      @include studentProfile();
    }
  }
  &__block {
    margin-top: 30px;
    height: calc(100% - 160px);
  }
  &__view {
    column-gap: 10px;
    margin-top: 10px;
    &--search {
      display: flex;
      gap: 10px;
    }
    Input {
      margin-top: 0px !important;
    }
    &--select-flex {
      display: flex;
      gap: 10px;
    }
    &--table {
      @include table();
      margin-top: 10px;
      height: calc(100% - 120px);
      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }
      &--admno {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }
      &--regno {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }
      &--year {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-slno {
        width: calc(100% - 96%);
      }
      &--th-admno {
        width: calc(100% - 88%);
      }
      &--th-regno {
        width: calc(100% - 88%);
      }
      &--th-year {
        width: calc(100% - 92%);
      }
      &--name {
        width: calc(100% - 80%);
      }
    }
  }
}
