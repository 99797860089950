@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.new-book {
  margin: 0px 10px 0px 10px;
  height: calc(100% - 185px);
  &__row {
    margin: 10px;
    height: calc(100% - 45px);
    column-gap: 20px;
  }
  &__span-2 {
    column-count: 2;
    column-gap: 20px;
    &--grid {
      .label-grid {
        display: grid;
        grid-template-columns: 1.4fr 1fr;
      }
    }
  }
  &__place {
    .label-grid {
      display: grid;
      grid-template-columns: 0.79fr 2fr;
    }
  }
  &__vendor-details {
    column-gap: 25px;
    .label-grid {
      display: grid;
      grid-template-columns: 1.4fr 1fr;
    }
  }

  &__label-grid {
    display: grid;
    grid-template-columns: 0.79fr 2fr !important;
    .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
      right: 10px !important;
    }
  }
  &__image-grid {
    display: grid;
    grid-template-columns: 0.8fr 1.85fr 0.15fr;
    .data-fetch-icon {
      margin-top: 10px;
    }
  }
  &__frame {
    margin-top: 10px;
    height: calc(100% - 20px);
    overflow-y: auto;
    padding-left: 5px;
    padding-right: 5px;
    &--total {
      margin-top: 10px;
      height: calc(100% - 60px);
      overflow-y: auto;
    }
  }
  &__total {
    text-align: end;
    padding: 5px;
    border-radius: 6px !important;
    background: colors.get-color(colors.$Green, level_6) !important;
    color:colors.get-color(colors.$colors, white);
  }
  &__two-column-grid {
    .label-grid {
      display: grid;
      grid-template-columns: 1.3fr 1fr !important;
    }
  }
}

.new-book__frame::-webkit-scrollbar,
.new-book__frame--total::-webkit-scrollbar {
  @include scrollbar();
}

.new-book__frame::-webkit-scrollbar-thumb,
.new-book__frame--total::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.new-book__frame::-webkit-scrollbar-thumb:hover,
.new-book__frame--total::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
