@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.feedemand {
  &__select {
    padding: 5px;
  }

  &__row {
    @include selectTableRow();
    @include autoCompleteInTableRow();

    .MuiAutocomplete-root,
    .auto-complete:focus {
      background-color: var(--bg-color) !important;
    }

    input {
      &:focus {
        background-color:colors.get-color(colors.$colors, white);
      }
    }

    td {
      color: colors.get-color(colors.$colors, black) !important;
    }
  }

  &__table {
    height: 100%;
    @include Accountstable();

    select {
      @include selectInTable();
    }

    &--slno {
      width: calc(100% - 95%);
      font-family: fonts.$eduate-number-font;
    }

    &--amount {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-slno {
      width: calc(100% - 95%);
    }

    &--th-amount {
      width: calc(100% - 92%);
    }

    &--actions {
      width: calc(100% - 95%);

      img {
        width: 20px;
      }
    }

    tfoot {
      position: sticky;
      position: -webkit-sticky;
      bottom: 0px;
    }
  }

  &__tableblock {
    height: calc(100% - 355px);
    overflow: auto;
    margin: 15px 20px 0px 20px;

    &--edit {
      @extend .feedemand__tableblock;
      height: calc(100% - 395px);
    }
  }

  &__editprofile {
    filter: invert(1);
    margin-right: 10px;
    width: 15px;
  }

  &__inputbase {
    font-size: 14px !important;
    text-align: right !important;
    background: hsl(55, 79%, 94%);

    &:focus {
      background-color: white;
    }
  }

  tfoot td {
    display: flex;
    justify-content: flex-end;
    margin-top: 100px;
  }

  &__block {
    height: 130px;
    margin: 0px 10px 0px 10px;

    Label {
      margin-top: 5px;
      padding: 0px;
    }

    .MuiFormControl-root {
      margin-top: 3px !important;
    }

    &--edit {
      @extend .feedemand__block;
      height: 170px;
    }
    .MuiAutocomplete-root{
      margin-top: 0px !important;
    }
  }

  &__buttons {
    margin-left: 20px;
    display: flex;
    justify-content: space-between;

    .raise-demand-btn {
      margin-right: 20px;
    }
  }

  &__checkboxes {
    column-count: 5;
    column-gap: 20px;

    label {
      width: 100%;
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      color: var(--text-color);
      font-family: fonts.$eduate-font-family;

      &:hover {
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }
  }

  &__categorydetails {
    height: calc(100% - 35px);
    overflow: auto;
    padding-left: 10px;

    .all-select {
      font-weight: bold;
      margin-left: 10px;
      font-size:fonts.$eduate-default-font-size;
      color: var(--text-color);
      font-family: fonts.$eduate-font-family;
    }
  }

  &__extend-date {
    .MuiFormControl-root {
      margin-top: 10px !important;
    }

    &--textfield {
      @include textfield();
      width: fit-content !important;
    }
  }
}

.table__input {
  border-bottom: 1px solid white !important;
  border: none !important;
}

.MuiTypography-root {
  font-family: fonts.$eduate-font-family;
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: var(--text-color);
}

.MuiFormControlLabel-root {
  margin-left: 0px !important;
}

.MuiButtonBase-root-MuiCheckbox-root {
  padding: 2px !important;
}

.feedemand__table::-webkit-scrollbar,
.feedemand__categorydetails::-webkit-scrollbar {
  @include scrollbar();
}

.feedemand__table::-webkit-scrollbar-thumb,
.feedemand__categorydetails::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.feedemand__table::-webkit-scrollbar-thumb:hover,
.feedemand__categorydetails::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

.copy-demand {
  &__label-grid {
    display: grid;
    grid-template-columns: 0.8fr 3fr;
    padding: 5px;

    .MuiAutocomplete-root {
      width: 100%;
    }
  }

  &__tableblock {
    height: calc(100% - 130px);
    overflow-y: auto;
    margin-top: 10px;
  }

  &__table {
    @include Accountstable();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--fee {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--fee {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--date {
      width: calc(100% - 88%);
    }

    &--th-slno {
      width: calc(100% - 96%);
    }

    &--th-fee {
      width: calc(100% - 88%);
    }

    &--th-date {
      width: calc(100% - 88%);
    }
  }

  &__button {
    margin-top: 10px;
    display: flex;
    margin-left: auto !important;
    margin-right: 10px !important;
  }
}
