@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
  .customized-calendar {
      height: 100%;

      &--month {
          display: flex;
          justify-content: space-between;
          gap: 20px;

          img {
              width: 25px;
              height: 25px;
          }
      }

      &--grid-container {
          height: calc(100% - 30px);
          width: 100%;
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          grid-template-rows: auto auto auto auto auto auto auto;

          gap: 5px;


      }

      &--day {
          text-align: center;
          font-family: fonts.$eduate-font-family;
          font-size:fonts.$eduate-default-font-size;
          display: flex;
          align-items: center;
          justify-content: center;


      }

      &--grid-item {
          border-radius: 3px;
          border: 1px solid var(--border-color);
          display: flex;
          align-items: center;
          position: relative;
          justify-content: center;

          ul {
              margin-bottom: 0px !important;
          }

          &:hover {
              background-color: var(--level-2);

              .customized-calendar--date {
                  font-weight: 700;
              }


          }
      }

      &--date {
          font-family: fonts.$eduate-number-font;
          font-size: 12px;
      }

      &--legends {
          display: flex;
          height: 30px;
          margin-top: 10px;
          column-gap: 10px;

          &--box {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 30px;
              width: 30px;
              border: 1px solid var(--border-color);
              border-radius: 3px;

              &--date {
                  font-size: 12px;
                  font-family: fonts.$eduate-number-font;
              }

              &--text {
                  font-size: 12px;
                  font-family: fonts.$eduate-font-family;
                  margin-top: 6px;
              }

              &--vacations {
                  @extend .customized-calendar--date;
                  position: absolute;
                  left: 0;
                  right: 0;
                  top: 50%;
                  border-top: 2px solid red;

                  span {
                      position: relative;
                      z-index: 1;
                  }
              }

          }

          &--outline {
              display: flex;
              column-gap: 2px;


          }

          &--vacations {
              @extend .customized-calendar--legends--box;
              position: relative;


              &--date {
                  font-size: 12;
                  font-family: fonts.$eduate-number-font;
              }


          }

          &--holiday {
              @extend .customized-calendar--legends--box;
              border-left: 2px solid red;
          }

          &--marked {
              @extend .customized-calendar--legends--box;
              background-color: colors.get-color(colors.$Green, level_5)
          }

          &--un-marked {
              @extend .customized-calendar--legends--box;
              background-color: colors.get-color(colors.$red, level_6)
          }

          &--events {
              @extend .customized-calendar--legends--box;

              &--dot {
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;
                  background-color: colors.get-color(colors.$sky, level_7);
                  margin-top: 5px;
              }
          }

          &--present-day {
              @extend .customized-calendar--legends--box;
              background-color: var(--level-2);

              .customized-calendar--legends--box--date {
                  font-weight: 700;
                  color: var(--level-6);
              }

          }
      }
  }

  .events-in-calendar {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-top: 14px;
      margin-left: -2px;


  }

  .weekends-in-calendar {
      @extend .customized-calendar--date;
      color: colors.get-color(colors.$red, level_7) !important;
      font-weight: 700;
  }

  .holidays-in-calendar {
      @extend .customized-calendar--grid-item;
      border-left: 3px solid red !important;
  }

  .vacations-in-calendar {
      @extend .customized-calendar--date;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      border-top: 2px solid red;

      span {
          position: relative;
          z-index: 1;
      }
  }

  .present-date-in-calendar {
      @extend .customized-calendar--date;
      color: var(--level-7);
      font-weight: 700;

  }

  .present-day-in-calendar {
      @extend .customized-calendar--grid-item;
      background-color: var(--level-2);

  }