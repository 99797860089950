@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.monthly-attendance-list {
  height: calc(100% - 180px);
  margin: 10px 20px 0px 20px;

  &__tableblock {
    height: calc(100% - 40px);
  }

  &__table {
    @include table();

    tr:hover {
      .monthly-attendance-list__table--month {
        @include tdContainsOnclick();
      }
    }

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-slno {
      width: calc(100% - 96%);
    }

    &--date {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-date {
      width: calc(100% - 92%);
    }

    &--actions {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--status {
      width: calc(100% - 90%);
    }
  }

  &__textfield {
    @include textfield();
    margin: 10px 0px 0px 0px !important;
  }

  &__days {
    column-gap: 10px;
  }

  &__view {
    height: 100%;

    &--status {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
      color: colors.get-color(colors.$Green, level_5);
    }

    &--form-labels {
      margin-top: 10px;

      .MuiFormControlLabel-root {
        margin-left: 5px !important;
      }

      .MuiTypography-root {
        font-weight: bold !important;
      }
    }

    &--label-grid {
      display: grid;
      grid-template-columns: 1fr 2fr;
    }

    .MuiSwitch-root {
      left: 15px !important;
    }
  }
}
