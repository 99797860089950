
.logo {
  &__upload {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;

    position: static;
    width: 259.5px;
    height: 40px;
    left: 0px;
    top: 326px;

    /* #553C9A */

    background: #553c9a;
    border-radius: 30px;

    /* Inside Auto Layout */

    flex: none;
    order: 7;
    flex-grow: 0;
    margin: 10px 0px;
  }
}
