@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.student-enquiry {
  height: calc(100% - 15px);
  margin-top: 15px;

  .buttons-flex {
    display: flex;
    justify-content: flex-end;
  }

  .student-add-button {
    margin: 0px;
  }

  .student-clear-button {
    margin: 0px 10px 0px 0px;
  }

  &--modal {
    height: calc(100% - 75px);

    .buttons-flex {
      display: flex;
      justify-content: flex-end;
    }

    .student-clear-button {
      margin: 0px;
    }
  }

  &__options {
    column-gap: 10px;
    margin-top: 5px;
    &--filters {
      display: flex;
      column-gap: 10px;
    }
  }

  &__search {
    input {
      margin-top: 0px;
    }
  }

  &__frame {
    height: calc(100% - 88px);
    margin-top: 10px;

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-slno {
        width: calc(100% - 96%);
      }

      &--inst {
        width: calc(100% - 88%);
      }

      &--desc {
        width: calc(100% - 93%);
      }

      &--mobile {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-mobile {
        width: calc(100% - 92%);
      }

      &--email {
        width: calc(100% - 90%);
      }

      &--status {
        width: calc(100% - 96%);
      }

      &--action {
        width: calc(100% - 92%);
      }
    }

    &--modal {
      height: calc(100% - 45px);
      margin-top: 10px;
    }
  }

  &__form {
    height: calc(100% - 30px);

    &--data {
      height: calc(100% - 45px);
      overflow: auto;
      column-gap: 30px;
      padding-right: 10px;

      .MuiAutocomplete-endAdornment {
        right: 10px !important;
      }

      &--gender {
        display: grid;
        grid-template-columns: 1fr 2fr;
      }

      &--logo-grid {
        display: grid;
        grid-template-columns: 0.8fr 0.8fr 0.6fr 0.6fr;
        gap: 10px;

        .storybook-button {
          margin: 10px 0px 0px 0px;
        }

        &--browse {
          @include browseFiles();
        }
      }

      .label-grid {
        display: grid;
        grid-template-columns: 1fr 2fr;
      }

      &--label-grid {
        display: grid;
        grid-template-columns: 1fr 2fr;
        margin-top: 10px;
      }
    }

    &--textfield {
      @include textfield();
      margin: 10px 0px 0px 0px !important;
    }
  }

  &__total {
    display: flex;
    justify-content: flex-end;
  }

  &__success {
    font-family: fonts.$eduate-font-family;
    display: flex;
    gap: 20px;
  }
}

.student-enquiry__form--data::-webkit-scrollbar {
  @include scrollbar();
}

.student-enquiry__form--data::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.student-enquiry__form--data::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
