@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.book-search {
  height: calc(100% - 235px);
  margin: 10px 20px 0px 20px;
  &--modal {
    height: calc(100% - 80px);
    margin: 10px 0px 0px 0px;
  }
  &__details {
    column-gap: 10px;
    &--input {
      margin-top: 0px !important;
    }
  }
  &__tableblock {
    height: calc(100% - 40px);
    margin-top: 10px;
  }
  &__table {
    @include table();
    tbody {
      tr:hover {
        td {
          @include tdContainsOnclick();
        }
      }
    }
    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-slno {
      width: calc(100% - 96%);
    }
    &--th-number {
      width: calc(100% - 94%);
    }
    &--number {
      width: calc(100% - 94%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-clsno {
      width: calc(100% - 95%);
    }
    &--clsno {
      width: calc(100% - 95%);
      font-family: fonts.$eduate-number-font;
    }
    &--desc {
      width: calc(100% - 94%);
    }
  }
}
