@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.academics-test-report {
  @include mainFrameWithTitle();

  &__select {
    column-gap: 10px;
  }

  &__tabs {
    margin-top: 10px;
    @include muiTabs();
    width: fit-content;
  }

  &__swipable {
    height: calc(100% - 85px);

    &--tabs {
      height: calc(100% - 10px);
    }

    .react-swipeable-view-container,
    .MuiTypography-body1 {
      height: 100% !important;
    }
  }

  &__search {
    margin-top: 10px;

    #search {
      margin-top: 0px;
    }
  }

  &__datablock {
    height: calc(100% - 85px);
    overflow: auto;

    &--grids {
      display: grid;
      grid-template-columns: auto auto auto auto;
      column-gap: 20px;
      padding-top: 10px;

      &--cards {
        @include blocksInDashboard();
        height: 140px;

        &:hover {
          .academics-test-report__datablock--grids--cards--button {
            display: flex;
            justify-content: center;
          }
        }

        margin-bottom: 20px;

        &--title {
          display: flex;
          justify-content: center;
          font-family: fonts.$eduate-font-family;
          font-size: 16px;
          gap: 10px;
        }

        &--date {
          display: flex;
          justify-content: center;
          gap: 20px;
          font-size: 12px;
          font-family: fonts.$eduate-number-font;
        }

        &--status {
          display: flex;
          justify-content: space-between;

          &--separator {
            color: colors.get-color(colors.$cool_grey, level_4);
            font-weight: 700;
          }

          &--present {
            margin: auto;

            b {
              font-family: fonts.$eduate-font-family;
              font-size:fonts.$eduate-default-font-size;
              display: block;
            }

            span {
              display: block;
              text-align: center;
              font-weight: 700;
              font-family: fonts.$eduate-number-font;
              font-size:fonts.$eduate-default-font-size;
            }
          }
        }

        &--button {
          display: none;
          height: 40px;
        }
      }
    }
  }

  &__filters {
    display: flex;

    .MuiAutocomplete-root {
      top: 10px;
      position: relative;
    }

    .subtitle1 {
      top: 10px;
      position: relative;
    }
  }
}