@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/colors" as colors;
@use "../../../../../scss/common/fonts" as fonts;
.consolidated-pay-modal {
  height: calc(100% - 100px);

  &--balance {
    display: block;
    font-size: 16px;
    font-family: fonts.$eduate-font-family;
    text-align: center;
    margin-top: 30px;

    color: var(--text-color);
  }
  &--balance-amt {
    @extend .consolidated-pay-modal--balance;
    font-size: 18px;
    font-family: fonts.$eduate-number-font;
    color: colors.get-color(colors.$colors, red);
  }
  &--paying-amt {
    @extend .consolidated-pay-modal--balance;
    font-size: 18px;
    font-family: fonts.$eduate-number-font;
    color: colors.get-color(colors.$colors, green);
  }
  &--textfield {
    @include textfieldInForms();
    margin-top: 30px !important;
  }
  &--buttons {
    display: flex;
    justify-content: center;
  }
}
