@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.std-report__agewise {
  height: 100%;

  &--filters {
    .MuiAutocomplete-root {
      margin-right: 10px !important;
      width: calc(100% - 10px) !important;
    }

    &--flex {
      display: flex;
      column-gap: 10px;
    }

    &--textfield {
      @include textfield();
      background-color: colors.get-color(colors.$yellow, level_2);
      width: calc(100% - 10px) !important;

      label {
        color: var(--text-color) !important;
      }

      input {
        font-weight: 400 !important;
      }
    }
  }

  &--search {
    margin-top: 10px;

    .MuiAutocomplete-root {
      margin-right: 10px !important;
      width: calc(100% - 10px) !important;
    }

    .storybook-input {
      margin-top: 0px !important;
      margin-right: 10px;
      width: calc(100% - 10px) !important;
    }
  }

  &--tableblock {
    margin-top: 10px;
    height: calc(100% - 115px);
  }

  &--table {
    @include table();

    &--slno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--number {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--age {
      width: calc(100% - 95%);
      font-family: fonts.$eduate-number-font;
    }
  }

  &--total {
    text-align: right;
  }
}
