.class-wise-performance {
  height: calc(100% - 30px);
  &__graph {
    height: calc(100% - 80px);
    .echarts-for-react {
      height: 100% !important;
    }
  }
  &__next {
    display: flex;
    justify-content: flex-end;
    img {
      width: 30px;
    }
  }
}
