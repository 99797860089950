@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/colors" as colors;
@use "../../../../../scss/common/fonts" as fonts;
.test-wise-view{
    @include mainFrameWithTitle();
    &__select{
        column-gap: 10px;
    }
    &__title{
        display: flex;
        column-gap: 10px;
        b{
            color: var(--level-6);
            font-size: 16px;
            font-family: fonts.$eduate-font-family;
        }
        span{
            font-size: 14px;
            font-family: fonts.$eduate-font-family;
            color: colors.get-color(colors.$cool_grey, level_6);
        }
    }
    &__tableblock{
        margin-top: 10px;
        height: calc(100% - 110px );
    }
    &__table{
        @include table();
        &--actions{
            width: calc(100% - 82%);

        }
        &--view{
            @include viewButton();
        }
        &--toppers{
            background-color: var(--bg-color) !important;
            width: calc(100% - 50%);
            ul{
                margin-bottom: 0px;
            }
            li {
                border-radius: 30px;
                padding: 5px 10px;
                display: flex;
                background-color: colors.get-color(colors.$colors, snow-white);
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin-top: 5px;
                img {
                    width: 30px;
                }
            }
            &--l {
                display: flex;
                column-gap: 10px;
                align-items: center;
                &--name {
                    b {
                        font-family: fonts.$eduate-font-family;
                        font-size:fonts.$eduate-default-font-size;
                    }
                    span {
                        display: block;
                        font-family: fonts.$eduate-number-font;
                        color: colors.get-color(colors.$grey, level_4) !important;
                        font-size: 12px;
                    }
                }
            }
            &--perc {
                color: var(--level-6);
                font-size: 16px;
                font-family: fonts.$eduate-number-font;
            }
        }
        &--actions{
            width: calc(100% - 92%);
        }
    }
    &__multiple{
        height: 100%;
        &--title{
            img{
            @include closeIconInModal();
            }
        }
        &--ul{
            margin-bottom: 0px !important;
            height: calc(100% - 205px);
            margin-top: 10px;
            overflow: auto;

            li{
                display: flex;
                column-gap: 10px;
                margin-top: 10px;
                padding-bottom: 10px;
                align-items: center;
                border-bottom: 1px dashed var(--border-color);


            }
            &--std-details{
            span{
                display: block;
                font-size: 14px;
                font-family: fonts.$eduate-font-family;
            }
            b{
                font-family: fonts.$eduate-font-family;
                font-size: 14px;
            }

            }
        }
        &--grade{
            height:80px;
            width:100px;
            display: block;
            margin-left: auto;
            margin-right: auto;
            img{
                width: 40px;
                display: block;
            }
            b{
                color: var(--level-6);
                font-size: 16px;
                font-family:fonts.$eduate-number-font;
            }
            span{
                font-size: 14px;
                font-family: fonts.$eduate-font-family;
                color: colors.get-color(colors.$cool_grey, level_6);
                display: block;
            }
        }
        &--select{
            column-gap: 10px;
            margin-top: 10px;
            &--textfield{
                @include textfield();
            }

        }
    }
}
.test-wise-view__multiple--ul::-webkit-scrollbar {
    @include scrollbar();
    background-color: rgba(255, 255, 255, 0.3) !important ;
}

.test-wise-view__multiple--ul::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.test-wise-view__multiple--ul::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}