@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
.assign-salary-structure {
  height: calc(100% - 175px);
  margin: 0px 20px 0px 20px;
  &__details {
    height: 125px;
    column-gap: 20px;
    border-bottom: 2px dashed var(--border-color);
    .MuiAutocomplete-root {
      margin-top: 6px !important;
    }
    &--textfield {
      @include textfield();
      margin-top: 8px !important;
      &--date {
        @include textfield();
        width: fit-content !important;
        margin-top: 8px !important;
      }
    }
    &--select {
      margin-top: 10px;
    }
    &--imagefield {
      display: flex;
      .data-fetch-icon {
        margin-top: 8px;
      }
    }
    &--image {
      height: 120px;
      @include studentProfile();
    }
  }
  &__datablock {
    height: calc(100% - 170px);
    margin-top: 10px;
  }
  &__tableblock {
    height: calc(100% - 35px);
    margin-top: 10px;
  }
  &__table {
    @include table();
    @include inputReportsTable();

    &--th-slno {
      width: calc(100% - 96%);
    }
    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--desc {
      width: calc(100% - 88%);
    }
    &--amount {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    &--th-amount {
      width: calc(100% - 90%);
    }
    &--actions {
      width: calc(100% - 97%);
    }
    tfoot td {
      border-style: none !important;
    }
    Input {
      text-align: right;
      padding-right: 5px;
      margin: 0px !important;
      background-color: var(--level-2);
      &:focus {
        background-color: var(--bg-color);
      }
    }
  }
  &__edit-rule {
    margin-top: 10px;
    height: calc(100% - 80px);
    &--grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  &__textfield {
    width: 100%;
    @include textfield();
    input {
      text-align: right;
      font-weight: 400 !important;
      &:disabled {
        font-weight: 700 !important;
      }
    }
  }
}

.assign-salary-structure__table::-webkit-scrollbar {
  @include scrollbar();
}

.assign-salary-structure__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.assign-salary-structure__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
