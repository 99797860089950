
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.purchase-entry-masters {
    @include mainFrameWithTitle();

    &__modal {
        height: calc(100% - 30px);
    }

    &__datablock {
        height: calc(100% - 55px);
        column-gap: 40px;
        margin-top: 10px;

        &--frame-left {
            border: 1px solid colors.get-color(colors.$colors, cloud-white);
            padding: 10px 10px 5px 10px;
            height: 100%;
            border-radius: 0px 40px 0px 0px;
        }

        &--frame-right {
            border-radius: 40px 0px 0px 0px;
            @extend .purchase-entry-masters__datablock--frame-left;
        }

        &--title {
            .storybook-input {
                margin-top: 0px !important;
            }
        }

    }

    &__tableblock {
        height: calc(100% - 40px);
        margin-top: 10px;
    }

    &__table {
        @include table();
        margin-top: 10px;
        height: calc(100% - 10px);

        &--slno {
            width: calc(100% - 92%);
            font-family: fonts.$eduate-font-family;
        }

        &--actions {
            width: calc(100% - 92%);
        }
    }

    &__grid-trio {
        display: grid;
        grid-template-columns: 0.44fr 1fr 0.1fr;

        .data-fetch-icon {
            margin-top: 10px;
        }
    }
}