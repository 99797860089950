@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
.maker-checker-list {
  margin: 0px 20px;
  height: calc(100% - 165px);

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 95px);
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--date {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--number {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--amount {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--type {
      width: calc(100% - 88%);
    }

    &--name {
      width: calc(100% - 88%);
    }

    tr:hover {
      .maker-checker-list__table--name {
        @include tdContainsOnclick();
      }
    }
  }

  &__select {
    column-gap: 10px;

    .MuiAutocomplete-root {
      margin-top: 10px;
    }
  }

  &__textfields {
    display: flex;
    column-gap: 10px;

    &--date {
      @include textfieldInForms();
      width: fit-content !important;
    }
  }
}
