@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.transport-route-details {
  margin: 0px 20px 0px 20px;
  height: calc(100% - 170px);
  &__data {
    height: 120px;
    &--label-grid {
      display: grid;
      grid-template-columns: 0.8fr 2fr 0.2fr;
      .data-fetch-icon {
        margin-top: 10px;
      }
    }
    &--image {
      display: block;
      margin: auto;
    }
  }
  &__tableblock {
    height: calc(100% - 170px);
    margin-top: 10px;
  }
  &__table {
    @include table();
    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-slno {
      width: calc(100% - 96%);
    }
    &--ledger {
      width: calc(100% - 90%);
    }
    &--actions {
      width: calc(100% - 90%);
    }
  }
  &__view {
    height: calc(100% - 30px);
    &--block {
      height: calc(100% - 40px);
      &--table {
        @include table();
      }
      &--tableblock {
        height: calc(100% - 70px);
      }
    }
  }
  &__delete {
    height: calc(100% - 30px);
    &--tableblock {
      height: calc(100% - 85px);
      margin-top: 10px;
    }
    &--table {
      @include table();
    }
  }
}
