@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.extend-feedemand {
  height: calc(100% - 30px);
  &__button {
    display: flex;
    justify-content: flex-end;
    .storybook-button {
      margin: 0px 0px 0px 0px !important;
    }
  }
  &__options {
    margin-top: 10px;
    column-gap: 10px;
  }

  &__table {
    height: calc(100% - 95px);
    margin-top: 10px;
    &--input {
      .MuiInputBase-root {
        margin: 2px !important;
      }
      .MuiInputBase-input {
        padding: 2px 14px !important;
      }
    }
  }
}
