
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.bank-reconcilation {
  display: flex;
  height: calc(100% - 130px);
  gap: 20px;
  margin: 0px 20px 0px 20px;
  &__listblock {
    width: 250px;
    box-shadow: 2px 2px 7px rgba(80, 80, 80, 0.5);
  }
  &__tableblock {
    width: calc(100% - 270px);
    &--monthly-total {
      height: 50%;
      box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
    }
    &--transaction-details {
      margin-top: 10px;
      box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
      height: calc(50% - 10px);
      &--table {
        @include table();
        height: calc(100% - 88px);

        &--date {
          width: calc(100% - 92%);
          font-family: fonts.$eduate-number-font;
        }
        &--voucher-type {
          width: calc(100% - 86%);
        }
        &--voucher-number {
          width: calc(100% - 86%);
          font-family: fonts.$eduate-number-font;
        }
        &--amount {
          width: calc(100% - 84%);
          font-family: fonts.$eduate-number-font;
        }
      }
      &--title {
        display: flex;
        justify-content: space-between;
        &--textfield {
          @include textfield();
          width: fit-content !important;
        }
        .LabelPrimary {
          margin-top: 0px !important;
        }
        input[type="checkbox"] {
          margin-right: 10px;
        }
      }
    }
  }
}
