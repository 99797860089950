@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.transport-dashboard {
 @include mainFrameWithTitle();
  &__blocks {
    column-gap: 20px;
    margin-top: 10px;
    &--cards {
      @include blocksInDashboard();
      height: 120px;
      margin-bottom: 10px;
      &--title {
        display: flex;
        justify-content: space-between;
      }
      &--images {
        display: flex;
        justify-content: space-between;
      }
      &--total-students {
        font-family: fonts.$eduate-number-font;
        color: colors.get-color(colors.$purple, level_6);
        text-align: right;
        margin-top: auto;
        margin-bottom: auto;
        font-weight: bold;
        font-size: 16px;
      }
      &--total-routes {
        @extend .transport-dashboard__blocks--cards--total-students;
        color: colors.get-color(colors.$sky, level_6);
      }
      &--total-stages {
        @extend .transport-dashboard__blocks--cards--total-students;
        color:colors.get-color(colors.$amber, level_6);
      }
      &--routes-not-assigned {
        @extend .transport-dashboard__blocks--cards--total-students;
        color: colors.get-color(colors.$red, level_6);
      }
    }
  }
  &__select {
    column-gap: 10px;
    margin-top: 10px;
    .storybook-input {
      margin-top: 0px !important;
    }
  }
  &__tableblock {
    height: calc(100% - 225px);
    margin-top: 10px;
  }
  &__table {
    @include table();
    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--actions {
      width: calc(100% - 96%);
      img {
        width: 25px;
        filter: invert(0%);

        &:hover {
          background:colors.get-color(colors.$colors, white);
          border-radius: 50%;
        }
      }
    }
    &--admno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }
    &--desc {
      width: calc(100% - 92%);
    }
  }
}
