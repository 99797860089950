@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.test-list-view{
    @include mainFrameWithTitle();
    &__filters{
        column-gap: 10px;
        margin-top: 10px;
    }
    &__title{
        display: flex;
        column-gap: 10px;
        margin-top: 5px;
        b{
            color: var(--level-6);
            font-size: 16px;
            font-family: fonts.$eduate-font-family;
        }
        span{
            font-size: 14px;
            font-family: fonts.$eduate-font-family;
            color: colors.get-color(colors.$cool_grey, level_6);
        }
    }
    &__tableblock{
        height: calc(100% - 125px);
        margin-top:10px;
    }
    &__table{
        @include table();
    }
}