@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.elective-sub {
 @include mainFrameWithTitle();
  &__options {
    column-gap: 10px;
    .MuiAutocomplete-root {
      margin-top: 10px;
    }
  }
  &__tableblock {
    height: calc(100% - 120px);
    margin-top: 10px;
    
  }
  &__table {
    @include table();
    @include tableCheckbox();
  }
  &__move-to {
    height: calc(100% - 30px);
    padding: 0px 20px;
    &--tableblock {
      height: calc(100% - 95px);
      margin-top: 10px;
    }
  }
}
