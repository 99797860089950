@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.edu-global-configuration {
  height: calc(100% - 250px);
  margin: 10px 20px 0px 20px;
  &__modal {
    @extend .edu-global-configuration;
    height: calc(100% - 35px);
    margin: 0px !important;
  }
  .MuiFormControlLabel-root {
    margin-right: 0px !important;
  }
  .MuiTypography-root {
    color:colors.get-color(colors.$colors, white);
    margin-top: 3px;
  }
  .MuiSwitch-root {
    margin-top: 8px;
  }
  .Label {
    margin-top: 5px;
  }
  .select {
    margin-top: 5px !important;
  }
  &__data {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 50px;
    -moz-column-gap: 50px;
    column-gap: 50px;
    column-rule: 4px dotted var(--border-color);
    &--parameters {
      display: grid;
      grid-template-columns: 1fr 1fr;
      border-bottom: 1px dashed var(--border-color);
      padding-bottom: 5px;
    }
  }
  &__block {
    height: calc(100% - 50px);
    overflow-y: auto;
    margin-top: 10px;

    .MuiAccordion-region {
      height: 100%;
    }
    .MuiCollapse-wrapperInner {
      height: 100% !important;
    }

    &--details {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
      column-fill: auto;
      -webkit-column-gap: 50px;
      -moz-column-gap: 50px;
      column-gap: 50px;
      column-rule: 4px dotted var(--border-color);
      height: 100%;
    }
  }
}

.edu-global-configuration__block::-webkit-scrollbar {
  @include scrollbar();
}

.edu-global-configuration__block::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.edu-global-configuration__block::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
