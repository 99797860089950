@use "../../../scss/common//mixins" as *;
@use "../../../scss/common/fonts" as fonts;
.vms-supplier {
  @include mainFrameWithTitle();

  &__addnew {
    display: flex !important;
    justify-content: flex-end !important;
    margin-left: auto !important;
    margin-right: 0px !important;
  }

  &__tableblock {
    height: calc(100% - 100px);
    margin-top: 10px;
  }

  &__table {
    @include table();

    tr:hover {
      .vms-supplier__table--name {
        @include tdContainsOnclick();
      }
    }

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--number {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--actions {
      width: calc(100% - 96%);
    }
  }

  &__add {
    height: calc(100% - 70px);
  }
}
