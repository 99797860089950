@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.emp-attendance-overview {
  @include mainFrameWithTitle();

  .MuiDrawer-paperAnchorRight {
    top: 95px !important;
    bottom: 0px !important;
    width: 30% !important;
    border-radius: 6px 0px;
  }

  &__data {
    height: calc(100% - 55px);
    column-gap: 40px;
    margin-top: 10px;
  }

  &__blocks {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 20px;

    &--cards {
      @include blocksInDashboard();
      height: 70px;

      b {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-number-font;
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }

      &--present {
        color: colors.get-color(colors.$Green, level_4);
      }

      &--absent {
        color: colors.get-color(colors.$red, level_5);
      }
    }
  }

  &__tableblock {
    height: calc(100% - 120px);
    margin-top: 10px;
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--admno {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--regno {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--status {
      width: calc(100% - 90%);
    }

    tr:hover {
      .attendance-overview__table--name {
        @include tdContainsOnclick();
      }
    }
  }

  &__rightblock {
    height: 100%;

    &--date {
      height: 110px;
      display: flex;
      justify-content: flex-end;
      margin-right: 0px !important;
    }

    &--buttons {
      display: flex;
      justify-content: flex-end;
      margin-right: 0px !important;

      .storybook-button {
        margin: 10px 0px 0px 0px !important;
      }
    }

    &--textfield {
      @include textfield();

      &--date {
        @include textfield();
        width: fit-content !important;
      }
    }
  }

  &__monthly-overview {
    height: 100%;
    padding: 10px;

    &--date {
      display: flex;
      justify-content: space-between;
      height: 35px;

      Button {
        margin-top: 0px;
      }
    }

    &--calendar {
      height: 260px;
      margin-top: 10px;
      @include CalendartoShowOnlyAttendance();

      &--legends {
        border-top: 1.8px dashed var(--border-color);
        padding-top: 10px;
        height: calc(100% - 355px);
        overflow: auto;
      }

      &--li {
        display: flex;
      }
    }
  }

  &__absenties-list {
    height: 100%;

    &--title {
      margin-top: 10px;
    }

    &--tableblock {
      height: calc(100% - 70px);
      margin-top: 10px;
    }

    &--table {
      @include table();

      tbody {
        background-color: var(--bg-color) !important;

        td {
          color: colors.get-color(colors.$red, level_5) !important;
          font-weight: 700;
        }
      }
    }

    &--textfield {
      @include textfield();
    }
  }

  &__student-attendance {
    height: 100%;
    padding: 10px;

    &--details {
      height: 100px;
      border: 1.8px dashed var(--border-color);

      &--image {
        padding-top: 10px;
        height: calc(100% - 10px);
        @include studentProfile();
      }

      &--list {
        display: grid;
        grid-template-columns: 1fr 0.2fr 1fr;

        Label {
          margin-top: 0px !important;
        }
      }
    }

    &--percentage {
      display: flex;
      justify-content: space-between;
      height: 30px;

      b {
        margin-top: 10px;
      }
    }

    &--calendar {
      margin-top: 10px;
      height: calc(100% - 270px);
    }
  }
}

.emp-attendance-overview__monthly-overview--calendar--legends::-webkit-scrollbar {
  @include scrollbar();
}

.emp-attendance-overview__monthly-overview--calendar--legends::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.emp-attendance-overview__monthly-overview--calendar--legends::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
