// same css used for reservation/newadmission/tables in dashboard
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.std-reservation {
  height: calc(100% - 175px);
  margin: 10px 20px 0px 20px;

  &__modal {
    height: calc(100% - 30px);
    margin-top: 10px;

    &--modal {
      height: calc(100% - 70px);
      margin-top: 10px;
    }
  }

  &__add {
    height: calc(100% - 165px);
    margin: 0px 20px 0px 20px;

    &--data {
      column-gap: 40px;
      height: calc(100% - 45px);
      overflow: auto;
    }

    .MuiAutocomplete-endAdornment {
      right: 10px !important;
    }
  }

  &__update {
    @extend .std-reservation__add;
    height: calc(100% - 30px);
    margin: 0px;
  }

  &__options {
    column-gap: 10px;

  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 90px);
  }

  &__table {
    @include table();

    &--slno {
      font-family: fonts.$eduate-number-font;
      width: calc(100% - 96%);
    }

    &--admno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--email {
      width: calc(100% - 88%);
    }

    &--mobile {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--fname {
      width: calc(100% - 88%);
    }

    &--status {
      width: calc(100% - 96%);
    }

    &--actions {
      width: calc(100% - 96%);
    }
  }

  &__btn {
    .storybook-button {
      margin: 0px 0px 0px 0px !important;
    }

    display: flex !important;
    justify-content: flex-end !important;
    margin: 0px 0px 0px auto !important;
    column-gap: 10px;
  }

  &__total {
    text-align: right;

    .student-total-count {
      margin: 10px 0px 0px 0px !important;
    }
  }
}