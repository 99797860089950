@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/colors" as colors;
@use "../../../../../scss/common/fonts" as fonts;
.report-card-test {
    @include mainFrameWithTitle();
    &__filters {
        column-gap: 10px;
    }
    &__tableblock {
        height: calc(100% - 85px);
        margin-top: 10px;
    }
    &__table {
        @include table();
        &--row{
            @include selectTableRow();
            @include autoCompleteInTableRow();
            td{
              padding: 0px 0px  !important;
            }
        }
        &--slno{
            width: calc(100% - 94%);
            font-family: fonts.$eduate-number-font;
        }
        &--actions{
            width: calc(100% - 94%);
        }
    }
}

.report-cards {
    height: 100%;
    &__block {
        height: calc(100% - 50px);
        margin-top: 10px;
        overflow: auto;
    }
    &__grids {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 10px;
    }
    &__grid {
        border: 1px solid var(--border-color);
        border-radius: 6px;
        padding: 5px;
        text-align: center;
        background-color: var(--level-1);
        font-family: fonts.$eduate-font-family;
        font-size: 14px;
        &--view {
            margin-top: 10px;
            @include viewButton();
        }
        &--button {
            display: flex;
            justify-content: center;
        }
    }
    &__test-details {
        height: calc(100% - 165px);
        margin: 0px 20px;
        &--flex {
            display: flex;
            column-gap: 10px;
            align-items: center;
            height: 60px;
            width: 100%;
            background: var(--level-1);
            border-radius: 10px;
            padding: 5px;
            margin-top: 10px;
        }
        &--test-name {
            font-family: fonts.$eduate-font-family;
            font-size: 14px;
            border-radius: 12px;
            border: 1px solid var(--border-color);
            padding: 2px 8px;
        }
    }
    &__std-list{
        margin-top: 10px;
        height: calc(100% - 165px);
    }
}

.report-cards__block::-webkit-scrollbar {
    @include scrollbar();
}

.report-cards__block::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.report-cards__block::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}
