@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.campus-list {
  height: calc(100% - 160px);
  margin: 0px 20px;

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 103px);

    ul {
      margin-bottom: 0px !important;
    }
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    margin-left: auto !important;
    margin-right: 0px !important;
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--actions {
      width: calc(100% - 96%);
    }

    &--primary {
      display: flex;

      img {
        width: 20px;
      }
    }
  }

  &__addnew {
    height: 100%;

    &--label-grid {
      display: grid;
      grid-template-columns: 1fr 1fr !important;

      .MuiChip-label {
        font-family: fonts.$eduate-font-family;
        font-size: 12px !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
      }

      .MuiChip-root {
        height: 25px !important;
      }

      .MuiAutocomplete-endAdornment {
        background: none !important;
      }
    }

    &--data {
      height: calc(100% - 65px);

      &--update {
        height: calc(100% - 105px);
      }
    }

    &--primary {
      height: 40px;
      border-radius: 3px;
      background-color: colors.get-color(colors.$cool_grey, level_2);
      display: flex;
      align-items: center;
      column-gap: 5px;
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;

      img {
        width: 20px;
      }

      &--label {
        width: 160px;
      }

      &--inst {
        background-color: var(--bg-color);
        padding: 3px;
        display: flex;
        justify-content: space-between;
        border-radius: 3px;
        width: calc(100% - 160px);

        span {
          width: calc(100% - 50px);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: var(--level-4);
          font-weight: 700;
        }
      }
    }

    &--select-primary {
      background: colors.get-color(colors.$cool_grey, level_1);
      min-height: fit-content;
      max-height: calc(100% - 95px);
      overflow: auto;
      border-radius: 3px;

      label {
        padding-left: 10px;
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
      }
    }
  }
}

.campus-list__addnew--select-primary::-webkit-scrollbar {
  @include scrollbar();
}

.campus-list__addnew--select-primary::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.campus-list__addnew--select-primary::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

#input-with-primary-color {
  color: var(--level-5) !important;
  font-weight: 700;
}
