
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.std-complete-fee-receipt {
  height: calc(100% - 165px);
  margin: 0px 10px 0px 10px;
  &--modal {
    height: calc(100% - 30px);
  }
  &__details {
    height: 120px;
    margin: 10px 10px 0px 10px;
    .MuiAutocomplete-root {
      margin-top: 7px !important;
    }
    column-gap: 20px;
  }
  &__grid {
    display: flex;
  }
  &__buttons {
    margin-left: 10px;
  }
  &__block {
    height: calc(100% - 180px);
    overflow: auto;
    margin: 5px 10px 0px 10px;
    &--modal {
      height: calc(100% - 178px);
      overflow: auto;
      margin: 5px 10px 0px 5px;
    }
  }
  &__frame {
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    margin-top: 10px;
    &--table {
      @include Accountstable();

      &--amount {
        font-family: fonts.$eduate-number-font;
        width: calc(100% - 90%);
      }
      &--slno {
        font-family: fonts.$eduate-number-font;
        width: calc(100% - 96%);
      }
      &--date {
        font-family: fonts.$eduate-number-font;
        width: calc(100% - 90%);
      }
      &--rcptno {
        font-family: fonts.$eduate-number-font;
        width: calc(100% - 90%);
      }
      &--th-rcptno {
        width: calc(100% - 90%);
      }
      &--th-amount {
        width: calc(100% - 90%);
      }
      &--th-slno {
        width: calc(100% - 96%);
      }
      &--th-date {
        width: calc(100% - 90%);
      }
    }
    &--tableblock {
      column-gap: 20px;
      margin-top: 10px;
    }
    &--block {
      padding: 10px;
      border: 1px solid var(--border-color);
      border-radius: 10px;
    }
  }
  &__image {
    @include studentProfile();
  }
}

.std-complete-fee-receipt__block::-webkit-scrollbar,
.std-complete-fee-receipt__block--modal::-webkit-scrollbar {
  @include scrollbar();
}
.std-complete-fee-receipt__block::-webkit-scrollbar-thumb,
.std-complete-fee-receipt__block--modal::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.std-complete-fee-receipt__block::-webkit-scrollbar-thumb:hover,
.std-complete-fee-receipt__block--modal::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
