@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.selected-sub-wise-status {
  height: 100%;

  &__title {
    display: flex;
    justify-content: space-between;
  }

  &__details {
    display: block;
    margin: 20px auto 10px auto;
    height: 120px;
    border-radius: 6px;
    background-color: var(--level-1);
    padding: 10px 20px;
    width: fit-content;

    &--subject {
      display: flex;
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
      justify-content: center;

      b {
        color: var(--level-5);
      }

      span {
        color: colors.get-color(colors.$grey, level_5);
      }
    }

    &--total-text {
      color: colors.get-color(colors.$grey, level_5);
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
      display: block;
      padding: 10px;
    }

    &--total {
      background-color: var(--level-3);
      display: block;
      margin-left: auto;
      margin-right: auto;
      border-radius: 50%;
      width: 35px;
      height: 30px;

      b {
        color: var(--text-color);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  &__sessions {
    height: calc(100% - 240px);
    overflow: auto;

    &--flex {
      display: flex;
      column-gap: 10px;
      align-items: center;
    }

    li {
      padding: 5px;
      border-bottom: 1.5px dashed var(--border-color);
    }

    &--filters {
      display: flex;
      column-gap: 10px;
      align-items: center;
      width: calc(100% - 60px);

      img {
        width: 20px;
        display: block;
        margin-right: 5px;
        margin-left: 5px;
      }
    }

    &--status {
      width: 60px;

      span {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
      }
    }

    &--textfield {
      @include textfield();
      margin: 10px 0px 0px 0px !important;
      width: fit-content !important;
    }
  }
}

.selected-sub-wise-status__sessions::-webkit-scrollbar {
  @include scrollbar();
}

.selected-sub-wise-status__sessions::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.selected-sub-wise-status__sessions::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
