@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.instconfig {
  &__table {
    margin-top: 20px;
    @include table();
  }
}
.masterconfig-details {
  margin: 10px;
}
.css3-tabstrip {
  height: 40px;
  margin-top: 5px;
  margin-left: 10px;
}
.css3-tabstrip li {
  display: inline-block;
  border-radius: 6px;
  border: 1px solid colors.get-color(colors.$colors, cloud-white);
  text-align: center;
  margin: 2px 2px 0 10px;
  font-family: fonts.$eduate-font-family;

  .active {
    display: inline-block;
    background-color: var(--level-5);
    border: solid 1px var(--level-5);
    border-radius: 6px;
    font-weight: 500;
  }
  .active label {
    color: colors.get-color(colors.$colors, white) !important;
  }
}

.css3-tabstrip label {
  color: var(--text-color) !important;
  padding: 4px;
  font-family: fonts.$eduate-font-family;

  cursor: pointer;
  margin-right: 10px;

  .active {
    color: colors.get-color(colors.$colors, white) !important;
  }
}

.css3-tabstrip input[type="radio"] {
  opacity: 0;
}
.Dropdown_dropdown__eszNv {
  padding-right: 2px !important;
}
.Item_item__xXudN {
  padding: 3px !important;
}
.Dropdown_dropdown__eszNv .Dropdown_menu__VtDkt ul {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
