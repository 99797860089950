@use "../../../../scss/common/mixins" as*;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.derivatives-list {
    margin: 5px 20px 0px 20px;
    height: calc(100% - 170px);

    &__title {
        padding-top: 5px;
        display: flex;
        height: 30px;
        align-items: center;
        column-gap: 10px;
        font-weight: 700;
        font-family: fonts.$eduate-font-family;

        &--derivative {
            @include derivativeSpan()
        }
    }

    &__tableblock {
        margin-top: 10px;
        height: calc(100% - 120px);
    }

    &__table {
        @include table();

        &--groupheader {
            th {
                height: 30px;
            }
        }

        &--subheader {
            th {
                top: 30px;
            }
        }

        &--slno {
            width: calc(100% - 96%);
            font-family: fonts.$eduate-number-font;
        }

        &--number {
            width: calc(100% - 90%);
            font-family: fonts.$eduate-number-font;
        }

        &--sub {
            width: calc(100% - 85%);

            span {
                margin-left: 10px;
                color: colors.get-color(colors.$cool_grey, level_7) !important;
            }
        }

        &--marks {
            width: calc(100% - 94%);
            font-family: fonts.$eduate-number-font;
        }

        &--t-marks {
            width: calc(100% - 94%);
            font-family: fonts.$eduate-number-font;
            font-weight: 700;
            background-color: var(--disabled-color) !important;
        }
    }
}