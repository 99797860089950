@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.delete-config {
  margin: 30px 20px 0px 20px;
  height: calc(100% - 195px);
  &__block {
    column-gap: 20px;
    height: calc(100% - 45px);
    ul {
      margin-bottom: 0px !important;
    }

    &--list {
      font-size: 16px;
      font-weight: bold;
    }
    &--list-items {
      //   border: 1px solid green;
      border-radius: 10px;
      margin-left: 20px;
    }
  }
}
