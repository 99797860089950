@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.document-layout {
  @include mainFrameWithTitle();

  &__print {
    height: 100%;

    .document-layout__frame--left {
      border-style: none !important;
      width: 100% !important;
    }
  }

  &__frame {
    display: flex;
    column-gap: 40px;
    height: calc(100% - 55px);
    width: 100%;
    margin-top: 10px;

    &--right {
      height: 100%;
      border: 1px solid var(--border-color);
      flex: 1;
      overflow: hidden;
    }

    &--left {
      height: 100%;
      border: 1px solid var(--border-color);
      overflow: hidden;
      resize: horizontal;
      overflow: auto;
      width: 70%;

      &--header {
        display: flex;
        column-gap: 10px;
        padding-bottom: 20px;
        justify-content: center;

        &--logo {
          width: 90px;
          height: 90px;

          img {
            width: 100%;
          }
        }

        &--cust-name {
          font-size: 16px;
          font-family: fonts.$eduate-font-family;
        }

        &--inst-name {
          font-size: 22px;
          font-family: fonts.$eduate-font-family;
          font-weight: 700;
        }

        &--inst-address {
          font-size: 12px;
          font-family: fonts.$eduate-font-family;
        }
      }

      &--body {
        color: var(--text-color);

        &--number {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          color: var(--text-color);
          font-family: fonts.$eduate-font-family;
          margin-left: 90px;
          margin-right: 28px;
        }

        &--sub-title {
          display: flex;
          justify-content: center;
          font-family: fonts.$eduate-font-family;
          font-size: 20px;
          font-weight: 700;
        }

        &--container {
          height: calc(100% - 100px);
        }

        &--footer {
          display: flex;
          justify-content: space-evenly;
          height: 70px;
          align-items: center;
          width: 100%;

          b {
            font-family: fonts.$eduate-font-family;
            font-size: 14px;
            color: var(--text-color);
          }
        }
      }

      &--footer {
        text-align: center;
        font-size: 14px;
        font-family: fonts.$eduate-font-family;
        color: var(--text-color);
      }
    }
  }
}
.doc-ack {
  &-table {
    @include tableWithCompleteBorders();
    height: calc(100% - 70px);
    td {
      padding: 8px !important;
    }
    margin-top: 10px;
  }
  &-std-details {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    &--grid {
      display: grid;
      grid-template-columns: 1fr 0.1fr 1fr;
      label,
      span,
      b {
        font-size: 14px;
        font-family: fonts.$eduate-font-family;
      }
    }
    &--flex {
      display: flex;
      column-gap: 10px;
      img {
        width: 80px;
        height: 80px;
      }
    }
  }
}
