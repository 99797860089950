@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.daily-diary {
    height: 100%;

    &__title {
        img {
            @include closeIconInModal();
        }
    }

    &__textfield {
        @include textfield();
    }

    &__numbers {
        margin-top: 10px;
        height: 45px;
        width: 100%;
        border-radius: 6px;
        background-color: var(--level-1);
        border-color: var(--border-color);

        padding: 5px;

        ul {
            margin-bottom: 0px !important;
            display: flex;
            column-gap: 10px;
            align-items: center;
        }

        li {
            height: 35px;
            width: 35px;
            border: 1px solid var(--border-color);
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            color: colors.get-color(colors.$cool_grey, level_6);
            border-radius: 5px;


        }

        .active {
            border: 1px solid var(--level-5);
            color: var(--text-color);
            font-weight: 700;
        }
    }

    &__datablock {
        margin-top: 10px;
        height: calc(100% - 165px);

        &--qa {


            .storybook-textarea {
                height: 125px !important;
            }
        }

        &--message {
            height: calc(100% - 200px);
            border: var(--border-color);
            border-radius: 2px;
            margin-top: 30px;
            background-color: var(--disabled-color);
            padding: 5px;



            &--header {
                height: 40px;
                display: flex;
                column-gap: 10px;
                align-items: center;

                .MuiAutocomplete-root {
                    width: 150px !important
                }

                .MuiAutocomplete-endAdornment {
                    background-color: transparent !important;
                }

                &--date {
                    font-size:fonts.$eduate-default-font-size;
                    font-family: fonts.$eduate-number-font;
                    color:colors.get-color(colors.$cool_grey, level_5);
                }

                &--previous-response {
                    border: 1px solid var(--level-4);
                    color: var(--level-7);
                    font-size:fonts.$eduate-default-font-size;
                    font-family: fonts.$eduate-font-family;
                    height: 30px;
                }
            }

            &--footer {
                height: 45px;
                margin-top: 2px;

                &--copy {
                    padding: 6px 15px;
                    border-radius: 30px;
                    color: colors.get-color(colors.$cool_grey, level_6);
                    font-size:fonts.$eduate-default-font-size;
                    font-weight: 700;
                    font-family: fonts.$eduate-font-family;
                    border: 1px solid var(--border-color);

                    img {
                        width: 20px;
                        margin-right: 10px;
                        filter: invert(95%);
                    }
                }
            }

            &--text {
                height: calc(100% - 80px);
                overflow: auto;
            }
        }

        p {
            font-weight: 700;
            font-family: fonts.$eduate-font-family;
            font-size: 16px;
            margin-bottom: 0px !important;
        }

    }

    &__btns {
        display: flex;
        justify-content: space-between;
    }
}

.daily-diary__datablock--message--text::-webkit-scrollbar {
    @include scrollbar();
}

.daily-diary__datablock--message--text::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.daily-diary__datablock--message--text::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}