.att-report-marked{
    height: calc(100% - 170px);
    margin: 0px 20px;
    display: flex;
    column-gap: 20px;
    &__left{
        height: 100%;
        border: 1px solid var(--border-color);
        border-radius: 6px;
        width: 280px;
    }
    &__right{
        width: calc(100% - 280px);
        height: 100%;
        border: 1px solid var(--border-color);
        border-radius: 6px;
        padding: 6px;
    }
    &__cal{
        height: calc(100% - 95px);
        margin-top: 10px;
        .attendance-overview__monthly-overview--calendar{
            height: calc(100% - 165px) !important;
        }
    }

}