@use "../../../../scss/common/mixins" as *;
.view-rating-factor {
    height: 100%;
    &__title{
        img{
            @include closeIconInModal();
        }
    }
    &__select{
        column-gap: 10px;
    }
    &__tableblock{
        margin-top: 10px;
        height: calc(100% - 160px);
    }
}