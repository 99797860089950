@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/colors" as colors;
@use "../../../../../scss/common/fonts" as fonts;
.class-wise-rank {
    height: calc(100% - 35px);
    &__overview {
        .MuiAutocomplete-root {
            background-color: white;
        }
        height: 50px;
        margin-top: 5px;
        border-radius: 30px;
        padding: 0px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--disabled-color);
        b {
            font-family: fonts.$eduate-font-family;
            font-size: 16px;
        }
        &--images {
            display: flex;
            column-gap: 10px;
            .active {
                background-color: var(--level-5);
                border-radius: 6px;

                img {
                    filter: invert(100%);
                    width: 30px;
                    padding: 5px;
                }
            }
            img {
                width: 30px;
            }
        }
    }
    &__datablock {
        column-gap: 20px;
        height: calc(100% - 60px);
        margin-top: 5px;
        display: flex;
    }
    h6 {
        color: var(--text-color);
        font-family: fonts.$eduate-font-family;
        text-align: center;
    }
    &__graph {
        height: 100%;
        width: 50%;
        border-radius: 30px;
        background-color: var(--disabled-color);
        padding: 10px;
        .echarts-for-react {
            height: calc(100% - 30px) !important;
        }
    }
    &__toppers {
        height: 100%;
        position: relative;
        border-radius: 30px;
        overflow: hidden;
        z-index: 2;
        width: 50%;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: url("../../../../../images/BgImageForToppersList.svg");
            background-position: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            filter: blur(16px);
            z-index: -1;
            border-radius: 30px;
        }

        &--view {
            position: relative;
            z-index: 1;

            padding: 10px;
            border-radius: 30px;
            height: 100%;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.3);
                border-radius: 30px;
                z-index: -1;
            }

            img {
                width: 80px;
                display: block;
                margin: 10px auto;
            }
            &--ul {
                height: calc(100% - 110px);
                overflow: auto;
                margin-bottom: 0px;
            }
            li {
                border-radius: 30px;
                padding: 5px 10px;
                display: flex;
                background-color: colors.get-color(colors.$colors, snow-white);
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin-top: 5px;
                img {
                    width: 30px;
                }
            }
            &--l {
                display: flex;
                column-gap: 10px;
                align-items: center;
                &--name {
                    b {
                        font-family: fonts.$eduate-font-family;
                        font-size:fonts.$eduate-default-font-size;
                    }
                    span {
                        display: block;
                        font-family: fonts.$eduate-number-font;
                        color: colors.get-color(colors.$cool_grey, level_4);
                        font-size: 12px;
                    }
                }
            }
            &--perc {
                color: var(--level-6);
                font-size: 16px;
                font-family: fonts.$eduate-number-font;
            }
        }
    }
    &__t-view {
        height: 100%;
        width: 100%;
        &--tableblock {
            height: calc(100% - 50px);
            margin-top: 10px;
        }
        &--select {
            column-gap: 10px;
        }
    }
    &__sub-wise{
        &--tableblock{
            margin-top: 10px;
            height: calc(100% - 90px);
        }
    }
 
}

.class-wise-rank__toppers--view--ul::-webkit-scrollbar {
    @include scrollbar();
    background-color: rgba(255, 255, 255, 0.3) !important ;
}

.class-wise-rank__toppers--view--ul::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.class-wise-rank__toppers--view--ul::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}
