@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
.teacher-dashboard__subjects {
    height: calc(100% - 30px);

    &--tableblock {
        height: calc(100% - 50px);
        margin-top: 10px;
    }

    &--table {
        @include table();

        &--slno {
            width: calc(100% - 94%);
            font-family: fonts.$eduate-number-font;
        }

        &--code {
            width: calc(100% - 85%);
            font-family: fonts.$eduate-number-font;
        }
    }

}