@use "../../../../../../scss/common/mixins" as *;
@use "../../../../../../scss/common/colors" as colors;
@use "../../../../../../scss/common/fonts" as fonts;
.add-combinations {
    @include mainFrameWithTitle();
    &__row {
        .label-grid{
            grid-template-columns: 1fr 1fr !important;
        }
    }
    &__title{
        img{
            @include closeIconInModal()
        }
    }
    &__modal{
        height: calc(100% - 30px);
    }
    &__block {
        background-color: var(--disabled-color);
        border-radius: 6px;
        padding: 5px;
        .MuiAutocomplete-root {
            background-color: var(--bg-color) !important;
        }
    }
    &__tableblock {
        height: calc(100% - 110px);
        margin-top: 10px;
    }
    &__table {
        @include table();
        &--row {
            @include selectInTable();
            @include autoCompleteInTableRow();
            .MuiAutocomplete-root {
                margin-top: 0px !important;
            }
            td{
                padding: 0px 3px !important;
            }
        }
        &--select {
            padding: 0px !important;
        }
        &--slno {
            width: calc(100% - 96%);
            font-family: fonts.$eduate-number-font;
        }
        &--actions {
            width: calc(100% - 90%);
        }
        &--subcode {
            width: calc(100% - 88%);
        }
        &--group{
            td{
                background-color: var(--bg-color) !important;
                font-size: 16px !important;
                font-weight: 700 !important;
            }
        }
    }
}