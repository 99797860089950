@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.att-for-teacher {
 @include mainFrameWithTitle();

  &__filters {
    column-gap: 10px;
    margin-top: 10px;

    &--textfield {
      @include textfieldInForms();
    }
  }

  &__tableblock {
    height: calc(100% - 95px);
    margin-top: 10px;
  }

  &__table {
    @include table();

    &--desc {
      display: flex;
      justify-content: space-between;
    }

    &--actions {
      width: calc(100% - 92%);

      .view-button {
        @include viewButton();
      }
    }

    .assign_teacher {
      @include assignButton();
    }
  }
}
