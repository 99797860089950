@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.salary-process {
  height: calc(100% - 170px);
  margin: 0px 20px 0px 20px;

  .subtitle1 {
    margin-top: 10px !important;
  }

  &__data {
    height: calc(100% - 50px);
    display: flex;
    gap: 20px;
    margin-top: 10px;

    &--left-block {
      width: 300px;
      border: 1px solid #edf2f7;
      height: 100%;
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 4px 6px -2px rgba(0, 0, 0, 0.05);

      ul {
        height: 100%;

        li {
          height: 48px;
          padding-left: 5px;
          color: var(--text-color);
          font-size: fonts.$eduate-default-font-size;
          font-family: fonts.$eduate-font-family;

          img {
            margin-right: 20px;
          }
        }

        .active {
          li {
            background-color: var(--level-8) !important;
            height: 48px;
            padding-left: 5px;
            color: colors.get-color(colors.$colors, white) !important;
          }

          img {
            filter: invert(100%);
          }
        }
      }

      &--options {
        gap: 10px;

        .MuiAutocomplete-root {
          width: 100% !important;
        }
      }
    }

    &--right-block {
      width: calc(100% - 300px);

      &--data {
        height: calc(100% - 40px);

        .subtitle1 {
          text-align: left;
        }
      }
    }
  }

  &__select-month {
    &--table {
      @include table();

      &--th-number {
        width: calc(100% - 80%);
      }

      &--th-total {
        width: calc(100% - 85%);
      }

      &--total {
        width: calc(100% - 85%);
        font-family: fonts.$eduate-number-font;
      }

      &--month {
        width: calc(100% - 90%);
      }

      &--number {
        width: calc(100% - 80%);
        font-family: fonts.$eduate-number-font;
      }

      &--completed-row {
        background-color: colors.get-color(
          colors.$warm_Grey,
          level_2
        ) !important;
      }

      th {
        height: 50px !important;
      }
    }

    &--calender {
      height: calc(50% - 10px);
      margin-top: 10px;
    }
  }

  &__select-emp {
    &--details {
      height: 40px;
      gap: 10px;

      #search {
        margin-top: 0px;
      }

      &--textfield {
        display: flex;
        justify-content: flex-end;
      }

      &--date {
        @include textfield();
      }
    }

    &--tableblock {
      height: calc(100% - 90px);

      b {
        font-family: fonts.$eduate-font-family;
      }
    }

    &--table {
      margin-top: 10px;
      @include table();
      @include tableCheckbox();

      th {
        height: 50px;
      }

      &--slno {
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-slno {
        width: calc(100% - 94%);
      }

      &--title {
        width: calc(100% - 96%);
      }

      &--gender {
        width: calc(100% - 96%);
      }

      &--date {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-date {
        width: calc(100% - 92%);
      }

      &--desc {
        width: calc(100% - 90%);
      }

      &--status {
        width: calc(100% - 96%);
      }
    }

    &--legends {
      display: flex;
      column-gap: 40px;
    }

    &--added {
      height: calc(100% - 80px);

      &--details {
        height: 40px;
        gap: 10px;
        margin-top: 10px;

        #search {
          margin-top: 0px;
        }
      }

      .salary-process__select-emp--table {
        height: calc(100% - 50px);
      }
    }
  }

  &__emp-attendance {
    &--details {
      height: 40px;
      gap: 10px;

      #search {
        margin-top: 0px;
      }

      &--textfield {
        display: flex;
        justify-content: flex-end;
      }
      &--table {
        height: 100%;
        @include table();
      }
    }

    &--details1 {
      @extend .salary-process__emp-attendance--details;
      margin-top: 17px;

      &--textfield {
        @include textfield();
      }
    }

    &--table {
      height: calc(100% - 100px);

      th {
        .MuiTableCell-root {
          border-bottom: none !important;
        }

        td {
          text-align: center !important;
        }
      }

      tr:hover {
        .salary-process__emp-attendance--table--name {
          @include tdContainsOnclick();
        }
      }

      &--number {
        font-family: fonts.$eduate-number-font;
        text-align: center !important;
        padding: 0px !important;
      }
    }

    &--leave-ledger {
      height: calc(100% - 30px);

      &--details {
        height: 130px;
        gap: 20px;
        border-bottom: 2px dashed var(--border-color);
        padding-bottom: 5px;

        &--image {
          height: 100%;
          @include studentProfile();
        }

        &--textfield {
          @include textfield();
          margin: 10px 0px 0px 0px !important;
        }
      }

      &--dates {
        margin-top: 10px;
        gap: 10px;
      }

      &--table {
        @include table();

        &--input {
          @include inputReportsTable();
          width: calc(100% - 92%);
          font-family: fonts.$eduate-number-font;

          Input {
            background-color: var(--level-2) !important;
            text-align: center;
            border-style: none !important;

            &:focus {
              background-color: var(--bg-color) !important;
            }
          }
        }

        &--th-input {
          width: calc(100% - 92%);
        }

        &--th-slno {
          width: calc(100% - 94%);
        }

        &--slno {
          width: calc(100% - 94%);
          font-family: fonts.$eduate-number-font;
        }
      }

      &--datablock {
        height: calc(100% - 40px);
        column-gap: 40px;

        &--left {
          height: 100%;
        }

        &--right {
          height: 100%;
          border: 1px solid var(--border-color);
          border-radius: 6px;
          padding: 5px;
        }
      }

      &--tableblock {
        margin-top: 10px;
        height: calc(100% - 220px);
      }

      &--textfield {
        @include textfield();
      }

      &--graph {
        height: 125px;
        display: flex;
        border-bottom: 1.8px dashed var(--border-color);
        gap: 20px;

        .echarts-for-react {
          height: 100% !important;
        }
      }
    }

    &--calendar {
      height: calc(100% - 130px);
      overflow: auto;
      @include CalendarForAttendance();

      &--grid-item {
        &--present {
          @extend .salary-process__emp-attendance--calendar--grid-item;
          @include CalendarShowPresent();
        }

        &--absent {
          @extend .salary-process__emp-attendance--calendar--grid-item;
          @include CalendarshowAbsent();
        }

        &--general-holidays {
          @extend .salary-process__emp-attendance--calendar--grid-item;
          @include CalendarGeneralHolidays();
        }

        &--weekends {
          @extend .salary-process__emp-attendance--calendar--grid-item;
          @include CalendarWeekends();
        }
      }
    }
  }

  &__confirm-salary {
    &--details {
      height: 50px;
      text-align: right;
    }

    &--select {
      column-gap: 10px;

      #search {
        margin-top: 0px !important;
      }

      &--textfield {
        @include textfield();
      }
    }

    &--tableblock {
      height: calc(100% - 50px);
      @include tableCheckbox();
    }

    &--table {
      tr:hover {
        .salary-process__confirm-salary--table--name {
          @include tdContainsOnclick();
        }
      }
    }

    &--salary-details {
      &--table {
        @include table();
        margin-top: 10px;
        height: calc(100% - 250px);

        &--amount {
          @include inputReportsTable();
          width: calc(100% - 94%);
          font-family: fonts.$eduate-number-font;

          Input {
            background-color: var(--level-2) !important;
            text-align: right;
            border-style: none !important;

            &:focus {
              background-color: var(--bg-color) !important;
            }
          }
        }

        &--th-input {
          width: calc(100% - 94%);
        }

        &--desc {
          width: calc(100% - 88%);
        }

        tfoot td {
          border-style: none !important;
        }

        &--slno {
          width: calc(100% - 92%);
          font-family: fonts.$eduate-number-font;
        }

        &--th-slno {
          width: calc(100% - 92%);
        }
      }
    }

    &--emp-details {
      height: calc(100% - 75px);

      &--tableblock {
        margin-top: 10px;
        column-gap: 40px;
        height: calc(100% - 190px);
      }

      &--table {
        @include table();
        height: calc(100% - 30px);
      }
    }
  }

  &__prov-salary {
    &--label {
      height: 40px;

      &--textfield {
        margin: 10px 0px 0px 0px !important;
        @include textfield();
      }

      &--buttons {
        display: flex;
        gap: 20px;
      }

      .Label {
        border: 1px solid var(--border-color);
        padding: 10px;
        margin-top: 0px;
      }

      .active .Label {
        background-color: var(--level-7);
        color: white !important;
        padding: 10px;
        height: 40px;
        margin-top: 0px;
      }
    }

    &--tableblock {
      @include table();

      margin-top: 10px;
      height: calc(100% - 120px);
      // th {
      //   .MuiTableCell-root {
      //     border-bottom: none !important;
      //   }
      //   td {
      //     text-align: center !important;
      //   }
      // }

      tr:hover {
        .salary-process__prov-salary--table--name {
          background-color: var(--bg-color) !important;
          font-weight: bold !important;
        }
      }
    }

    &--details {
      margin-top: 10px;
      height: calc(100% - 50px);
      column-gap: 20px;

      &--block {
        height: fit-content;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
          0px 4px 6px -2px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        border: 1px solid colors.get-color(colors.$grey, level_3);
        transition: transform 0.2s;
        /* Animation */
        padding: 5px 20px 5px 20px;

        &:hover {
          box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1),
            0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4);
        }

        span {
          font-size: fonts.$eduate-default-font-size;
          font-family: fonts.$eduate-number-font;
          color: colors.get-color(colors.$Green, level_6);
          justify-content: center;
          display: flex;
          font-weight: bold;
        }

        &--image-flex {
          display: flex;
          gap: 20px;
          justify-content: space-between;

          span {
            font-size: fonts.$eduate-default-font-size;
            font-family: fonts.$eduate-number-font;
            text-align: center !important;
            color: var(--text-color);
          }

          &--block {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
    &--table {
      @include table();
    }
  }

  &__close-salary {
    &--textfield {
      display: flex;
      justify-content: flex-end;
      height: 40px;
    }

    &--details {
      margin-top: 10px;
      height: calc(100% - 50px);
      column-gap: 20px;

      &--block {
        height: 185px;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
          0px 4px 6px -2px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        border: 1px solid colors.get-color(colors.$grey, level_3);
        transition: transform 0.2s;
        /* Animation */
        padding: 5px 20px 5px 20px;

        &:hover {
          box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.1),
            0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4);
        }

        span {
          font-size: fonts.$eduate-default-font-size;
          font-family: fonts.$eduate-number-font;
          color: colors.get-color(colors.$Green, level_6);
          justify-content: center;
          display: flex;
          font-weight: bold;
        }

        &--image-flex {
          display: flex;
          gap: 20px;
          justify-content: space-between;

          span {
            font-size: fonts.$eduate-default-font-size;
            font-family: fonts.$eduate-number-font;
            text-align: center !important;
            color: var(--text-color);
          }

          &--block {
            display: flex;
            flex-direction: column;
          }
        }

        &--image-flex2 {
          display: flex;
          justify-content: space-between;

          img {
            margin: auto;
          }

          span {
            font-size: fonts.$eduate-default-font-size;
            font-family: fonts.$eduate-number-font;
            color: var(--text-color);
            margin: auto;
          }
        }

        &--image-flex3 {
          display: flex;
          justify-content: space-between;

          img {
            margin: auto;
          }

          span {
            font-size: fonts.$eduate-default-font-size;
            font-family: fonts.$eduate-number-font;
            color: colors.get-color(colors.$red, level_6);
            margin: auto;
          }
        }
      }
    }
  }

  &__emp-attendance-status {
    height: 100%;
    padding: 0px 10px;

    &--title {
      display: flex;
      justify-content: space-between;
    }

    &--select {
      margin-top: 10px;
    }

    .font-green,
    .font-red {
      margin-left: 10px;
      font-family: fonts.$eduate-font-family;
    }
  }
}

.salary-process__emp-attendance--calendar::-webkit-scrollbar {
  @include scrollbar();
}

.salary-process__emp-attendance--calendar::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.salary-process__emp-attendance--calendar::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
