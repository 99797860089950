@use "../../../../../../scss/common/mixins" as *;
@use "../../../../../../scss/common/colors" as colors;
@use "../../../../../../scss/common/fonts" as fonts;
.view-groups{
    height: 100%;
    &__title {
        img {
            @include closeIconInModal();
        }
    }
    &__selected-catg {
        display: flex;
        justify-content: center;
        color: var(--level-5);
        font-size: 18px;
        font-family: fonts.$eduate-font-family;
    }
    &__tableblock {
        margin-top: 10px;
        height: calc(100% - 110px);
    }
}