@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.assigned-routes__treeview {
  height: calc(100% - 170px);
  margin: 0px 20px;
  &--block {
    height: calc(100% - 50px);
    column-gap: 20px;
    margin-top: 10px;
    &--frame {
      border: 1px solid var(--border-color);
      padding: 10px;
      height: 100%;
      #search {
        margin-top: 0px !important;
      }
    }
  }
  &--tableblock {
    height: calc(100% - 40px);
    margin-top: 10px;
  }
  &--table {
    @include table();
  }
}
