@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.calendar-events {
  height: calc(100% - 95px);
  margin: 10px 20px 0px 20px;
  &__data {
    height: calc(100% - 45px);
    column-gap: 20px;
    &--calendar {
      margin-top: 20px;
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 4px 6px -2px rgba(0, 0, 0, 0.05);
      padding: 5px;
      border-radius: 6px;
      border: 1px solid colors.get-color(colors.$grey, level_3);

      .react-calendar {
        width: 100%;
        &__navigation {
          button {
            font-size: 30px !important;
          }
          span {
            font-size: 18px !important;
          }
        }
      }
    }
    &--options {
      margin-top: 20px;
    }
    &--list {
      height: calc(50% - 10px);
      overflow: auto;
      @include calendarEventsList();
      &--events:hover {
        background-color: var(--level-3);
      }
    }
  }
}

.calendar-events__data--list::-webkit-scrollbar {
  @include scrollbar();
}

.calendar-events__data--list::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.calendar-events__data--list::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
