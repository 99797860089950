@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.alumini-student-list {
  &__btn {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px !important;
    margin-left: auto !important;
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 95%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-slno {
      width: calc(100% - 95%);
    }

    &--mobile {
      width: calc(100% - 94%);
    }

    &--th-mobile {
      width: calc(100% - 94%);
    }

    &--email {
      width: calc(100% - 86%);
    }

    &--year {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-year {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-font-family;
    }
  }

  &__select {
    column-gap: 10px;
    margin-top: 10px;
    margin-left: 20px;

    .storybook-button {
      margin-top: 0px !important;
    }
  }

  &__total {
    text-align: right;
  }
}

.alumini-student-list__table::-webkit-scrollbar,
.alumni-students__data--details::-webkit-scrollbar {
  @include scrollbar();
}

.alumini-student-list__table::-webkit-scrollbar-thumb,
.alumni-students__data--details:-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.alumini-student-list__table::-webkit-scrollbar-thumb:hover,
.alumni-students__data--details::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
.alumni-students {
  height: 100%;
  &__title {
    img {
      @include closeIconInModal();
    }
  }
  &__data {
    height: calc(100% - 30px);
    &--details {
      height: calc(100% - 45px);
      overflow: auto;
      &--row {
        column-gap: 20px;
      }
    }
  }
}
