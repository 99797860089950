
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.raise-student-demand {
  height: calc(100% - 140px);
  &__block1 {
    height: 250px;
  }
  &__block2 {
    height: calc(100% - 290px);
  }
  &__table {
    @include Accountstable();
    th {
      padding-left: 10px;
      padding-right: 10px;
    }
    &--slno {
      width: calc(100% - 94%);
      font-family: fonts.$eduate-number-font;
    }
    &--amount {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }
  }
  &__btns {
    margin-left: 10px;
  }
}
#fee-description-tablecell {
  width: 87.3% !important;
}
.raise-student-demand__table::-webkit-scrollbar {
  @include scrollbar();
}
.raise-student-demand__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.raise-student-demand__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
