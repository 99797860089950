
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
.student-default-docs {
  height: calc(100% - 165px);
  &__details {
    column-gap: 20px;
    .MuiAutocomplete-root {
      margin-top: 10px;
    }
  }

  &__block {
    height: calc(100% - 100px);
    margin-top: 10px;
    &--table {
      height: 100%;
      border: 1px solid var(--border-color);
      padding: 5px;
    }
  }
  &__table {
    @include table();
    &--slno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--preview {
      width: calc(100% - 96%);
    }
    &--actions {
      width: calc(100% - 96%);
      color: black;
    }
  }
}
