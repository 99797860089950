@use "../../../../../../scss/common/mixins" as *;
@use "../../../../../../scss/common/colors" as colors;
@use "../../../../../../scss/common/fonts" as fonts;
.combinations {
    @include mainFrameWithTitle();
    &__modal{
        height: calc(100% - 30px)
    }
    &__title{
        img{
            @include closeIconInModal();
        }
    }
    &__filters {
        column-gap: 10px;
        &--button {
            display: flex;
            justify-content: flex-end;
        }
    }
    &__tableblock {
        height: calc(100% - 100px);
        margin-top: 10px;
    }
    &__table {
        @include table();
        &--slno {
            width: calc(100% - 96%);
            font-family: fonts.$eduate-number-font;
        }
        &--actions {
            width: calc(100% - 86%);
        }
        &--assign{
            @include assignButton();
        }
        &--ul {
            margin-bottom: 0px !important;
            b{
                font-size: 16px !important;
            }
        }
        &--name {
            &:hover {
                @include tdContainsOnclick();
            }
        }
    
    }
    &__add {
        height: calc(100% - 165px);
        margin: 0px 20px;
        &--modal{
            height: calc(100% - 30px);
        }
        &--block {
            background-color: var(--disabled-color);
            border-radius: 6px;
            padding: 5px;
            .MuiAutocomplete-root {
                background-color: var(--bg-color) !important;
            }
        }
        &--tableblock {
            height: calc(100% - 150px);
            margin-top: 10px;
        }
        &--table {
            @include table();
            &--row {
                @include selectInTable();
                @include autoCompleteInTableRow();
                .MuiAutocomplete-root {
                    margin-top: 0px !important;
                }
            }
            &--select {
                padding: 0px !important;
            }
            &--slno {
                width: calc(100% - 96%);
                font-family: fonts.$eduate-number-font;
            }
            &--actions {
                width: calc(100% - 90%);
            }
            &--subcode {
                width: calc(100% - 88%);
            }
        }
    }
    &__view {
        height: 100%;
        &--title {
            img {
                @include closeIconInModal();
            }
        }
        &--selected-catg {
            display: flex;
            justify-content: center;
            color: var(--level-5);
            font-size: 18px;
            font-family: fonts.$eduate-font-family;
        }
        &--tableblock {
            margin-top: 10px;
            height: calc(100% - 110px);
        }
    }
}
