@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
.vouchers-approved-request {
    height: calc(100% - 30px);

    &__filters {
        column-gap: 10px;

        .MuiAutocomplete-root {
            margin-top: 10px;
        }

        &--date {
            display: flex;
            justify-content: flex-end;
            column-gap: 10px;
        }

        &--approved {
            text-align: center;
            margin-top: 10px;
        }
    }

    &__textfield {
        @include textfieldInForms();
        width: fit-content !important;
    }

    &__datablock {
        margin-top: 10px;
        height: calc(100% - 95px);
        overflow: auto;

        li {
            display: flex;
            justify-content: space-between;
            column-gap: 10px;
            padding: 5px;
            border-bottom: 1px solid var(--border-color);

            &:hover {
                background-color: var(--level-1);
            }
        }

        &--image {
            border-left: 1px solid var(--border-color);
            padding: 5px 5px 5px 25px;


            &:hover {
                span {
                    text-decoration: underline;
                }
            }
        }

        img {
            display: block;
            margin: auto;
        }

        span {
            font-size: 12px;
            font-family: fonts.$eduate-font-family;


        }

        &--v-details {
            display: flex;

            span {
                font-family: fonts.$eduate-font-family;
                font-size:fonts.$eduate-default-font-size;
                color: colors.get-color(colors.$grey, level_7);
                margin-left: 10px;
            }

            b {
                font-weight: 700;
                font-family: fonts.$eduate-font-family;
                font-size:fonts.$eduate-default-font-size;
                color: var(--text-color);
            }
        }

        &--approved-details {
            display: flex;
            column-gap: 10px;
            font-size:fonts.$eduate-default-font-size;
            font-family: fonts.$eduate-font-family;
            color: var(--text-color);
            width: fit-content;
        }

        &--approvedby {
            font-size:fonts.$eduate-default-font-size;
            font-family: fonts.$eduate-font-family;
            color: colors.get-color(colors.$grey, level_7);
        }

        &--remarks {
            font-size:fonts.$eduate-default-font-size;
            font-family: fonts.$eduate-font-family;
            color: colors.get-color(colors.$grey, level_7);
        }

        &--li {
            display: flex;
            justify-content: space-between;
            width: calc(100% - 150px);
        }

        &--date {
            font-size:fonts.$eduate-default-font-size;
            font-family: fonts.$eduate-number-font;
            color: var(--text-color);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 25px;
        }

        &--approved-status {
            display: flex;
            justify-content: center;
            column-gap: 20px;
            width: 150px;
        }

        &--arrow {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                transform: rotate(-90deg);
                display: block;
                margin: auto;
            }
        }
    }
}

.vouchers-approved-request__datablock::-webkit-scrollbar {
    @include scrollbar();
}

.vouchers-approved-request__datablock::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.vouchers-approved-request__datablock::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}