@use "../common/fonts" as fonts;
@use "../common/colors" as colors;
.td-sl-no {
  width: 60px;
  font-family: fonts.$eduate-number-font;
}

.td-adm-no {
  font-family: fonts.$eduate-font-family;
  width: 120px;
}

.td-marks {
  width: 150px;
}
.td-amount {
  width: 150px !important;
  max-width: 150px !important;
  margin-right: 10px;
}
.td-actions-print {
  width: 200px;

  img {
    width: 20px;
  }
}
.td-actions-plan {
  min-width: 200px !important;
  max-width: 200px !important;
  img {
    width: 20px;
  }
}
.td-actions-view {
  min-width: 120px !important;
  max-width: 120px !important;
  img {
    width: 20px;
  }
}
.td-actions {
  min-width: 200px !important;
  max-width: 200px !important;
  img {
    width: 20px;
  }
}
.td-actions-print-header {
  width: 200px;

  img {
    width: 20px;
  }
}

.td-name {
  width: 200px;
}

.td-sub {
  width: 120px;
}

.td-sub-code {
  width: 100px;
}

.td-date {
  width: 100px;
  font-family: fonts.$eduate-number-font;
}
.td-number {
  width: 60px;
  font-family: fonts.$eduate-number-font;
}
.td-mobile {
  width: 150px;
  font-family: fonts.$eduate-number-font;
}
.td-status {
  width: 80px;
  font-family: fonts.$eduate-number-font;
  padding: 0px !important;
  &__cell {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
  }
  &__more {
    width: 25px;
    filter: invert(0%);

    &:hover {
      background: colors.get-color(colors.$colors, white);
      border-radius: 50%;
    }
  }
}
.td-sub-type {
  width: 120px;
}

.td-att-present {
  width: 150px;
  font-family: fonts.$eduate-number-font;
  color: colors.get-color(colors.$Green, level_6) !important;
}

.td-att-absent {
  @extend .td-att-present;
  color: colors.get-color(colors.$red, level_6) !important;
}

.td-att-percentage {
  @extend .td-att-present;
  padding: 0px 0px !important;
  text-align: center;
}
.td-results {
  width: 80px;
}
.td-std-type {
  width: 80px;
}
.td-gender {
  width: 80px;
}
.td-yes {
  width: 80px;
}
.td-remarks {
  width: 120px;
}
