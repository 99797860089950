@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.voucher-receipts {
    height: 100%;
    overflow: auto;

    span,
    b,
    p {
        font-family: fonts.$eduate-font-family;
        font-size:fonts.$eduate-default-font-size;
    }

    &__block {
        border: 1px solid colors.get-color(colors.$colors, black);

        &--inst-data {
            border-bottom: 1px solid colors.get-color(colors.$colors, black);
            padding: 0px 10px;
            margin-top: 10px;

            &--voucher-type {
                font-size: 14px !important;
                text-transform: uppercase;
                text-align: center;
                display: block;
            }

            &--inst-name {
                text-align: center;
                font-size: 18px;
                text-transform: uppercase;
                display: block;
            }

            &--inst-address {
                display: flex;
                justify-content: center;
                font-size: 16px;
            }

            &--details {
                display: flex;
                gap: 10px;

                img {
                    height: 120px;
                    width: 120px;
                }
            }

            &--flex {
                display: flex;
                justify-content: space-between;
            }

            &--details-block {
                width: calc(100% - 120px);

                &--without-image {
                    width: 100%;
                }
            }
        }

        &--payment-info {
            padding: 10px;
            border-bottom: 1px solid colors.get-color(colors.$colors, black);

            &--uppercase {
                text-transform: uppercase;
            }

            &--capitalize {
                text-transform: capitalize;
            }

            &--trans-summary {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;

                &--amount {
                    width: 250px;
                    height: 60px;
                    border: 1px solid colors.get-color(colors.$colors, black);
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    span {
                        font-weight: 700;
                        border-radius: 2px;
                        font-family: fonts.$eduate-number-font;
                    }
                }

                &--approved-by {
                    border: 1px solid colors.get-color(colors.$colors, black);
                    padding: 0px 10px 2px 10px;
                    border-radius: 2px;
                    height: 60px;
                    width: 250px;
                    text-align: center;

                    span {
                        position: relative;
                        top: 33px;
                    }
                }
            }
        }

        &--sender-info {
            padding: 10px;

            &--signatures {
                display: flex;
                justify-content: space-between;
                height: 60px;


                &--reciever-type {
                    padding: 0px 10px 2px 10px;
                    width: calc(100% - 80%);
                    text-align: center;
                    width: 250px;
                    border: 1px solid colors.get-color(colors.$colors, black);
                    border-radius: 2px;

                    span {
                        position: relative;
                        top: 33px;
                    }
                }

                &--reciever-sign {
                    @extend .voucher-receipts__block--sender-info--signatures--reciever-type;
                    width: 250px;
                }
            }
        }

        &--time {
            font-family: fonts.$eduate-number-font;
            font-size: 12px;
        }
    }

    &__print {
        width: 100%;
        height: calc(100% - 45px);

    }
}

.voucher-receipts::-webkit-scrollbar {
    @include scrollbar();
}

.voucher-receipts::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.voucher-receipts::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}