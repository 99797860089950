.view-overall-att{
    height: calc(100% - 170px);
    margin: 0px 20px;
    display: flex;
    column-gap: 10px;
    &__left{
        border: 1px solid var(--border-color);
        height: 100%;
        border-radius: 6px;
        width: 200px;
    }
    &__right{
        height: 100%;
        border: 1px solid var(--border-color);
        border-radius: 6px;
        padding: 10px;
        width: calc(100% - 200px);


    }
    &__tableblock{
        height: calc(100% - 90px);
        margin-top: 10px;
    }
    &__monthly{
        margin-top: 10px;
        height: calc(100% - 130px);
    }
    &__select{
        .subtitle1{
            margin-top: 10px !important;
        };
    }

}