@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.admin-config {
  height: calc(100% - 165px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__title{
    img{
      @include closeIconInModal();
    }
  }
  
  &__block {
    padding: 20px;
    background-color: colors.get-color(colors.$cool_grey, level_1);
    border-radius: 10px;
    width: 600px;
    height: fit-content;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    p {
      font-family: fonts.$eduate-font-family;
      font-size: 18px;
      line-height: 1.6;
      text-align: justify;
      color: var(--text-color);
    }
  }
}
