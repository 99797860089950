
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.finyr-post {
  height: calc(100% - 175px);
  margin-top: 10px;

  &__data {
    border: 1px solid var(--border-color);
    border-radius: 12px;
    height: 100%;

    &--warning {
      background-color: var(--level-1);
      padding: 10px 8px;
      overflow: auto;
      height: calc(100% - 280px);
      text-align: justify;
      color: colors.get-color(colors.$red, level_7);
      font-family: fonts.$eduate-font-family;
      font-size:fonts.$eduate-default-font-size;

      li {
        margin-top: 10px;
      }
    }

    &--component {
      padding: 30px 50px;
      height: 280px;
.storybook-button{
margin: 5px 10px 0px 0px !important;
}
      &--label-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
      &--grid{
        display: grid;
        grid-template-columns: 2fr 1fr;
      }
    }
  }
}

.finyr-post__data--warning::-webkit-scrollbar {
  @include scrollbar();
}

.finyr-post__data--warning::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.finyr-post__data--warning::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}