@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.accountsconfig {
  margin: 10px 0px 0px 0px;
  height: calc(100% - 80px);
  overflow-y: auto;
  &__data {
    column-count: 3;
    column-gap: 40px;
    column-fill: auto;
    column-rule: 4px double colors.get-color(colors.$colors, cloud-white);
  }

  &__settings {
    width: 30px;
    padding-top: 5px;
  }
  &__label-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-bottom: 1px dashed colors.get-color(colors.$colors, cloud-white);
  }
  &__select-option {
    &--label-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding-bottom: 5px !important;
      border-bottom: 1px dashed colors.get-color(colors.$colors, cloud-white);
    }
  }
  &__switches {
    border-bottom: 1px dashed colors.get-color(colors.$colors, cloud-white);
  }
}

.accountsconfig::-webkit-scrollbar {
  @include scrollbar();
}

.accountsconfig::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.accountsconfig::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
