
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.ledger-wise-student-report {
  @include mainFrameWithTitle();

  &__options {
    column-gap: 10px;
    margin-top: 5px;

    &--modal {
      column-gap: 10px;
    }
  }

  &__tableblock {
    height: calc(100% - 91px);
    margin-top: 10px;

    ul {
      margin: 2px !important;
    }
  }

  &__table {
    height: 100% !important;
    @include table();
    &--ledgers {
      font-size: 12px;

      &--total {
        text-align: right;
        font-size: 12px;
        font-weight: 700;
        border-top: 1.8px dashed var(--border-color);
      }
    }
    &--amount{
      width: 180px !important;
      font-family: fonts.$eduate-number-font;
    }
    &--slno{
      width: 60px !important;
      font-family: fonts.$eduate-number-font;
    }
    &--admno{
      width: 100px !important;
      font-family: fonts.$eduate-number-font;
    }
    &--name{
      width: 250px !important;
    }
  }

  &__total {
    text-align: right;

    .student-total-count {
      margin: 10px 0px 0px 0px !important;
    }
  }
}