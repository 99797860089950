
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.balance-reports {
  margin-left: 10px;

  &__index {
    height: calc(100% - 70px);
    margin: 10px 20px 10px 20px;
    width: 250px;
  }

  &__tableblock {
    width: 200px;
    height: calc(100% - 30px);
  }

  &__table {
    @include Accountstable();

    tr:hover {
      .bank-book__table--ledger {
        @include tdContainsOnclick();
      }
    }

    .active {
      color: var(--level-4);
      background-image: url("../../../../images/Pointer.svg");
      background-repeat: no-repeat;
      background-size: 12px;
      background-position-x: 0px 50px;
      padding-left: 20px;
    }
  }

  &__list {
    height: calc(100% - 30px);
  }
}

//complete fee balance report
.fee-balance-reports {
  height: calc(100% - 220px);
  margin: 10px 20px 0px 20px;
  display: flex;

  &__tableblock {
    width: 250px;
    border-right: 1px solid white;
    box-shadow: 2px 2px 7px rgba(80, 80, 80, 0.5);
    height: 100%;
  }

  &__reportblock {
    height: 100%;
    width: calc(100% - 250px);

    &--details {
      border: 2px dashed colors.get-color(colors.$grey, level_3);
      margin: 10px 0px 0px 10px;
      height: calc(100% - 80px);
      padding: 10px;
    }

    &--filter-options {
      margin: 5px 0px 0px 10px;
      column-gap: 10px;

      &--search {
        margin-top: 0px;
      }
    }
  }

  &__buttons {
    margin-left: 20px;
  }

  &__select-options {
    .MuiAutocomplete-root {
      margin-left: 10px !important;
      width: calc(100% - 10px) !important;
    }
  }

  &__select-options2 {

    margin-top: 10px;

    #search {
      margin-top: 0px !important;
      margin-left: 10px;
      width: calc(100% - 10px) !important;
    }

    .MuiAutocomplete-root {
      margin-left: 10px !important;
      width: calc(100% - 10px) !important;
    }

    &--textfield {
      @include textfield();
      margin: 0px 0px 0px 10px !important;
      width: calc(100% - 10px) !important;

      label {
        color: var(--text-color) !important;
      }
    }
  }

  &__auto-complete {
    width: 450px;
    height: 30px;
    text-overflow: ellipsis;

    .MuiOutlinedInput-notchedOutline {
      border-width: 1px !important;
    }
  }

  &__total {
    text-align: right;
  }

}