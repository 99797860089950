@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.combination-sub-list {
  height: 100%;
  overflow: auto;
  &__grids {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 5px;
  }
  &__grid {
    text-align: center;
    background-color: var(--level-1);
    border-radius: 6px;
    border: 1px solid var(--border-color);
    padding: 5px;
    b {
      font-family: fonts.$eduate-font-family;
      font-size: 16px;
    }
    span {
      font-family: fonts.$eduate-font-family;
      font-size: 14px;
      display: block;
      color:colors.get-color(colors.$cool_grey, level_9);
    }
    &--view {
      @include viewButton();
    }
    &--flex{
        display: flex;
        column-gap: 20px;
        justify-content: center;
    }
  }
  &__view {
    height: 100%;
    &--datablock {
      height: calc(100% - 150px);
      margin-top: 10px;
      column-gap: 20px;
      &--left{
        height: 100%;
        border: 1px solid var(--border-color);
        border-radius: 6px;
        padding: 5px
      }
      &--right{
        @extend .combination-sub-list__view--datablock--left;
        background-color: var(--disabled-color)
      }
    }
    &--title {
      img {
        @include closeIconInModal();
      }
    }
    &--subtitle {
      display: flex;
      justify-content: flex-start;
      b {
        font-size: 20px !important;
      }
    }
    &--tableblock{
        height: calc(100% - 85px);
        margin-top: 10px;
    }
  }
}

.combination-sub-list::-webkit-scrollbar {
  @include scrollbar();
}

.combination-sub-list::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.combination-sub-list::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
