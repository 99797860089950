@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/colors" as colors;
@use "../../../../../scss/common/fonts" as fonts;
.std-login-lib {
  margin: 0px 20px;
  height: calc(100% - 170px);

  &__modal {
    height: calc(100% - 30px);
  }

  &__details {
    column-gap: 20px;
    height: 120px;

    &--image {
      @include studentProfile();
    }

    &--textfield {
      @include textfield();
      margin: 10px 0px 0px 0px !important;
    }

    &--issued-details {
      display: grid;
      grid-template-columns: 1fr 0.4fr 0.2fr 0.4fr;
      column-gap: 5px;

      &--issued {
        background-color: colors.get-color(colors.$Green, level_5) !important;
      }

      &--limit {
        background-color: colors.get-color(colors.$red, level_5) !important;
      }

      span {
        margin-top: 10px;
        text-align: center;
        font-weight: 700;
      }
    }
  }

  &__tableblock {
    height: calc(100% - 170px);
    margin-top: 10px;
  }

  &__history {
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    img {
      width: 25px;
    }
  }

  &__table {
    @include table();
    height: calc(100% - 35px);

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--date {
      width: calc(100% - 95%);
      font-family: fonts.$eduate-number-font;
    }

    &--accessno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--delayed {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }
  }
}