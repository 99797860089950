@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.individual-std-report {
    margin: 0px 20px;
    height: calc(100% - 170px);
    display: flex;
    column-gap: 10px;
    &__left {
        height: 100%;
        border: 1px solid var(--border-color);
        border-radius: 6px;
        width: 200px;
    }
    &__right {
        height: 100%;
        border: 1px solid var(--border-color);
        border-radius: 6px;
        padding: 10px;
        width: calc(100% - 200px);
    }
    &__select {
        height: 130px;
        column-gap: 20px;
        .MuiAutocomplete-root {
            margin-top: 8px;
        }
        &--profile {
            height: 100%;
            img {
                @include studentProfile();
                height: calc(100% - 20px);
                width: 100%;
            }
        }
    }
    &__bottom{
        margin-top: 10px;
        height: calc(100% - 140px);
        column-gap: 20px;
        &--frame{
            border: 1px solid var(--border-color);
            border-radius: 6px;
            height: calc(100% - 10px);
            padding: 10px;
        }
    }
}
