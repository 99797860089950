@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.ledger-print {
  width: 100%;
  height: calc(100% - 45px);
  overflow: auto;

  .bank-book__table-section--transaction-details--table--title {
    margin-top: 10px;
  }

  &--inst-data {
    border-bottom: 1px solid colors.get-color(colors.$colors, black);
    padding: 10px;
    font-family: fonts.$eduate-font-family;

    &--details {
      display: flex;
      gap: 10px;

      img {
        height: 120px;
        width: 120px;
      }
    }

    &--inst-name {
      text-align: center;
      font-size: 18px;
      text-transform: uppercase;
      display: block;
    }

    &--inst-address {
      display: flex;
      justify-content: center;
      font-size: 16px;
    }

    &--flex {
      display: flex;
      justify-content: space-between;
    }

    &--details-block {
      width: calc(100% - 120px);
    }
  }

  &--ledger-type {
    display: flex;
    justify-content: center;
    margin-left: 120px;
    font-size: 16px;
    font-family: fonts.$eduate-font-family;
  }

  &--table {
    max-height: fit-content !important;

    &--header {
      th {
        font-size: 16px !important;
      }
    }

    &--date {
      max-width: 90px !important;
      min-width: 90px !important;
      font-family: fonts.$eduate-number-font;
    }

    &--th-date {
      max-width: 90px !important;
      min-width: 90px !important;
    }

    &--amount {
      max-width: 120px !important;
      min-width: 120px !important;
      font-family: fonts.$eduate-number-font;
    }

    &--th-amount {
      max-width: 120px !important;
      min-width: 120px !important;
    }

    &--subheader {
      th {
        color: colors.get-color(colors.$grey, level_6) !important;
      }
    }

    th {
      font-family: fonts.$table-font-family;
      font-weight: 700;
      border-style: none !important;
      padding: 5px !important;

      td {
        text-align: center;
      }
    }

    tbody {
      td {
        padding: 5px !important;
        font-style: normal;
        font-size: 12px;
        font-family: fonts.$table-font-family;
        color: var(--text-color);
        border-style: none !important;
      }
    }

    .ledger-print--table--th-date,
    .ledger-print--table--th-amount,
    .ledger-print--table--th-vno,
    .ledger-print--table--th-name,
    .ledger-print--table--th-type {
      // border-right: 1px solid var(--text-color) !important;
      border-bottom: 1px solid var(--text-color) !important;
      border-top: 1px solid var(--text-color) !important;
    }

    &--ob-text {
      font-weight: 700 !important;
      font-size: 14px !important;
      border-left: 1px solid var(--text-color) !important;
    }

    &--ob-amount {
      font-weight: 700 !important;
      font-family: fonts.$eduate-number-font;
      font-size: 14px !important;
      max-width: 120px !important;
      min-width: 120px !important;
    }
  }

  &--closing-balance {
    display: flex;
    font-size: fonts.$eduate-default-font-size;
    width: 100%;

    &--text {
      font-family: fonts.$eduate-font-family;
      text-align: right;
      padding: 5px;
      width: calc(100% - 240px) !important;
      // border-right: 1px solid var(--text-color) !important;
      // border-left: 1px solid var(--text-color) !important;
    }

    &--amount {
      font-family: fonts.$eduate-number-font;
      width: 120px !important;
      text-align: right;
      padding: 5px;
      // border-right: 1px solid var(--text-color) !important;
    }
  }

  &--total {
    @extend .ledger-print--closing-balance;
    border-top: 1px solid var(--text-color) !important;
  }
}

.ledger-print::-webkit-scrollbar {
  @include scrollbar();
}

.ledger-print::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.ledger-print::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
