@use "../../../scss/common//mixins" as *;
@use "../../../scss/common/fonts" as fonts;
@use "../../../scss/common/colors" as colors;
.emp-complete-logs {
  margin: 0px 20px;
  height: calc(100% - 170px);

  &__select {
    column-gap: 10px;

    .MuiAutocomplete-root {
      margin-top: 10px;
    }
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 90px);
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--number {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--date {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--actions {
      width: calc(100% - 96%);

      img {
        width: 20px;
      }
    }
  }
}
