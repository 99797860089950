@use "../../../../scss/common/mixins" as *;

.documents-print {
  height: calc(100% - 45px);
  overflow: auto;

  &__container {
    height: 100%;

    &--form {
      height: 100%;
    }
  }

  .page-break {
    page-break-after: always;
  }
}

.documents-print::-webkit-scrollbar {
  @include scrollbar();
}

.documents-print::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.documents-print::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
