@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.holiday-list {
  height: calc(100% - 95px);
  margin: 10px 20px 0px 20px;
  .subtitle1 {
    margin-top: 10px;
  }
  &__data {
    height: calc(100% - 45px);
    column-gap: 40px;
    &--calendar {
      margin-top: 15px;
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 4px 6px -2px rgba(0, 0, 0, 0.05);
      padding: 5px;
      border-radius: 6px;
      border: 1px solid colors.get-color(colors.$grey, level_3);

      .react-calendar {
        width: 100%;
        &__navigation {
          button {
            font-size: 30px !important;
          }
          span {
            font-size: 18px !important;
          }
        }
        &__year-view__months__month {
          font-family: fonts.$eduate-font-family;
          padding: 6px;
        }
        &__tile {
          &--now {
            background: var(--level-5) !important;
            color:colors.get-color(colors.$colors, white);
          }
        }
      }
    }
    &--options {
      margin-top: 20px;
    }
    &--details {
      column-gap: 10px;
      margin-top: 30px;
    }
    &--column {
      height: 100%;
      overflow: auto;
    }
    &--year {
      margin-top: 5px;
    }
  }
  &__table {
    margin-top: 55px;
    @include table();
    height: calc(50% - 55px);
    &--th-slno {
      width: calc(100% - 94%);
    }
    &--slno {
      width: calc(100% - 94%);
      font-family: fonts.$eduate-number-font;
    }
    &--date {
      font-family: fonts.$eduate-number-font;
      width: calc(100% - 80%);
    }
    &--th-date {
      width: calc(100% - 80%);
    }
    &--actions {
      width: calc(100% - 94%);
    }
    &--status {
      width: calc(100% - 85%);
    }
  }
  &__list {
    height: calc(50% - 45px);
    margin-top: 10px;
    overflow: auto;
    li {
      display: flex;
      gap: 10px;
    }
    &--events {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      background-color: var(--level-2);
      border-radius: 6px;
      width: 100%;
      padding: 5px;
      font-size: 12px;
      border-left: 5px solid colors.get-color(colors.$red, level_7);
      span {
        font-family: fonts.$eduate-number-font;
      }
      b {
        font-family: fonts.$eduate-font-family;
      }
      .Label {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }
}
.highlight {
  color: rgb(40, 75, 0) !important;
}

.holiday-list__list::-webkit-scrollbar,
.holiday-list__table::-webkit-scrollbar,
.holiday-list__data--column::-webkit-scrollbar {
  @include scrollbar();
}

.holiday-list__list::-webkit-scrollbar-thumb,
.holiday-list__table::-webkit-scrollbar-thumb,
.holiday-list__data--column::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.holiday-list__list::-webkit-scrollbar-thumb:hover,
.holiday-list__table::-webkit-scrollbar-thumb:hover,
.holiday-list__data--column::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
