@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.departmenttreeview {
  margin-top: 20px;
  font-family: fonts.$eduate-font-family;
  font-style: fonts.$label-font-style;
  font-weight: fonts.$label-font-weight !important;
  font-size: 12px !important;
  &__header {
    color: green;
  }
  img {
    width: 20px;
    margin-right: 10px;
  }
  a {
    color: black;
  }
}
.tree-view {
  margin-top: 20px;
  height: calc(100% - 10px);
  overflow-y: auto;
  overflow-x: hidden;
  .MuiTreeItem-content {
    &:hover {
      background-color: var(--level-2) !important;
      .MuiTreeItem-content .MuiTreeItem-label {
        color: colors.get-color(colors.$colors, black) !important;
      }
    }
  }
  .MuiSvgIcon-root {
    fill: var(--text-color) !important;
  }
}
.tree-view::-webkit-scrollbar {
  @include scrollbar();
}

.tree-view::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.tree-view::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

.MuiTreeItem-content .MuiTreeItem-label {
  font-size: 14px !important;
  font-family: fonts.$eduate-font-family;
  color: var(--text-color);
  &:hover {
    color: colors.get-color(colors.$colors, black);
  }
}
.MuiTreeItem-content.Mui-selected {
  padding: 6px;
  border-radius: 15px;
  background: var(--level-4) !important;
  padding-left: 10px;
  color:colors.get-color(colors.$colors, white) !important;
  font-weight: 500 !important;
  &:hover {
    background: var(--level-4) !important;
    color:colors.get-color(colors.$colors, white) !important;
  }
}

.MuiTreeItem-group {
  border-left: 2px dotted var(--level-4) !important ;
}
