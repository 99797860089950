@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.assign-class-teacher {
  @include mainFrameWithTitle();

  &__select {
    column-gap: 10px;
    margin-top: 10px;
  }

  &__block {
    height: 70px;
    padding: 5px;
    width: fit-content;
    flex-direction: column;
    display: flex;
    justify-content: flex-end;
    border-radius: 6px;
    border: 1px solid var(--border-color);
    margin-right: 0px;
    margin-left: auto;
    margin-top: 10px;

    &--image {
      display: flex;
      column-gap: 10px;

      img {
        width: 25px;
      }

      &--assign {
        font-size: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size:fonts.$eduate-default-font-size;
        font-weight: 700;
        font-family: fonts.$eduate-font-family;
      }

      &--text {
        display: block;
        font-size: 12px;
        font-family: fonts.$eduate-font-family;
        margin-top: 5px;
      }
    }

    &--emp-details {
      &--emp-id {
        display: block;
        font-size: 10px;
        font-family: fonts.$eduate-number-font;
      }

      b {
        font-size: 12px;
        font-family: fonts.$eduate-font-family;
      }
    }

    &--button {
      align-items: center;
      display: flex;
      column-gap: 15px;
    }
  }

  &__assign {
    @include assignButton();
  }

  &__remove {
    @include removeButton();
  }

  &__replace {
    @include replaceButton();
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 175px);
  }

  &__table {
    @include table();

    &--open-elective {
      td {
        background-color: var(--bg-color);
        color: var(--text-color);
        font-weight: 700;
        font-size: 16px;
      }
    }

    &--code {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--teacher {
      width: calc(100% - 80px);

      img {
        width: 25px;
      }

      span {
        display: block;
      }

      &--details {
        display: flex;
        column-gap: 10px;
      }
    }

    &--actions {
      img {
        width: 25px;
        filter: invert(0%);

        &:hover {
          background:colors.get-color(colors.$colors, white);
          border-radius: 50%;
        }
      }

      ul {
        margin-bottom: 0px !important;
      }
    }
  }

  &__list {
    height: 100%;

    &--title {
      display: flex;
      justify-content: space-between;
    }

    &--filter {
      .MuiAutocomplete-root {
        margin-top: 10px;
      }
    }

    &--tableblock {
      height: calc(100% - 125px);
      margin-top: 10px;
    }

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 92%);
        display: flex;
        font-family: fonts.$eduate-number-font;

        img {
          width: 20px;
        }
      }

      &--id {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }

      &--code {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--exp {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--grade {
        width: calc(100% - 90%);
      }

      &--actions {
        width: calc(100% - 92%);
        color: colors.get-color(colors.$Green, level_7) !important;

        &:hover {
          text-decoration: underline;
          font-weight: 700;
        }
      }
    }
  }
}