@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.eduate-user-association {
  height: calc(100% - 140px);
  margin: 10px 20px 0px 20px;
  &__search-options {
    column-gap: 20px;
  }
  &__data {
    height: calc(100% - 85px);
    margin-top: 10px;
    display: flex;
    gap: 20px;
    &--tableblock {
      height: 100%;
      width: calc(100% - 250px);
      &--table {
        @include table();
        @include tableCheckbox();
        &--checkbox {
          width: calc(100% - 95%);
        }
        &--icons {
          width: calc(100% - 95%);
        }
        &--subdata-icons {
          padding-left: 30px !important;
        }
        &--name {
          width: calc(100% - 85%);
        }
        &--slno {
          width: calc(100% - 96%);
          font-family: fonts.$eduate-number-font;
        }
        &--th-slno {
          width: calc(100% - 96%);
        }
        &--place {
          width: calc(100% - 85%);
        }
        &--person {
          width: calc(100% - 90%);
        }
        &--mobile {
          width: calc(100% - 92%);
          font-family: fonts.$eduate-number-font;
        }
        &--total {
          width: calc(100% - 95%);
          font-family: fonts.$eduate-number-font;
        }
        &--th-mobile {
          width: calc(100% - 92%);
        }
        &--th-total {
          width: calc(100% - 95%);
        }
        &--modal {
          @extend .eduate-user-association__data--tableblock--table;
          height: calc(100% - 30px);
        }
      }
    }
    &--userblock {
      width: 250px;
      &--users {
        height: calc(100% - 35px);
        padding: 10px;
        overflow: auto;
        li {
          display: flex;
          justify-content: space-between;
        }
        span {
          font-family: fonts.$eduate-font-family;
          font-weight: 500;
        }
        &--totalcount {
          background-color: var(--level-4);
          height: 27px;
          border-radius: 50%;
          -moz-border-radius: 50%;
          -webkit-border-radius: 50%;
          width: 27px;
          text-align: center;
          padding: 2px;
          font-family: fonts.$eduate-number-font;
          color:colors.get-color(colors.$colors, white);
        }
      }
    }
  }
}
.view-eduate-associated-list {
  height: calc(100% - 40px);
}

.eduate-user-association__data--userblock--users::-webkit-scrollbar {
  @include scrollbar();
}

.eduate-user-association__data--userblock--users::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.eduate-user-association__data--userblock--users::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
