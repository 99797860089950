@use "../../../scss/common/mixins" as *;
@use "../../../scss/common/colors" as colors;
@use "../../../scss/common/fonts" as fonts;
$LOGIN_PAGE_BG: #01366e;
$DEV_LOGIN_BG: #FF0000;
.login {
  height: 100%;
  display: flex;
  background-color: $LOGIN_PAGE_BG;
  .react-tel-input {
    top: 0px !important;
  }
  &__left-block {
    height: 100%;
    width: 75%;
    position: relative;

    &--container {
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      margin: 20px;
      border-radius: 30px;
      background: var(--bg-color);
      box-shadow: -61.733333587646484px 61.733333587646484px
          61.733333587646484px 0px rgba(255, 255, 255, 0.07) inset,
        61.733333587646484px -61.733333587646484px 61.733333587646484px 0px rgba(
            86,
            97,
            114,
            0.07
          ) inset;
      backdrop-filter: blur(12.5px);
    }
  }

  &__right-block {
    height: 100%;
    width: 35%;

    &--container {
      margin: 20px;
      height: calc(100% - 135px);
      width: calc(100% - 40px);
      display: flex;
      justify-content: center;
      align-items: center;

      &--data {
        width: 100%;
        &--title {
          display: flex;
          justify-content: center;
          column-gap: 10px;

          h4 {
            font-weight: 700;
            font-size: 20px;
            font-family: fonts.$eduate-font-family;
            margin-top: 10px;
            color: colors.get-color(colors.$colors, white);
          }
        }
      }

      b {
        font-weight: 700;
        font-size: 26px;
        display: flex;
        justify-content: center;
        font-family: fonts.$eduate-font-family;
        margin-top: 20px;
        color: colors.get-color(colors.$colors, white);
      }
    }
  }

  &__block {
    height: 330px;
    padding: 20px;
    border-radius: 6px;
    box-shadow: -61.733333587646484px 61.733333587646484px 61.733333587646484px
        0px rgba(255, 255, 255, 0.07) inset,
      61.733333587646484px -61.733333587646484px 61.733333587646484px 0px rgba(
          86,
          97,
          114,
          0.07
        ) inset;
    backdrop-filter: blur(12.5px);
    margin: 20px;
    background: $LOGIN_PAGE_BG;

    .Label {
      color: colors.get-color(colors.$colors, white);
    }

    .MuiFormControl-root {
      background-color: var(--bg-color) !important;
      border-radius: 10px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 0px !important;
    }

    .MuiOutlinedInput-input {
      padding: 5.5px 14px !important;
    }

    .MuiSvgIcon-root {
      font-size: 20px !important;
    }
    &--flex {
      display: flex;
      justify-content: space-between;
    }

    &--span {
      font-size: 14px;
      font-family: fonts.$eduate-font-family;
      color: colors.get-color(colors.$cool_grey, level_5);
      display: flex;
      justify-content: center;
      margin-top: 10px;
    }
    &--phnno {
      display: block;
      margin: auto;
      margin-top: 10px;
      border-radius: 20px;
      background-color: var(--bg-color);
      color: colors.get-color(colors.$red, level_5);
      font-size: 14px;
      font-family: fonts.$eduate-font-family;
      font-weight: 700;
      padding: 5px 10px;
    }
    &--clear {
      height: 25px;
      padding: 2px 12px;
      background-color: colors.get-color(colors.$red, level_7);
      color: white;
      font-size: 14px;
      font-family: fonts.$eduate-font-family;
      border-radius: 20px;
      margin-top: 10px;
    }
    &--otp {
      width: 25px !important;
      height: 25px !important;
    }
  }
  &__tabs {
    position: relative;
    height: 100%;
    padding-top: 30px;
    padding-left: 10px;
    &--left {
      background-color: $LOGIN_PAGE_BG;
      border-radius: 30px;
      position: absolute;
      top: 40;
      left: 10;
      width: 60px;
      ul {
        margin-bottom: 0px !important;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding-top: 30px;
        padding-bottom: 30px;
        li {
          cursor: pointer;
        }
        .active {
          background-color: var(--bg-color);
          border-radius: 20px 0px 0px 20px;
          margin-left: 10px;
          padding: 5px;
          width: calc(100% - 10px);
          img {
            filter: invert(0%);
          }
        }

        img {
          width: 35px;
          height: 35px;
          filter: invert(100%);
          margin: auto;
          display: block;
        }
      }
    }
    &--right {
      width: calc(100% - 100px);
      height: calc(100% - 40px);
      position: relative;
      left: 80px;
      &--video-block {
        width: calc(100% - 40px);
        display: flex;
        align-items: center;
        column-gap: 10px;
        height: calc(100% - 115px);
        img {
          width: 25px;
          height: 25px;
          cursor: pointer;
        }
      }
      &--video {
        height: 100%;
        width: calc(100% - 40px);
      }
      p {
        font-size: 15px;
        font-family: fonts.$eduate-font-family;
      }
      h5 {
        border-radius: 30px;
        background: linear-gradient(
          180deg,
          rgba(1, 54, 110, 0.1) 0%,
          rgba(2, 104, 212, 0.2) 100%
        );
        backdrop-filter: blur(7.434642314910889px);
        padding: 3px;
        text-align: center;
        font-family: fonts.$eduate-font-family;
      }
    }
  }
  &__dev {
    @extend .login;
    background-color: $DEV_LOGIN_BG;

  }
}

.loginfield {
  width: 100%;

  input {
    font-weight: 400 !important;
  }
}
#recaptcha-container {
  display: flex;
  justify-content: center;
}
