@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.test-planner {
 @include mainFrameWithTitle();

  &__cards {
    height: 60px;
    margin-top: 10px;

    &--list {
      display: flex;
      column-gap: 10px;
      height: 100%;

      &--grid {
        @include gridsInExamPlannerAndSubjectDetails();
      }
    }
  }

  &__filters {
    column-gap: 10px;

    .MuiAutocomplete-root {
      margin-top: 10px;
    }

    &--textfield {
      @include textfieldInForms();
      width: fit-content !important;
      margin-left: 10px !important;
    }
  }

  &__data {
    height: calc(100% - 165px);
    overflow: auto;
    margin-top: 10px;

    &--list {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(5, 1fr);

      .planned {
        background-color: colors.get-color(colors.$Green, level_1);
      }

      .notplanned {
        background-color: colors.get-color(colors.$red, level_1);
      }
    }

    &--li {
      margin-bottom: 10px;
      padding: 5px;

      &:hover {
        background-color: var(--level-2) !important;
        border-radius: 3px;
      }

      img {
        width: 20px;
      }

      span {
        color: var(--text-color);
        font-size:fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
      }
    }

    &--grid {
      @include blocksInDashboard();
      &--image {
        width: 20px;
        margin-left: auto;
        margin-right: 0px;
        display: block;
      }

      &--title {
        color: var(--text-color);
        font-size: 16px;
        font-family: fonts.$eduate-font-family;
        text-align: center;
        display: block;
      }

      &--subtitle {
        color: colors.get-color(colors.$grey, level_7);
        font-size: 12px;
        font-family: fonts.$eduate-font-family;
        text-align: center;
        display: block;
      }
      &--derivative {
        @include derivativeSpan();
        display: block;
        margin-left: auto;
        margin-right: auto;

      }  
         &--non-academic{
        @include nonAcademicSpan();
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
      &--flex {
        display: flex;
        justify-content: center;
        column-gap: 10px;
        margin-top: 10px;

        &--textfield {
          @include textfield();
          margin: 0px 0px 0px 0px !important;
          width: fit-content !important;
        }
      }

      .font-red {
        text-align: center !important;
      }

      &--number {
        font-family: fonts.$eduate-number-font;
        font-size:fonts.$eduate-default-font-size;
        display: block;
        text-align: center;
        color: var(--text-color);
      }

      &--total {
        column-gap: 10px;
        margin-top: 10px;
      }

      &--view {
        margin-top: 10px;
        display: flex;
        justify-content: center;

        button {
          @include viewButton();
        }
      }
    }
  }

  &__view {
    @include mainFrameWithTitle();

    &--filters {
      column-gap: 10px;
      margin: 10px 0px;
    }

    &--cards {
      height: 60px;
      margin-top: 10px;

      &--list {
        display: flex;
        column-gap: 10px;
        height: 100%;

        &--grid {
          @include gridsInExamPlannerAndSubjectDetails();
        }
      }
    }

    &--tableblock {
      height: calc(100% - 200px);
      margin-top: 10px;
    }

    &--table {
      @include table();

      &--number {
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
      }

      &--actions {
        width: calc(100% - 85%);

        .allocate-button {
          @include assignButton();
        }

        .edit-button {
          @include updateButton();
          margin: 5px;
        }

        .delete-button {
          @include deleteButton();
        }
      }

      &--groupheader {
        th {
          height: 30px;
        }
      }

      &--subheader {
        th {
          top: 30px;
        }
      }
    }
  }
}

.test-planner__data::-webkit-scrollbar {
  @include scrollbar();
}

.test-planner__data::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.test-planner__data::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
