
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.staffconfiguration {
  height: 100%;
  &__department {
    background: #fffff0;
    border-radius: 20px;
    // padding: 6px;
    width: 100%;
    margin-bottom: 3px;
    &--footer {
      margin: 5px;
      label {
        background: rgba(0, 0, 0, 0.06);
        border-radius: 30px;
        padding: 4px;
        margin: 2px;
        font-size: 12px;
      }
    }
    b {
      font-size: 13px;
      margin-left: 10px;
      margin-top: 0px !important;
      color: colors.get-color(colors.$colors, black);
    }
  }
  &__user {
    text-transform: uppercase;
    margin-left: 20px;
    font-size: 18px;
    color: var(--text-color);
    font-family: fonts.$eduate-font-family;
  }
  &__branch {
    @extend .staffconfiguration__department;
    background: #fff5f5;
    &--footer {
      @extend .staffconfiguration__department--footer;
    }
  }
  &__class {
    @extend .staffconfiguration__department;
    background: #f0fff4;
    &--footer {
      @extend .staffconfiguration__department--footer;
    }
  }
  &__semester {
    @extend .staffconfiguration__department;
    background: #e6fffa;
    &--footer {
      @extend .staffconfiguration__department--footer;
    }
  }
  &__new {
    margin-top: 20px;
    height: calc(100% - 50px);
    overflow-y: auto;
  }
  &__section {
    @extend .staffconfiguration__department;
    background: #faf5ff;
    &--footer {
      @extend .staffconfiguration__department--footer;
    }
  }
  &__buttonspace {
    margin: 0px 0px 10px 20px;
  }
}
#staff-layout {
  height: calc(100% - 130px);
  margin: 0px 10px 0px 10px;
}

.staffdetails::-webkit-scrollbar {
  width: 18px;
  border-radius: 20px;
}

.staffdetails::-webkit-scrollbar-thumb {
  border: 5px solid white;
  background-color: colors.get-color(colors.$colors, gray);
  border-radius: 30px;
}
.staffdetails::-webkit-scrollbar-thumb:hover {
  border: 3px solid white;
  border-radius: 20px 0px;
}
#staff {
  height: calc(100% - 50px);
}
.staff-frame {
  height: calc(100% - 10px);
  overflow-y: auto;
  .Heading {
    color: var(--level-5);
  }
}
.staffdetails {
  height: calc(100% - 70px);
  overflow-y: auto;
  .Label {
    margin-top: 0px;
  }
}
.staff-frame::-webkit-scrollbar {
  @include scrollbar();
}

.staff-frame::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.staff-frame::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
