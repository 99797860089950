@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.student_add_step {
  height: 100%;
}

.student_docs {
  height: calc(100% - 145px);
  margin: 10px 20px 0px 20px;

  &__body {
    &--table {
      @include table();
      height: calc(100% - 30px);

      .storybook-button {
        margin-top: 0px !important;
      }
      &--actions {
        div {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        input {
          display: none;
        }
      }
      &--imput {
        @include inputReportsTable();
        width: calc(100% - 92%);
      }
    }
  }

  &__table {
    &--create {
      height: calc(100% - 280px);
    }
    &--update {
      height: calc(100% - 205px);
    }
  }
}
