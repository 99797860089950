@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.transport-assign-route {
  @include mainFrameWithTitle();
  &__modal {
    margin: 0px;
    height: calc(100% - 30px);
  }
  &__details {
    height: 120px;
    column-gap: 20px;
    &--image-flex {
      display: flex;
      .MuiAutocomplete-root {
        margin-top: 8px;
      }
      .data-fetch-icon {
        margin-top: 8px;
      }
    }
    &--textfield {
      @include textfield();
      margin: 8px 0px 0px 0px !important;
      width: 100% !important;
    }
    &--date {
      @include textfield();
      width: fit-content !important;
      margin: 8px 0px 0px 0px !important;
    }
    &--studentprofile {
      @include studentProfile();
    }
  }
  &__datablock {
    height: calc(100% - 205px);
    display: flex;
    gap: 20px;

    .storybook-button--active {
      margin-top: 0px;
      display: flex !important;
      justify-content: flex-end !important;
      margin-right: 0px !important;
      margin-left: auto !important;
    }
    &--textfield {
      display: flex;
      gap: 10px;
      &--date {
        @include textfield();
        width: fit-content !important;
      }
    }
  }
  &__select {
    gap: 20px;
    padding: 10px 0px 0px 0px;
    border-top: 1.8px dashed var(--border-color);
    &--view {
      gap: 20px;
      padding: 5px 0px 0px 0px;
      border-top: 1.8px dashed var(--border-color);
    }
  }
  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 40px);
  }
  &__choosed-route {
    margin-top: 10px;
    padding: 5px 10px;
    height: calc(100% - 10px);
    border: 1.8px dashed var(--border-color);
    &--list {
      height: calc(100% - 95px);
      margin-top: 20px;
      overflow: auto;
      padding-top: 5px;
      li {
        width: 100%;
        margin-top: 5px;
      }
      &--textfield {
        @include textfield();
        margin: 0px 0px 5px 0px !important;
        .Mui-disabled input {
          -webkit-text-fill-color: var(--level-5) !important;
        }
      }
    }
    &--label-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  &__view {
    height: calc(100% - 30px);
  }
  &__delete {
    height: calc(100% - 30px);
  }
}

.transport-assign-route__choosed-route--list::-webkit-scrollbar {
  @include scrollbar();
}

.transport-assign-route__choosed-route--list::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.transport-assign-route__choosed-route--list::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
