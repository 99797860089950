@use "../../../scss/common//mixins" as *;
@use "../../../scss/common/fonts" as fonts;
@use "../../../scss/common/colors" as colors;
.vms-v-details-new {
  margin: 0px 20px;
  height: calc(100% - 150px);

  &__stepper {
    margin-top: 10px;
  }

  &__data {
    height: calc(100% - 140px);
    overflow: auto;
    margin-top: 8px;
    column-gap: 40px;

    &--update {
      @extend .vms-v-details-new__data;
      height: calc(100% - 78px);
    }

    &--grid {
      display: grid;
      grid-template-columns: 0.6fr 1fr 0.1fr;
      column-gap: 10px;
    }

    &--image {
      background-color: var(--level-4);
      margin-top: 10px;
      border-radius: 3px;
    }
  }

  &__other-details {
    height: 135px;

    &--common {
      column-count: 3;
      column-gap: 20px;
      height: 100%;
      border-bottom: 1.6px dashed var(--border-color);
    }

    &--textfield {
      @include textfield();
      margin-top: 10px !important;
    }

    &--data {
      margin-top: 10px;
      height: calc(100% - 277px);
      overflow: auto;
      column-gap: 30px;

      &--update {
        @extend .vms-v-details-new__other-details--data;
        height: calc(100% - 205px);
      }

      &--grid {
        display: grid;
        grid-template-columns: 1fr 0.9fr 0.8fr 0.8fr;
      }
    }
  }

  &__current-details {
    height: calc(100% - 277px);
    overflow: auto;
    margin-top: 10px;

    &--update {
      @extend .vms-v-details-new__current-details;
      height: calc(100% - 205px);
    }

    .font-red {
      text-align: left !important;
    }

    .MuiSwitch-root {
      margin-top: 18px;
    }

    &--insurance {
      column-gap: 30px;
      padding-bottom: 10px;
      border-bottom: 1.6px dashed var(--border-color);
    }

    &--title {
      font-family: fonts.$eduate-font-family;
      font-size: 16px;
      font-weight: 700;
    }

    &--tyre-details {
      column-gap: 30px;
    }
  }
}

.vms-v-details-new__data::-webkit-scrollbar,
.vms-v-details-new__other-details--data::-webkit-scrollbar,
.vms-v-details-new__current-details::-webkit-scrollbar {
  @include scrollbar();
}

.vms-v-details-new__data::-webkit-scrollbar-thumb,
.vms-v-details-new__other-details--data::-webkit-scrollbar-thumb,
.vms-v-details-new__current-details::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.vms-v-details-new__data::-webkit-scrollbar-thumb:hover,
.vms-v-details-new__other-details--data::-webkit-scrollbar-thumb:hover,
.vms-v-details-new__current-details::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
