@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.receiptconfig {
  height: calc(100% - 135px);
  &__btns {
    margin-left: 10px;
    margin-top: 5px;
  }
  &__checkbox {
    margin-top: 2px;
  }
  &__settings {
    width: 30px;
    padding-top: 5px;
  }
  &__blocks {
    height: 100%;
    overflow-y: auto;
    &--grid {
      display: grid;
      grid-template-columns: 0.57fr 2fr;
    }
  }
}
.receiptconfig__blocks::-webkit-scrollbar {
  @include scrollbar();
}
.receiptconfig__blocks::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.receiptconfig__blocks::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
