@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.channeldescription {
  height: 100%;

  h4 {
    text-decoration: underline;
    text-align: center;
  }

  &__channelname {
    color: var(--level-5) !important;
    text-transform: capitalize !important;
    padding-left: 5px !important;
  }

  &__frame {
    border: 1px solid #dedede;
    border-radius: 5px;
    padding: 10px;
    margin: 10px;
    height: 100%;

    &--userlist {
      height: calc(100% - 70px);
      overflow-y: auto;

      &--table {
        @include table();

        &--th-slno {
          width: calc(100% - 92%);
        }

        &--slno {
          width: calc(100% - 92%);
          font-family: fonts.$eduate-number-font;
        }

        &--desc {
          width: calc(100% - 80%);
        }
      }
    }

    &--header {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
  }

  &__buttonspace {
    margin: 10px 0px 0px 10px;
  }

  &__totalCount {
    @include totalCountShadowEffect();
  }
}

#chat-layout {
  height: calc(100% - 140px);
  margin: 0px 10px 10px 10px;
  column-gap: 20px;

  .MuiAutocomplete-endAdornment {
    right: 10px !important;
  }

  .institution__table {
    margin-top: 10px !important;

    tr:hover .institution__table--name {
      @include tdContainsOnclick();
    }

    &--slno {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--action {
      width: calc(100% - 88%);
    }

    &--year {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--date {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-slno {
      width: calc(100% - 90%);
    }

    &--th-year {
      width: calc(100% - 88%);
    }

    &--th-date {
      width: calc(100% - 88%);
    }
  }
}

#channeluser-layout {
  height: calc(100% - 140px);
  margin: 0px 10px 10px 10px;
}

#channel-tabel {
  height: calc(100% - 40px);
}

.chat-frame {
  height: calc(100% - 30px);
  overflow: auto;

  &--multiselect {
    @include muiMultiSelect();
  }
}

.chat-frame::-webkit-scrollbar {
  @include scrollbar();
}

.chat-frame::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.chat-frame::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

.channeldescription__frame--userlist::-webkit-scrollbar {
  @include scrollbar();
}

.channeldescription__frame--userlist::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.channeldescription__frame--userlist::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}