@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.pending-data-std-posting {
  @include mainFrameWithTitle();

  &__datablock {
    height: calc(100% - 95px);
    margin-top: 10px;
    &--actions {
      @include assignButton();
    }
    &--status {
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 30px;
    }
  }
  &__std-info {
    height: 100%;
    &--title {
      img {
        @include closeIconInModal();
      }
    }
    &--select {
      height: 130px;
      border-bottom: 1px dashed var(--border-color);
      padding-bottom: 5px;
      column-gap: 20px;
      .student-profile {
        @include studentProfile();
        height: 100%;
        margin-top: 10px;
      }
    }
    &--row-span {
      column-count: 2;
      column-gap: 20px;
    }
    &--textfield {
      @include textfield();
      margin: 10px 0px 0px 0px !important;
    }
    &--data {
      height: calc(100% - 235px);
      margin-top: 10px;
      border: 1px solid var(--border-color);
      border-radius: 6px;
      padding: 10px 5px 5px;
      background-color: var(--level-1);
      h4 {
        font-family: fonts.$eduate-font-family;
        font-size: 16px;
        color: var(--text-color);
        text-align: center;
      }
    }
    &--sub-title {
      color: var(--level-6);
      font-weight: 700;
      font-family: fonts.$eduate-font-family;
      text-align: center;
      padding-top: 5px;
    }
    &--year {
      @include textfield();
      margin: 10px 0px 0px 0px !important;
      width: fit-content !important;
    }

    &--flex {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      column-gap: 10px;
      .MuiAutocomplete-root {
        width: 200px !important;
        margin-top: 10px;
      }
    }
    &--class-info {
      height: 80px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 3px;
      background-color: var(--level-2);
      margin-top: 10px;
      padding: 0px 10px;
      &--from {
        text-align: center;
        font-family: fonts.$eduate-font-family;
        font-size: 14px;
        span {
          display: block;
          color:colors.get-color(colors.$cool_grey , level_7);
          font-family: fonts.$eduate-font-family;
          font-size: 14px;
        }
      }
      &--to {
        @extend .pending-data-std-posting__std-info--class-info--from;
        span {
          color: var(--level-6);
          font-weight: 700;
        }
      }
    }
    &--balance {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;
      .font-red {
        font-family: fonts.$eduate-number-font;
      }
      b {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        color: var(--text-color);
      }
      span {
        color: colors.get-color(colors.$cool_grey, level_6);
        font-size: 12px;
        font-family: fonts.$eduate-font-family;
      }
    }
    &--status {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 15px;
      b {
        font-family: fonts.$eduate-font-family;
        font-size: fonts.$eduate-default-font-size;
      }
      label {
        display: block;
        font-size: 12px;
        font-family: fonts.$eduate-font-family;
        color: colors.get-color(colors.$colors, blue);
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &--button {
      display: flex;
      justify-content: center;
      margin-top: 15px;
      .storybook-button {
        width: 100% !important;
        text-align: center;
      }
    }
  }
}
