@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.book-issue {
  height: calc(100% - 220px);
  margin: 0px 20px 0px 20px;

  &__details {
    height: 130px;
    column-gap: 10px;

    &--colspan {
      column-count: 2;
      column-gap: 20px;
      margin-right: 10px;
    }
    &--imageflex {
      display: flex;
      margin-top: 5px;
      margin-right: 10px;
      .MuiAutocomplete-root {
        margin-top: 5px !important;
      }
    }
    &--textflex {
      display: grid;
      margin-right: 10px;
      grid-template-columns: 1.4fr 1fr 0.6fr 1fr;
      span {
        margin: auto;
        font-weight: bold;
        font-size: 20px;
      }
    }
    &--image {
      @include studentProfile();
    }
    &--textfield {
      @include textfield();
      margin: 10px 0px 0px 0px !important;
      &--date {
        @extend .book-issue__details--textfield;
        width: fit-content;
      }
    }
  }
  &__tableblock {
    height: calc(100% - 130px);
    &--books-table {
      @include table();
      height: calc(50% - 30px);
      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-slno {
        width: calc(100% - 96%);
      }
      &--accno {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-accno {
        width: calc(100% - 88%);
      }
      &--publication {
        width: calc(100% - 85%);
      }
      &--date {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-date {
        width: calc(100% - 92%);
      }
      &--amount {
        width: calc(100% - 95%);
        font-family: fonts.$eduate-number-font;
      }
      &--th-amount {
        width: calc(100% - 95%);
      }
      &--actions {
        width: calc(100% - 95%);
      }
    }
  }
  &__date {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;
  }
  &__view {
    column-gap: 10px;
    &--colspan {
      column-count: 2;
      gap: 10px;
    }
    &--librarybook {
      height: 120px;
      margin-top: 10px;
      width: 100%;
    }
    &--textfield {
      @include textfield();
      margin: 10px 10px 0px 0px !important;

      &--price {
        @extend .book-issue__view--textfield;

        border-radius: 5px;
        padding: -1px;
      }
    }
  }
}

.book-issue__tableblock--books-table::-webkit-scrollbar {
  @include scrollbar();
}

.book-issue__tableblock--books-table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.book-issue__tableblock--books-table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
