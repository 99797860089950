
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.student_add_step {
  height: 100%;
}

.studentregistration {
  height: calc(100% - 145px);
  margin: 10px 20px 0px 20px;

  .MuiAutocomplete-endAdornment {
    right: 10px !important;
  }

  .date-input {
    display: grid;
    grid-template-columns: 0.76fr 0.8fr;
    column-gap: 20px;

    span {
      margin-left: 5px;
    }

    .label-grid {
      display: grid;
      grid-template-columns: 1.54fr 1fr;
    }

    &--label {
      margin-top: 15px;
      font-size:fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
      color: colors.get-color(colors.$cool_grey, level_6);
    }
  }

  &__span-2 {
    column-count: 2;
    column-gap: 60px;
  }

  &__span-3 {
    column-count: 3;
    column-gap: 20px;
  }

  &__basic-data {
    column-gap: 80px;

    &--update {
      height: calc(100% - 40px);
      overflow: auto;
      column-count: 2;
      column-gap: 40px;
    }
  }

  &__form {
    height: calc(100% - 60px);

    &--details {
      height: calc(100% - 75px);
      overflow: auto;
    }
  }
}

.previous-inst {
  height: calc(100% - 145px);
  margin: 10px 20px 0px 20px;

  &__form {
    height: calc(100% - 190px);

    &--details {
      margin-top: 10px;
      height: calc(100% - 55px);
      overflow: auto;

      .MuiAutocomplete-endAdornment {
        right: 10px !important;
      }
    }
  }
}

.parent-student {
  margin-top: 30px;
}

.parent-student span {
  color: grey;
  display: block;
  padding: 5px;
  font-family: fonts.$eduate-font-family;
  font-size:fonts.$eduate-default-font-size;
}

.open-button {
  background: var(--level-5);
  // height: 20px;
  padding: 6px;
  margin-top: 12px;
  margin-left: 10px;
  border-radius: 6px;
  filter: invert(0%);
}

.student-parent-association {
  column-gap: 20px;
}

.studentregistration__form--details::-webkit-scrollbar,
.previous-inst__form--details::-webkit-scrollbar,
.std-reservation__add--data::-webkit-scrollbar,
.studentregistration__basic-data--update::-webkit-scrollbar {
  @include scrollbar();
}

.studentregistration__form--details::-webkit-scrollbar-thumb,
.previous-inst__form--details::-webkit-scrollbar-thumb,
.std-reservation__add--data::-webkit-scrollbar-thumb,
.studentregistration__basic-data--update::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.studentregistration__form--details::-webkit-scrollbar-thumb:hover,
.previous-inst__form--details::-webkit-scrollbar-thumb:hover,
.std-reservation__add--data::-webkit-scrollbar-thumb:hover,
.studentregistration__basic-data--update::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
