@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.std-edit-adm-no {
 @include mainFrameWithTitle();

  &__tabs {
    @include muiTabs();
  }

  &__swipable {
    height: calc(100% - 40px);

    .react-swipeable-view-container,
    .MuiTypography-root {
      height: 100%;
    }

  }

  &__block {
    height: 100%;
  }

  &__details {
    height: 130px;
    column-gap: 20px;
    top: 10px;
    position: relative;

    &--image-field {
      display: flex;

      .MuiAutocomplete-root {
        margin-top: 8px !important;
      }

      .data-fetch-icon {
        margin-top: 7px !important;
      }
    }

    &--textfield {
      @include textfield();
      margin: 8px 0px 0px 0px !important;
    }

    &--profile {
      @include studentProfile();
    }

    #receivedamount {
      margin-top: 7px;
      text-align: left !important;
      padding-right: 10px;
    }
  }

  &__datablock {
    height: calc(100% - 180px);
  }

  &__bulk {
    height: 100%;

    &--select {
      column-gap: 10px;
      top: 10px;
      position: relative;

      #search {
        margin-top: 0px !important;
      }
    }

    &--tableblock {
      margin-top: 15px;
      height: calc(100% - 97px);
    }

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }

      &--desc {
        width: calc(100% - 90%)
      }

      &--admno {
        width: calc(100% - 92%);
      }

      &--input {
        width: calc(100% - 92%);
        @include inputReportsTable();
      }
    }
  }

}