@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.group-ledger {
  height: calc(100% - 150px);
  margin: 0px 10px 0px 10px;

  &__blocks {
    height: calc(100% - 70px);
    margin-bottom: 10px;
  }

  &__frames {
    height: 100%;
  }

  &__btns {
    margin-left: 10px;
  }
}

.reorder-groupledger {
  &__tableblock {
    height: calc(100% - 220px);
    margin: 10px 20px 0px 20px;

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
      }
    }
  }

  &__total {
    text-align: right;
  }
}