@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.view-feedemand {
  height: calc(100% - 78px);
  &__button {
    display: block;
    margin-left: auto;
    margin-right: 10px;
  }
  &__options {
    margin-top: 5px;
    display: flex;
    gap: 10px;
  }
  &__buttons {
    margin: 5px 0px 10px 10px;
  }
  .tree-view {
    margin-top: 0px;
  }

  &__table-view {
    height: calc(100% - 50px);
    overflow: auto;
    margin-top: 10px;
  }
  &__block1 {
    border-radius: 15px;
    padding: 10px;
  }
  &__block2 {
    margin-top: 10px;
    @extend .view-feedemand__block1;
  }
  &__table--pink {
    @include Accountstable();

    tfoot {
      background-color: var(--level-1);
    }
    &--slno {
      width: calc(100% - 94%);
      font-family: fonts.$eduate-number-font;
    }
    &--fee {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-slno {
      width: calc(100% - 94%);
    }
    &--th-fee {
      width: calc(100% - 88%);
    }
  }
  &__table--green {
    @include Accountstable();

    tfoot {
      background-color: var(--level-1);
    }
  }
}
.view-feedemand__table-view::-webkit-scrollbar {
  @include scrollbar();
}
.view-feedemand__table-view::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.view-feedemand__table-view::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
