@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.transport-ledger {
 @include mainFrameWithTitle();
  &__options {
    column-gap: 10px;
    &--image-flex {
      display: flex;
    }
    .MuiAutocomplete-root {
      margin-top: 5px;
    }
  }
  &__amount {
    column-gap: 10px;
    &--input {
      background-color: colors.get-color(colors.$yellow, level_2) !important;
    }
    &--button {
      display: flex;
      justify-content: flex-end;
    }
  }
  &__tableblock {
    height: calc(100% - 135px);
    margin-top: 10px;
  }
  &__table {
    @include table();
    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--actions {
      width: calc(100% - 96%);
    }
    &--ledger {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }
  }
}
