@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
.non-acd-test {
 @include mainFrameWithTitle();
  &__subtitle {
    height: 50px;
    display: flex;
    align-items: center;
    color: var(--level-7);
    font-size: 18px;
    font-family: fonts.$eduate-font-family;
    &--nonacademic {
      @include nonAcademicSpan();
    }
  }
  &__grid {
    display: flex;
    height: 50px;
    align-items: center;
    background-color: var(--level-2);
    font-size:fonts.$eduate-default-font-size;
    font-family: fonts.$eduate-font-family;
    width: fit-content;
    column-gap: 10px;
    padding: 10px;
  }
  &__tableblock {
    height: calc(100% - 195px);
    margin-top: 10px;
    border: 1px solid var(--border-color);
    &--without-remarks{
      @extend .non-acd-test__tableblock;
      height: calc(100% - 145px);
    }
  }
  &__table {
    @include table();
    &--grade {
      @include autoCompleteInTableRow();
    }
  }
}
