@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
.std-enquiry-view {
  height: 100%;
  &__title {
    img {
      @include closeIconInModal();
    }
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 110px);
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--count {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--date {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--mobile {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--email {
      width: calc(100% - 88%);
    }

    &--status {
      width: calc(100% - 88%);
    }
  }
}
