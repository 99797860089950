@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.customer-predefined-data {
  @include mainFrameWithTitle();
  &__button {
    display: flex;
    justify-content: flex-end;
    margin-right: 0px;
  }
  &__tableblock {
    height: calc(100% - 88px);
  }
  &__table {
    @include table();
    margin-top: 10px;
    height: calc(100% - 35px);
  }
}
