@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.enquiry-teacher {
    @include mainFrameWithTitle();
    &__filters {
        display: flex;
        justify-content: center;
        column-gap: 10px;
        align-items: center;
    }
    &__textfield {
        @include textfieldInForms();
        width: fit-content !important;
    }
    &__cards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 20px;
        height: 180px;
        margin-top: 10px;
    }
    &__card {
        @include blocksInDashboard();
        height: 100% !important;
        &--title {
            display: flex;
            justify-content: space-between;
            img {
                width: 25px;
            }
        }
        &--url {
            height: 80px !important;
            width: 80px !important;
            display: block;
            margin: 20px auto;
        }
        &--text {
            font-family: fonts.$eduate-font-family;
            font-size: 16px;
            display: flex;
            justify-content: center;
        }
        ul {
            margin-bottom: 0px !important;
        }
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 5px;
            img {
                width: 40px;
                margin-right: 10px;
            }
            span {
                font-family: fonts.$eduate-font-family;
                font-size: 14px;
            }
            b {
                font-size: 16px;
                font-family: fonts.$eduate-number-font;
            }
        }
    }
    &__tableblock {
        margin-top: 10px;
        height: calc(100% - 240px);
        &--title {
            display: flex;
            justify-content: flex-start;
        }
        &--filters {
            column-gap: 10px;
            .storybook-input {
                margin-top: 0px !important;
            }
            .student-total-count {
                float: right;
            }
        }
    }
    &__table {
        height: calc(100% - 75px);
        margin-top: 10px;
        &--button {
            @include viewButton();
        }
    }
}
