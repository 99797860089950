@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.eduate-calendar-events {
  height: calc(100% - 175px);
  margin: 10px 20px 0px 20px;

  &__row {
    height: calc(100% - 45px);
    column-gap: 30px;

    .account-frames {
      margin: 0px 0px 0px 0px !important;
    }
  }

  &__calendar {
    height: 100%;
    border: 1px solid #edf2f7;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 5px;

    &--grid-container {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      height: calc(100% - 40px);
      margin-bottom: 0px !important;
      gap: 10px;
    }

    &--month {
      display: flex;
      justify-content: center;
      column-gap: 40px;
    }

    &--day {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
    }

    &--date {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: fonts.$eduate-number-font;
      font-size: fonts.$eduate-default-font-size;
      border: 1px solid var(--border-color);
      border-radius: 6px;
      position: relative;

      &:hover {
        background-color: var(--level-1);
      }
    }
  }

  &__textfield {
    @include textfield();

    &--date {
      width: fit-content !important;
      @include textfield();
    }
  }

  &__details {
    padding: 5px;
    column-gap: 20px;

    #search {
      margin-top: 0px;
    }

    .eduate-calendar-events__textfield--date {
      margin-right: 20px;
    }
  }

  &__holidays-list {
    height: calc(100% - 70px);
    overflow: auto;
    @include calendarEventsList();
  }

  &__list {
    height: calc(50% - 10px);

    &--list {
      height: calc(100% - 25px);
      overflow: auto;
      @include calendarEventsList();
    }
  }

  &__insert-button {
    margin-bottom: 0px !important;

    img {
      transform: rotate(90deg);
      margin-right: 10px;
    }
  }
}

.eduate-calendar-events__list--list::-webkit-scrollbar,
.eduate-calendar-events__holidays-list::-webkit-scrollbar {
  @include scrollbar();
}

.eduate-calendar-events__list--list::-webkit-scrollbar-thumb,
.eduate-calendar-events__holidays-list::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.eduate-calendar-events__list--list::-webkit-scrollbar-thumb:hover,
.eduate-calendar-events__holidays-list::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
