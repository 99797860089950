@use "../../../scss/common//mixins" as *;
@use "../../../scss/common/fonts" as fonts;
@use "../../../scss/common/colors" as colors;

.vms-staff-details-new {
  margin: 0px 20px;
  height: calc(100% - 150px);

  &__stepper {
    margin-top: 10px;
  }

  &__form {
    height: calc(100% - 140px);
    overflow: auto;
    margin-top: 8px;
    column-gap: 40px;

    &--update {
      @extend .vms-staff-details-new__form;
      height: calc(100% - 78px);
    }

    .MuiSwitch-root {
      margin-top: 15px;
    }

    &--grid {
      display: grid;
      grid-template-columns: 0.425fr 1fr 0.1fr;
      column-gap: 10px;
    }

    &--date-grid {
      display: grid;
      grid-template-columns: 1fr 0.9fr 0.8fr 0.8fr;
    }

    &--image {
      background-color: var(--level-4);
      margin-top: 10px;
      border-radius: 3px;
    }
  }

  &__documents-details {
    height: 300px;
    margin: auto;
    width: 450px;
    border: 1px solid var(--border-color);
    border-radius: 10px;
    margin-top: 20px;

    &--update {
      @extend .vms-staff-details-new__documents-details;
      height: 355px;
      margin-top: 10px;
    }

    &--header {
      margin: 5px;
      background: colors.get-color(colors.$cool_grey, level_2);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;
      height: 30px;

      &--name {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        width: calc(100% - 150px);
      }

      &--type {
        @extend .vms-staff-details-new__documents-details--header--name;
        width: 150px;
        border-left: 2px solid colors.get-color(colors.$cool_grey, level_4);
        padding-left: 5px;
      }
    }

    &--list {
      height: calc(100% - 108px);
      overflow: auto;
      padding: 5px;
      display: flex;
      column-gap: 10px;

      &--button {
        font-size: 12px;
        font-weight: 700;
        padding: 3px 8px;
        color: colors.get-color(colors.$colors, white);
        border-radius: 30px;
        font-family: fonts.$eduate-font-family;
        background-color: colors.get-color(colors.$colors, blue);
        display: block;
        margin: 5px auto;
      }

      &--select {
        width: 150px;
      }

      &--file-type {
        width: calc(100% - 150px);

        li {
          display: flex;
          justify-content: space-between;
          padding: 3px;
          background-color: colors.get-color(colors.$cool_grey, level_2);
          border-radius: 6px;
          margin-top: 10px;

          img {
            width: 30px;
            margin-right: 10px;
          }

          span {
            font-size: fonts.$eduate-default-font-size;
            font-family: fonts.$eduate-font-family;
            color: colors.get-color(colors.$grey, level_7);
            width: calc(100% - 70px);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    &--footer {
      @extend .vms-staff-details-new__documents-details--header--name;
      width: 100%;
      font-size: 12px;
      height: 35px;
      background-color:colors.get-color(colors.$cool_grey, level_4);
      border-radius: 0px 0px 10px 10px;
      display: flex;
      align-items: center;
      padding-left: 10px;
    }
  }

  &__documents-block {
    height: calc(100% - 150px);
  }
}

.vms-staff-details-new__documents-details--list::-webkit-scrollbar {
  @include scrollbar();
}

.vms-staff-details-new__documents-details--list::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.vms-staff-details-new__documents-details--list::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
