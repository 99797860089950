@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.student-documents {
  height: calc(100% - 210px);
  margin: 0px 20px 0px 20px;
  &__details {
    height: 120px;
    column-gap: 20px;
    &--flex {
      display: flex;
      column-gap: 5px;
      height: 35px;
      .MuiAutocomplete-root {
        margin-top: 5px;
      }
      .MuiOutlinedInput-root {
        padding: 6px !important;
      }
    }

    &--imagefield {
      display: flex;
      .MuiFormControl-root {
        margin-top: 7px !important;
      }
      .data-fetch-icon {
        margin-top: 7px !important;
      }
    }
    &--textfield {
      @include textfield();
      margin: 7px 10px 0px 0px !important;

      &--date {
        @include textfield();
        width: fit-content !important;
        margin: 7px 10px 0px 0px !important;
      }
    }
    &--image {
      @include studentProfile();
      height: 100%;
      margin-top: 0px;
    }
  }
  &__upload {
    column-gap: 20px;
    margin-top: 10px;

    &--buttons {
      margin-top: 35px;
    }
    &--label-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  &__tableblock {
    height: calc(100% - 120px);
  }
  &__table {
    @include table();
    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-slno {
      width: calc(100% - 96%);
    }
    &--preview {
      width: calc(100% - 96%);
    }
    &--actions {
      width: calc(100% - 96%);
    }
  }
  &__browse {
    @include browseFiles();
    margin: 10px 10px 0px 0px;
  }
}

.student-documents__table::-webkit-scrollbar {
  @include scrollbar();
}

.student-documents__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.student-documents__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
