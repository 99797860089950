
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.active-years {
  margin: 10px 20px 0px 20px;
  height: calc(100% - 255px);
  &__datablock {
    height: calc(100% - 40px);
    column-gap: 40px;
    .booktype-left,
    .booktype-right {
      margin: 0px 0px 0px 0px !important;
    }
  }
  &__tableblock {
    height: calc(100% - 75px);
    margin-top: 10px;
    &--table {
      @include table();
      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }
    }
  }
}
