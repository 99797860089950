.add-deposit-ledgers {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  &--btns {
    width: fit-content;
  }
}
