@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.teacher-report {
  margin: 10px 20px;
  height: calc(100% - 180px);
  display: flex;
  column-gap: 30px;
  &__left {
    height: 100%;
    width: 300px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
  }
  &__right {
    height: 100%;
    width: calc(100% - 300px);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    padding: 10px;
    &--select {
      column-gap: 10px;
      margin-top: 10px;
    }
    &--title {
      font-size: 18px;
      font-family: fonts.$eduate-font-family;
      display: flex;
      justify-content: center;
      color: var(--level-5) !important;
    }
  }
  &__table {
    @include table();
    @include inputReportsTable();
    a {
      color: var(--text-color);
    }
    a.active {
      color: var(--level-5) !important;
      font-weight: 700;
    }
    &--teacher{
      display: flex !important;
      column-gap: 10px;
      img{
        height: 40px;
        width: 40px;
      }
      span{
        display: block;
      }
    }
    &--subjects{
      width: calc(100% - 84%);
      ul{
        margin-bottom: 0px !important;
      }
      li{
        display: flex;
        justify-content: space-between;
        padding: 2px;
      }
    }
  }
  &__tableblock{
    margin-top: 10px;
    height: calc(100% - 90px);
  }
}

.alloted-teacher-report{
  margin: 10px 20px;
  height: calc(100% - 180px);
  display: flex;
  column-gap: 30px;
  &__left {
    height: 100%;
    width: 300px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
  }
  &__right {
    height: 100%;
    width: calc(100% - 300px);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    padding: 10px;
    &--select {
      column-gap: 10px;
      margin-top: 10px;
      .storybook-input{
        margin-top: 0px !important;
      }
    }
    &--title {
      font-size: 18px;
      font-family: fonts.$eduate-font-family;
      display: flex;
      justify-content: center;
      color: var(--level-5) !important;
    }
    &--tableblock{
      margin-top:10px;
      height: calc(100% - 90px);
  
    }
  }
  &__view{
    height: calc(100% - 20px);
    &--details{
      column-gap: 20px;
      height: 120px;
    }
    &--textfield{
      @include textfield();
      margin: 10px 0px 0px 0px !important;
    }
    &--date{
      @include textfield();
      margin: 10px 0px 0px 0px !important;
      width: fit-content !important;
    }
    &--image{
      @include studentProfile();
      height: 100%;
    }
    &--select{
      column-gap: 10px;
      margin-top: 15px;
    }
    &--tableblock{
      height: calc(100% - 200px);
      margin-top: 10px;
    }
  }
}