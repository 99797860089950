@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;

.acd-reports {
  @include mainFrameWithTitle();

  &__select {
    column-gap: 10px;
    margin-top: 10px;
  }

  &__tableblock {
    height: calc(100% - 95px);
    margin-top: 10px;
  }

  &__table {
    @include table();

    &--view-button {
      @include viewButton();
    }

    &--actions {
      width: calc(100% - 90%);
    }
    &--number{
      font-family: fonts.$eduate-number-font;
      width: calc(100% - 92%);
    }
  }

  &__view {
    @include mainFrameWithTitle();

    &--tabs {
      @include muiTabs();
      margin-top: 10px;
    }

    &--swipable {
      height: calc(100% - 150px);
      margin-top: 10px;

      .react-swipeable-view-container,
      p {
        height: 100%;
      }
    }
  }

  &__test {
    height: 100%;

    &--select {
      column-gap: 10px;

      .MuiAutocomplete-root {
        margin-top: 10px;
      }
    }

    &--cards {
      height: 60px;
      margin-top: 10px;

      img {
        width: 40px;
      }
    }

    &--card {
      height: 100%;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 40px;
      background-color: var(--disabled-color);
      padding: 0px 10px;
      border-radius: 6px;

      &--flex {
        display: flex;
        column-gap: 10px;
        align-items: center;

        span {
          font-family: fonts.$eduate-font-family;
          color: colors.get-color(colors.$cool_grey, level_8);
          font-size:fonts.$eduate-default-font-size;
        }

        b {
          font-family: fonts.$eduate-number-font;
          font-size:fonts.$eduate-default-font-size;
          color:colors.get-color(colors.$fuchsia, level_7);
        }
      }
    }

    &--datablock {
      margin-top: 10px;
      height: calc(100% - 125px);
      overflow: auto;
    }

    &--grids {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;
    }

    &--flex {
      display: flex;
      column-gap: 5px;
      justify-content: center;

      &--textfield {
        @include textfieldInForms();
        width: fit-content !important;

        .MuiInputBase-root .Mui-disabled {
          background-color: var(--bg-color) !important;
        }
      }
    }

    &--grid {
      background-color: var(--level-1);
      border-radius: 6px;
      padding: 5px;
      text-align: center;

      &--test-name {
        color: var(--text-color);
        font-family: fonts.$eduate-font-family;
        font-size:fonts.$eduate-default-font-size;
        display: block;
      }

      &--test-type {
        color: colors.get-color(colors.$cool_grey, level_7);
        font-size:fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        display: block;
      }

      &--test-type {
        color: colors.get-color(colors.$cool_grey, level_6);
        font-size:fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        display: block;
      }

      &--sub-text {
        @extend .acd-reports__test--grid--test-type;
        color: colors.get-color(colors.$cool_grey, level_8);
      }

      &--sub-count {
        @extend .acd-reports__test--grid--test-type;
        color: colors.get-color(colors.$cool_grey, level_8);
        font-family: fonts.$eduate-number-font;
      }

      &--view-button {
        @include viewButton();
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;
      }
    }

    &--textfields {
      display: flex;
      column-gap: 5px;
      align-items: center;
    }
  }

  &__per-test {
    @include mainFrameWithTitle();
    &--modal {
      height: calc(100% - 40px);
      .acd-reports__per-test--tableblock {
        height: calc(100% - 165px);
        margin-top: 10px;
      }
    }
    &--title {
      img {
        @include closeIconInModal();
      }
    }
    &--select {
      column-gap: 10px;
      margin-top: 10px;
    }

    &--textfields {
      display: flex;
      justify-content: flex-end;
      column-gap: 10px;
    }

    &--textfield {
      @include textfield();
    }

    &--cards {
      width: fit-content !important;
      display: flex;
      justify-content: space-between;
      background-color: var(--disabled-color);
      margin-top: 10px;
      border-radius: 6px;
      padding: 5px;
      column-gap: 30px;
    }

    &--card {
      display: flex;
      flex-direction: column;

      span {
        font-family: fonts.$eduate-font-family;
        font-size:fonts.$eduate-default-font-size;
      }

      b {
        font-size:fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-number-font;
        text-align: center;
      }
    }

    &--tableblock {
      height: calc(100% - 200px);
      margin-top: 10px;
    }

    &--button {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        border-radius: 30px;
        padding: 5px 15px;
        border: 1px solid var(--border-color);
        font-family: fonts.$eduate-font-family;
        font-size:fonts.$eduate-default-font-size;
        background: var(--bg-color);
        color: var(--text-color);

        &:hover {
          // border: 1px solid var(--level-2);
          color: var(--level-5);
          text-decoration: underline;
          font-weight: 700;
        }
      }
    }
  }

  &__attendance {
    height: 100%;

    &--data {
      height: calc(100% - 45px);
    }

    &--cards {
      @extend .acd-reports__test--cards;
      display: flex;
      column-gap: 10px;
    }

    &--card {
      @extend .acd-reports__test--card;

      &--flex {
        @extend .acd-reports__test--card--flex;
      }
    }

    &--tableblock {
      margin-top: 10px;
      height: calc(100% - 50px);
      border: 1px solid var(--border-color);
    }

    &--month {
      display: flex;
      justify-content: center;
      column-gap: 10px;

      img {
        width: 20px;
      }
    }
  }

  &__view-sub {
    height: 100%;

    &--title {
      display: flex;
      justify-content: space-between;
    }

    &--tableblock {
      margin-top: 5px;
      height: calc(100% - 80px);
    }
  }
}

.acd-reports__test--datablock::-webkit-scrollbar {
  @include scrollbar();
}

.acd-reports__test--datablock::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.acd-reports__test--datablock::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
