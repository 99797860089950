@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/colors" as colors;
@use "../../../../../scss/common/fonts" as fonts;
.test-wise-detailed-report{
    @include mainFrameWithTitle();
    &__select{
        column-gap: 10px;
    }
    &__title{
        display: flex;
        column-gap: 10px;
        margin-top: 5px;
        b{
            font-family: fonts.$eduate-font-family;
            font-size: 16px;

        }
        span{
            font-family: fonts.$eduate-font-family;
        }
    }
    &__tabs{
        @include muiTabs();
        display: flex;
        justify-content: center;
        margin-top: 5px;
    }
    &__tabpanel{
        height: calc(100% - 130px);
        margin-top: 10px;
        p{
            height: 100% !important;
        }
    }
}