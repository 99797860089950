@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.studentdata {
  &__close {
    float: right;
  }
}

.exceldata {
  &__tabledata {
    @include table();
  }
}

.studentexcel {
  &__options {
    display: flex;
    justify-content: space-between;
    button {
      white-space: nowrap;
      font-size: 15px;
      padding: 12px;
    }
  }
  &__input {
    border: 2px solid colors.get-color(colors.$colors, cloud-white);
    padding: 5px;
    margin: 10px;
    // margin-top: 10px;
  }

  &__browse {
    padding: 6px;
    background-color: var(--level-5);
    margin-left: 10px;
    color: white;
    text-transform: uppercase;
    border-radius: 6px;
  }
  &__block {
    margin-left: 10px !important;
  }

  &__downloadtemplate {
    margin-top: 300px !important;
  }
  &__dropdown {
    select {
      @extend .studentexcel__input;
      border: 2px solid colors.get-color(colors.$colors, cloud-white);
      background-color: none;
      padding: 2px;
      color: gray;
    }
  }
}

.choose::-webkit-file-upload-button {
  color: white;
  display: inline-block;
  background: var(--level-5);
  border: none;
  padding: 7px 15px;
  font-weight: 700;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 10pt;
  text-transform: uppercase;
  margin-right: 10px;
}
