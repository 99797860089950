@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.customized-tree-view {
  margin-top: 10px;
  height: calc(100% - 50px);

  &__left {
    border: 1px solid var(--border-color);
    border-radius: 5px;
    padding: 10px;
    height: calc(100% - 45px);

    &--header {
      margin-bottom: 0px !important;
      //   background-color: var(--level-2);
      margin-bottom: 10px;

      &--title {
        width: calc(100% - 500px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: auto;
        font-family: fonts.$eduate-font-family;
        font-size: 18px;
        font-weight: 700;
      }

      &--image {
        width: 100px;
        border: 1px solid var(--border-color);
        border-radius: 6px;

        img {
          display: block;
          margin-left: auto !important;
          margin-right: auto !important;
          justify-content: center !important;
          width: 50px;
        }

        span {
          text-align: center;
          font-family: fonts.$eduate-number-font;
          font-size: 16px;
          font-weight: 700;
          display: block;
          margin-left: auto !important;
          margin-right: auto !important;
          border-top: 1px solid var(--border-color);
        }
      }

      &--li {
        display: flex;
        column-gap: 5px;
        padding: 5px;
      }
    }
  }

  li {
    padding: 2px;
    display: flex;
    column-gap: 5px;
    border-radius: 6px;

    &:hover {
      background-color: var(--level-2);
    }

    &:focus {
      background-color: var(--level-4);
      color: white;
    }
  }

  &__count {
    font-family: fonts.$eduate-number-font;
    font-size:fonts.$eduate-default-font-size;
    text-align: center;
    width: 100px;
  }

  &__total {
    font-family: fonts.$eduate-number-font;
    font-size:fonts.$eduate-default-font-size;
    text-align: center;
    width: 100px;
  }

  &__level-1 {
    height: calc(100% - 95px);
    overflow: auto;

    &--li {
      border: 1.5px dashed var(--border-color);
    }

    &--title {
      width: calc(100% - 500px) !important;
      font-family: fonts.$eduate-font-family;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__level-2 {
    &--title {
      width: calc(100% - 500px) !important;
      font-family: fonts.$eduate-font-family;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &--li {
      border-left: 1.5px dashed var(--border-color);
      border-bottom: 1.5px dashed var(--border-color);
      border-right: 1.5px dashed var(--border-color);
    }
  }
}

.customized-tree-view__level-1::-webkit-scrollbar {
  @include scrollbar();
}

.customized-tree-view__level-1::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.customized-tree-view__level-1::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}