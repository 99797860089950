@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.applicationfee {
  margin: 10px 20px 0px 20px;
  column-gap: 20px;

  .MuiAutocomplete-root {
    margin-bottom: 8px !important;
  }

  &__tableblock {
    height: calc(100% - 350px);
    overflow: auto;
    margin: 0px 10px 0px 10px;
  }

  &__frame {
    margin: 0px 10px 5px 10px !important;
    height: 120px;

    .account-frames {
      margin: 0px 10px 0px 10px;
    }

    Label {
      margin-top: 0px;
    }

    Input {
      margin-top: 3px !important;
    }

    .MuiFormControl-root {
      margin-top: 3px !important;
    }

    &--label-grid {
      display: grid;
      grid-template-columns: 0.6fr 3fr;

    }
  }

  &__buttons {
    margin-left: 20px;
  }

  &__table {
    @include table();
    height: calc(100% - 120px) !important;
    margin-top: 10px;

    &--slno {
      width: calc(100% - 97%);
      font-family: fonts.$eduate-number-font;
    }

    &--regno {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--desc {
      width: calc(100% - 92%);
    }

    &--amount {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-slno {
      width: calc(100% - 97%);
    }

    &--th-regno {
      width: calc(100% - 88%);
    }

    &--th-amount {
      width: calc(100% - 88%);
    }
  }

  &__amount {
    background:  colors.get-color(colors.$amber, level_2) !important;
    @include textfield();
  }

  &__textfield-date {
    @include textfield();
    width: fit-content !important;
    margin: 0px 10px 0px 0px !important;
  }

  &__textfield {
    @include textfield();
    margin: 2px 10px 0px 0px !important;

    input {
      font-weight: 400 !important;
    }
  }
}