
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.eduate-module {
  height: calc(100% - 215px);
  margin: 10px 20px 0px 20px;

  &__modal {
    height: 100%;
  }

  &__details {
    height: calc(100% - 85px);
    column-gap: 20px;
    margin-top: 10px;

    .booktype-left {
      margin: 0px !important;

      .MuiSwitch-root {
        top: 10px !important;
      }
    }

    .booktype-right {
      margin: 0px !important;
    }

    input[type="number"] {
      font-family: fonts.$eduate-number-font;
      width: fit-content !important;
    }

    &--frame {
      height: calc(100% - 25px);
    }

    &--textfield {
      &--sdate {
        @include textfield();
        width: fit-content !important;
        background-color: var(--bg-color);

        input {
          color: colors.get-color(colors.$Green, level_5) !important;
        }
      }

      &--edate {
        @include textfield();
        width: fit-content !important;
        background-color: var(--bg-color);

        input {
          color: colors.get-color(colors.$red, level_6) !important;
        }
      }

      &--gperiod {
        @include textfield();
        background-color: var(--bg-color);

        width: fit-content !important;
      }
    }

    &--add {
      column-gap: 10px;
      display: flex;
      justify-content: space-around;
      height: 130px;

      &--dates {
        display: flex;
        column-gap: 10px;
        justify-content: center;
      }

      &--image {
        text-align: center;
      }

      &--gperiod {
        margin-top: 10px;
        display: flex;
        justify-content: center;
      }

      &--block {
        background-color: colors.get-color(colors.$grey, level_2);
        padding: 20px 10px 10px 10px;
        border-radius: 6px;
      }

      &--license {
        display: flex;
        justify-content: center;

        img {
          width: 20px;
          height: 30px;
          margin-top: 10px;
        }
      }

      &--total {
        text-align: center;
        font-weight: 700;
        color: colors.get-color(colors.$grey, level_8);

        span {
          font-size: 16px;
          font-family: fonts.$eduate-number-font;
        }

        font-size:fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
      }
    }
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--actions {
      width: calc(100% - 96%);
    }
  }

  &__tableblock {
    height: calc(100% - 140px);
    margin-top: 10px;
  }
}
.eduate-module-config{
  height: calc(100% - 165px);
  margin: 10px 20px 0px 20px;
  &__tableblock {
    height: calc(100% - 60px);
    margin-top: 10px;
  }
}