@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.std-enquiry-print {
  height: calc(100% - 45px);

  .Heading {
    margin-bottom: 20px;
  }

  &__url {
    height: 160px !important;
    width: 160px !important;
    display: block !important;
    margin: auto;
    align-items: center !important;

    &--footer1 {
      background-color: var(--level-3);
      border-radius: 30px;
      color: var(--text-color);
      width: fit-content;
      margin-top: 20px;
    }

    &--footer2 {
      color: var(--text-color);
      text-align: center;
      margin-top: 5px;
    }
  }

  &__inst-details {
    height: 100px;
    display: flex;
    column-gap: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dashed var(--border-color);
    padding: 0px 20px;

    &--logo {
      height: 100%;
      width: 100px;

      img {
        display: block;
        margin: auto;
        width: 80px;
        height: 80px;
      }
    }

    &--c-name {
      font-size: 12px !important;
      font-family: fonts.$eduate-font-family;
      color: colors.get-color(colors.$grey, level__6);
      font-weight: 400;
    }

    &--address {
      width: calc(100% - 180px);
      text-align: center;

      span {
        display: block;
        font-size: 12px;
        font-family: fonts.$eduate-font-family;
      }

      b {
        display: block;
        font-size: fonts.$eduate-default-font-size;
        padding: 2px;
        font-family: fonts.$eduate-font-family;
      }
    }
  }

  &__footer {
    text-align: right;
    font-size: fonts.$eduate-default-font-size;
    font-family: fonts.$eduate-font-family;

    img {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }

  &__qrcode {
    height: calc(100% - 140px);
  }

  span {
    font-family: fonts.$eduate-font-family;
    font-size: fonts.$eduate-default-font-size;
    display: block;
    font-weight: 700;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 10px;
  }

  &__config {
    .module-wise-configuration__block--details {
      column-count: 1 !important;
    }
  }
}
