@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.inst-payment-issue{
    height: calc(100% - 170px);
    margin: 0px 80px;
    &__title{
        margin-top: 60px;
        display: flex;
        align-items: center;
        column-gap: 20px;
        height: 100px;
        padding:5px;

        box-shadow:
        0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 4px 6px -2px rgba(0, 0, 0, 0.05);
        b{
            color: colors.get-color(colors.$amber , level_6);
            font-size: 25px;
            font-family: fonts.$eduate-font-family;
            font-weight: 700;
        }
        img{
            width:80px;
            height: 80px;
        }
    }
    &__block{
        color: var(--text-color);
        font-size: 18px;
        font-family: fonts.$eduate-font-family;
        margin-top: 40px;
        text-align: justify;
    }
    button{
        padding:5px 10px;
        font-size: 16px;
        font-family: fonts.$eduate-font-family;
        background-color: var(--bg-color) !important;
        border: 1px solid var(--border-color);
        border-radius: 20px;
        margin-top: 20px;
    }

}