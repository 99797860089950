@use "../../scss/common/mixins" as *;
@use "../../scss/common/colors" as colors;
@use "../../scss/common/fonts" as fonts;
.MuiTablePagination-selectLabel {
  margin-top: 10px;
  font-size: 12px !important;
  font-family: fonts.$eduate-font-family;
  font-weight: bold !important;
}
.MuiTablePagination-select {
  border: 1px solid var(--border-color) !important;
  border-radius: 3px !important;
  .MuiInputBase-root {
    border: 1px solid var(--border-color);
    border-radius: 3px !important;
  }
}

.MuiTablePagination-displayedRows {
  color: var(--level-6) !important;
  font-family: fonts.$eduate-font-family;
  font-weight: bold !important;
  margin-top: 15px;
  font-size: 14px !important;
}
.MuiTablePagination-menuItem {
  font-size: 12px !important;
  font-family: fonts.$eduate-number-font;
  &:hover {
    background-color: var(--level-2) !important;
  }
  .active {
    color: var(--level-6) !important;
    background-color: var(--level-6) !important;
  }
}
.MuiTableFooter {
  .MuiBox-root {
    margin-left: 10px !important;
  }
  .MuiButtonBase-root {
    padding: 5px !important;
  }
}
