@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.institution-calendar-events {
  height: calc(100% - 95px);
  margin: 0px 20px 0px 20px;

  &__data {
    height: calc(100% - 90px);
    column-gap: 30px;
    margin-top: 10px;

    .account-frames {
      margin: 0px 0px 0px 0px !important;
    }

    &--calendar {
      height: calc(50% - 10px);
      column-gap: 30px;

      .react-calendar {
        width: 100%;

        &__navigation {
          button {
            font-size: 30px !important;
          }

          span {
            font-size: 18px !important;
          }
        }
      }

      &--block {
        border: 1px solid #edf2f7;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
          0px 4px 6px -2px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
      }

      .institution-calendar-events__textfield--date {
        margin-top: 10px !important;
      }
    }
  }

  &__insert-button {
    margin-bottom: 0px !important;

    img {
      transform: rotate(90deg);
      margin-right: 10px;
    }
  }

  &__textfield {
    @include textfield();

    &--date {
      width: fit-content !important;
    }
  }

  &__holidays-list {
    height: calc(100% - 70px);
    overflow: auto;
    @include calendarEventsList();

    &--events:hover {
      background-color: var(--level-3);
    }
  }

  &__list {
    height: calc(50% - 10px);
    margin-top: 10px;

    &--list {
      height: calc(100% - 25px);
      overflow: auto;
      @include calendarEventsList();
    }
  }

  &__details {
    padding: 5px;
    column-gap: 20px;

    #search {
      margin-top: 0px;
    }

    .institution-calendar-events__textfield--date {
      margin-right: 20px;
    }
  }

  &__button {
    display: block;
    margin-left: auto;
    margin-right: 0px;
    background-color: colors.get-color(colors.$sky, level_6);

    &:hover {
      background-color: colors.get-color(colors.$sky, level_5);
    }

    &:focus {
      background-color: colors.get-color(colors.$sky, level_8);
    }
  }

  &__predefined-list {
    height: calc(100% - 70px);
    column-gap: 30px;

    .account-frames {
      margin: 0px !important;
    }

    &--data {
      border-top: 1.5px dashed var(--border-color);
      height: calc(100% - 60px);
      overflow: auto;
      @include calendarEventsList();
    }

    .select-all {
      margin-left: 0px;
    }
  }
}

.institution-calendar-events__list--list::-webkit-scrollbar,
.institution-calendar-events__holidays-list::-webkit-scrollbar,
.institution-calendar-events__predefined-list--data::-webkit-scrollbar {
  @include scrollbar();
}

.institution-calendar-events__list--list::-webkit-scrollbar-thumb,
.institution-calendar-events__holidays-list::-webkit-scrollbar-thumb,
.institution-calendar-events__predefined-list--data::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.institution-calendar-events__list--list::-webkit-scrollbar-thumb:hover,
.institution-calendar-events__holidays-list::-webkit-scrollbar-thumb:hover,
.institution-calendar-events__predefined-list--data::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}