@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.bank-ledger {
  &__title {
    color: var(--level-4);
  }
  &__search {
    margin-left: 20px;
  }
  &__tableblock {
    margin: 10px 20px 0px 20px;
  }
  &__table {
    @include Accountstable();
    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--amount {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }
    &--transactions {
      width: calc(100% - 94%);
    }
  }
  &__buttons {
    margin-left: 20px;
  }
}
