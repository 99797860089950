@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/colors" as colors;
@use "../../../../../scss/common/fonts" as fonts;
.test-wise-report {
    @include mainFrameWithTitle();
    &__select {
        column-gap: 10px;
        .MuiAutocomplete-root {
            margin-top: 10px;
        }
    }
    &__datablock {
        height: calc(100% - 95px);
        margin-top: 10px;
        overflow: auto;
        &--cards {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 5px;
        }
        &--card {
            border-radius: 6px;
            border: 1px solid var(--border-color);
            background-color: var(--level-1);
            padding: 5px;
            b {
                font-family: fonts.$eduate-font-family;
                font-size: 16px;
                color: var(--level-6);
                display: flex;
                justify-content: center;
            }
            span {
                font-family: fonts.$eduate-font-family;
                text-align: center;
                font-size: 14px;
                color: colors.get-color(colors.$cool_grey, level_6);
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
            &--flex {
                display: flex;
                column-gap: 2px;
                justify-content: center;
                margin-top: 10px;
                align-items: center;
            }
            &--view {
                @include viewButton();
                display: block;
                margin-left: auto;
                margin-right: auto;
                margin-top: 10px;
            }
        }
  
    }
}


.test-wise-report__datablock::-webkit-scrollbar {
    @include scrollbar();
}

.test-wise-report__datablock::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.test-wise-report__datablock::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}
