@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.challan-receipts {
  height: 100%;
  margin: auto;

  .storybook-button--print {
    margin: 0px 10px 0px 0px !important;
  }

  span,
  b {
    color: var(--text-color);
    font-family: fonts.$eduate-font-family;
  }

  &__ss {
    display: flex;
    height: calc(100% - 45px);
    overflow: auto;
    gap: 30px;
    width: 100%;
  }

  &__dd {
    height: 100%;
    width: 100%;
  }
}

.challan-pr-t1 {
  height: 715px;
  border: 1px solid var(--border-color);
  padding: 5px;

  &__inst-details {
    height: 150px;
    overflow: hidden;
    display: flex;

    flex-direction: column;
    gap: 2px;

    &--logo {
      height: 40px;
      width: 40px;
      display: block;
      margin-left: auto;
      margin-right: auto;

      img {
        width: 100%;
      }
    }

    &--cust-name {
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
      text-align: center;
      color: var(--text-color);
    }

    &--inst-name {
      font-size: 16px;
      font-family: fonts.$eduate-font-family;
      text-align: center;
      color: var(--text-color);
    }

    &--inst-address {
      font-size: 12px;
      font-family: fonts.$eduate-font-family;
      text-align: center;
    }

    &--header {
      font-size: 12px;
      font-family: fonts.$eduate-font-family;
      text-align: center;
    }
  }

  &__receipt {
    font-size: 16px;
    font-family: fonts.$eduate-font-family;
    text-align: center;
    padding: 2px;
    font-weight: 700;
  }

  &__bank-details {
    max-height: 60px;
    overflow: hidden;

    &--flex {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: fonts.$eduate-font-family;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      b {
        font-size: fonts.$eduate-default-font-size;
      }

      &--number {
        font-family: fonts.$eduate-number-font;
      }
    }

    &--flex-2 {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &--bank-name {
      display: flex;
      font-size: 12px;
      font-family: fonts.$eduate-font-family;
      color: var(--text-color);
    }
  }

  &__std-details {
    max-height: 75px;
    border-top: 1px dashed var(--border-color);
    overflow: hidden;

    b {
      font-size: 12px;
      font-family: fonts.$eduate-font-family;
    }

    label {
      font-size: 12px;
      font-family: fonts.$eduate-font-family;
    }
  }

  &__tableblock {
    margin-top: 3px;
    height: calc(100% - 575px);
    overflow: hidden;
  }

  &__table {
    border: 1px solid var(--text-color) !important;
    height: 100%;
    border-radius: 2px;

    th {
      border-top: none !important;
      border-bottom: 1px solid var(--text-color) !important;
      border-right: 1px solid var(--text-color);
      padding: 2px 5px !important;
      color: var(--text-color);
      font-family: fonts.$eduate-font-family;
      font-size: 12px;
      font-weight: 700;
    }

    td {
      border-bottom: 1px solid var(--text-color) !important;
      border-right: 1px solid var(--text-color);
      color: var(--text-color);
      padding: 2px 5px !important;
      color: var(--text-color);
      font-family: fonts.$eduate-font-family;
      font-size: 12px;
    }

    &--amount {
      width: 100px !important;
      border-right: none !important;
    }

    &--footer {
      display: flex;
      align-items: center;
      border-right: 1px solid var(--text-color);
      border-left: 1px solid var(--text-color);
      border-bottom: 1px solid var(--text-color);

      &--total {
        font-size: 12px;
        font-family: fonts.$eduate-font-family;
        width: calc(100% - 100px);
        font-weight: 700;
        text-align: right;
        padding-right: 5px;
      }

      &--amount {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-number-font;
        width: 100px;
        border-left: 1px solid var(--text-color);
        text-align: right;
        font-weight: 700;
        padding-right: 5px;
      }
    }

    // @include tableInPrint();
  }

  &__footer {
    // position: absolute;
    // bottom: 5px;

    &--amount-in-words {
      font-size: 12px;
      font-family: fonts.$eduate-font-family;
      color: var(--text-color);
    }

    &--date-sign {
      margin-top: 30px;
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
      display: flex;
      justify-content: space-between;
    }

    &--date-seal {
      margin-top: 40px;
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px dashed var(--border-color);
    }

    &--receipt-no {
      &--title {
        display: flex;
        justify-content: center;
        font-family: fonts.$eduate-font-family;
        font-size: 16px;
      }

      &--flex {
        display: flex;
        justify-content: space-between;
        font-size: fonts.$eduate-default-font-size;
      }
    }

    &--note {
      font-size: 12px;
      font-family: fonts.$eduate-font-family;
      list-style: circle;
      height: 80px;
      overflow: hidden;
    }
  }
}

.challan-receipts__ss::-webkit-scrollbar {
  @include scrollbar();
}

.challan-receipts__ss::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.challan-receipts__ss::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
