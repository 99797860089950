@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.assigned-route-std-list {
  height: calc(100% - 165px);
  margin: 10px 20px 0px 20px;
  &__select {
    gap: 10px;
    &--button {
      display: flex !important;
      justify-content: flex-end !important;
      gap: 10px;
      margin-right: 0px !important;
    }
    #search,
    .storybook-button {
      margin-top: 0px !important;
    }
  }
  &__tableblock {
    height: calc(100% - 100px);
    margin-top: 10px;
  }
  &__table {
    @include table();
    &--desc {
      width: calc(100% - 90%);
    }
    &--actions {
      width: calc(100% - 95%);
    }
    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--admno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }
  }
}
