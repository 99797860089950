@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.sub-allocation-individual {
    @include mainFrameWithTitle();
    &--title {
        display: flex;
        color: var(--level-6);
        font-family: fonts.$eduate-font-family;
        font-size: 16px;
        margin-top: 10px;
    }
    &--filters {
        &--total {
            margin-top: 10px;
            border-radius: 10px;
            background-color: colors.get-color(colors.$cool_grey, level_1);
            display: flex;
            font-size: 14px;
            font-family: fonts.$eduate-font-family;
            width: fit-content;
            padding: 5px;
            display: flex;
            align-items: center;
            &--number {
                background-color: var(--level-3);
                color: white;
                font-family: fonts.$eduate-number-font;
                padding: 2px 5px;
                margin-left: 5px;
            }
        }
    }
    &--datablock {
        height: calc(100% - 175px);
        margin-top: 10px;
        border: 1px solid var(--border-color);
        overflow: auto;
        padding: 5px;

        &--grids {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 5px;
        }
        &--grid {
            border: 1px solid var(--border-color);
            border-radius: 6px;
            padding: 5px;
            background-color: var(--level-1);
            &--title {
                font-size: 16px;
                font-family: fonts.$eduate-font-family;
                color: var(--text-color);
                display: flex;
                justify-content: center;
            }
            &--span {
                display: grid;
                grid-template-columns: 1fr 1fr;
                span {
                    font-size: 14px;
                    font-family: fonts.$eduate-font-family;
                }
                b {
                    font-family: fonts.$eduate-number-font;
                    text-align: center;
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
            &--view {
                @include viewButton();
              
            }
            &--button{
                display: flex;
                justify-content: center;
            }
        }
    }
}
