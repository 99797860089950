@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
.edit-accno {
  &__search {
    margin-left: 20px;
  }
  &__tableblock {
    margin: 10px 20px 0px 20px;
    height: calc(100% - 263px);
  }
  &__table {
    @include table();
    &--th-slno {
      width: calc(100% - 96%);
    }

    &--input {
      width: calc(100% - 88%);
      padding-right: 0px !important;
      font-family: fonts.$eduate-number-font;
      input {
        width: 100%;
        border-radius: 0px;
        margin-left: -2px !important;
      }
    }
  }
}

.edit-accno__table::-webkit-scrollbar {
  @include scrollbar();
}

.edit-accno__table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.edit-accno__table::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
