
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.purchase-entry-view {
    height: calc(100% - 30px);

    &__filters {
        .storybook_button {
            margin-top: 0px !important;
        }

        &--flex {
            display: flex;
            justify-content: flex-end;
            column-gap: 10px;
        }

        &--textfield {
            @include textfield();
            margin: 8px 0px 0px 0px !important;
            width: fit-content !important;


            label {
                color: var(--text-color) !important;
            }

            input {
                font-weight: 400 !important;
            }
        }
    }

    &__tableblock {
        margin-top: 10px;
        height: calc(100% - 95px);
    }

    &__table {
        @include table();

        tr:hover {
            .purchase-entry-view__table--number {
                @include tdContainsOnclick()
            }
        }

        &--slno {
            width: calc(100% - 94%);
            font-family: fonts.$eduate-number-font;
        }

        &--date {
            width: calc(100% - 90%);
            font-family: fonts.$eduate-number-font;
        }

        &--items {
            width: calc(100% - 92%);
            font-family: fonts.$eduate-number-font;
        }

        &--number {
            width: calc(100% - 90%);
            font-family: fonts.$eduate-number-font;
        }

        &--amount {
            width: calc(100% - 88%);
            font-family: fonts.$eduate-number-font;
        }

    }

    &__details {
        height: calc(100% - 30px);

        &--filters {
            column-gap: 20px;
            height: 120px;

            &--textfield {
                @include textfield();
                margin: 8px 0px 0px 0px !important;
                width: 100% !important;
            }

            &--date {
                @include textfield();
                margin: 8px 0px 0px 0px !important;
                width: fit-content !important;
            }

            &--flex {
                display: flex;
                column-gap: 10px;
            }
        }

        &--tableblock {
            margin-top: 5px;
            height: calc(100% - 170px)
        }

        &--table {
            @include table();
        }
    }


}