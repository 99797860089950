@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.vacation-entry {
  height: calc(100% - 180px);
  margin: 10px 20px 0px 20px;
  &__block {
    height: calc(100% - 40px);
    column-gap: 40px;

    &--textfield {
      @include textfield();
      margin: 10px 0px !important;
      width: fit-content !important;
    }
    &--frame {
      height: 100%;
      border: 1px solid var(--border-color);
      padding: 10px;
      border-radius: 6px;
      &--date {
        column-gap: 20px;
      }
      &--details {
        column-gap: 10px;
      }
      &--button {
        display: flex;
        justify-content: flex-end;
        margin-left: auto !important;
        margin-right: 0px !important;
      }
      &--holiday-list {
        height: calc(100% - 80px);
        overflow: auto;
        margin-top: 10px;
        @include calendarEventsList();
      }
    }
  }
}
.vacation-entry__block--frame--holiday-list::-webkit-scrollbar {
  @include scrollbar();
}

.vacation-entry__block--frame--holiday-list::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.vacation-entry__block--frame--holiday-list::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
