@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.transaction-failed {
  height: 100%;
  &__footer {
    height: 80px;
    border-radius: 5px;
    background-color: colors.get-color(colors.$cool_grey, level_2);
    margin-top: 20px;
    padding: 10px;
    &--flex {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      label {
        font-family: fonts.$eduate-font-family;
        font-size: 14px;
        color: var(--text-color);
      }
      span {
        font-family: fonts.$eduate-font-family;
        font-size: 16px;
        color: var(--level-5);
        font-weight: 600;
      }
      b {
        font-family: fonts.$eduate-font-family;
        font-size: 14px;
        color: colors.get-color(colors.$cool_grey, level_8);
        font-weight: 400;
      }
    }
  }
  &__title {
    img {
      @include closeIconInModal();
    }
  }
  &__datablock {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: calc(100% - 180px);

    img {
      height: 350px;
      width: 350px;
      display: block;
      margin: auto;
    }
  }
}
