@use "./fonts" as fonts;
@use "./mixins" as *;
.calendar-for-input {

    &--title {
        display: flex;
        justify-content: center;
        column-gap: 10px;
        margin-bottom: 10px;

        img {
            width: 20px;
        }
    }

    &--grid-container {

        height: fit-content;
        width: 230px;
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-template-rows: auto auto auto auto auto auto auto;
        /* Rows will adjust to fit content */
        gap: 5px;
    }

    &--day {
        text-align: center;
        font-family: fonts.$eduate-font-family;
        font-size: 12px;
        height: 20px;
        font-weight: 700;
        color: var(--text-color);

    }

    &--date {
        text-align: center;
        font-family: fonts.$eduate-number-font;
        font-size: 11px;
        height: 20px;
        border-radius: 2px;
        padding: 3px;
        color: var(--text-color);


        &:hover {
            background-color: var(--level-2);
        }
    }

    &--textfield {
        margin: 0px 0px 0px 0px !important;
        @include textfield();
        width: fit-content !important;
    }
}