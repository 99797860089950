@use "../../../../scss/common/mixins" as*;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;

.academics__channel {
  height: 100%;

  &--title {
    display: flex;
    align-items: center;
    height: 60px;

    .Heading {
      text-align: left !important;
    }
  }

  &--datablock {
    height: calc(100% - 45px);
    display: flex;
    column-gap: 20px;


    &--left {
      height: calc(100% - 10px);
      width: calc(100% - 300px);
      border: 1px solid var(--border-color);
      margin-top: 10px;
      position: relative;
      overflow: auto;
      &--header {
        display: flex;
        height: 40px;
        justify-content: space-between;
        align-items: center;
        position: sticky;
        top: 0px;
    
        padding: 0px 5px;
        background:  rgba(0, 0, 0, 0.04);
        backdrop-filter: blur(16.133333206176758px);
        .storybook-input {
          margin-top: 0px;
          width: 150px;
        }
      }
      &--messages {
        height: calc(100% - 35px);
        display: flex;
        padding: 5px 20px;

        ul {
          width: 100% !important;
          height: 100%;
        }

        &--list {
          display: flex;
          gap: 10px;
          height: fit-content;
          width: fit-content;
          border-radius: 6px;
          background-color:colors.get-color(colors.$colors, white);
          padding: 5px 10px;

          &--li {
            padding: 5px 200px 5px 5px;
          }

          Label {
            margin-top: 2px;
            padding: 0px;
            font-weight: 700 !important;
            margin-right: 10px;
          }

          &--sender {
            border-radius: 6px;
            display: flex;
            justify-content: flex-end !important;
            margin-right: 0% !important;
            width: fit-content;
            margin-left: auto !important;
            background-color: var(--level-1);
            padding: 5px 10px;
            border-radius: 6px;

            &--li {
              padding: 5px 5px 5px 200px;
            }

            &--left {
              width: 25%;
            }
          }
        }

        &--file-image {
          width: 320px;
          height: 240px;
        }

        p {
          font-family: fonts.$eduate-font-family;
          font-size:fonts.$eduate-default-font-size;
          margin: 0px;
        }

        b {
          font-family: fonts.$eduate-font-family;
          font-size: 16px;
          color: colors.get-color(colors.$grey, level_7) !important;
        }
      }
    }
    &--right {
      width: 300px;
      @extend .academics__channel--datablock--left;
      &--list {
        height: 100%;
        .storybook-input {
          margin-top: 0px !important;
        }
        .storybook-button {
          width: 100%;
          display: flex;
          justify-content: center;
        }
        &--header {
          height: 40px;
          background-color: colors.get-color(colors.$cool_grey, level_5);
          font-size: 16px;
          display: flex;
          align-items: center;
          color:colors.get-color(colors.$colors, white);
          justify-content: space-between;
          padding: 0px 5px;
          font-weight: 700;
        }
        &--send{
          display: none;
        }
        &--ul {
          margin-top: 10px;
          height: calc(100% - 200px);
          padding: 5px;
          overflow: auto;
          margin-bottom: 0px !important;
          li {
            display: flex;
            justify-content: space-between;
            column-gap: 10px;
            align-items: center;
            font-size: 14px;
            font-family: fonts.$eduate-font-family;
            padding: 5px;
            border-radius: 6px;
            &:hover{
                background-color: var(--level-1);
                .academics__channel--datablock--right--list--send{
                    display: flex;
                    width: 20px !important;
                    height: 20px !important;
                }
            }
         
     

          }
        }
        &--sel {
          @extend .academics__channel--datablock--right--list--ul;
          height: calc(100% - 130px);
        }
        &--subheader {
          display: flex;
          justify-content: space-between;

          margin-top: 5px;
          padding: 0px 5px;
          b {
            font-size: 14px;
            font-family: fonts.$eduate-font-family;
            color: colors.get-color(colors.$cool_grey, level_5);
          }
        }
        &--flex{
            display: flex;
            column-gap: 5px;
            align-items: center;
            img{
                width: 30px ;
                height: 30px;
            }
        }
     
      }
    }
  }

  &--view-more {
    height: calc(100% - 75px);
  }

}

.academics__channel--datablock--left::-webkit-scrollbar,
.academics__channel--send-message--block--right--ul::-webkit-scrollbar,
.academics__channel--datablock--right--list--ul::-webkit-scrollbar,
.academics__channel--datablock--right--list--sel::-webkit-scrollbar {
  @include scrollbar();
}

.academics__channel--datablock--left::-webkit-scrollbar-thumb,
.academics__channel--send-message--block--right--ul::-webkit-scrollbar-thumb,
.academics__channel--datablock--right--list--ul::-webkit-scrollbar-thumb,
.academics__channel--datablock--right--list--sel::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.academics__channel--datablock--left::-webkit-scrollbar-thumb:hover,
.academics__channel--send-message--block--right--ul::-webkit-scrollbar-thumb:hover,
.academics__channel--datablock--right--list--ul::-webkit-scrollbar-thumb:hover,
.academics__channel--datablock--right--list--sel::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
