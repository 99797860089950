@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/colors" as colors;
@use "../../../../../scss/common/fonts" as fonts;
.std-acd-dashboard {
  height: calc(100% - 175px);
  margin: 0px 20px;

  &__data {
    height: calc(100% - 45px);

    &--tabs {
      margin-top: 10px;
      //   width: fit-content !important;
      @include muiTabs();
    }

    &--swipable {
      height: calc(100% - 55px);
      margin-top: 10px;

      &--tabs {
        height: calc(100% - 10px) !important;
      }

      .react-swipeable-view-container,
      .MuiTypography-body1 {
        height: 100% !important;
      }
    }
  }

  &__exam {
    height: 100%;
    column-gap: 20px;
    display: flex;
    justify-content: space-between;
    &--modal {
      @extend .std-acd-dashboard__exam;
      height: calc(100% - 75px);
    }

    &--left-block {
      height: 100%;
      width: 360px;
    }

    &--select {
      column-gap: 10px;
      margin-top: 10px;
      @include muiMultiSelect();
    }

    &--performance {
      height: calc(100% - 45px);
      margin-top: 10px;
      border: 1px solid var(--border-color);
      border-radius: 6px;
      padding: 5px;
    }

    &--graph {
      height: calc(100% - 40px);

      .echarts-for-react {
        height: 100% !important;
      }
    }

    &--flex {
      display: flex;
      column-gap: 10px;

      img {
        width: 18px;
      }
    }

    &--flex-end {
      display: flex;
      justify-content: flex-end;
      column-gap: 10px;

      .MuiAutocomplete-root {
        margin-top: 10px;
      }
    }

    &--textfield {
      @include textfieldInForms();
      margin: 10px 0px 0px 0px !important;
      width: fit-content !important;
    }

    &--right-block {
      height: 100%;
      width: 100%;
      &--select {
        column-gap: 10px;
      }
    }

    &--grid-list {
      margin-top: 10px;
      height: calc(100% - 45px);
      overflow: auto;

      &--grids {
        height: 100%;
        gap: 10px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
      }

      &--grid {
        border-radius: 6px;
        height: 210px;
        padding: 5px;
        background-color: var(--level-1);

        &:hover {
          box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.06),
            0px 4px 6px -1px rgba(0, 0, 0, 0.1);
        }

        &--subtitle {
          font-size: fonts.$eduate-default-font-size;
          display: block;
          text-align: center !important;
          font-family: fonts.$eduate-font-family;
          color: colors.get-color(colors.$grey, level_6);
          margin-top: 5px;
        }

        &--number {
          @extend .std-acd-dashboard__exam--grid-list--grid--subtitle;

          font-family: fonts.$eduate-number-font;
          color: colors.get-color(colors.$grey, level_7);
        }

        &--flex {
          display: flex;
          column-gap: 5px;
          margin-top: 5px;
          justify-content: center;

          img {
            width: 15px;
          }
        }

        &--view {
          margin-top: 5px;
          display: flex;
          justify-content: center;

          button {
            @include viewButton();

            img {
              margin-right: 5px;
            }
          }
        }

        &--textfield {
          @include textfield();
          width: fit-content !important;
        }
      }
    }
  }

  &__per-exam {
    height: calc(100% - 75px);
    column-gap: 20px;

    &--left {
      height: 100%;
      border-right: 1px dashed var(--border-color);
      padding-right: 10px;
    }

    &--right {
      height: 100%;
    }

    &--tableblock {
      height: calc(100% - 55px);
      margin-top: 10px;
    }

    &--table {
      @include table();

      &--groupheader {
        th {
          height: 30px;
        }
      }

      &--subheader {
        th {
          top: 50px;
        }
      }

      &--slno {
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
      }

      &--code {
        width: calc(100% - 88%);
      }

      &--number {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }
    }

    &--graph {
      height: calc(100% - 35px);

      .echarts-for-react {
        height: 100% !important;
      }
    }
  }
}

.std-acd-dashboard__exam--test-details::-webkit-scrollbar,
.std-acd-dashboard__exam--grid-list::-webkit-scrollbar {
  @include scrollbar();
}

.std-acd-dashboard__exam--test-details::-webkit-scrollbar-thumb,
.std-acd-dashboard__exam--grid-list::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.std-acd-dashboard__exam--test-details::-webkit-scrollbar-thumb:hover,
.std-acd-dashboard__exam--grid-list::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

#bg-green {
  background-color: colors.get-color(colors.$Green, level_1) !important;
}

#bg-red {
  background-color: colors.get-color(colors.$red, level_1) !important;
}

#bg-amber {
  background-color: colors.get-color(colors.$amber, level_6) !important;
}
