@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.id-vertical-temp1 {
  height: 100%;
  margin: 0px 20px;

  &__card {
    height: calc(100% - 55px);
    padding: 5px;
    width: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &--li {
      display: flex;
      width: 100%;
      padding: 2px;
    }

    &--fs {
      height: 50%;

      &--li {
        width: 100%;
        height: 175px;

        .id-vertical-temp1__card--fs--id--inst-logo {
          width: 20px;
          height: 20px;
        }

        .id-vertical-temp1__card--fs--id--c-name,
        .id-vertical-temp1__card--fs--id--inst-name {
          font-size: 10px;
        }

        .id-vertical-temp1__card--fs--id--std-image {
          width: 40px;
          height: 40px;
        }

        .id-vertical-temp1__card--fs--id--grid {
          label,
          span,
          b {
            font-size: 10px;
          }
        }
      }

      &--id {
        border: 1px solid var(--border-color);
        height: calc(100% - 30px);
        border-radius: 6px;

        &--profile {
          height: calc(100% - 25px);

          &--header {
            display: flex;
            column-gap: 5px;
          }
        }

        &--std-details {
          display: flex;
          column-gap: 5px;
        }

        &--grid {
          display: grid;
          grid-template-columns: 1fr 0.1fr 1fr;
          padding: 5px;

          label,
          span,
          b {
            font-size: 12px;
            margin-top: 0px;
            font-family: fonts.$eduate-font-family;
          }
        }

        &--std-image {
          width: 80px;
          height: 80px;
          margin: 5px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &--footer {
          height: 25px;
          display: flex;
          justify-content: center;

          align-items: center;

          span {
            font-size: fonts.$eduate-default-font-size;
            font-family: fonts.$eduate-font-family;
          }
        }

        &--c-name {
          display: block;
          font-size: 8px;
          margin-top: 5px;
          font-family: fonts.$eduate-font-family;
        }

        &--inst-name {
          font-size: 12px;
          font-family: fonts.$eduate-font-family;
          display: block;
        }

        &--inst-logo {
          width: 40px;
          height: 40px;
          margin: 5px;

          img {
            width: 100%;
          }
        }

        &--inst-address {
          height: 60px;
          overflow: hidden;
        }

        &--address {
          height: calc(100% - 60px);
        }

        &--addr {
          font-size: 12px;
          font-family: fonts.$eduate-font-family;
          line-height: 13px;
          margin-top: 10px;
        }

        &--signature {
          text-align: right;
          font-size: 12px;
          font-family: fonts.$eduate-font-family;
        }
      }
    }

    &--bs {
      @extend .id-vertical-temp1__card--fs;

      &--id {
        @extend .id-vertical-temp1__card--fs--id;
      }

      &--grid {
        display: grid;
        grid-template-columns: 0.35fr 0.1fr 1fr;
        padding: 0px 5px;

        label,
        span,
        b {
          font-size: 13px;
          margin-top: 0px;
          font-family: fonts.$eduate-font-family;
        }
      }
    }
  }

  &__button {
    border-top: 1.8px dashed var(--border-color);

    button {
      background-color: colors.get-color(colors.$cool_grey, level_3);
      padding: 5px;
      border-radius: 20px;
      text-align: center;
      width: 100%;
      display: block;
      margin-top: 10px;
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
      font-weight: 700;
    }
  }
}

.selected-id-template {
  border: 1px solid var(--level-3);
  border-radius: 10px;
  margin: 0px 10px;

  .id-vertical-temp1 {
    margin: 0px 0px;
  }
}
