@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.bank-book {
  margin: 10px 20px 0px 20px;
  height: calc(100% - 220px);
  column-gap: 20px;
  display: flex;

  &--modal {
    height: calc(100% - 80px);
    margin-top: 10px;
  }

  &__group-ledger {
    width: 250px;

    &--table {
      @include Accountstable();



      &--groupheader {
        @include groupHeader();

        th img {
          margin-right: 0px !important;
          width: 25px;
        }
      }

      &--subheader {
        @include subHeader();
        @include selectReportsTable();

        .MuiFormControl-root {
          margin-top: 0px !important;
        }

        .MuiAutocomplete-root {
          padding: 5px !important;
        }
      }

      td {
        max-width: 100px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &--input {
        @include inputReportsTable();
      }
    }

    &--group-ledger-table {
      th img {
        margin-right: 0px !important;
        width: 25px;
      }

      @extend .bank-book__group-ledger--table;
      height: 60px;

      &--yearly--details {
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.25);
        height: calc(50% - 60px);
        overflow: auto;

        &--label-grid {
          display: grid;
          grid-template-columns: 1fr 0.2fr 1fr;
          border-bottom: 1px dashed var(--border-color);

          Label {
            margin-top: 0px;
            padding: 2px;
            font-size: 12px;
            margin-left: 2px;
          }
        }

        &--value {
          text-align: end;
          margin-right: 5px;
          font-family: fonts.$eduate-number-font;
        }
      }
    }

    &--account-ledger-table {
      height: calc(50% - 10px);
      margin-top: 10px;
      @extend .bank-book__group-ledger--table;

      th img {
        margin-right: 0px !important;
      }
    }
  }

  &__table-section {
    width: calc(100% - 250px);

    &--modal {
      width: 100%;
    }

    &--monthly-total {
      height: calc(50% - 10px);
      box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);

      &--table {
        @include Accountstable();
        height: calc(100% - 40px);

        tr {
          &:hover {
            .bank-book__table-section--monthly-total--table--month {
              @include tdContainsOnclick();
            }
          }
        }

        &--month {
          width: calc(100% - 98%);
          font-family: fonts.$eduate-number-font;
        }

        &--month-tr {
          width: calc(100% - 98%);
          font-family: fonts.$eduate-number-font;
        }

        &--amount {
          width: calc(100% - 92%);
          font-family: fonts.$eduate-number-font;
        }

        &--th-month {
          width: calc(100% - 98%);
        }

        &--th-amount {
          width: calc(100% - 92%);
        }
      }
    }

    &--transaction-details {
      margin-top: 10px;
      height: 50%;
      box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);

      &--table {
        @include Accountstable();
        height: calc(100% - 40px);
        margin-top: 5px;

        &--title {
          display: flex;
          justify-content: space-between;
          padding-left: 5px;
        }

        &--year {
          width: calc(100% - 86%);
          font-family: fonts.$eduate-number-font;
        }

        &--amount {
          width: calc(100% - 88%);
          font-family: fonts.$eduate-number-font;
        }

        &--date {
          width: calc(100% - 92%);
          font-family: fonts.$eduate-number-font;
        }

        &--balance {
          width: calc(100% - 88%);
          font-family: fonts.$eduate-number-font;
        }

        &--slno {
          width: calc(100% - 94%);
          font-family: fonts.$eduate-number-font;
        }

        &--th-slno {
          width: calc(100% - 92%);
        }

        &--th-year {
          width: calc(100% - 86%);
        }

        &--th-amount {
          width: calc(100% - 88%);
        }

        &--th-date {
          width: calc(100% - 92%);
        }

        &--th-balance {
          width: calc(100% - 88%);
        }

        tr:hover {
          .bank-book__table-section--transaction-details--table--name {
            @include tdContainsOnclick();
          }
        }

        &--ledger-list {
          li {
            display: flex;
            justify-content: space-between;
          }
        }


      }


      &--reconcil-table {
        @extend .bank-book__table-section--transaction-details--table;
        height: calc(100% - 50px);

        &--button {
          display: flex;
          justify-content: flex-end;
        }
      }

      &--tableblock {
        height: calc(100% - 45px);
      }
    }
  }

  &__menu-items {
    Label {
      margin-top: 0px;
    }

    .active li {
      background-color: var(--level-5);
      border-radius: 6px;

      Label {
        color:colors.get-color(colors.$colors, white) !important;
      }
    }

    li {
      &:hover {
        background-color: var(--level-2);
        border-radius: 3px;

        Label {
          color: colors.get-color(colors.$colors, black) !important;
        }
      }
    }
  }

  &__table {
        @include table();
    height:100%;
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);

    tr:hover {
      .bank-book__table--ledger {
        @include tdContainsOnclick();
      }
    }

    &--groupledger {
      margin-top: 10px;
      height: calc(100% - 43px);
      box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
    }

    &--select-space {
      height: 33px;
    }
  }

  .css3-tabstrip li {
    margin: 0px 10px 0 0px;
  }

  .css3-tabstrip {
    height: 40px;
    margin-top: 0px;
    margin-left: 0px;
  }

  &__export-modal {
    &--flex {
      display: flex;

      input {
        margin-top: 10px;
      }
    }
  }

  &__total {
    text-align: right;
  }
}

.selected-ledger {
  background-color: var(--level-4);
}

.bank-book__group-ledger--group-ledger-table--yearly--details::-webkit-scrollbar,
.bank-book__group-ledger--table--account-ledger-table::-webkit-scrollbar {
  @include scrollbar();
}

.bank-book__group-ledger--group-ledger-table--yearly--details::-webkit-scrollbar-thumb,
.bank-book__group-ledger--table--account-ledger-table::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.bank-book__group-ledger--table--account-ledger-table::-webkit-scrollbar-thumb:hover,
.bank-book__group-ledger--group-ledger-table--yearly--details::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

.pointer-icon {
  margin-right: 10px;
  filter: invert(0%);
}

.dropdown-pointer {
  filter: invert(0%);
  margin-right: 0px;
}

.number-font {
  font-family: fonts.$eduate-number-font;
}