@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.allocate-teacher {
  @include mainFrameWithTitle();

  &__tableblock {
    height: calc(100% - 95px);
    margin-top: 10px;

    &--without-filters {
      margin-top: 10px;
      height: calc(100% - 55px);
    }
  }

  &__table {
    @include table();

    &--assign {
      @include assignButton();
    }

    &--view {
      @include viewButton();
    }
  }

  &__assign {
    height: 100%;

    &--title {
      display: flex;
      justify-content: space-between;
    }

    .student-total-count {
      text-align: right;
    }

    &--filters {
      .MuiAutocomplete-root {
        margin-top: 10px;
      }
    }

    &--sub-details {
      height: 60px;
      background-color: colors.get-color(colors.$cool_grey, level_2);
      border-radius: 6px;

      padding: 5px;

      &--grid {
        display: grid;
        grid-template-columns: 0.3fr 0.04fr 1fr;
        font-family: fonts.$eduate-font-family;
        font-size: fonts.$eduate-default-font-size;
        color: var(--text-color);
      }

      &--code {
        color: var(--text-color);
        font-family: fonts.$eduate-number-font;
        font-size: fonts.$eduate-default-font-size;
        font-weight: bold;
      }
      &--name {
        color: var(--text-color);
        font-family: fonts.$eduate-font-family;
        font-size: fonts.$eduate-default-font-size;
        font-weight: bold;
      }
    }

    &--tableblock {
      height: calc(100% - 205px);
      margin-top: 10px;
    }

    &--table {
      @include table();
      @include tableCheckbox();

      &--slno {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }

      &--id {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }

      &--count {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }

      &--grade {
        width: calc(100% - 90%);
      }

      //To be reviewed by padma
      &--assign {
        width: calc(100% - 90%);
        color: green !important;
        text-decoration: underline;
      }

      tr:hover {
        .allocate-teacher__assign--table--name {
          @include tdContainsOnclick();
        }

        .allocate-teacher__assign--table--assign {
          color: var(--level-6) !important;
        }
      }
    }
  }

  &__details {
    height: calc(100% - 30px);

    .student-total-count {
      text-align: right;
    }

    &--image {
      @include studentProfile();
      height: calc(100% - 10px);
      margin-top: 10px;
    }

    &--title {
      display: flex;
      justify-content: space-between;
    }

    &--textfield {
      @include textfield();
      margin: 10px 0px 0px 0px !important;
    }

    &--data {
      column-gap: 10px;
      height: 120px;
    }

    &--tableblock {
      height: calc(100% - 185px);
    }

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--code {
        width: calc(100% - 84%);
      }
    }
  }
}
