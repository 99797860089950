@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.voucher-print {
  height: 100%;

  &__block {
    height: calc(100% - 60px);
    overflow: auto;

    p {
      margin-bottom: 0px !important;
      font-family: fonts.$eduate-font-family;
    }

    &--inst-data {
      padding: 10px;
      font-family: fonts.$eduate-font-family;

      &--voucher-type {
        font-size: 14px !important;
        text-align: center;
        display: block;
      }

      &--inst-name {
        text-align: center;
        font-size: 18px;
        display: block;
      }

      &--inst-address {
        display: flex;
        justify-content: center;
        font-size: 16px;
      }

      &--details {
        display: flex;
        gap: 10px;

        img {
          height: 100px;
          width: 100px;
        }
      }

      &--details-block {
        width: calc(100% - 100px);
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &--v-details {
      display: flex;
      justify-content: space-between;
      border-bottom: 1.8px dashed var(--border-color);
      font-family: fonts.$eduate-font-family;
      font-size: fonts.$eduate-default-font-size;
      margin-top: 10px;

      b {
        font-family: fonts.$eduate-number-font;
      }

      &--vtype {
        font-family: fonts.$eduate-font-family !important;
        text-transform: uppercase;
        font-weight: 700;
      }
    }

    &--payment-info {
      padding: 5px;

      &--uppercase {
        text-transform: capitalize;
      }

      &--capitalize {
        text-transform: capitalize;
      }

      &--trans-summary {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        &--amount {
          span {
            padding: 5px 10px 5px 10px;
            text-align: center;
            font-weight: 700;
            border: 1px solid colors.get-color(colors.$colors, black);
            margin: auto;
            border-radius: 2px;
            font-family: fonts.$eduate-number-font;
          }
        }

        &--approved-by {
          border: 1px solid colors.get-color(colors.$colors, black);
          padding: 0px 10px 2px 10px;
          border-radius: 2px;
          height: 60px;

          span {
            position: relative;
            top: 33px;
          }
        }
      }
    }

    &--sender-info {
      padding: 10px;

      &--signatures {
        display: flex;
        justify-content: space-between;
        height: 60px;

        &--reciever-type {
          border: 1px solid colors.get-color(colors.$colors, black);
          border-radius: 2px;
          padding: 0px 10px 2px 10px;
          width: calc(100% - 80%);
          text-align: center;

          span {
            position: relative;
            top: 33px;
          }
        }

        &--reciever-sign {
          @extend .voucher-print__block--sender-info--signatures--reciever-type;
          width: fit-content;
        }
      }
    }
  }

  &__tableblock {
    margin-top: 10px;
  }

  &__table {
    @include tableInPrint();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
      border-left: 1px solid var(--text-color) !important;
    }

    &--amount {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--th-slno {
      border-right: 1px solid var(--text-color) !important;
      border-left: 1px solid var(--text-color) !important;
    }

    &--th-desc {
      border-right: 1px solid var(--text-color) !important;
    }

    &--th-amount {
      border-right: 1px solid var(--text-color) !important;
      padding-right: 5px !important;
    }
  }

  &__signature {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;

    &--block {
      font-family: fonts.$eduate-font-family;
      font-size: fonts.$eduate-default-font-size;

      span {
        text-align: center;
        display: block;
      }
    }
  }

  &__created-date {
    text-align: right;
  }

  .voucher-print__amount {
    font-family: fonts.$eduate-number-font;
  }
}

.voucher-print__block::-webkit-scrollbar {
  @include scrollbar();
}

.voucher-print__block::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.voucher-print__block::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
