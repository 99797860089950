@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.all-yr-fee {
  @include mainFrameWithTitle();
  &__details {
    height: 140px;
    column-gap: 20px;
    .MuiAutocomplete-root {
      margin-top: 6px;
    }
    &--profile {
      @include studentProfile();
      width: 100%;
      height: calc(100% - 10px) !important;
    }
    &--flex {
      display: flex;
      column-gap: 5px;
    }
    &--textfield {
      @include textfield();
      margin: 8px 0px 0px 0px !important;
    }
    &--date {
      @include textfield();
      margin: 8px 0px 0px 0px !important;
      width: fit-content !important;
    }
  }
  &__flex {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  &__data {
    height: calc(100% - 188px);
    overflow: auto;
    &--block {
      border: 1px solid var(--border-color);
      border-radius: 6px;
      background-color: var(--level-1);
      padding: 8px 15px;
      margin-top: 10px;
    }
    &--b {
      font-size: 16px;
      font-family: fonts.$eduate-font-family;
      padding: 8px 5px;
    }

    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      outline: none;
      &:focus {
        outline: none;
      }
      &--flex {
        display: flex;
        column-gap: 60px;
        align-items: center;
      }
      &--text {
        font-family: fonts.$eduate-font-family;
        font-size: 18px;
        color: colors.get-color(colors.$cool_grey, level_8);
        display: block;
      }

      &--grids {
        background-color: white;
        box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
          0px 2px 4px -1px rgba(0, 0, 0, 0.06);
        display: flex;
        width: fit-content;
        column-gap: 20px;
        padding: 10px;
        border-radius: 6px;
      }
      &--block {
        display: block;
        text-align: center;
      }
      &--b {
        background-color: var(--level-6);
        color: white;
        padding: 10px;
        font-family: fonts.$eduate-number-font;
        font-size: 16px;
        border-radius: 5px;
      }
      &--amount {
        font-family: fonts.$eduate-number-font;
        font-size: 18px;
      }
    }

    &--content {
      display: flex;
      column-gap: 40px;
      margin-top: 10px;
      &--block {
        background-color: white;
        border-radius: 6px;
        padding: 10px;
        width: 50%;
        b {
          display: flex;
          justify-content: center;
          font-size: 18px;
          font-family: fonts.$eduate-font-family;
        }
      }
      &--tableblock {
        margin-top: 10px;
      }
  
    }
    &__data {
        height: calc(100% - 188px);
        overflow: auto;
        &--block {
            border: 1px solid var(--border-color);
            border-radius: 6px;
            background-color: var(--level-1);
            padding: 8px 15px;
            margin-top: 10px;
           

        }
        &--b {
            font-size: 16px;
            font-family: fonts.$eduate-font-family;
            padding: 8px 5px;
        }

        &--header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            outline: none;
            &:focus{
                outline: none;
            }
            &--flex {
                display: flex;
                column-gap: 60px;
                align-items: center;
            }
            &--text{
                font-family: fonts.$eduate-font-family;
                font-size: 18px;
                color: colors.get-color(colors.$cool_grey, level_8);
                display: block;
            }

            &--grids {
                background-color: white;
                box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.10), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);                display: flex;
                width: fit-content;
                column-gap: 20px;
                padding:10px;
                border-radius: 6px;
            }
            &--block {
                display: block;
                text-align: center;
               
            }
            &--b{
                background-color: var(--level-6);
                color: white;
                padding:  10px;
                font-family: fonts.$eduate-number-font;
                font-size: 16px;
                border-radius: 5px;
            }
            &--amount{
                font-family: fonts.$eduate-number-font;
                font-size: 18px;
            }
        }

        &--content {
            display: flex;
            column-gap: 40px;
            margin-top: 10px;
            &--block {
                background-color: white;
                border-radius: 6px;
                padding: 10px;
                width: 50%;
                b{
                   display: flex;
                   justify-content: center;
                    font-size: 18px;
                    font-family: fonts.$eduate-font-family;
                }
            }
            &--tableblock{
                margin-top: 10px;
            }
          
        }
        &--contentallyr{
            @extend .all-yr-fee__data--content;
            width: 100%;
        }
    }
  }
}

.all-yr-fee__data::-webkit-scrollbar {
  @include scrollbar();
}

.all-yr-fee__data::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.all-yr-fee__data::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
.all-yr-fee__data--header:focus ~ .all-yr-fee__data--block {
  border: 1px solid var(--level-3);
}
