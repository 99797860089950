@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.reset-config {
  margin: 0px 20px 0px 20px;
  height: calc(100% - 150px);

  &__data {
    height: calc(100% - 95px);
    column-gap: 20px;
    margin-top: 10px;

    &--block {
      height: fit-content;
      padding: 10px;
      background-color: colors.get-color(colors.$cool_grey, level_2);
      border-radius: 10px;

      .storybook-button {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

      &--image {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}