@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.per-student-attendance-report {
  margin: 0px 20px 0px 20px;
  height: calc(100% - 160px);
  &__details {
    height: 120px;
    column-gap: 20px;
    .MuiAutocomplete-root {
      width: 100% !important;
      margin-top: 8px;
    }
    &--imagefield {
      display: flex;
      .data-fetch-icon {
        margin-top: 8px;
      }
    }
    &--image {
      @include studentProfile();
    }
    &--textfield {
      @include textfield();
      margin: 8px 10px 0px 0px !important;
    }
    &--date {
      @include textfield();
      margin: 8px 10px 0px 0px !important;
      width: fit-content !important;
    }
  }
  &__calendar {
    height: calc(100% - 180px);
    margin-top: 10px;
    .react-calendar {
      width: 100% !important;
      height: 320px !important;
      border-radius: 10px;
      border: 1px solid var(--border-color);
      &__tile {
        padding: 10px !important;
        border: 5px solid var(--bg-color);
        border-radius: 10px !important;
        font-size: 15px;
        font-family: fonts.$eduate-font-family;
        &:enabled:focus {
          background-color: var(--level-4) !important;
          color: black !important;
          border-radius: 6px;
        }
        &:enabled:hover {
          background-color: var(--level-2) !important;
          color: black !important;
          border-radius: 6px;
        }
        &--now,
        .react-calendar__tile--now:enabled:hover,
        .react-calendar__tile--now:enabled:focus {
          background: var(--level-8) !important;
          border-radius: 6px;
          font-weight: 500;
          color:colors.get-color(colors.$colors, white) !important;
        }
        &--active {
          background: var(--level-4) !important;
          border-radius: 6px;
          font-weight: 500;
          color:colors.get-color(colors.$colors, white) !important;
        }
      }
      &__navigation {
        margin-top: 10px;
        font-family: fonts.$eduate-number-font;
        height: 30px !important;
        &__arrow {
          font-weight: 500;
          font-size: 30px;
        }
      }
    }
  }
  &__legends {
    display: flex;
    justify-content: flex-end;
    margin-top: 13px;
    margin-right: 20px;
    gap: 5px;
    &--fullday {
      background-color: colors.get-color(colors.$Green, level_5);
      border-radius: 50%;
      height: 30px;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      //   padding: 5px;
      width: 30px;
      color:colors.get-color(colors.$colors, white);
    }
    &--halfday {
      @extend .per-student-attendance-report__legends--fullday;
      background-color:colors.get-color(colors.$amber, level_5) !important;
    }
    Label {
      padding: 0px;
      margin-top: 5px !important;
    }
  }
}

.full-day {
  background-color: colors.get-color(colors.$Green, level_5) !important; /* green */
}
.half-day {
  background-color:colors.get-color(colors.$amber, level_5) !important; /* orange */
}
