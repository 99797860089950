@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.payment-general-register {
  margin: 10px 20px 0px 20px;
  height: calc(100% - 220px);
  display: flex;

  &--modal {
    height: calc(100% - 70px);
  }

  &__tableblock {
    width: 250px;
    border-right: 1px solid white;
    box-shadow: 2px 2px 7px rgba(80, 80, 80, 0.5);
    height: 100%;

    &--table {
      @include table();
      width: 250px;

      tr:hover {
        td {
          @include tdContainsOnclick();
        }
      }

      &--li {
        display: flex;
        justify-content: space-between;
      }

      &--input {
        @include inputReportsTable();
      }

      &--amount {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }

      &--number {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }

      &--name {
        width: calc(100% - 85%);
      }

      &--date {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-amount {
        width: calc(100% - 88%);
      }

      &--th-number {
        width: calc(100% - 90%);
      }

      &--th-date {
        width: calc(100% - 90%);
      }
    }
  }

  &__reportblock {
    width: calc(100% - 250px);
    height: 100%;

    &--select-options {
      margin-bottom: 10px !important;
      column-gap: 10px;
      margin-left: 10px;
    }

    &--details {
      border: 2px dashed colors.get-color(colors.$grey, level_3);
      margin: 10px 0px 0px 10px;
      height: calc(100% - 43px);
      padding: 10px 10px 0px 10px;

      &--modal {
        height: calc(100% - 10px);
      }
    }

    &--table {
      @include table();
    }

    &--textfield {
      @include textfield();

      input {
        font-weight: 400 !important;
      }

      &--date {
        @include textfield();
        width: fit-content !important;

        input {
          font-weight: 400 !important;
        }
      }
    }

    &--modal {
      @extend .payment-general-register__reportblock;
      width: 100%;
      height: 100% !important;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-right: 20px;

    &--total {
      text-align: right;
    }

  }

  &__buttons {
    margin-left: 20px;
  }
}