@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.student-status {
 @include mainFrameWithTitle();

  .MuiAutocomplete-root {
    margin-top: -2px !important;
  }

  .react-swipeable-view-container,
  .MuiTypography-root {
    height: 100% !important;
  }

  &__tabs {
    @include muiTabs();
    margin-top: 10px;
  }

  &__details {
    height: 120px;
    column-gap: 20px;

    &--imagefield {
      display: flex;

      .data-fetch-icon {
        margin-top: 7px !important;
      }
    }

    &--textfield {
      @include textfield();
      margin: 8px 10px 0px 0px !important;

      &--date {
        @include textfield();
        width: fit-content !important;
        margin: 8px 0px 0px 0px !important;
      }


    }
  }

  &__remarks {
    &--not-elligible {
      height: 180px;

      .account-frames {
        margin: 10px 0px 0px 0px !important;
      }
    }

    &--make-elligible {
      height: 140px;

      .account-frames {
        margin: 10px 0px 0px 0px !important;
      }
    }
  }

  &__block {
    &--make-elligible {
      height: calc(100% - 330px);
      margin-top: 10px;
      column-gap: 20px;
    }

    &--not-elligible {
      height: calc(100% - 370px);
      margin-top: 10px;
      column-gap: 20px;
    }
  }

  &--image {
    @include studentProfile();
  }

  &__view {
    &--search {
      margin-top: 7px !important;
    }

    &--select-flex {
      margin-left: 10px;
      display: flex;
      gap: 10px;
    }

    &--select-columns {
      @include filterColumns();

      img {
        margin-left: 0px;
      }

      Label {
        margin-top: 0px;
      }
    }

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }

      &--admno {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--regno {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--date {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--year {
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-slno {
        width: calc(100% - 96%);
      }

      &--th-admno {
        width: calc(100% - 92%);
      }

      &--th-regno {
        width: calc(100% - 92%);
      }

      &--th-date {
        width: calc(100% - 92%);
      }

      &--th-year {
        width: calc(100% - 94%);
      }

      &--remarks {
        width: calc(100% - 85%);
      }

      &--dept {
        width: calc(100% - 86%);
      }
    }

    &--tableblock {
      height: calc(100% - 125px);
      margin-top: 10px;
    }

    &--total {
      text-align: right;
    }
  }
}