@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.institution__table {
  margin-top: 20px;

  @include table();
  height: calc(100% - 20px);

  tr:hover .institution__table--name {
    @include tdContainsOnclick();
  }

  &--slno {
    width: calc(100% - 90%);
    font-family: fonts.$eduate-number-font;
  }

  &--th-slno {
    width: calc(100% - 90%);
  }

  &--action {
    width: calc(100% - 88%);
  }
}

#department-table {
  height: calc(100% - 10px);

  .institution__table {
    &--slno {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--action {
      width: calc(100% - 88%);
    }
  }
}

#category-table {
  height: calc(100% - 10px);
}

.inst-config {
  &__buttons {
    margin-left: 20px;
  }

  &__label-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.activefinancialyearIcon {
  margin-right: 5px;
}

.registration {
  height: calc(100% - 30px);

  &__data {
    height: calc(100% - 45px);
    overflow: auto;
    column-gap: 30px;

    .MuiAutocomplete-endAdornment {
      right: 10px !important;
    }

    &--logo-grid {
      display: grid;
      grid-template-columns: 0.8fr 1.5fr 0.6fr;
      gap: 10px;

      .storybook-button {
        margin: 10px 0px 0px 0px;
      }

      &--span {
        font-size:fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        color: var(--text-color);
      }

      &--image {
        display: flex;

        &--close-icon {
          width: 25px;
          height: 25px;
          margin-top: 10px;

          &:hover {
            background-color: colors.get-color(colors.$colors, white-smoke);
            border-radius: 6px;
          }
        }
      }

      &--browse {
        @include browseFiles();
      }
    }
  }
}

.inststeppers {
  margin-top: 10px;
}

.inst-registration {
  height: calc(100% - 245px);
  margin: 10px 20px 0px 20px;

  &__modal {
    height: calc(100% - 25px);
  }

  &__data {
    height: calc(100% - 55px);
    overflow: auto;
    column-gap: 42px;
    margin-top: 10px;

    .MuiAutocomplete-endAdornment {
      right: 10px !important;
    }
  }
}

.institution__table::-webkit-scrollbar,
.registration__data::-webkit-scrollbar,
.inst-registration__data::-webkit-scrollbar {
  @include scrollbar();
}

.institution__table::-webkit-scrollbar-thumb,
.registration__data::-webkit-scrollbar-thumb,
.inst-registration__data::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.institution__table::-webkit-scrollbar-thumb:hover,
.registration__data::-webkit-scrollbar-thumb:hover,
.inst-registration__data::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}