@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.employee-details {
  height: calc(100% - 145px);
  margin: 10px 20px 0px 20px;

  .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    right: 10px !important;
  }
  &__form {
    height: calc(100% - 130px);
  }
  &__block {
    height: 100%;
    overflow-y: auto;

    &--frame {
      column-count: 2;
      column-gap: 60px;
      &--select-grid {
        display: grid;
        grid-template-columns: 0.8fr 1.85fr 0.15fr;
        .data-fetch-icon {
          margin-top: 10px;
        }
      }
      &--grid-trio {
        display: grid;
        grid-template-columns: 0.4fr 0.3fr 0.7fr;
        .label-grid {
          display: grid;
          grid-template-columns: 0fr 2fr;
        }
      }
      .MuiFormControlLabel-root {
        display: flex !important;
        width: calc(100% - 10px) !important;
        justify-content: space-between !important;
        margin-top: 10px;
        margin-left: 5px !important;
      }
      &--flex{
        display: flex;
        column-gap: 5px;
        .storybook-button{
          margin: 10px 0px 0px 0px !important;
          width: 120px;
        }
      }
    }
  }

  &__basic-details {
    height: 120px;
    &--image {
      height: calc(100% - 10px);
      @include studentProfile();
    }
    &--block {
      height: calc(100% - 45px);
      overflow-y: auto;
      .MuiAutocomplete-root
        .MuiOutlinedInput-root
        .MuiAutocomplete-endAdornment {
        right: 10px !important;
      }
      &--frame {
        column-gap: 60px;
        column-count: 2;
      }
    }
    &--frame {
      height: calc(100% - 180px);
    }
  }
  &__correspondance-details {
    column-gap: 60px;
  }
}

.employee-details__basic-details--block::-webkit-scrollbar,
.employee-details__block::-webkit-scrollbar {
  @include scrollbar();
}

.employee-details__basic-details--block::-webkit-scrollbar-thumb,
.employee-details__block::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}
.employee-details__basic-details--block::-webkit-scrollbar-thumb:hover,
.employee-details__block::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
