@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.employee-tree {
  @include mainFrameWithTitle();

  &__tabs {
    @include muiTabs();
  }

  .react-swipeable-view-container,
  .MuiTypography-root {
    height: 100%;
  }

  .employee-list {
    margin: 0px !important;
    padding-top: 15px;
    height: calc(100% - 5px);
  }
}

.horizontal-tree {
  display: inline-block;
  padding: 10px;
  height: calc(100% - 60px);
  width: 100%;
  overflow: auto;
}

.horizontal-tree * {
  box-sizing: border-box;
}

.horizontal-tree .horizontal-tree__branch {
  padding: 5px 0 5px 20px;
}

.horizontal-tree .horizontal-tree__branch:not(:first-child) {
  margin-left: 270px;
}

.horizontal-tree .horizontal-tree__branch:not(:first-child):after {
  content: "";
  width: 20px;
  border-top: 1.8px dashed var(--border-color);
  position: absolute;
  left: 250px;
  top: 50%;
  margin-top: 1px;
}

.horizontal-tree__entry {
  position: relative;
  min-height: 70px;
  display: block;

  .active {
    .horizontal-tree__entry--span {
      border: 1px solid var(--level-6);
      background-color: var(--level-1);
    }

    .horizontal-tree__branch::after,
    .horizontal-tree__entry:before,
    .horizontal-tree__entry:last-child:after,
    .horizontal-tree__branch .horizontal-tree__branch::before,
    .horizontal-tree__branch .horizontal-tree__branch::after,
    .horizontal-tree__entry::after,
    .horizontal-tree__branch::before,
    .horizontal-tree__branch::before {
      border-color: var(--level-6);
    }
  }

  &--b {
    padding: 5px;
    border: 1px solid var(--border-color);
    font-family: fonts.$eduate-number-font;
    height: 30px;
    width: 30px;
    font-size: 12px;
    text-align: center;
  }

  &--image {
    display: flex;
    column-gap: 10px;

    img {
      width: 30px;
    }
  }

  &:before {
    content: "";
    height: 100%;
    border-left: 1.8px dashed var(--border-color);
    position: absolute;
    left: -20px;
  }

  &:first-child:after {
    height: 10px;
    // border-radius: 10px 0 0 0;
  }

  &:first-child:before {
    width: 10px;
    height: 50%;
    top: 50%;
    margin-top: 1px;
    // border-radius: 10px 0 0 0;
  }

  &:after {
    content: "";
    width: 20px;
    transition: border 0.5s;
    border-top: 1.8px dashed var(--border-color);
    position: absolute;
    left: -20px;
    top: 50%;
    margin-top: 1px;
  }

  &:last-child:before {
    width: 10px;
    height: 50%;
    // border-radius: 0 0 0 10px;
  }

  &:last-child:after {
    height: 10px;
    border-top: none;
    transition: border 0.5s;
    border-bottom: 1.8px dashed var(--border-color);
    // border-radius: 0 0 0 10px;
    margin-top: -9px;
  }

  &:only-child:before {
    display: none;
  }

  &--span {
    border: 1px solid var(--border-color);
    display: block;
    min-width: 250px;
    padding: 5px 10px;
    line-height: 20px;

    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -15px;
    color: var(--text-color);
    font-family: fonts.$eduate-font-family;
    font-size: fonts.$eduate-default-font-size;
    display: inline-block;
    border-radius: 5px;
    transition: all 0.5s;
    display: flex;
    justify-content: space-between;
  }

  &--text {
    width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.horizontal-tree__entry .horizontal-tree__entry:only-child:after {
  width: 10px;
  height: 0px;
  margin-top: 1px;
  border-radius: 0px;
}

.horizontal-tree .horizontal-tree__entry .horizontal-tree__entry--span:hover,
.horizontal-tree
  .horizontal-tree__entry
  .horizontal-tree__entry--span:hover
  + .horizontal-tree__branch
  .horizontal-tree__entry
  .horizontal-tree__entry--span {
  border-color: var(--level-3);
}

.horizontal-tree
  .horizontal-tree__entry
  .horizontal-tree__entry--span:hover
  + .horizontal-tree__branch
  .horizontal-tree__entry::after,
.horizontal-tree
  .horizontal-tree__entry
  .horizontal-tree__entry--span:hover
  + .horizontal-tree__branch
  .horizontal-tree__entry::before,
.horizontal-tree
  .horizontal-tree__entry
  .horizontal-tree__entry--span:hover
  + .horizontal-tree__branch::before,
.horizontal-tree
  .horizontal-tree__entry
  .horizontal-tree__entry--span:hover
  + .horizontal-tree__branch::after,
.horizontal-tree
  .horizontal-tree__entry
  .horizontal-tree__entry--span:hover
  + .horizontal-tree__branch
  .horizontal-tree__branch::before {
  border-color: var(--level-3);
}

.horizontal-tree::-webkit-scrollbar {
  @include scrollbar();
}

.horizontal-tree::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.horizontal-tree::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
