@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.parent-preview {
  margin: 0px 20px 0px 20px;
  height: calc(100% - 185px);
  &__span-3 {
    column-count: 3;
    column-gap: 20px;
    padding-bottom: 3px;
  }
  &__frame {
    margin-top: 10px;
    height: calc(100% - 200px);
    overflow: auto;
    &--span-3 {
      column-count: 3;
      column-gap: 20px;
    }
  }
  &__textfield {
    @include textfield();
    margin-left: 0px !important;
    margin: 6px 0px 0px 0px !important;
  }
  &__tableblock {
    height: calc(100% - 242px);
    margin-top: 10px;
  }

  &__details {
    border-bottom: 1.8px dashed var(--border-color);
    padding-bottom: 7px;
  }
}

.parent-preview__frame::-webkit-scrollbar {
  @include scrollbar();
}

.parent-preview__frame::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.parent-preview__frame::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
