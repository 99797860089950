
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.config {
  &__details {
    padding: 12px;
    border: 1px solid colors.get-color(colors.$colors, snow-white);
    margin: 0 auto;
    border-radius: 12px;
    width: 430px;
    background: colors.get-color(colors.$colors, snow-white);
    box-sizing: border-box;
  }
}

.delete-modal {
  &__body {
    text-align: left;
    font-family: fonts.$eduate-font-family;
    font-size:fonts.$eduate-default-font-size;
    margin-top: 20px;
    height: calc(100% - 85px);
  }

  &__title {
    font-family: fonts.$eduate-font-family;
    height: 25px;

    .close-icon {
      @include closeIconInModal();
    }
  }
}

.old-balance-modal {
  &__title {
    font-family: fonts.$eduate-font-family;
    height: 70px;
    .close-icon {
      @include closeIconInModal();
    }
  }
}
