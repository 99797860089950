@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.fb-form-view {
  margin-top: 10px;
  height: calc(100% - 10px);

  &__title {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    height: 70px;
    border: 1px solid var(--border-color);
    align-items: center;
    padding: 5px;
    margin-top: 10px;
    column-gap: 10px;
    &--acd {
      font-family: fonts.$eduate-font-family;
      font-size: 14px;
      background-color: var(--level-4);
      color: white;
      font-weight: 600;
      border-radius: 2px;
      width: fit-content;
      padding: 5px 10px;
    }
    &--non-acd {
      @extend .fb-form-view__title--acd;
      background-color:  colors.get-color(colors.$cool_grey, level_3);
      color: colors.get-color(colors.$cool_grey, level_6);
    }
    &--left {
      display: flex;
      column-gap: 10px;

      b {
        font-family: fonts.$eduate-font-family;
        font-size: 22px;
        color: var(--text-color);
        font-weight: 700;
      }
    }
    &--flex {
      display: flex;
      justify-content: flex-end;
      column-gap: 10px;
    }
    &--textfield {
      @include textfield();
      margin: 10px 0px 0px 0px !important;
    }
  }
  &__block {
    margin-top: 15px;
    height: calc(100% - 75px);
    &--tableblock {
      height: calc(100% - 100px);
      margin-top: 10px;
      display: flex;
      column-gap: 20px;
    }
    &--table {
      @include table();
      width: calc(100% - 340px);
    }
    &--graph {
      height: 100%;
      width: 300px;
    }
  }
  &__subtitle {
    margin-top: 15px;
    color: var(--level-5);
    font-family: fonts.$eduate-font-family;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    column-gap: 10px;
    align-items: center;
    span {
      font-size: 12px;
      font-weight: 400;
    }
  }
  &__cards {
    display: flex;
    column-gap: 10px;
    margin-top: 10px;
    height: 70px;
  }
  &__card {
    display: flex;
    align-items: center;
    column-gap: 30px;
    width: fit-content;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 6px;
    background-color: colors.get-color(colors.$cool_grey, level_2);
    img {
      width: 35px;
    }
    span {
      font-size: 16px;
      font-family: fonts.$eduate-font-family;
    }
    b {
      font-family: fonts.$eduate-number-font;
      font-size: 18px;
      font-weight: 700;
    }
  }
  &__detailed {
    &--select {
      column-gap: 10px;
      margin-top: 15px;
    }
    &--tableblock {
      margin-top: 10px;
      height: calc(100% - 300px);
    }
    &--table {
      @include table();
      &--elective {
        td {
          background-color: var(--bg-color);
          color: var(--text-color);
          font-size: 18px !important;
          font-weight: 700 !important;
        }
      }
      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }
      &--code {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }
      &--emp {
        width: calc(100% - 90%);
        &--flex {
          display: flex;
          column-gap: 10px;
          img {
            width: 30px;
            height: 30px;
          }
        }
        &--block {
          span {
            display: block;
            font-size: 14px;
            color: colors.get-color(colors.$cool_grey, level_6);
            font-family: fonts.$eduate-number-font;
          }
          b {
            font-family: fonts.$eduate-font-family;
            font-size: 16px;
            color: var(--text-color);
          }
        }
      }
      &--button {
        width: calc(100% - 92%);
      }
      &--view {
        @include viewButton();
      }
      &--number {
        width: calc(100% - 95%);
        font-family: fonts.$eduate-number-font;
      }
    }
  }
  &__questionwise {
    margin-top: 10px;
    height: calc(100% - 175px);

    border: 1px solid var(--border-color);

    &--table {
      height: 100%;
    }
    &--emp {
      padding: 5px;
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
        0px 4px 6px -2px rgba(0, 0, 0, 0.05);
      &--flex {
        display: flex;
        column-gap: 10px;

        img {
          width: 30px;
          height: 30px;
        }
      }
      &--block {
        span {
          display: block;
          font-size: 14px;
          color: colors.get-color(colors.$cool_grey, level_6);
          font-family: fonts.$eduate-number-font;
        }
        b {
          font-family: fonts.$eduate-font-family;
          font-size: 16px;
          color: var(--text-color);
        }
      }
    }
    &--graph {
      padding: 5px;
      border-radius: 6px;
      width: 100%;
      height: 100%;
      .echarts-for-react {
        height: 100% !important;
        width: 100% !important;
      }
    }
    &--remarks {
      height: 100%;
      &--title {
        display: flex;
        justify-content: space-between;
      }
      &--data {
        margin-top: 10px;
        height: calc(100% - 85px);
        padding-left: 20px;
      }
      &--ques {
        font-size: 16px;
        font-family: fonts.$eduate-font-family;
        height: 70px;
        margin-top: 10px;
      }
      &--block {
        border: 1px solid var(--border-color);
        border-radius: 6px;
        height: calc(100% - 60px);
        overflow: auto;
        margin-top: 10px;
      }
      &--total {
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid var(--border-color);
        background-color: colors.get-color(colors.$cool_grey, level_2);
        padding: 0px 15px;
        &--text {
          display: flex;
          column-gap: 10px;
          img {
            width: 30px;
            height: 30px;
          }
        }
        &--number {
          color: var(--level-5);
          font-family: fonts.$eduate-number-font;
          font-size: 16px;
        }
      }
      &--list {
        margin-bottom: 0px !important;
        margin-top: 15px;
        li {
          padding: 15px 10px;

          border-bottom: 1px solid var(--border-color);
          label {
            font-family: fonts.$eduate-font-family;
            font-size: 16px;
            padding: 5px 10px;
            color: colors.get-color(colors.$cool_grey, level_5);
            background-color: var(--level-1);
            border-radius: 6px;
          }
          span {
            display: block;
            margin-top: 10px;
            background-color: colors.get-color(colors.$cool_grey, level_1);
            padding: 8px 10px;
            width: 100%;
            border-radius: 6px;
            font-size: 14px;
            font-family: fonts.$eduate-font-family;
          }
        }
      }
    }
    &--detailed {
      height: 100%;
      &--title {
        img {
          @include closeIconInModal();
        }
      }
      &--table {
        height: calc(100% - 330px);
        margin-top: 10px;
      }
    }
  }
  &__ques-graph {
    margin-top: 10px;
    height: calc(100% - 175px);
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    border: 1px solid var(--border-color);
    overflow: auto;
    padding: 5px;
    .echarts-for-react {
      height: calc(100% - 45px) !important;
      width: 100% !important;
    }
    &--button {
      display: flex;
      justify-content: center;
      button {
        @include viewButton();
      }
    }
  }
}

.fb-form-view__questionwise--remarks--block::-webkit-scrollbar {
  @include scrollbar();
}

.fb-form-view__questionwise--remarks--block::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.fb-form-view__questionwise--remarks--block::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
