@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.feedback-view{
    height: 100%;
    &__row{
        margin-top: 10px;
        height: calc(100% - 85px);
        column-gap: 40px;
        display: flex;
        column-gap: 40px;
        .tree-view {
            margin-top: 10px !important;
        }
        &--left{
            height: 100%;
            border: 1px solid var(--border-color);
            padding: 5px;
            width: 280px;
        }
        &--right{
            height: 100%;
            width: calc(100% - 280px);
            &--flex{
                column-gap: 10px;
                .storybook-button{
                    margin: 0px 10px 0px 0px !important;
                }
            }
        }
        &--tableblock{
            margin-top: 10px;
            height: calc(100% - 95px);
        }
        
    }
    &__title{
        img{
            @include closeIconInModal();
        }
    }
}