@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.library-dashboard {
  @include mainFrameWithTitle();

  &__cards {
    margin-top: 10px;

    column-gap: 20px;
    height: 245px;

    &--grid {
      height: 100% !important;
      @include blocksInDashboard();
      &--image {
        margin-right: 20px;
        width: 35px;
      }

      &--li {
        display: flex;
        justify-content: space-between;
        margin-top: 5px;
        align-items: center;

        span {
          font-size: fonts.$eduate-default-font-size;
          font-family: fonts.$eduate-font-family;
          color: var(--text-color);
        }
      }
      &--cardtitle {
        display: flex;
        justify-content: space-between;
        @include muiTabsInDashboard();
      }
    }
  }
  &__datablock {
    margin-top: 10px;
    height: calc(100% - 265px);
    column-gap: 15px;
    .customized-calendar {
      height: calc(100% - 35px) !important;
    }

    &--grid {
      @extend .library-dashboard__cards--grid;
    }

    &--chart {
      height: 100% !important;
    }
  }
  &__table {
    height: calc(100% - 40px);
    @include table();
    &--totalcount {
      font-size: 14px;
      font-family: fonts.$eduate-font-family;
      b {
        background-color: var(--level-5);
        color: white;
        padding: 2px 5px;
        font-family: fonts.$eduate-number-font;
        font-size: 16px;
        margin-right: 5px;
      }
    }
  }
  &__tableblock {
    height: calc(100% - 30px);
    .font-red{
      text-align: center !important;
    }
  }
}

.library-dashboard::-webkit-scrollbar {
  @include scrollbar();
}

.library-dashboard::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.library-dashboard::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
