@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
.vouchers-approved-status {
    height: calc(100% - 30px);

    &__datablock {
        height: calc(100% - 10px);
        overflow: auto;

        &--approved-details {
            display: flex;
            column-gap: 10px;
            font-size:fonts.$eduate-default-font-size;
            font-family: fonts.$eduate-font-family;
            color: var(--text-color);
        }

        &--approved-by {
            display: flex;
            column-gap: 10px;

            img {
                width: 25px !important;
            }

            &--desn {
                font-weight: 700;
                font-size: 16px;
            }
        }

        &--approved {
            display: flex;

            &--line {
                border-left: 1px dashed colors.get-color(colors.$grey, level_5);
                margin-left: -30px;
                margin-top: 20px;
            }

            &--without-line {
                margin-left: -30px;
                margin-top: 20px;
            }

            &--details {
                padding-left: 30px;
            }
        }


        &--remarks {
            font-size:fonts.$eduate-default-font-size;
            font-family: fonts.$eduate-font-family;
            color: colors.get-color(colors.$grey, level_5);
        }

        &--li {
            display: flex;
            justify-content: space-between;
            padding: 5px;

            img {
                width: 40px;
            }
        }

        &--mode {
            font-size:fonts.$eduate-default-font-size;
            font-family: fonts.$eduate-font-family;
            color: var(--text-color);
            margin-top: 10px;
        }

        &--date {

            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 120px;

            span {
                text-align: right;
                font-size: 12px;
                font-family: fonts.$eduate-number-font;
                color: var(--text-color);
            }
        }


    }
}

.vouchers-approved-status__datablock::-webkit-scrollbar {
    @include scrollbar();
}

.vouchers-approved-status__datablock::-webkit-scrollbar-thumb {
    @include scrollbarthumb();
}

.vouchers-approved-status__datablock::-webkit-scrollbar-thumb:hover {
    @include scrollbarthumb-hover();
}