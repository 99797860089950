@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.sub-allocation-comb {
    @include mainFrameWithTitle();
    &__title {
        display: flex;
        color: var(--level-6);
        font-family: fonts.$eduate-font-family;
        font-size: 16px;
        margin-top: 10px;
    }
    &__datablock {
        height: calc(100% - 130px);
        margin-top: 10px;
        display: flex;
        column-gap: 40px;
        &--search {
            .student-total-count {
                display: flex;
                justify-content: flex-end;
            }
        }
        &--left {
            height: 100%;
            border: 1px solid var(--border-color);
            border-radius: 6px;
            width: calc(100% - 400px);
            padding: 5px;
        }
        &--right {
            @extend .sub-allocation-comb__datablock--left;
            width: 400px;
        }
        &--std-list {
            height: calc(100% - 30px);
            width: calc(100% - 20px) !important;
            &--tableblock {
                margin-top: 10px;
                height: calc(100% - 60px);
                width: 100% !important;
            }
        }
    }
    &__sub-list {
        height: calc(100% - 30px);
        &--tableblock {
            margin-top: 10px;
            height: calc(100% - 50px);
        }
        &--table{
            @include table();
            height: calc(100% - 40px )!important;
        }
    }
    &__selected-students {
        height: 100%;
        &--title {
            display: flex;
            justify-content: space-between;
            img {
                @include closeIconInModal();
            }
        }
        &--b {
            font-size: 16px;
            font-family: fonts.$eduate-font-family;
            color: var(--level-5);
        }
        &--tableblock {
            height: calc(100% - 145px);
            margin-top: 10px;
        }
    }
}
