@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.combinations-std-list {
    height: 100%;
    &__tableblock {
        margin-top: 10px;
        height: calc(100% - 95px);
        &--view {
            @include viewButton();
        }
    }
}
.combinations-individual {
    @include mainFrameWithTitle();
    &__title{
        img{
            @include closeIconInModal();
        }
    }
    &__back {
        margin-top: 10px;
        border: 1px solid var(--border-color);
        padding: 5px 8px;
        color: colors.get-color(colors.$cool_grey, level_6);
        font-size: 14px;
        border-radius: 20px;
        font-family: fonts.$eduate-font-family;
        img {
            width: 20px;
            transform: rotate(180deg);
        }
    }
    &__details {
        column-gap: 20px;
        height: 130px;
    }
    &__textfield {
        @include textfield();
        margin: 10px 0px 0px 0px !important;
    }
    &__profile {
        height: 100% !important;
        img {
            @include studentProfile();
            margin-top: 10px;
            height: calc(100% - 10px) !important;
            width: 100%;
        }
    }
    &__tabs {
        @include muiTabs();
    }
    &__tabpanel {
        margin-top: 10px;
        height: calc(100% - 270px);
        .MuiTypography-root,p {
            height: 100%;
        }
        &--frame {
            border: 1px solid var(--border-color);
            border-radius: 6px;
            padding: 5px;
            height: 100%;
        }
        &--block {
            height: 100%;
            column-gap: 20px;

            .student-dashboard__attendance--full-view--subjects-list {
                height: calc(100% - 115px) !important;
            }
        }
        &--modal{
            height: calc(100% - 230px);
            margin-top: 10px;
        }
    }
    &__testwise{
        height: 100%;
        &--cards{
            margin-top: 10px;
            display: grid;
            grid-template-columns: repeat(5,1fr);
            gap: 5px;
           

        }
        &--block{
            overflow: auto;
            height: calc(100% - 85px);
        }
    }
    &__subject-wise{
        height: 100%;
      &--block{
        border: 1px solid var(--border-color);
        padding: 5px;
        border-radius: 6px;
        height: calc(100% - 45px);
      }
  
    }
    &__modal{
        height: calc(100% - 30px);
        p{
            height: 100% !important;
        }
    }
}


.combinations-individual__testwise--block::-webkit-scrollbar {
  @include scrollbar();
}

.combinations-individual__testwise--block::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}


.combinations-individual__testwise--block::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
