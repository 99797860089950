@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.view-test-report {
  @include mainFrameWithTitle();
  &__options {
    column-gap: 10px;
    margin-top: 10px;
    &--textfield {
      @include textfield();
      &--date {
        @include textfield();
        width: fit-content;
      }
    }
  }
  &__datablock {
    border: 1.8px dashed var(--border-color);
    padding: 5px;
    margin-top: 10px;
    height: calc(100% - 95px);
    &--date {
      display: flex;
      gap: 10px;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }
  &__status {
    margin-top: 10px;
  }
  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 45px);

  }
  &__table{
    @include table();
    &--sticky-header{
      position: sticky !important;
      top: 49px !important;
      z-index: 998 !important;
      height: 25px  !important ;
      overflow: hidden;
    }
    &--slno{
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
  }
}
