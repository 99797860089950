@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.payroll-reports {
  height: calc(100% - 220px);
  margin: 10px 20px 0px 20px;
  display: flex;
  column-gap: 20px;

  &__leftblock {
    width: 300px;
    height: 100%;
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);

    &--table {
      @include table();

      &--input {
        @include inputReportsTable();
      }
    }
  }

  &__rightblock {
    height: 100%;
    width: calc(100% - 320px);

    &--details {
      column-gap: 10px;

      #search {
        margin-top: 0px !important;
      }
    }

    &--data {
      margin-top: 8px;
      height: calc(100% - 40px);
      border: 1.8px dashed var(--border-color);
      padding: 10px;

      &--table {
        @include table();
        margin-top: 10px;
        height: calc(100% - 35px);

        th {
          .MuiTableCell-root {
            border-bottom: none !important;
          }

          td {
            text-align: center !important;
          }
        }

        tr {
          &:hover td {
            background-color: var(--bg-color) !important;
            font-weight: bold !important;
          }
        }
      }

      &--title {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__payslip {
    height: calc(100% - 70px);

    &--details {
      height: 130px;
      column-gap: 20px;

      &--textfield {
        @include textfield();
        margin: 8px 0px 0px 0px !important;
      }

      &--options {
        padding-top: 10px;
        border-top: 1px dashed var(--border-color);
        height: 45px;
        column-gap: 10px;
      }

      &--image {
        @include studentProfile();
      }
    }

    &--tableblock {
      margin-top: 10px;
      height: calc(100% - 180px);

      &--tables {
        column-gap: 30px;
        height: calc(100% - 80px);
      }

      &--table {
        @include table();

        &--groupheader {
          @include groupHeader();
        }

        &--subheader {
          @include subHeader();
        }

        &--amount {
          width: 132px;
        }
      }

      &--total {
        height: 40px;
        column-gap: 30px;
        position: sticky;
        bottom: 0px;
        overflow: hidden;
        background-color: var(--bg-color);

        &--label {
          display: flex;
          justify-content: flex-end;

          span {
            height: 25px;
            font-family: fonts.$eduate-number-font;
            border-radius: 2px;
            text-align: right;
            font-size:fonts.$eduate-default-font-size;
            width: 150px;
            border: 1px solid var(--border-color);
            padding-right: 10px;
          }
        }
      }

      &--grandtotal {
        @extend .payroll-reports__payslip--tableblock--total;
        display: flex;
        justify-content: center;

        span {
          background-color: colors.get-color(colors.$Green, level_5);
          height: 25px;
          font-family: fonts.$eduate-number-font;
          border-radius: 2px;
          text-align: right;
          font-size:fonts.$eduate-default-font-size;
          width: 200px;
          border: 1px solid var(--border-color);
          padding-right: 10px;
        }
      }
    }

    &--salary-table {
      @include table();
    }
  }
}