@use "../../../scss/common//mixins" as *;
@use "../../../scss/common/fonts" as fonts;
@use "../../../scss/common/colors" as colors;
.vms-fuel-purchase {
  @include mainFrameWithTitle();

  &__select {
    column-gap: 10px;

    &--addnew {
      display: flex !important;
      justify-content: flex-end !important;
      margin-left: auto !important;
      margin-right: 0px !important;
    }
  }

  &__tableblock {
    height: calc(100% - 100px);
    margin-top: 10px;
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--number {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--actions {
      width: calc(100% - 96%);
    }
  }

  &__add {
    height: 100%;

    &--details {
      height: 120px;
      column-gap: 20px;

      .MuiAutocomplete-root {
        margin-top: 10px;
      }

      &--flex {
        display: flex;
        column-gap: 5px;
      }

      &--row {
        column-gap: 5px;
      }

      &--textfield {
        @include textfield();
        margin: 10px 0px 0px 0px !important;

        &--date {
          @extend .vms-fuel-purchase__add--details--textfield;
          width: fit-content !important;
        }
      }
    }

    &--tableblock {
      height: calc(100% - 110px);
    }

    &--datablock {
      height: calc(100% - 200px);
      margin-top: 10px;
    }

    &--table {
      @include table();

      td {
        padding: 0px 3px 0px 3px !important;
      }

      &--slno {
        width: calc(100% - 95%);
        font-family: fonts.$eduate-number-font;
      }

      &--code {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }

      &--number {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }

      &--rate {
        @include inputSalaryTable();
        padding: 0px !important;
        width: calc(100% - 90%);
      }

      &--amount {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
      }

      &--actions {
        width: calc(100% - 95%);
      }
    }

    &--footer {
      height: 110px;
      column-gap: 20px;

      &--frame {
        height: 100%;
        border: 1px solid var(--border-color);
        border-radius: 10px;
        padding: 5px;
      }
    }
  }
}
