@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.unknown-bank-trans {
  @include mainFrameWithTitle();
  &--modal{
    height: calc(100% - 30px);
  }

  &__select {
    column-gap: 10px;

    .MuiFormControl-root {
      margin-top: 10px;
    }

    &--textfield {
      width: fit-content !important;
      @include textfield();

      margin: 10px 10px 0px 0px !important;
    }

    &--button {
      display: flex !important;
      column-gap: 10px;
      justify-content: flex-end;

      .storybook-button {
        margin-right: 0px !important;
      }
    }
  }

  &__tableblock {
    height: calc(100% - 100px);
    margin-top: 10px;
    &--modal {
      margin-top: 10px;
      height: 100%;
    }
  }

  &__table {
    @include table();

    tr:hover {
      .unknown-bank-trans__table--receipt-no {
        @include tdContainsOnclick();
      }
    }

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--date {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--number {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--receipt-no {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--amount {
      width: calc(100% - 88%);
      font-family: fonts.$eduate-number-font;
    }

    &--type {
      width: calc(100% - 92%);
    }

    &--actions {
      width: calc(100% - 94%);

      img {
        width: 20px;
      }
    }
  }

  &__add {
    @include mainFrameWithTitle();

    &--button {
      column-gap: 10px;
      .MuiAutocomplete-root{
        margin-top: 10px !important;
      }

     
    }

    &--tableblock {
      height: calc(100% - 255px);
      margin-top: 10px;
      &--modal {
        height: calc(100% - 130px);
        margin-top: 10px;
      }
    }

    &--receiptblock {
      height: 150px;
      column-gap: 20px;
      margin-top: 10px;

      &--frame {
        border: 1px solid var(--border-color);
        height: 100%;
        border-radius: 6px;
        padding: 5px;

        &--label-grid {
          display: grid;
          grid-template-columns: 1fr 1fr;
          .MuiAutocomplete-root{
            margin-top: 10px !important
          }
        }
      }
    }

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }

      &--row {
        @include selectTableRow();
        @include autoCompleteInTableRow();
      }

      &--amount {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
        @include inputSalaryTable();
      }

      &--actions {
        width: calc(100% - 94%);
      }
    }
  }

  &__adjustments {
    height: calc(100% - 30px);

    .student-total-count {
      margin-top: 15px !important;
    }

    &--options {
      column-gap: 10px;
      margin-top: 10px;

      #search {
        margin-top: 0px !important;
      }
    }

    &--tableblock {
      height: calc(100% - 95px);
      margin-top: 10px;
    }

    &--table {
      @include table();
      @include tableCheckbox();

      &--slno {
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
      }

      &--checkbox {
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
      }

      &--regno {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }

      &--desc {
        width: calc(100% - 90%);
      }
    }

    &--drawer {
      height: 100%;

      &--details {
        &--date {
          @include textfield();
          margin: 10px 10px 0px 0px !important;
          width: fit-content !important;
        }
      }

      &--flex {
        display: flex;
        justify-content: space-between;
        column-gap: 10px;
      }

      &--temp-flex {
        display: flex;
        justify-content: space-between;
      }

      &--remaining-bal {
        font-family: fonts.$eduate-font-family;
        margin-top: 15px;
        column-gap: 10px;

        span {
          font-family: fonts.$eduate-number-font;
          margin-left: 5px;
        }
      }

      &--tableblock {
        margin-top: 10px;
        height: calc(100% - 235px);
      }

      &--table {
        @include table();
        @include tableCheckbox();

        td {
          padding-top: 0px !important;
          padding-bottom: 0px !important;
        }

        tbody {
          .MuiTableRow-root,
          .Mui-selected {
            td {
              background-color: var(--level-1) !important;
            }
          }
        }

        &--slno {
          width: calc(100% - 94%);
          font-family: fonts.$eduate-number-font;
        }

        &--checkbox {
          width: calc(100% - 94%);
          font-family: fonts.$eduate-number-font;
        }

        &--regno {
          width: calc(100% - 88%);
          font-family: fonts.$eduate-number-font;
        }

        &--input {
          text-align: right;
          @include inputSalaryTable();
          width: calc(100% - 86%);

          height: 33px;
        }
      }
    }
  }

  &__receiptDetails {
    height: calc(100% - 35px);

    &--tableblock {
      height: calc(100% - 45px);
      margin-top: 10px;
    }

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 92%);
        font-family: fonts.$eduate-number-font;
      }

      &--receipt-no {
        width: calc(100% - 80%);
        font-family: fonts.$eduate-number-font;
      }

      &--amount {
        width: calc(100% - 80%);
        font-family: fonts.$eduate-number-font;
      }
    }
  }

  &__total {
    text-align: right;
  }

  &__advance-fee {
    height: calc(100% - 30px);

    &--select {
      column-gap: 20px;
      height: 120px;

      .MuiAutocomplete-root,
      .data-fetch-icon {
        margin-top: 8px;
      }

      &--flex {
        display: flex;
      }

      &--textfield {
        @include textfield();
        width: 100%;
        margin: 8px 0px 0px 0px !important;

        &--date {
          @include textfield();
          width: fit-content;
          margin: 8px 0px 0px 0px !important;
        }
      }

      &--image {
        height: 100%;
        @include studentProfile();

        &--textfield {
          @include textfield();
          width: 100%;
          margin: 8px 0px 0px 0px !important;
        }

        &--date {
          @include textfield();
          width: fit-content;
          margin: 8px 0px 0px 0px !important;
        }
      }

      &--button {
        column-gap: 10px;
      }
    }

    &--tableblock {
      height: calc(100% - 280px);
      margin-top: 10px;
    }

    &--receiptblock {
      height: 110px;
      column-gap: 20px;

      .Label,
      Input {
        margin-top: 5px !important;
      }

      &--frame {
        height: 100%;
        border: 1px solid var(--border-color);
        padding: 10px;
      }
    }

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }

      &--amount {
        width: calc(100% - 90%);
        font-family: fonts.$eduate-number-font;
        @include inputSalaryTable();
      }
    }
  }
}
