@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
$DARK_BLUE: #155192;
$LIGHT_BLUE: #e8f3f9;
.bgs-tenth-pr {
  width: 100%;
  height: calc(100% - 100px);

  &__block {
    width: 100%;
    height: 100%;
    overflow: auto;
    .page-break {
      page-break-after: always !important;
    }
  }
  &__bs {
    height: 100%;
    width: 100%;
    padding: 5px;
    &__block {
      width: 100%;
      height: 100%;
      .page-break {
        page-break-after: always !important;
      }
    }
    &--title {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $DARK_BLUE;
      b {
        color: white;
        font-family: fonts.$eduate-font-family;
        font-size: 16px;
      }
    }
    &--flex {
      display: flex;
      column-gap: 15px;
      margin-top: 10px;
      align-items: center;
      &--name {
        width: 28%;
        display: flex;
        align-items: center;
        column-gap: 5px;
      }
      &--class {
        width: 24%;
        @extend .bgs-tenth-pr__bs--flex--name;
      }
      span {
        font-size: 12px;
        color: white;
        font-family: fonts.$eduate-font-family;
        padding: 0px 5px;
        background-color: $DARK_BLUE;
      }
      input {
        height: 20px;
        border: 1px solid var(--text-color);
        border-radius: 0px;
        width: 100%;
      }
    }
    &--tableblock {
      margin-top: 10px;
    }
    &--table {
      border-left: 1px solid var(--text-color);
      border-top: 1px solid var(--text-color);

      height: 100%;
      width: 100%;
      &--first-row {
        th:nth-child(even) {
          background-color: $DARK_BLUE; /* light gray */
        }
      }

      td:nth-child(odd) {
        background-color: $LIGHT_BLUE; /* light gray */
      }

      th:nth-child(odd) {
        background-color: $LIGHT_BLUE; /* light gray */
      }

      th {
        height: 25px !important;
        padding: 0px !important;
        border-bottom: 1px solid var(--text-color);
        border-right: 1px solid var(--text-color);
        font-family: fonts.$eduate-font-family;
        font-size: 12px;
        text-align: center;
      }
      td {
        padding: 3px !important;
        border-bottom: 1px solid var(--text-color);
        border-right: 1px solid var(--text-color);
        font-size: 12px !important;
        color: var(--text-color) !important;
        font-family: fonts.$eduate-font-family;
      }
      &--sub {
        width: 200px !important;
      }
      &--remarks {
        td {
          padding: 15px 3px !important;
        }
      }
      &--sign {
        td {
          padding: 10px 3px !important;
        }
      }
    }
  }
}
