@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.inst-level-report {
  height: calc(100% - 160px);
  margin: 0px 20px;
  &__datablock {
    height: 100%;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
  }
  &__tabpanel {
    height: calc(100% - 100px);
    margin-top: 5px;
    p {
      height: 100% !important;
    }
    &--wp{
      height: calc(100% - 55px);
      margin-top: 5px;
      p {
        height: 100% !important;
      }
    }
    &--formodal{
      height: calc(100% - 45px);
      p{
        height: 100% !important;
      }
    }
  }
  &__lt {
    height: 100%;
    width: 280px;
    &--table {
      @include table();
      border: 1px solid var(--border-color);

      &--input {
        @include inputReportsTable();
        width: 100%;
      }
      &--voucher {
        @include table();
        width: 100%;
        height: calc(100% - 35px) !important;
        margin-top: 5px;
        border: 1px solid var(--border-color);
      }
    }
  }
  &__rt {
    height: 100%;
    border: 1px solid var(--border-color);
    width: 280px;
    &--header {
      height: 40px;
      background-color: var(--disabled-color);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--select {
      .select {
        margin-top: 0px !important;
      }
    }
    &--block {
      height: calc(100% - 50px);
      overflow: auto;
      padding: 10px;
    }
    &--switch {
      border: 1px solid var(--border-color);
      padding: 5px;
      margin-top: 10px;
      &--form-labels {
        display: flex !important;
        justify-content: space-between !important;
        font-size: 14px;
        font-family: fonts.$eduate-font-family;
        margin-right: 5px !important;
      }
    }
  }
  &__md {
    height: 100%;
    border: 1px solid var(--border-color);
    width: calc(100% - 560px);
    &--block {
      height: calc(100% - 95px);
      overflow: auto;
      padding: 10px;
      &--flex {
        display: flex;
        margin-top: 8px;
        align-items: center;
        .MuiFormControlLabel-root {
          width: 190px !important;
          font-size: 14px !important;
        }
        .MuiAutocomplete-root {
          width: calc(100% - 450px) !important;
        }
      }
      &--compare {
        margin-top: 10px;
        width: 25px;
        height: 25px;
      }
      &--age {
        width: 100px !important;
        margin-right: 10px;
      }
      &--title {
        font-family: fonts.$eduate-font-family;
        color:colors.get-color(colors.$colors, white);
        font-size: 14px;
        background-color: var(--level-5);
        border-radius: 5px;
        padding: 5px;
        width: fit-content;
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &--header {
      @extend .inst-level-report__rt--header;
      b {
        color: var(--level-5);
      }
    }
    &--buttons {
      margin-left: 10px;
    }
    &--flex {
      display: flex;
      column-gap: 10px;
      justify-content: center;
      margin-top: 15px;
      align-items: center;
    }
    &--textfield {
      @include textfield();
      width: fit-content !important;
    }
    &--nodata {
      width: 250px;
      height: 250px;
      display: block;
      margin: auto;
      img {
    
        height: 100%;
        width: 100%;
      }
    }
    &--formodal{
      @extend .inst-level-report__md;
      width: calc(100% - 280px);
    }
    &--vouchers-block{
      height: calc(100% - 90px);
      padding: 5px;
    }
  }
  &__initialize {
    height: 100%;
    &--title {
      img {
        @include closeIconInModal();
      }
    }
    &--subtitle {
      background-color: var(--disabled-color);
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      span {
        color: colors.get-color(colors.$cool_grey, level_7);
        font-family: fonts.$eduate-font-family;
        font-size: 16px;
        border-radius: 6px;
      }
    }
    &--block {
      height: calc(100% - 115px);
      overflow: auto;
      ul {
        margin-bottom: 0px !important;
      }
      li {
        padding: 8px;
        background-color: var(--level-1);
        color: var(--text-color);
        border-bottom: 1px solid var(--border-color);
        font-family: fonts.$eduate-font-family;
        &:hover {
          background-color: var(--level-2);
        }
      }
    }
  }
  &__preview {
    height: 100%;
    &--title {
      img {
        @include closeIconInModal();
      }
    }
    &--tableblock {
      height: calc(100% - 85px);
      margin-top: 10px;
    }
    &--table {
      @include table();
      &--slno {
        width: calc(100% - 92%);
      }
    }
  }
  &__export {
    height: 100%;
    &--title {
      img {
        @include closeIconInModal();
      }
    }
    &--block {
      height: calc(100% - 85px);
      overflow: auto;
      margin-top: 10px;
      ul {
        margin-bottom: 0px !important;
      }
      &--header {
        border: 1px solid var(--border-color);
        margin-top: 20px;
        font-family: fonts.$eduate-font-family;
        &--flex {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid var(--border-color);
          padding: 8px;
          font-weight: 700;
          color: var(--text-color);
          font-size: 18px;
          border-radius: 5px;
        }
      }

      &--options {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        padding: 5px;
      }
      &--list {
        background-color: colors.get-color(colors.$cool_grey, level_1);
        &--h {
          font-size: 16px !important;
          margin: 5px 0px !important;
        }
      }
    }
  }
  &__tabs {
    @include muiTabs();
  }
  &--formodal{
height: 100%;
  }
}
.inst-level-report__initialize--block::-webkit-scrollbar,
.inst-level-report__export--block::-webkit-scrollbar,
.inst-level-report__md--block::-webkit-scrollbar{
  @include scrollbar();
}

.inst-level-report__initialize--block::-webkit-scrollbar-thumb,
.inst-level-report__export--block::-webkit-scrollbar-thumb,
.inst-level-report__md--block::-webkit-scrollbar-thumb  {
  @include scrollbarthumb();
}

.inst-level-report__initialize--block::-webkit-scrollbar-thumb:hover,
.inst-level-report__export--block::-webkit-scrollbar-thumb:hover,
.inst-level-report__md--block::-webkit-scrollbar-thumb:hover{
  @include scrollbarthumb-hover();
}
