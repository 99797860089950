@use "../../../../scss/common/mixins" as *;

.inst-logo {
  @include mainFrameWithTitle();

  &__frame {
    column-gap: 10px;
  }

  &__browse {
    @include browseFiles();
    margin: 10px 10px 0px 0px;

    &--fileattach {
      &--input {
        display: none;
      }
    }
  }

  &__buttons {
    display: flex;
  }

  &__container {
    height: calc(100% - 30px);
  }

  &__table {
    @include table();
    margin-top: 10px;

    &--sl {
      width: calc(100% - 92%);
    }
  }

  &__datablock {
    height: 100%;
    column-gap: 40px;
  }

  &__image {
    margin: auto;
    display: block;
    width: 250px;
  }
  &__tabs {
    @include muiTabs();
    margin-top: 10px;
  }
  &__tabpanel {
    height: calc(100% - 110px);
    margin-top: 10px;
  }
}
