@use "../../scss/common/mixins" as *;
@use "../../scss/common/colors" as colors;
@use "../../scss/common/fonts" as fonts;
.excel-loading {
  height: 450px;
  width: 400px;
  border: 1px solid var(--border-color);

  &__top {
    height: 30%;
    background-color: var(--level-1);
    padding-top: 20px;
    img {
      width: 30px;
      height: 30px;
    }
    b {
      font-family: fonts.$eduate-font-family;
      color: var(--level-6);
      font-size: 25px;
    }
    &--flex {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--text {
      font-size: 20px;
      color: colors.get-color(colors.$cool_grey, level_6);
      text-align: center;
      font-family: fonts.$eduate-font-family;
    }
    &--total {
      text-align: center;
      font-family: fonts.$eduate-number-font;
      font-size: 20px;
    }
    &--details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100% - 30px);
    }
  }
  &__bottom {
    height: 70%;
    &--flex {
      display: flex;
      justify-content: space-between;
      height: 40px;
      &--f {
        display: flex;
        column-gap: 10px;
        align-items: center;
        img {
          width: 30px;
          height: 30px;
        }
        span {
          font-size: 16px;
          font-family: fonts.$eduate-number-font;
        }
        b {
          font-size: 18px;
          font-family: fonts.$eduate-font-family;
        }
      }
    }
    &--loading {
      width: 100%;
      height: calc(100% - 40px);
      img {
        height: 180px;
        width: 200px;
        display: block;
        margin: auto;
      }
    }
    &--details {
      height: calc(100% - 25px);
      padding: 40px 40px 30px 40px;
    }
  }
}
