@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/colors" as colors;
@use "../../../../../scss/common/fonts" as fonts;
.std-login-fee {
  margin: 0px 20px;
  height: calc(100% - 170px);

  &__details {
    height: 120px;
    column-gap: 20px;

    &--textfield {
      @include textfield();
      margin: 8px 0px 0px 0px !important;
    }

    &--profile {
      @include studentProfile();
    }
  }

  &__year {
    margin-top: 10px;
    border-top: 1.8px dashed var(--border-color);
    padding-top: 10px;
  }

  &__blocks {
    height: 60px;
    column-gap: 20px;
    margin-top: 10px;

    img {
      width: 30px;
    }

    &--cards {
      @include blocksInDashboard();
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 10px;
      background-color: colors.get-color(colors.$grey, level_2);
      &--title {
        display: flex;
        column-gap: 5px;
        b {
          font-size: 16px;
          font-family: fonts.$eduate-font-family;
        }
      }

      &--total-fee {
        font-family: fonts.$eduate-number-font;
        font-size: fonts.$eduate-default-font-size;
        color: colors.get-color(colors.$fuchsia, level_6);
        font-weight: 700;
      }

      &--paid {
        @extend .std-login-fee__blocks--cards--total-fee;
        color: colors.get-color(colors.$Green, level_6);
      }

      &--balance {
        @extend .std-login-fee__blocks--cards--total-fee;
        color: colors.get-color(colors.$red, level_6);
      }
    }

    &--button {
      display: flex;
      justify-content: flex-end;
      margin-left: auto;
      margin-right: 0px;
      height: 45px;
    }
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 290px);
    &--proceed {
      height: 100%;
      &--gif {
        height: calc(100% - 30px);
        width: 100% !important;
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 120px;
          height: 120px;
        }
      }
    }
  }

  &__table {
    height: calc(100% - 35px);
    @include table();

    .totalcount {
      border-right: 1px solid var(--border-color) !important;
    }

    tr:hover {
      .std-login-fee__table--desc {
        @include tdContainsOnclick();
      }
    }

    &--slno {
      font-family: fonts.$eduate-number-font;
      width: calc(100% - 96%);
    }

    &--receiptno {
      font-family: fonts.$eduate-number-font;
      width: calc(100% - 92%);
    }

    &--date {
      font-family: fonts.$eduate-number-font;
      width: calc(100% - 92%);
    }

    &--amount {
      font-family: fonts.$eduate-number-font;
      width: calc(100% - 90%);
    }
  }

  &__nodata {
    height: calc(100% - 35px);
    img {
      display: block;
      margin: auto;
    }
  }

  &__modal {
    height: calc(100% - 35px);
  }

  &__pay-online {
    height: calc(100% - 35px);

    &--grids {
      background-color: var(--disabled-color);
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 10px;
      padding: 0px 5px;

      img {
        width: 40px;
        margin-right: 5px;
      }

      span {
        font-size: fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
      }

      b {
        font-size: 16px;
        font-family: fonts.$eduate-number-font;
      }
    }

    &--cards {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 70px;
      column-gap: 10px;
      margin-top: 10px;
    }

    &--tableblock {
      height: calc(100% - 125px);
      margin-top: 10px;
    }

    &--table {
      @include table();
      @include tableCheckbox();

      &--amount {
        width: calc(100% - 88%);
        font-family: fonts.$eduate-number-font;
      }

      &--slno {
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
      }

      .totalcount {
        border-right: 1px solid var(--border-color) !important;
      }

      .balance-count {
        border-bottom: 1px solid var(--border-color) !important;
      }
    }
  }
}
