@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.active-employee {
  @include mainFrameWithTitle();
  &__tabs {
    @include muiTabs();
  }
  &__swipable {
    height: calc(100% - 45px);
    .bulk-data-alter__swipable,
    .react-swipeable-view-container,
    .MuiTypography-body1 {
      height: 100% !important;
    }
  }
  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 95px);
  }
  &__move-to {
    height: 100%;
    margin: 0px 20px;
    &--tableblock {
      margin-top: 10px;
      height: calc(100% - 120px);
    }
  }
}
