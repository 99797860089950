@use "../../../scss/common/mixins" as *;
@use "../../../scss/common/fonts" as fonts;
@use "../../../scss/common/colors" as colors;
.feeds {
  @include mainFrameWithTitle();

  &__tabs {
    @include muiTabs();
    margin-top: 10px;
  }

  &__swipable {
    height: calc(100% - 65px);
    margin-top: 10px;

    .MuiTypography-root {
      height: 100%;
    }

    &--data {
      height: 100%;
      margin-top: 10px;
      @include muiChipToShowDates();

      &--list {
        height: calc(100% - 45px);
        background-color: colors.get-color(colors.$cool_grey, level_1);
        overflow: auto;

        ul {
          height: 100%;
          margin-bottom: 0px !important;
        }

        &--content {
          height: 100%;
          width: 70%;
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    &--messages {
      height: calc(100% - 35px);
      overflow: auto;
      display: flex;
      padding: 5px 20px;

      ul {
        height: 100% !important;
      }

      &--list {
        display: flex;
        gap: 10px;
        border-radius: 6px;
        padding: 5px 10px;

        &--sender {
          border-radius: 6px;
          margin-right: 0% !important;
          background-color: var(--level-2) !important;
          padding: 5px 10px;
          border-radius: 6px;
          width: 100%;

          &--li {
            padding: 5px;
          }
        }

        &--time {
          font-family: fonts.$eduate-number-font;
          font-size: 12px;
          font-weight: 700;
        }

        &--name {
          font-family: fonts.$eduate-font-family;
          font-size: fonts.$eduate-default-font-size;
          width: 200px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        &--header {
          font-size: 20px;
          display: flex;
          justify-content: center;
          font-family: fonts.$eduate-font-family;
          color: var(--text-color);
        }

        &--flex {
          display: flex;
          justify-content: space-between;
        }

        &--image {
          width: 35px;
          height: 35px;
          margin-top: 5px;
        }
      }

      &--file-image {
        width: 100%;
        height: 240px;
      }

      p {
        font-family: fonts.$eduate-font-family;
        font-size: fonts.$eduate-default-font-size;
        margin: 0px;
      }
    }
  }
  &__image {
    display: flex;
    justify-content: space-between;
    img {
      width: 25px;
      height: 25px;
    }
  }
}

.feeds__swipable--data--list::-webkit-scrollbar {
  @include scrollbar();
}

.feeds__swipable--data--list::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.feeds__swipable--data--list::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
