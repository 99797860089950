@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.collegedetails {
  display: flex;
  padding: 5px;
  justify-content: center !important;

  span {
    margin-right: 10px;
    background-color: white;
    border-radius: 2px;
    border: 0.5px solid colors.get-color(colors.$colors, cloud-white);
    padding: 5px;
    cursor: pointer;

    a {
      color: colors.get-color(colors.$colors, black);
    }

    .active {
      background: var(--level-5);
      padding: 8px;
      margin: -6px;
      border: 0px;
      border-radius: 5px;
      box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.25);
    }
  }
}

.instindex__container {
  height: calc(100% - 130px) !important;

  >img {
    display: block;
    margin: auto !important;
    width: 75%;
    height: 75%;
  }

  &--group {
    margin: 10px 20px 10px 20px;
    height: 120px;
    column-gap: 20px;
  }

  &--card {
    background-color: var(--bg-color);
    border-radius: 6px;
    box-shadow: 6px 4px 4px 4px rgba(0, 0, 0, 0.25);
    height: 100%;
    text-align: center;
    padding: 20px;

    Label,
    img {
      font-size: 18px;
      margin: auto;
    }
  }
}

.inst-breadcrumbs {
  height: 20px;


  a {
    font-size:fonts.$eduate-default-font-size;
    margin-bottom: 0px;
    color: var(--level-6);
    font-weight: 500;
    font-family: fonts.$eduate-font-family;
  }
}

.masters-frame {
  height: calc(100% - 275px);
  margin: 20px 10px 0px 10px;
  column-gap: 20px;

  &--active {
    height: calc(100% - 305px);
    margin: 20px 10px 0px 10px;
    column-gap: 20px;
  }
}

.fin-masters-frame {
  height: calc(100% - 215px);
  margin: 10px 20px 0px 20px;

  &__modal {
    height: 100%;
  }

  &__datablock {
    margin-top: 20px;
    column-gap: 20px;
    height: calc(100% - 95px);

    .booktype-right {
      margin: 0px !important;
    }
  }

  &__tableblock {
    height: calc(100% - 80px);
  }
}

.acd-masters-frame {
  height: calc(100% - 215px);
  margin: 10px 20px 0px 20px;

  &__modal {
    height: 100%;
  }

  &__form {
    margin-top: 20px;
    height: calc(100% - 95px);
  }

  &__datablock {
    column-gap: 20px;
    height: 100%;

    .booktype-right {
      margin: 0px !important;
    }
      .booktype-left {
      margin: 0px !important;
    }
  }

  &__tableblock {
    height: calc(100% - 80px);
  }

  &__acd {
    height: 100%;
  }
}

.masterconfig {
  height: calc(100% - 260px);
  margin: 0px 10px 0px 10px;

  &__details {
    height: calc(100% - 55px);
    column-gap: 20px;
  }

  &__table {
    @include table();
    margin-top: 10px;
    height: calc(100% - 35px);

    tr:hover .institution__table--name {
      @include tdContainsOnclick();
    }
  }

  &__buttons {
    margin: 10px 0px 0px 10px;
  }

  &__category {
    height: calc(100% - 160px);
    margin: 0px 10px 0px 10px;
  }

  &__tree-view {
    height: calc(100% - 35px);
    overflow: auto;

    &--ol {
      border-left: 2px dashed var(--border-color) !important;
    }

    &--icons {
      width: 20px;
    }

    &--li {
      display: flex;
      align-items: center;
      column-gap: 5px;
      border-radius: 30px;
      padding: 5px;
      margin-left: -15px;
      font-family: fonts.$eduate-font-family;
      font-size:fonts.$eduate-default-font-size;

      &:hover {
        background-color: var(--level-2);
        font-weight: 600;
      }

      &:focus {
        background-color: var(--level-4);
        color: white;
      }
    }
  }
}

.masterconfig__tree-view::-webkit-scrollbar {
  @include scrollbar();
}

.masterconfig__tree-view::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.masterconfig__tree-view::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}