@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.miscellaneous-add{
    height: calc(100% - 170px);
    margin: 0px 20px;
    &__details{
        column-gap: 20px;
        height: 130px;
        &--image{
            height: 100%;
            @include studentProfile();
        }
        &--flex{
            display: flex;
            .MuiAutocomplete-root,.data-fetch-icon{
                margin-top: 10px;
            }
        }
        &--textfield{
            @include textfield();
            margin: 10px 0px 0px 0px !important;
        }
    }
    &__datablock{
        margin-top: 10px;
        height: 145px;
        column-gap: 20px;
        &--frame{
            border: 1px solid var(--border-color);
            padding: 5px;
            border-radius: 6px;

        }
        &--form-labels{
            display: flex !important;
            justify-content: space-between;
            margin: 0px 5px !important
        }

    }
    &__tableblock{
        height: calc(100% - 325px);

    }
}