@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.payroll-import-masterdata {
  height: 100%;
  &__datablock {
    margin-top: 10px;
    height: calc(100% - 80px);
    b {
      font-family: fonts.$eduate-font-family;
      font-size: 18px;
    }
  }
  &__tableblock {
    height: calc(100% - 42px);
  }
  &__table {
    @include table();
    &--slno {
      width: calc(100% - 95%);
      font-family: fonts.$eduate-number-font;
    }
  }
  &__total {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    &--count {
      @include totalCountShadowEffect();
      margin-top: 5px;
    }
  }
}
