@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.challan-receipt {
  @include mainFrameWithTitle();

  &__select {
    column-gap: 10px;

    .MuiAutocomplete-root {
      margin-top: 10px;
    }

    &--textfield {
      @include textfieldInForms();
      width: fit-content !important;
    }

    &--flex {
      display: flex;
      column-gap: 10px;
      justify-content: flex-end;
    }
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 95px);
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--date {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }

    &--admno {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--bank {
      width: calc(100% - 86%);
    }

    &--type {
      width: calc(100% - 90%);
    }

    &--amount {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }

    &--actions {
      width: calc(100% - 88%);
    }

    &--generate {
      @include viewButton();

      img {
        width: 18px;
      }
    }
  }
}
