@use "../common/mixins" as *;
@use "../common/fonts" as fonts;
.label-grid {
  display: grid;
  grid-template-columns: 0.8fr 2fr;
}

.select-grid {
  display: grid;
  grid-template-columns: 1.2fr 3.4fr;
}

.layout-80 {
  height: calc(100% - 80px);
}

.details {
  @include details();

  >h4 {
    @include heading();

    img {
      filter: invert(100%);
      padding-right: 10px;
    }
  }
}

.button-left {
  margin-left: 20px;
}

.parent-std-association {
  height: calc(100% - 180px);

  &--update {
    @extend .parent-std-association;
    height: calc(100% - 147px);
  }

  .data-fetch-icon {
    margin-left: 0px;
    margin-top: 10px;
  }

  &__tableblock {
    height: calc(100% - 133px);
    margin-top: 10px;
  }

  &__table {
    @include table();

    &--slno {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }

    &--desc {
      width: calc(100% - 90%);
    }

    &--actions {
      width: calc(100% - 95%);
    }
  }

  &__image-flex {
    display: flex;
    column-gap: 10px;

    .MuiAutocomplete-root {
      margin-top: 10px;
    }
  }
}