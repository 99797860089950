@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.bulk_pic_upload {
  @include mainFrameWithTitle();
&__select{
  column-gap: 10px;
  .MuiAutocomplete-root{
    margin-top: 10px;
  }
}
  &__tableblock {
    height: calc(100% - 100px);
    border: 1px solid var(--border-color);
    margin-top: 10px;
    .td-center {
      text-align: center;
    }
  }
  &__file {
    display: flex;
    column-gap: 5px;
    align-items: center;
    b {
      font-size: 14px;
      font-family: fonts.$eduate-font-family;
      color: colors.get-color(colors.$cool_grey , level_8);
      margin-top: 8px;
    }

    input {
      display: none;
    }
    button {
      @include browseFiles();
      font-weight: 700;
      display: flex;
      align-items: center;
      width: fit-content !important;
      img {
        width: 25px;
        height: 25px;
        filter: invert(100%);
      }
    }
  }
}
