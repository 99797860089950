@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
.fb-form-report {
 @include mainFrameWithTitle();
  display: flex;
  column-gap: 20px;
  &__datablock {
    height: calc(100% - 50px);
    overflow: auto;
    &--grids {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      column-gap: 10px;
    }
    &--grid {
      height: fit-content;
      width: fit-content;
      padding: 10px;
      background-color: var(--level-1);
      margin-top: 10px;
      b {
        font-family: fonts.$eduate-font-family;
        font-size: 18px;
        text-align: center;
        display: block;
      }
      &--acd {
        font-family: fonts.$eduate-font-family;
        font-size: 14px;
        background-color: var(--level-4);
        color: white;
        font-weight: 600;
        padding: 3px 10px;
        border-radius: 2px;
        display: block;
        margin: 15px auto;
        width: fit-content;
      }
      &--flex {
        display: flex;
        column-gap: 20px;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
        .MuiInputBase-root .Mui-disabled {
          background-color: var(--bg-color) !important;
        }
      }
      &--textfield {
        @include textfield();
        width: fit-content !important;
      }
      &--button {
        @include viewButton();
        display: block;
        margin: 15px auto;
      }
      &--block {
        span,
        b {
          font-family: fonts.$eduate-font-family;
          font-size: 14px;
        }
      }
    }
    &--event {
      height: fit-content;
      width: fit-content;
      padding: 10px;
      background-color:  colors.get-color(colors.$cool_grey, level_1);
      margin-top: 10px;
      b {
        font-family: fonts.$eduate-font-family;
        font-size: 18px;
        text-align: center;
        display: block;
      }
      &--acd {
        background-color:  colors.get-color(colors.$cool_grey, level_3);
        color: colors.get-color(colors.$cool_grey, level_6);
      }
      &--grid {
        @extend .fb-form-report__datablock--grid--acd;
        background-color:  colors.get-color(colors.$cool_grey, level_1);
      }
    }
  }
  &__left {
    height: 100%;
    width: 250px;
    &--table {
      @include table();
      @include inputReportsTable();
      a {
        color: var(--text-color);
      }
      a.active{
        color: var(--level-5) !important;
        font-weight: 700;
      }

      
    }
  }
  &__right {
    height: 100%;
    width: calc(100% - 250px);
    border: 1px solid var(--border-color);
    border-radius: 6px;
    padding: 0px 10px;
  }
}

.fb-form-report__datablock::-webkit-scrollbar {
  @include scrollbar();
}

.fb-form-report__datablock::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.fb-form-report__datablock::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

.fb-teacher-wise-report {
  height: 100%;
  &__select {
    .MuiAutocomplete-root {
      margin-top: 10px;
    }
    column-gap: 10px;
  }
  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 100px);
  }
  &__datablock {
    height: calc(100% - 55px);
    overflow: auto;
    margin-top: 5px;
  }
  &__emp {
    padding: 5px;
    height: 65px;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 5px;
    box-shadow:
      0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    &--flex {
      display: flex;
      column-gap: 10px;
      align-items: center;
      width: 250px;
      padding: 5px;
      box-shadow:
      0px 10px 15px -3px rgba(0, 0, 0, 0.1),
      0px 4px 6px -2px rgba(0, 0, 0, 0.05);
text-overflow: ellipsis;
white-space: nowrap;
overflow: hidden;
      img {
        width: 30px;
        height: 30px;
      }
    }
    &--block {
      span {
        display: block;
        font-size: 14px;
        color: colors.get-color(colors.$cool-grey, level_6);
        font-family: fonts.$eduate-number-font;
      }
      b {
        font-family: fonts.$eduate-font-family;
        font-size: 16px;
        color: var(--text-color);
      }
    }
  }
  &__subtitle {
    color: var(--level-5);
    font-size: 20px;
    font-family: fonts.$eduate-font-family;
  }
}


.fb-teacher-wise-report__datablock::-webkit-scrollbar {
  @include scrollbar();
}

.fb-teacher-wise-report__datablock::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.fb-teacher-wise-report__datablock::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}
