@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.issued-book__history {
  height: calc(100% - 75px);
  &--details {
    column-gap: 10px;
    height: 125;
  }
  &--image {
    @include studentProfile();
    height: 100%;
  }
  &--tableblock {
    height: calc(50% - 105px);
    margin: 10px 0px 0px 0px;
  }
  &--table {
    @include table();
    &--slno {
      width: calc(100% - 95%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-slno {
      width: calc(100% - 95%);
    }
    &--isbnno {
      width: calc(100% - 92%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-isbnno {
      width: calc(100% - 92%);
    }
    &--date {
      width: calc(100% - 90%);
      font-family: fonts.$eduate-number-font;
    }
    &--th-date {
      width: calc(100% - 90%);
    }
    &--publication {
      width: calc(100% - 88%);
    }
  }
}
