@use "../../../../../../scss/common/mixins" as *;
@use "../../../../../../scss/common/colors" as colors;
@use "../../../../../../scss/common/fonts" as fonts;
.view-combinations{
    height: 100%;
    &__delete{
        height: calc(100% - 185px);
        &--span{
            font-size: 16px;
            font-family: fonts.$eduate-font-family;
        }
    }
    &__title {
        img {
            @include closeIconInModal();
        }
    }
    &__selected-catg {
        display: flex;
        justify-content: center;
        color: var(--level-5);
        font-size: 18px;
        font-family: fonts.$eduate-font-family;
    }
    &__tableblock {
        margin-top: 10px;
        height: calc(100% - 110px);
    }
    &__table{
        @include table();
        &--group{
            td{
                background-color: var(--bg-color) !important;
                font-size: 16px !important;
                font-weight: 700 !important;
            }
        }
        &--subcode{
            width: calc(100% - 85%);
        }
    }
}