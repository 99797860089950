@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.view-inst-details {
  height: calc(100% - 30px);

  &__datablock {
    height: calc(100% - 45px);

    column-gap: 20px;

    &--textfield {
      @include textfield();
      width: 100%;
      margin: 8px 0px 0px 0px !important;

      &--start-date {
        @include textfield();
        width: 100%;
        margin: 8px 0px 0px 0px !important;

        .MuiInputBase-root .Mui-disabled {
          -webkit-text-fill-color: colors.get-color(colors.$Green, level_4) !important;
        }
      }

      &--end-date {
        @include textfield();
        width: 100%;
        margin: 8px 0px 0px 0px !important;

        .MuiInputBase-root .Mui-disabled {
          -webkit-text-fill-color: colors.get-color(colors.$red, level_5) !important;
        }
      }
    }

    &--left {
      height: 100%;
      border: 1px solid var(--border-color);
      border-radius: 6px;
      padding: 10px;

      &--inst-logo {
        margin-top: 10px;
        width: 80px;
        height: 80px;
        display: block;
        margin-left: auto !important;
        margin-right: auto !important;

        img {
          height: 100%;
          width: 100%;
        }
      }

      &--details {
        margin-top: 10px;
        column-count: 2;
        overflow: auto;
      }
    }

    &--block {
      height: 100%;
      border: 1px solid var(--border-color);
      border-radius: 6px;
      padding: 10px;
      overflow: auto;

      &--list {
        height: calc(100% - 82px);
        overflow: auto;
        background-color: var(--level-1);
        border-radius: 6px;
        padding: 5px;

        li {
          padding: 5px;
          font-size:fonts.$eduate-default-font-size;
          font-family: fonts.$eduate-font-family;
          border-bottom: 1px solid var(--border-color);
        }
      }

      &--image {
        display: block;
        margin-left: auto !important;
        margin-right: auto !important;
        width: 40px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      &--finyr {
        height: fit-content;
        padding: 5px;
        background-color: var(--level-1);
        border-radius: 6px;

        .MuiInputBase-root .Mui-disabled {
          -webkit-text-fill-color: colors.get-color(colors.$Green, level_4) !important;
        }
      }

      &--acdyr {
        height: fit-content;
        padding: 5px;
        background-color: var(--level-1) !important;
        border-radius: 6px;
        margin-top: 10px;

        .MuiInputBase-root .Mui-disabled {
          -webkit-text-fill-color: colors.get-color(colors.$sky , level_4) !important;
        }
      }

      &--license {
        height: fit-content;
        padding: 5px;
        background-color: var(--level-1);
        border-radius: 6px;
        margin-top: 10px;

        &--days {
          font-family: fonts.$eduate-font-family;
          font-size:fonts.$eduate-default-font-size;
          text-align: center;

          span {
            font-weight: 700;
            font-size: 16px;
            font-family: fonts.$eduate-number-font;
            margin-right: 10px;
          }
        }

        &--active {
          display: flex;
          justify-content: center;
          width: fit-content;
          background-color: var(--bg-color);
          border-radius: 6px;
          padding: 0px 5px;
          font-size:fonts.$eduate-default-font-size;
          font-family: fonts.$eduate-font-family;
        }
      }

      &--compare-image {
        display: block;
        margin-left: auto !important;
        margin-right: auto !important;
        width: 20px;
        height: 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

.view-inst-details__datablock--block::-webkit-scrollbar,
.view-inst-details__datablock--block--list::-webkit-scrollbar,
.view-inst-details__datablock--left--details::-webkit-scrollbar {
  @include scrollbar();
}

.view-inst-details__datablock--block::-webkit-scrollbar-thumb,
.view-inst-details__datablock--block--list::-webkit-scrollbar-thumb,
.view-inst-details__datablock--left--details::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.view-inst-details__datablock--block::-webkit-scrollbar-thumb:hover,
.view-inst-details__datablock--block--list::-webkit-scrollbar-thumb:hover,
.view-inst-details__datablock--left--details::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}