@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.user-configuration {
  @include mainFrameWithTitle();
  &__filters {
    column-gap: 10px;
    margin-top: 5px;
    .storybook-input {
      margin-top: 0px !important;
    }
    .storybook-button {
      margin-top: 0px !important;
      display: flex;
      margin-left: auto;
      margin-right: 0px;
      justify-content: flex-end;
    }
  }
  &__tableblock {
    height: calc(100% - 75px);
    margin-top: 10px;
  }

  &__table {
    @include table();
    &--sl {
      width: calc(100% - 96%);
      font-family: fonts.$eduate-number-font;
    }
    &--id {
      width: calc(100% - 94%);
      font-family: fonts.$eduate-number-font;
    }
    &--actions {
      width: calc(100% - 96%);
    }
    &--desc {
      width: calc(100% - 90%);
    }
  }
}
