
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.ledger-summary {
  @include mainFrameWithTitle();

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 55px);
  }



  &__total {
    text-align: right;

    .student-total-count {
      margin: 10px 0px 0px 0px !important;
    }
  }
}
