
@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.view-concession-report {
    @include mainFrameWithTitle();

    &__select {
        .storybook-input,.Label {
            margin-top: 0px !important;
        }
    }

    &__modal {
        height: calc(100% - 30px);
    }

    &__tableblock {
        margin-top: 10px;
        height: calc(100% - 85px);

    }

  

    &__total {
        text-align: right;

        .student-total-count {
            margin: 10px 0px 0px 20px !important;
        }
    }
}