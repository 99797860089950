@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/colors" as colors;
@use "../../../../scss/common/fonts" as fonts;
.profile-pic {
  height: 100%;
  &__title {
    img {
      @include closeIconInModal();
    }
  }
  &__warning {
    height: 40px;
    background-color: colors.get-color(colors.$cool_grey, level_2);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: fonts.$eduate-font-family;
  }
  &__preview {
    height: calc(100% - 140px);
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__browse {
    @include browseFiles();
    margin-right: 5px;
  }
  &__btns {
    display: flex;
  }
  &__image {
    width: 25px;
    height: 25px;
  }
}
