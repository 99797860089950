@use "../../../../../scss/common/mixins" as *;
@use "../../../../../scss/common/fonts" as fonts;
@use "../../../../../scss/common/colors" as colors;
.id-template-2 {
  height: 100%;
  margin: 0px 20px;

  &__card {
    display: flex;
    justify-content: space-between;
    column-gap: 40px;
    height: calc(100% - 55px);
    padding: 5px;

    &--fs {
      height: calc(100% - 10px);
      margin-top: 10px;
      width: 50%;

      &--li {
        width: 100%;
        height: 350px;

        .id-template-2__card--fs--id--inst-logo {
          width: 25px;
          height: 25px;
        }

        .id-template-2__card--fs--id--c-name,
        .id-template-2__card--fs--id--inst-name {
          font-size: 10px;
        }

        .id-template-2__card--fs--id--std-image {
          width: 40px;
          height: 40px;
        }

        .id-template-2__card--fs--id--grid {
          label,
          span,
          b {
            font-size: 10px;
          }
        }
      }

      &--id {
        border: 1px solid var(--border-color);
        height: calc(100% - 30px);
        border-radius: 6px;

        &--profile {
          height: calc(100% - 30px);

          &--header {
            padding: 5px;
          }
        }

        &--grid {
          display: grid;
          grid-template-columns: 0.4fr 0.1fr 1fr;

          label,
          span,
          b {
            font-size: 12px;
            margin-top: 2px;
            font-family: fonts.$eduate-font-family;
          }
        }

        &--std-image {
          width: 80px !important;
          height: 80px !important;
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-top: 5px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &--footer {
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-size: fonts.$eduate-default-font-size;
            font-family: fonts.$eduate-font-family;
          }
        }

        &--c-name {
          display: block;
          font-size: 8px;
          font-family: fonts.$eduate-font-family;
          line-height: 1;
        }

        &--inst-name {
          font-size: 12px;
          font-family: fonts.$eduate-font-family;
          margin-top: 5px;
          line-height: 1;
        }

        &--inst-logo {
          width: 40px;
          height: 40px;

          img {
            width: 100%;
          }
        }

        &--inst-address {
          height: 65px;
          overflow: hidden;
          line-height: 1;
        }

        &--addr {
          font-size: 10px;
          font-family: fonts.$eduate-font-family;
          line-height: 1.5;
          font-weight: 700;
        }

        &--address {
          height: calc(100% - 65px);
          &--without-footer {
            height: calc(100% - 30px);
          }
        }

        &--signature {
          text-align: right;
          font-size: 12px;
          font-family: fonts.$eduate-font-family;
          span {
            display: block;
          }
          img {
            width: 80px;
            height: 25px;
          }
        }
      }
    }

    &--bs {
      @extend .id-template-2__card--fs;

      &--id {
        @extend .id-template-2__card--fs--id;
      }
    }
  }

  &__button {
    border-top: 1.8px dashed var(--border-color);
    display: flex;

    button {
      background-color: colors.get-color(colors.$cool_grey, level_3);
      padding: 5px;
      border-radius: 20px;
      text-align: center;
      width: 100%;
      display: block;
      margin-top: 10px;
      font-size: fonts.$eduate-default-font-size;
      font-family: fonts.$eduate-font-family;
      font-weight: 700;
    }

    img {
      width: 30px;
      height: 30px;
      margin-top: 10px;
    }
  }
}

.selected-id-template {
  border: 1px solid var(--level-3);
  border-radius: 10px;
  margin: 0px 10px;

  .id-template-2 {
    margin: 0px 0px;
  }
}
