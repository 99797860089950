@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.pr-temp1 {
    width: 100%;
    height: calc(100% - 75px);
    overflow: auto;
   
    &--block{
        width: 100%;
        height: 100%;
          .page-break {
            page-break-after: always !important;
            margin: 0px;
            padding: 0px;
        }
    }
}

.pr-temp1-fs {
    height: calc(100% - 35px);
    display: flex;
    column-gap: 30px;

    &__left {
        height: calc(100% - 45px);
        width: 50%;
        border-radius: 20px;
        border: 4px solid colors.get-color(colors.$grey, level_5);
        padding: 5px;
        box-shadow:
            0 0 0 2px #fff,
            0 0 0 3px colors.get-color(colors.$grey, level_5);
        &--table {
            @include table();
        }
    }

    &__right {
        @extend .pr-temp1-fs__left;
        border: 4px solid colors.get-color(colors.$blue, level_7);
        box-shadow:
            0 0 0 2px #fff,
            0 0 0 3px colors.get-color(colors.$blue, level_7);

        &--inst-details {
            margin-top: 10px;
            padding: 10px;
            height: 120px;
            display: flex;
            column-gap: 10px;

            b {
                display: block;
                text-align: center;
                font-family: fonts.$eduate-font-family;
                font-size: 22px;
                color: colors.get-color(colors.$blue, level_7);
            }

            span {
                display: block;
                text-align: center;
                font-family: fonts.$eduate-font-family;
                font-size: 16px;
                color: colors.get-color(colors.$blue, level_7);
            }
        }

        &--inst-logo {
            img {
                width: 100px;
                height: 100px;
            }
        }
        &--title {
            font-size: 16px;
            font-family: fonts.$eduate-font-family;
            display: flex;
            justify-content: center;
        }
        &--acd-yr {
            display: flex;
            justify-content: center;
            column-gap: 10px;
            font-size: 16px;
            font-family: fonts.$eduate-font-family;
        }
        &--std-details {
            display: grid;
            grid-template-columns: 0.6fr 1fr;
            margin-top: 10px;
            label {
                font-size:fonts.$eduate-default-font-size;
                font-family: fonts.$eduate-font-family;
            }
            input {
                font-family: fonts.$eduate-font-family;
                border-radius: 0px;
                border-bottom: 2px dotted var(--text-color);
                font-size: 14px;
            }
        }
    }
}

.pr-temp1-bs {
    height: calc(100% - 35px);
    overflow: auto;

    &__top {
        padding: 40px 40px 10px 40px;

        &--table {
            border-left: 1px solid var(--text-color) !important;

            th {
                border-top: none !important;
                border-right: 1px solid var(--text-color) !important;
                padding: 2px 5px !important;
            }

            td {
                border-bottom: 1px solid var(--text-color) !important;
                padding: 2px 5px !important;
            }

            @include tableInPrint();
        }
    }

    &__bottom {
        column-gap: 10px;
        padding: 0px 40px 10px 40px;
    }

    &__footer {
        padding: 10px 40px 10px 40px;
        column-gap: 20px;
        height: 60px;

        &--grid2 {
            .grid-container {
                display: grid;
                height: 100%;
                grid-template-columns: auto auto auto;
                padding: 5px 0px;
            }

            .grid-container > div {
                border: 1px solid black;
                text-align: center;
                padding: 5px 0;
                font-size:fonts.$eduate-default-font-size;
                align-items: center;
                font-family: fonts.$eduate-font-family;
            }

            .item1 {
                grid-row-start: 1;
                grid-row-end: 3;
            }
        }

        &--grid1 {
            .grid-container {
                height: 100%;
                display: grid;
                grid-template-columns: 1fr 2fr;
                padding: 5px 0px;
            }

            .grid-container > div {
                border: 1px solid black;
                text-align: center;
                padding: 5px 0;
                font-size:fonts.$eduate-default-font-size;
                align-items: center;
                font-family: fonts.$eduate-font-family;
            }
        }
    }
}



.pr-temp1::-webkit-scrollbar {
  @include scrollbar();
}


.pr-temp1::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}


.pr-temp1::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}