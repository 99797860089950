@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.std-enquiry-for-all {
  height: calc(100% - 40px);
  margin: 20px 0px 20px 0px;

  .MuiAutocomplete-option {
    &:hover {
      background-color: var(--level-2) !important;
    }

    &:focus {
      background-color: var(--level-2) !important;
    }

    height: 10px !important;
  }

  .MuiAutocomplete-root {
    margin-top: 10px;
  }

  &__form {
    height: calc(100% - 105px);
    background: linear-gradient(
      180deg,
      #fffdd5 0%,
      rgba(232, 232, 232, 0) 35.66%,
      rgba(232, 232, 232, 0) 100%
    );

    &--data {
      margin-top: 10px;
      height: calc(100% - 145px);
      padding: 0px 10px;
      overflow: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &--textfield {
      height: 30px;
      width: 100%;
      margin-top: 15px !important;

      .Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 1px solid var(--level-3) !important;
      }

      .MuiInputBase-input {
        padding: 5px !important;
      }

      input {
        font-size: 14px !important;
        font-family: fonts.$eduate-font-family;
        padding-left: 5px !important;
        color: colors.get-color(colors.$grey, level_8) !important;
        font-weight: 400 !important;
      }

      label {
        font-family: fonts.$eduate-font-family;
        font-size: 16px !important;
        color: var(--text-color) !important;
        z-index: 8;
      }

      &--captcha {
        width: 100%;

        label {
          font-family: fonts.$eduate-font-family;
          font-size: 14px !important;
          color: colors.get-color(colors.$grey, level_6) !important;
          z-index: 8;
        }

        .Mui-disabled {
          input {
            font-size: 14px !important;
            font-family: fonts.$eduate-font-family;
            padding-left: 5px !important;
            -webkit-text-fill-color: var(--level-5) !important;
            font-weight: bold !important;
          }
        }

        margin: 15px 0px 0px 0px !important;

        .MuiInputBase-input {
          padding: 5px !important;
        }
      }
    }

    &--required {
      @extend .std-enquiry-for-all__form--textfield;
      border-right: 3px solid red !important;
      border-radius: 5px !important;
    }

    &--buttons {
      padding: 0px 0px 0px 10px;
      margin-top: 10px;
      border-top: 1px dashed var(--border-color);
    }
  }

  &__inst-details {
    height: 100px;
    display: flex;
    column-gap: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dashed var(--border-color);
    padding: 0px 20px;

    &--logo {
      height: 100%;

      img {
        display: block;
        margin: auto;
        width: 50px;
        height: 50px;
      }
    }

    &--c-name {
      font-size: 10px !important;
      font-family: fonts.$eduate-font-family;
      padding: 2px;
      color: colors.get-color(colors.$grey, level__6);
    }

    &--address {
      width: calc(100% - 50px);
      text-align: center;

      span {
        display: block;
        font-size: 10px;
        padding: 2px;
        font-family: fonts.$eduate-font-family;
        text-transform: capitalize;
      }

      b {
        display: block;
        font-size: 12px;
        padding: 2px;
        font-family: fonts.$eduate-font-family;
      }
    }
  }

  &__title {
    img {
      height: 40px;
      width: 40px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__footer {
    text-align: center;
    font-size: 12px;
    font-family: fonts.$eduate-font-family;
    margin-top: 10px;
    padding-left: 10px;
    font-weight: 500;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  &__final-page {
    height: calc(100% - 10px);
    margin-top: 10px;

    &--text {
      height: fit-content;
      padding: 20px;
      height: 150px;
    }

    &--data {
      height: calc(100% - 100px);
      background: linear-gradient(
        180deg,
        #d6ffd5 0%,
        rgba(232, 232, 232, 0) 35.66%,
        rgba(232, 232, 232, 0) 100%
      );
    }

    &--image {
      display: flex;
      justify-content: center;
      height: calc(100% - 185px);

      img {
        width: 75%;
        height: 50%;
        border-radius: 10px;
      }
    }

    h2 {
      color: var(--text-color);
      font-family: fonts.$eduate-font-family;
      font-size: 18px !important;
      text-align: center;
    }
  }
}
