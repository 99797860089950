@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.academics__channel--send-message {
  height: 100%;
  &--title {
    img {
      @include closeIconInModal();
    }
  }
  &--block {
    height: calc(100% - 85px);
    margin-top: 10px;
    display: flex;
    column-gap: 20px;

    &--right {
      height: 100%;
      width: 300px;
      border: 1px solid var(--border-color);
      .storybook-input {
        margin-top: 0px !important;
      }
      &--ul {
        height: calc(100% - 75px);
        overflow: auto;
        li {
          padding: 5px;
          display: flex;
          align-items: center;
          column-gap: 10px;
          color: var(--text-color);
          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    &--left {
      height: 100%;
      width: calc(100% - 300px);
      border: 1px solid var(--border-color);
      padding: 5px;
    }
    &--fw {
      @extend .academics__channel--send-message--block--left;
      width: 100%;
    }
  }
  .ql-toolbar {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-style: none !important;
    z-index: 1;
    padding: 0px !important;
    border-top: none !important;
    border-bottom: 1px solid var(--border-color) !important;
    border-left: none !important;
    border-right: none !important;
  }

  .ql-container {
    border-style: none !important;
  }

  .ql-editor {
    padding: 5px !important;
    height: 105px !important;

    p {
      font-family: fonts.$eduate-font-family;
      font-size: 14px;
    }

    &::placeholder {
      font-family: fonts.$eduate-font-family;
      font-size: 14px;
    }
  }

  .quill {
    border: 1px solid var(--border-color) !important;
    margin-top: 10px !important;
  }
}

.send-message__form--attachments input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.send-message__form {
  height: 100%;

  &--label-grid1 {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    height: 150px;
  }

  &--label-grid2 {
    display: grid;
    grid-template-columns: 0.3fr 1fr;
    height: calc(100% - 160px);
    margin-top: 10px;
  }

  &--attachments {
    border: 1px solid var(--border-color);
    margin-top: 10px;
    border-radius: 6px;
    height: calc(100% - 55px);
    border: 1px solid var(--border-color);
    position: relative;
    overflow: hidden;

    ul {
      height: calc(100% - 25px);
      padding: 5px;
      background-image: url("../../../../images/Attachment.svg");
      background-size: 40px;
      background-repeat: no-repeat;
      background-position: center;
      list-style: none;
      margin-bottom: 0px !important;
    }

    li {
      font-family: fonts.$eduate-font-family;
      font-size: 14px;
    }

    &--footer {
      height: 40px;
      background-color: colors.get-color(colors.$cool_grey, level_2);
      color: colors.get-color(colors.$colors, black);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-family: fonts.$eduate-font-family;
      font-weight: 500;
    }
  }
}

.message-for-individual {
  height: 100%;
  .academics__channel {
    height: 100%;
    &--datablock {
      height: 100%;
      &--left {
        width: 100%;
        height: 100%;
        margin-top: 0px !important;
        border-style: none !important;
      }
    }
  }
  .channel__message-block--messages-list--messages {
    height: 100% !important;
  }
  &__title {
    img {
      @include closeIconInModal();
    }
  }

  &__message-list {
    height: calc(100% - 215px);
    border: 1px solid var(--border-color);
    margin-top: 10px;
  }
}
