@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;

.admintools-config {
  margin: 0px 20px;
  height: calc(100% - 165px);

  &__data {
    height: calc(100% - 95px);
    column-gap: 20px;
    margin-top: 20px;

    &--data-block {
      gap: 20px;
    }

    &--block {
    //   height: 200px;
    //   width: 300px;
      padding: 10px;
      background-color: colors.get-color(colors.$cool_grey, level_2);
      border-radius: 10px;
      margin-bottom: 20px;

      .storybook-button {
        display: block;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &__table {
    border: 1px solid var(--border-color);
    height: 100%;

    &--cell {
      @include table();
    }
  }
}
