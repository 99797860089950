@use "../../../../scss/common/mixins" as *;
@use "../../../../scss/common/fonts" as fonts;
@use "../../../../scss/common/colors" as colors;
.change-of-dept {
  height: calc(100% - 210px);
  margin: 0px 20px 0px 20px;

  &__details {
    height: 120px;
    gap: 20px;

    .data-fetch-icon {
      margin-top: 10px;
    }

    &--imagefield {
      display: flex;
    }

    .MuiAutocomplete-root {
      margin-top: 10px;
    }

    &--modal {
      column-gap: 20px;
    }

    &--textfield {
      width: 100%;
      margin: 10px 10px 0px 0px !important;
      @include textfield();
    }

    &--studentprofile {
      margin-top: 10px;
      @include studentProfile();
    }
  }

  &__tableblock {
    margin-top: 10px;
    height: calc(100% - 130px);
    gap: 15px;

    &--modal {
      margin-top: 5px;
      height: calc(100% - 315px);
      gap: 15px;
    }

    &--table {
      @include table();

      &--groupheader {
        @include groupHeader();
      }

      &--subheader {
        @include subHeader();
      }

      &--amount {
        font-family: fonts.$eduate-number-font;
        width: calc(100% - 80%);
      }

      &--th-amount {
        width: calc(100% - 80%);
      }

      &--th-slno {
        width: calc(100% - 90%);
      }

      &--slno {
        font-family: fonts.$eduate-number-font;
        width: calc(100% - 90%);
      }
    }
  }

  &__view {
    height: calc(100% - 125px);

    &--button {
      display: flex !important;
      justify-content: flex-end !important;
      margin-left: auto;
      margin-right: 0px;
      margin-top: 0px !important;
    }

    tr:hover {
      .change-of-dept__view--table--name {
        @include tdContainsOnclick();
      }
    }

    &--table {
      @include table();

      &--slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-slno {
        width: calc(100% - 96%);
        font-family: fonts.$eduate-number-font;
      }

      &--admno {
        width: calc(100% - 93%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-admno {
        width: calc(100% - 93%);
      }

      &--regno {
        width: calc(100% - 94%);
        font-family: fonts.$eduate-number-font;
      }

      &--th-regno {
        width: calc(100% - 94%);
      }

      &--desc {
        width: calc(100% - 92%);
      }
    }
  }

  &__receipt-details {
    height: 100%;

    &--data {
      height: calc(100% - 75px);

      ul {
        height: calc(100% - 60px);
        border-bottom: 1px dashed var(--border-color);
        overflow: auto;

        li {
          display: flex;
          justify-content: space-between;
          padding: 5px;
        }
      }

      &--receipts {
        font-size:fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-number-font;
        display: block;
      }

      &--amount {
        font-family: fonts.$eduate-number-font;
        font-size:fonts.$eduate-default-font-size;
        font-weight: 700;
        color: colors.get-color(colors.$Green, level_6);
        display: block;
      }

      &--balance {
        text-align: right;
      }

      &--new-demand {
        text-align: right;
        font-size:fonts.$eduate-default-font-size;
        font-family: fonts.$eduate-font-family;
        display: block;
      }

      &--demand-balance {
        color: colors.get-color(colors.$red, level_7);
        font-weight: 700;
        font-family: fonts.$eduate-number-font;
        display: block;
      }
    }
  }

  &__total {
    text-align: right;
  }
}

.change-of-dept__receipt-details--data ul::-webkit-scrollbar {
  @include scrollbar();
}

.change-of-dept__receipt-details--data ul::-webkit-scrollbar-thumb {
  @include scrollbarthumb();
}

.change-of-dept__receipt-details--data ul::-webkit-scrollbar-thumb:hover {
  @include scrollbarthumb-hover();
}

.initiate-receipt-transfer-image {
  filter: invert(100%) !important;
  width: 25px;
  height: 25px;
}