.update-agents{
margin:0px 20px;
height: calc(100% - 165px);
&__form{
    height: calc(100% - 45px);
    overflow: auto;
    
    &--data{
        column-count: 2;
        column-gap: 60px;
    }
}

}


